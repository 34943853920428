import React, {useEffect, useState} from 'react';
import {CircularProgress, Paper, TextField} from "@mui/material";
import {Button, Table} from "react-bootstrap";
import MenuBalancesTable from "./MenuBalancesTable";
import {useNavigate} from "react-router";
import {TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {money_format} from "./functions/numberFormat";
import {BiDownload} from "react-icons/bi";

const DigitalMenuRegisterTransactions = (props) => {

    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const [isLoaded, setIsLoaded] = useState(false);
    const [transactions, setTransactions] = useState([]);

    const fetchData = async () => {
        setIsLoaded(false);
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-register-transactions?register_id=${props.balance}&menu_id=${props.menu}&event_id=${props.event}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }});

        const response = await request.json();

        if (response.status === "success") {
            setTransactions(response.data);
        }
        setIsLoaded(true);
    }

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [token]);

    let balance = 0;

    return (
        <>
            {!isLoaded ? (
                <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                    <CircularProgress color="secondary" size={100} />
                </div>
            ) : (
                <div>
                    <div className="row mt-2 justify-content-between">
                        <div className="col-6">
                            <h3 className="mb-1">Transacciones</h3>
                            <p className="mb-0">{transactions[0]?.user_first_name} {transactions[0]?.user_last_name}</p>
                            {transactions[0]?.table_name ? (
                                <p>{transactions[0]?.tables}</p>
                            ) : null}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            {transactions?.length > 0 ? (
                                <TableContainer component={Paper}
                                                style={{maxHeight: 'calc(100vh - 60px)', overflowY: 'auto'}}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{fontWeight: 'bold'}}>Fecha</TableCell>
                                                <TableCell sx={{fontWeight: 'bold'}}>Concepto</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold' }}>Monto</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold' }}>Saldo</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold' }}>Método de pago</TableCell>
                                                <TableCell align={"center"} sx={{ fontWeight: 'bold' }}>Comprobante</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {transactions.map((transaction, index) => {
                                                const amount = parseFloat(transaction.amount);

                                                if (transaction.transaction_type === "adjustment") {
                                                    balance = amount;
                                                } else if (["withdrawal", "refund"].includes(transaction.transaction_type) && transaction.payment_method === "Efectivo") {
                                                    balance -= amount;
                                                } else if (["credit", "payment"].includes(transaction.transaction_type) && transaction.payment_method === "Efectivo") {
                                                    balance += amount;
                                                }

                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell>{transaction.created_at}</TableCell>
                                                        <TableCell>{transaction.transaction_type}</TableCell>
                                                        <TableCell>{money_format(transaction.amount)}</TableCell>
                                                        <TableCell>{money_format(balance)}</TableCell>
                                                        <TableCell>{transaction.payment_method}</TableCell>
                                                        <TableCell align={"center"}>
                                                            <a href={transaction?.downloadUrl} target="_blank"
                                                               rel="noreferrer">
                                                                <BiDownload/>
                                                            </a>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-info" role="alert">
                                            No hay información de transacciones.
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DigitalMenuRegisterTransactions;