import React, {useRef, useState} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Menu, MenuItem } from '@material-ui/core';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useNavigate } from 'react-router';
import DropdownMenu from "./DropdownMenu";

const columns = [
    { id: 'id', label: 'Codigo', maxWidth: 10, minWidth: 0 },
    { id: 'name', label: 'Nombre', minWidth: 170 },
    {
        id: 'actions',
        label: 'Acciones',
        minWidth: 170,
        align: 'right',
    },
];

export default function StickyHeadTable(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const navigate = useNavigate();

    const menuButtonRef = useRef(null);


    const handleClick = (event, row) => {
        setAnchorEl(menuButtonRef.current);
        setSelectedRow(row);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <TableContainer sx={{ maxHeight: "75vh" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.rows.map((row) => (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                            {column.id === 'actions' ? (
                                                <>
                                                    <div>
                                                        <Button className={"me-2"} variant="text" color="default" onClick={() => navigate(`./${row.id}/info`)}>Ver Saldos</Button>
                                                    </div>
                                                </>
                                            ) : column.format && typeof value === 'number' ? (
                                                column.format(value)
                                            ) : (
                                                value
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}