import React, {useEffect, useState} from 'react';
import {Nav, Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import './EventSidebar.css';
import {IconContext} from "react-icons";
import {EventSidebarData, EventSidebarDataAdmin, EventSidebarDataAdminAux} from "./EventSidebarData";
import {AiOutlineMenu} from "react-icons/ai";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {selectEventChange, setEventChange} from "../redux/eventsChangeSlice";
import {useNavigate} from "react-router";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button} from "@mui/material";

const EventSidebar = (props) => {

    const user = useSelector(selectUser);
    const eventChange = useSelector(selectEventChange);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [active, setActive] = useState(false);
    const [status, setStatus] = useState(null);

    const [permissions, setPermissions] = useState(null);

    const [redirection, setRedirection] = useState(null);

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleOpenConfirm = () => setOpenConfirm(true);
    const handleCloseConfirm = () => setOpenConfirm(false);

    const fetchStatus = async () => {
        if (user.token === "") return;
        const response = await fetch(`${process.env.REACT_APP_API_URL}/event-status?event_id=${props.event}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });

        const json = await response.json();

        setStatus(json.event_active);
    }

    const fetchPermisions = async () => {
        if (user.token === "") return;
        const response = await fetch(`${process.env.REACT_APP_API_URL}/event-permissions?event_id=${props.event}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });

        const json = await response.json();
        //console.log(json)

        if (json.status === "success") {
            setPermissions(json?.events?.validator);
        }
    }

    const handleClick = (e, link, replace) => {
        e.preventDefault();

        setActive(!active);

        if ((currentUrl() === "Información" || currentUrl() === "Ubicación") && eventChange.eventChange) {
            handleOpenConfirm();
        }
        else {
            navigate(link, {replace: replace});
        }

        setRedirection({
            link: link,
            replace: replace
        });
    }

    const currentUrl = () => {
        const pathname = window.location.pathname;
        switch (pathname.split("/")[4]) {
            case "info":
                return "Información";
            case "location":
                return "Ubicación";
            case "tickets":
                return "Tickets";
            case "team":
                return "Colaboradores";
            case "attendees":
                return "Lista Digital";
            case "analytics":
                return "Estadísticas";
            case "link-tickets":
                return "Enviar Tickets";
            case "single-link":
                return "Link único uso";
            case "publish":
                return "Publicar evento";
            default:
                return "Información";
        }
    }

    useEffect(() => {
        fetchStatus();
        fetchPermisions();
    }, [props.event, user.token]);

    return (
        <>
            <div className="event-sidebar-mobile d-block d-lg-none bg-light">
                <Container>
                    <div className="row align-items-start justify-content-center">
                        <div className="col-2 mt-1">
                            <Button size={"sm"} variant="link" className="ms-2" onClick={() => setActive(!active)}>
                                <AiOutlineMenu size={28} color={"black"} />
                            </Button>
                            {active ? (
                            <Nav className="event-nav-menu-mobile active">
                                <ul className="event-nav-menu-items">
                                    {permissions === 0 && [1,2,4].includes(status) && EventSidebarData.map((item, index) => {
                                    if (item.title !== "Publicar" && item.title !== "Enviar Tickets") {
                                        return (
                                            <li key={index} className={item.cNameMobile}>
                                                <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} replace={true} to={`/organizations/events/${props.event}/${item.path}`}>
                                                    {item.icon}
                                                    <span className="ms-3">{item.title}</span>
                                                </Link>
                                            </li>
                                        );
                                        }
                                    else if (item.title === "Publicar" && status === 4) {
                                            return (
                                                <li key={index} className={item.cNameMobile}>
                                                    <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} replace={true} to={`/organizations/events/${props.event}/${item.path}`}>
                                                        {item.icon}
                                                        <span className="ms-3">{item.title}</span>
                                                    </Link>
                                                </li>
                                            );
                                        }
                                    else if (item.title === "Publicar" && status === 1) {
                                        return (
                                            <li key={index} className={item.cNameMobile}>
                                                <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} replace={true} to={`/organizations/events/${props.event}/${item.path}`}>
                                                    {item.icon}
                                                    <span className="ms-3">Visibilidad</span>
                                                </Link>
                                            </li>
                                        );
                                    }
                                    })}
                                    {permissions === 0 && [1].includes(status) && (
                                        <>
                                            <hr className="ms-3 mt-2 mb-2" />
                                            {EventSidebarDataAdminAux.map((item, index) => {
                                                return(
                                                    <li key={index} className={item.cNameMobile}>
                                                        <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} replace={true} to={`/organizations/events/${props.event}/${item.path}`}>
                                                            {item.icon}
                                                            <span className="ms-3">{item.title}</span>
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </>

                                    )}
                                    {permissions === 0 && [1].includes(status) && <hr className="ms-3 mt-2 mb-2" />}
                                    {EventSidebarDataAdmin.map((item, index) => {
                                    if (item.title !== "Publicar" && item.title !== "Enviar Tickets" && [0,1].includes(status)) {
                                        return (
                                            <li key={index} className={item.cNameMobile}>
                                                <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} replace={true} to={`/organizations/events/${props.event}/${item.path}`}>
                                                    {item.icon}
                                                    <span className="ms-3">{item.title}</span>
                                                </Link>
                                            </li>
                                        );
                                    }
                                    else if (item.title === "Enviar Tickets" && status === 1) {
                                        return (
                                            <li key={index} className={item.cNameMobile}>
                                                <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} replace={true} to={`/organizations/events/${props.event}/${item.path}`}>
                                                    {item.icon}
                                                    <span className="ms-3">{item.title}</span>
                                                </Link>
                                            </li>
                                        );
                                    }
                                    })}
                                </ul>
                            </Nav>
                            ) : (
                            <Nav className="event-nav-menu-mobile">
                                <ul className="event-nav-menu-items">
                                    {permissions === 0 && [1,2,4].includes(status) && EventSidebarData.map((item, index) => {
                                        if (item.title !== "Publicar" && item.title !== "Enviar Tickets") {
                                        return (
                                            <li key={index} className={item.cNameMobile}>
                                                <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} to={`/organizations/events/${props.event}/${item.path}`}>
                                                    {item.icon}
                                                    <span className="ms-3">{item.title}</span>
                                                </Link>
                                            </li>
                                        );
                                        }
                                        else if (item.title === "Publicar" && status === 4) {
                                            return (
                                                <li key={index} className={item.cNameMobile}>
                                                    <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} replace={true} to={`/organizations/events/${props.event}/${item.path}`}>
                                                        {item.icon}
                                                        <span>{item.title}</span>
                                                    </Link>
                                                </li>
                                            );
                                        }
                                        else if (item.title === "Publicar" && status === 1) {
                                            return (
                                                <li key={index} className={item.cNameMobile}>
                                                    <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} replace={true} to={`/organizations/events/${props.event}/${item.path}`}>
                                                        {item.icon}
                                                        <span className="ms-3">Visibilidad</span>
                                                    </Link>
                                                </li>
                                            );
                                        }
                                    })}
                                    {permissions === 0 && [1].includes(status) && (
                                        <>
                                            <hr className="ms-3 mt-2 mb-2" />
                                            {EventSidebarDataAdminAux.map((item, index) => {
                                                return(
                                                    <li key={index} className={item.cNameMobile}>
                                                        <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} replace={true} to={`/organizations/events/${props.event}/${item.path}`}>
                                                            {item.icon}
                                                            <span className="ms-3">{item.title}</span>
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </>

                                    )}
                                    {permissions === 0 && [1].includes(status) && <hr className="ms-3 mt-2 mb-2" />}
                                    {EventSidebarDataAdmin.map((item, index) => {
                                        if (item.title !== "Publicar" && item.title !== "Enviar Tickets" && [0,1].includes(status)) {
                                            return (
                                                <li key={index} className={item.cNameMobile}>
                                                    <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} replace={true} to={`/organizations/events/${props.event}/${item.path}`}>
                                                        {item.icon}
                                                        <span className="ms-3">{item.title}</span>
                                                    </Link>
                                                </li>
                                            );
                                        }
                                        else if (item.title === "Enviar Tickets" && status === 1) {
                                            return (
                                                <li key={index} className={item.cNameMobile}>
                                                    <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} replace={true} to={`/organizations/events/${props.event}/${item.path}`}>
                                                        {item.icon}
                                                        <span className="ms-3">{item.title}</span>
                                                    </Link>
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                            </Nav>
                            )}
                        </div>
                        <div className="col">
                            <span style={{fontSize: "32px"}}>{currentUrl()}</span>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="d-none d-lg-block event-navbar">
                <IconContext.Provider value={{ color: '#000' }}>
                    <Nav className="event-nav-menu ps-0 p-2">
                        <ul className="event-nav-menu-items">
                            {permissions === 0 && [1,2,4].includes(status) && EventSidebarData.map((item, index) => {
                                if (item.title !== "Publicar" && item.title !== "Enviar Tickets") {
                                return (
                                    <li key={index} className={item.cName}>
                                        <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} replace={true} to={`/organizations/events/${props.event}/${item.path}`}>
                                            {item.icon}
                                            <span>{item.title}</span>
                                        </Link>
                                    </li>
                                );
                                }
                                else if (item.title === "Publicar" && status === 4) {
                                    return (
                                        <li key={index} className={item.cName}>
                                            <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} replace={true} to={`/organizations/events/${props.event}/${item.path}`}>
                                                {item.icon}
                                                <span>{item.title}</span>
                                            </Link>
                                        </li>
                                    );
                                }
                                else if (item.title === "Publicar" && status === 1) {
                                    return (
                                        <li key={index} className={item.cNameMobile}>
                                            <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} replace={true} to={`/organizations/events/${props.event}/${item.path}`}>
                                                {item.icon}
                                                <span className="ms-3">Visibilidad</span>
                                            </Link>
                                        </li>
                                    );
                                }
                            })}
                            {permissions === 0 && [1].includes(status) && (
                                <>
                                    <hr className="ms-3 mt-2 mb-2" />
                                    {EventSidebarDataAdminAux.map((item, index) => {
                                        return(
                                            <li key={index} className={item.cName}>
                                                <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} replace={true} to={`/organizations/events/${props.event}/${item.path}`}>
                                                    {item.icon}
                                                    <span>{item.title}</span>
                                                </Link>
                                            </li>
                                            );
                                    })}
                                </>

                            )}
                            {permissions === 0 && [1].includes(status) && <hr className="ms-3 mt-2 mb-2" />}
                            {EventSidebarDataAdmin.map((item, index) => {
                                if (item.title !== "Publicar" && item.title !== "Enviar Tickets" && [0,1].includes(status)) {
                                    return (
                                        <li key={index} className={item.cNameMobile}>
                                            <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} replace={true} to={`/organizations/events/${props.event}/${item.path}`}>
                                                {item.icon}
                                                <span className="ms-3">{item.title}</span>
                                            </Link>
                                        </li>
                                    );
                                }
                                else if (item.title === "Enviar Tickets" && status === 1) {
                                    return (
                                        <li key={index} className={item.cNameMobile}>
                                            <Link onClick={(e) => handleClick(e, `/organizations/events/${props.event}/${item.path}`, true)} replace={true} to={`/organizations/events/${props.event}/${item.path}`}>
                                                {item.icon}
                                                <span className="ms-3">{item.title}</span>
                                            </Link>
                                        </li>
                                    );
                                }
                            })}
                        </ul>
                    </Nav>
                </IconContext.Provider>
            </div>
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"¿Estás seguro de que quieres salir?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Si sales de esta página, perderás los cambios que no hayas guardado.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus className="me-2" onClick={handleCloseConfirm}>
                        Cancelar
                    </Button>
                    <Button onClick={() => {
                        navigate(redirection.link, {replace: redirection.replace});
                        dispatch(setEventChange(false));
                        handleCloseConfirm();
                    }} autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EventSidebar;
