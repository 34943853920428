import React, {useEffect} from 'react';
import {Button, Image} from "react-bootstrap";
import {BsTrash, BsPlusLg} from "react-icons/bs";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import {addItem, removeItem, substractItem} from "../redux/carroSlice";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import {number_format} from "./functions/numberFormat";
import {Skeleton} from "@mui/lab";

const CarroItems = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const addToCart = () => {
        dispatch(addItem({
            item: {
                id: props.item.id,
                price: +calculateUnitPrice(),
                variants: props.variants,
                observations: props.observations,
                max_per_order: props.item.max_units_per_order
            }
        }));
    }

    const substractFromCart = () => {
        dispatch(substractItem({
            item: {
                id: props.item.id,
                price: +calculateUnitPrice(),
                variants: props.variants,
                observations: props.observations,
                max_per_order: props.item.max_units_per_order
            }
        }));
    }

    const removeFromCart = () => {
        dispatch(removeItem({
            item: {
                id: props.item.id,
                price: +calculateUnitPrice(),
                variants: props.variants,
                observations: props.observations,
                max_per_order: props.item.max_units_per_order
            }
        }));
    }

    function calculateTotalPrice() {
        const basePrice = parseFloat(props.item?.price || 0) * props?.ammount;

        const variantsPrice = props.item?.variant_categories?.reduce((acc, curr) => {
            const selectedVariantId = props?.variants?.find(variante => variante?.category_id === curr?.id)?.value;
            const variant = curr?.variants?.find(v => v?.id === selectedVariantId);
            return acc + (parseFloat(variant?.price || 0) * props?.ammount);
        }, 0) || 0;

        const total = basePrice + variantsPrice;

        return total;
    }

    function calculateUnitPrice() {
        const basePrice = parseFloat(props.item?.price || 0);

        const variantsPrice = props.item?.variant_categories?.reduce((acc, curr) => {
            const selectedVariantId = props?.variants?.find(variante => variante?.category_id === curr?.id)?.value;
            const variant = curr?.variants?.find(v => v?.id === selectedVariantId);
            return acc + (parseFloat(variant?.price || 0));
        }, 0) || 0;

        const total = basePrice + variantsPrice;

        return total;
    }

    useEffect(() => {
        //console.log(props.item)
    }, []);

    return (
        <div className="row mt-0 mb-2 m-1 p-1 pe-2" style={{backgroundColor: "white", borderRadius: "8px", boxShadow: "0 3px 5px -2px darkgray"}}>
            {props.item ? (
                props.item.image ? (
                <>
                    <div className="col-3 p-1">
                        <Image src={props?.item?.image} fluid height="100%" />
                    </div>
                    <div className="col-9 pt-1 ps-0 m-0">
                        <div className="row ms-1">
                            <div className="col-12">
                                <p style={{fontSize: "16px", color: "black", margin: 0}}>{props?.item?.title}</p>
                                {props?.variants ? (
                                    <span style={{fontSize: "12px", color: "black"}}>
                                        {props.variants.map(variant => {
                                            // Find the category of this variant
                                            const category = props?.item?.variant_categories?.find(cat => cat.id === variant.category_id);

                                            // Find the actual variant details using the variant.value
                                            const variantDetails = category?.variants?.find(v => v.id === variant.value);

                                            return category && variantDetails ? `${category.title}: ${variantDetails.title}` : '';
                                        }).filter(str => str).join(', ')} {/* This filter removes empty strings */}
                                    </span>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        <div className="row mt-3 ms-1">
                            <div className="col-8">
                                <b>
                                    {props.item.price === 0 ?
                                        <span style={{fontSize: "20px", color: "black"}}>Gratuito</span> :
                                        <span style={{
                                            fontSize: "20px",
                                            color: "black"
                                        }}>$ {number_format(calculateTotalPrice(), 2, ',', '.')}</span>
                                    }
                                </b>
                            </div>
                            <div className="col-4">
                                <div className="row align-items-center menu-item-quantity-selector">
                                    {props.ammount > 1 ? (
                                        <div className="col-4 p-0 m-0 text-center">
                                            <button onClick={() => substractFromCart()} style={{
                                                background: "none",
                                                color: "inherit",
                                                border: "none",
                                                padding: 0,
                                                font: "inherit", cursor: "pointer", outline: "inherit"}}><AiOutlineMinus /></button></div>
                                    ) : (
                                        <div className="col-4 p-0 m-0 text-center"><button onClick={() => removeFromCart()} style={{background: "none", color: "inherit", border: "none", padding: 0, font: "inherit", cursor: "pointer", outline: "inherit"}}><BsTrash style={{marginBottom: "2.4px"}} size={14} /></button></div>
                                    )}
                                    <div className="col-4 p-0 m-0 text-center">{props?.ammount}</div>
                                        <div className="col-4 p-0 m-0 text-center">
                                            <button
                                                onClick={() => addToCart()}
                                                style={{
                                                    background: "none",
                                                    color: "inherit",
                                                    border: "none",
                                                    padding: 0,
                                                    font: "inherit",
                                                    cursor: "pointer",
                                                    outline: "inherit"
                                                }}
                                                disabled={props.ordersInProgress > 0 || props.item.price <= 0 || props?.ammount >= props.item.max_units_per_order}
                                            ><AiOutlinePlus/></button>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                        </>
                                        ) : (
                                        <>
                                        <div className="col-12 pt-1 ps-0 m-0">
                                        <div className="row ms-1">
                                        <div className="col-12">
                                        <p style={{fontSize: "16px", color: "black", margin: 0}}>{props?.item?.title}
                                </p>
                                {props?.variants ? (
                                    <span style={{fontSize: "12px", color: "black"}}>
                                        {props.variants.map(variant => {
                                            // Find the category of this variant
                                            const category = props?.item?.variant_categories?.find(cat => cat.id === variant.category_id);

                                            // Find the actual variant details using the variant.value
                                            const variantDetails = category?.variants?.find(v => v.id === variant.value);

                                            return category && variantDetails ? `${category.title}: ${variantDetails.title}` : '';
                                        }).filter(str => str).join(', ')} {/* This filter removes empty strings */}
                                    </span>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row mt-3 ms-1">
                                        <div className="col-8">
                                            <b>
                                                {props.item.price === 0 ?
                                                    <span style={{ fontSize: "20px", color: "black" }}>Gratuito</span> :
                                                    <span style={{ fontSize: "20px", color: "black" }}>$ {number_format(calculateTotalPrice(), 2, ',', '.')}</span>
                                                }
                                            </b>
                                        </div>
                                        <div className="col-4">
                                            <div className="row align-items-center menu-item-quantity-selector">
                                                {props.ammount > 1 ? (
                                                    <div className="col-4 p-0 m-0 text-center"><button onClick={() => substractFromCart()} style={{background: "none", color: "inherit", border: "none", padding: 0, font: "inherit", cursor: "pointer", outline: "inherit"}}><AiOutlineMinus /></button></div>
                                                ) : (
                                                    <div className="col-4 p-0 m-0 text-center"><button onClick={() => removeFromCart()} style={{background: "none", color: "inherit", border: "none", padding: 0, font: "inherit", cursor: "pointer", outline: "inherit"}}><BsTrash style={{marginBottom: "2.4px"}} size={14} /></button></div>
                                                )}
                                                <div className="col-4 p-0 m-0 text-center">{props?.ammount}</div>
                                                <div className="col-4 p-0 m-0 text-center">
                                                    <button
                                                        onClick={() => addToCart()}
                                                        disabled={props.ordersInProgress > 0 || props.item.price <= 0 || props?.ammount >= props.item.max_units_per_order}
                                                        style={{
                                                            background: "none",
                                                            color: "inherit",
                                                            border: "none",
                                                            padding: 0,
                                                            font: "inherit",
                                                            cursor: "pointer",
                                                            outline: "inherit"
                                                    }}
                                                    >
                                                        <AiOutlinePlus />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
            ) : (
                <>
                    <div className="col-3 p-1">
                        <Skeleton variant="rectangular" width={100} height={100} />
                    </div>
                    <div className="col-9 pt-1 ps-0 m-0">
                        <div className="row ms-1">
                            <div className="col-12">
                                <Skeleton className={"ms-3"} variant="text" width={200} height={20} />
                            </div>
                        </div>
                        <div className="row mt-3 ms-1">
                            <div className="col-8">
                                <Skeleton className={"ms-3"} variant="text" width={100} height={20} />
                            </div>
                            <div className="col-4">
                                <div className="row align-items-center menu-item-quantity-selector">
                                    <Skeleton variant="text" height={20} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default CarroItems;