import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as GoIcons from 'react-icons/go';
import {IoPeopleCircle, IoPersonAddSharp} from "react-icons/io5";
import {MdAnalytics, MdPeopleAlt} from "react-icons/md";
import {AddBusiness} from "@mui/icons-material";
import {MdPersonAddAlt1} from "react-icons/md";
import {FiLink} from "react-icons/fi";

export const EventSidebarData = [
    {
        title: 'Información',
        path: 'info',
        icon: <div className="rounded-circle bg-black d-flex align-items-center justify-content-center m-0" style={{height: 20, width: 20, color: "white", fontSize: 10}}>1</div>,
        cName: 'event-nav-text',
        cNameMobile: 'event-nav-text mobile',
        cNameMobileActive: 'event-nav-text mobile d-block',
    },
    {
        title: 'Ubicación',
        path: 'location',
        icon: <div className="rounded-circle bg-black d-flex align-items-center justify-content-center m-0" style={{height: 20, width: 20, color: "white", fontSize: 10}}>2</div>,
        cName: 'event-nav-text',
        cNameMobile: 'event-nav-text mobile',
        cNameMobileActive: 'event-nav-text mobile d-block',
    },
    {
        title: 'Tickets',
        path: 'tickets',
        icon: <div className="rounded-circle bg-black d-flex align-items-center justify-content-center m-0" style={{height: 20, width: 20, color: "white", fontSize: 10}}>3</div>,
        cName: 'event-nav-text',
        cNameMobile: 'event-nav-text mobile',
        cNameMobileActive: 'event-nav-text mobile d-block',
    },
    {
        title: 'Publicar',
        path: 'publish',
        icon: <div className="rounded-circle bg-black d-flex align-items-center justify-content-center m-0" style={{height: 20, width: 20, color: "white", fontSize: 10}}>4</div>,
        cName: 'event-nav-text',
        cNameMobile: 'event-nav-text mobile',
        cNameMobileActive: 'event-nav-text mobile d-block',
    },
];

export const EventSidebarDataAdmin = [
    {
        title: 'Lista Digital',
        path: 'attendees',
        icon: <IoPeopleCircle />,
        cName: 'event-nav-text',
        cNameMobile: 'event-nav-text mobile',
        cNameMobileActive: 'event-nav-text mobile d-block',
    },
    {
        title: 'Estadísticas',
        path: 'analytics',
        icon: <MdAnalytics />,
        cName: 'event-nav-text',
        cNameMobile: 'event-nav-text mobile',
        cNameMobileActive: 'event-nav-text mobile d-block',
    },
    {
        title: 'Enviar Tickets',
        path: 'link-tickets',
        icon: <IoPersonAddSharp />,
        cName: 'event-nav-text',
        cNameMobile: 'event-nav-text mobile',
        cNameMobileActive: 'event-nav-text mobile d-block',
    },
    {
        title: 'Links únicos',
        path: 'single-link',
        icon: <FiLink />,
        cName: 'event-nav-text',
        cNameMobile: 'event-nav-text mobile',
        cNameMobileActive: 'event-nav-text mobile d-block',
    },
];

export const EventSidebarDataAdminAux = [
    {
        title: 'Colaboradores',
        path: 'team',
        icon: <MdPeopleAlt />,
        cName: 'event-nav-text',
        cNameMobile: 'event-nav-text mobile',
        cNameMobileActive: 'event-nav-text mobile d-block',
    },
];