 import React, {useCallback, useState} from 'react';
import DoughnutChart from "./DoughnutChart";
import {Container, Table} from "react-bootstrap";
import BasicTable from "./BasicTable";
import {CircularProgress, Paper} from "@mui/material";
import './KPI.css';
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import BestSalesTimeChart from "./BestSalesTimeChart";
import BarChart from "./BarChart";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DateFilter from "./DateFilter";
 import EmailsSentAndRevenueChart from "./EmailsSentAndRevenueChart";
 import OpenRateAndCTRChart from "./OpenRateAndCTRChart";

const EventAnalytics = (props) => {

    const user = useSelector(selectUser)
    const tempDevShowGraphs = true;

    const [analyticsData, setAnalyticsData] = React.useState({});
    const [dailyData, setDailyData] = React.useState([]);
    const [dateRange, setDateRange] = useState({ fromDate: '', toDate: '' });

    const handleDateRangeChange = useCallback((newDateRange) => {
        setDateRange(newDateRange);
    }, []);

    const filteredData = dailyData.filter(
        (d) => d.date >= dateRange.fromDate && d.date <= dateRange.toDate
    );

    const fetchData = async () => {
        const timezoneOffset = new Date().getTimezoneOffset();

        const response = await fetch(`${process.env.REACT_APP_API_URL}/campaign-analytics?campaign=${props.campaign}&timezoneOffset=${timezoneOffset}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();

        //console.log(json);
        setAnalyticsData(json.email_stats);
        setDailyData(json.stats_daily);
        setIsLoaded(true);
    }

    function number_format (number, decimals, dec_point, thousands_sep) {
        // Strip all characters but numerical ones.
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token, props.campaign]);

    React.useEffect(() => {
        if (dailyData && dailyData.length > 0) {
            const minDate = dailyData.reduce((min, current) =>
                current.date < min.date ? current : min
            ).date;
            const maxDate = dailyData.reduce((max, current) =>
                current.date > max.date ? current : max
            ).date;

            setDateRange({ fromDate: minDate, toDate: maxDate });
        }
    }, [dailyData]);

    const minDate = dailyData.length
        ? dailyData.reduce((min, current) => (current.date < min.date ? current : min)).date
        : new Date().toISOString().split("T")[0];

    const maxDate = dailyData.length
        ? dailyData.reduce((max, current) => (current.date > max.date ? current : max)).date
        : new Date().toISOString().split("T")[0];


    const [isLoaded, setIsLoaded] = useState(false);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <div className="row mt-2">
                <div className="col">
                    <h2>Analytics</h2>
                </div>
            </div>

            <div className="row mt-3 align-items-center">
                <div className="col-md-3 mt-3 mt-md-0">
                    <div className="p-2" style={{boxShadow: "0 0 0.5rem #ccc", backgroundColor: "whitesmoke", borderRadius: "1rem", fontWeight: "bold"}}>
                        <div className="row">
                            <div className="col-12">
                                <h5 className="text-center">Enviados</h5>
                                <p style={{fontSize: "1.25rem"}} className="text-center p-0 m-0">{number_format(analyticsData?.sent,0,',','.')} emails</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 mt-3 mt-md-0">
                    <div className="p-2" style={{boxShadow: "0 0 0.5rem #ccc", backgroundColor: "whitesmoke", borderRadius: "1rem", fontWeight: "bold"}}>
                        <div className="row">
                            <div className="col-12">
                                <h5 className="text-center">Open Rate</h5>
                                <p style={{fontSize: "1.25rem"}} className="text-center p-0 m-0">{number_format(analyticsData?.open_rate, 0, 0, ',', '.')}%</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 mt-3 mt-md-0">
                    <div className="p-2" style={{boxShadow: "0 0 0.5rem #ccc", backgroundColor: "whitesmoke", borderRadius: "1rem", fontWeight: "bold"}}>
                        <div className="row">
                            <div className="col-12">
                                <h5 className="text-center">CTR</h5>
                                <p style={{fontSize: "1.25rem"}} className="text-center p-0 m-0">{number_format(analyticsData?.click_rate, 0, 0, ',', '.')}%</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 mt-3 mt-md-0">
                    <div className="p-2" style={{boxShadow: "0 0 0.5rem #ccc", backgroundColor: "whitesmoke", borderRadius: "1rem", fontWeight: "bold"}}>
                        <div className="row">
                            <div className="col-12">
                                <h5 className="text-center">Ingresos directos</h5>
                                <p style={{fontSize: "1.25rem"}} className="text-center p-0 m-0">$ {number_format(analyticsData?.revenue,0,',','.')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {tempDevShowGraphs && (
                <>
                    <div className="row mt-5">
                        <div className="col">
                            {dailyData.length > 0 && (
                                <div className="date-filter-wrapper" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                    <DateFilter
                                        fromDate={dateRange.fromDate}
                                        toDate={dateRange.toDate}
                                        onDateRangeChange={handleDateRangeChange}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <EmailsSentAndRevenueChart data={filteredData} />
                        </div>
                        <div className="col-md-6">
                            <OpenRateAndCTRChart data={filteredData} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
    }
};

export default EventAnalytics;