import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

const DialogConfirm = (props) => {
    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleCloseConfirm}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus className="me-2" onClick={props.handleCloseConfirm}>
                        Cancelar
                    </Button>
                    <Button variant="contained" color="error" onClick={props.click} autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DialogConfirm;