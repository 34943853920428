import React from 'react'
import { Bar } from 'react-chartjs-2'

const BarChartTicketGender = ({data}) => {

    const labels = data?.map(item => item.name);
    const values = data?.map(item => item.value);

    const total = values.reduce((a, b) => a + b, 0);
    const percentages = values.map(value => Math.round((value / total) * 100));

    const colorMap = {
        'Hombres': 'rgba(70, 130, 180, 1)',  // light blue
        'Mujeres': 'rgba(255, 85, 255, 1)',  // existing pink color
        'Todos': 'rgba(75, 0, 130, 1)',      // indigo
        'Otros': 'rgba(127, 255, 0, 1)',     // chartreuse
    };

    const backgroundColors = labels.map(label => colorMap[label]);

    return (
        <div className="p-3" style={{position: "relative", borderRadius: ".5rem", boxShadow: "0 0 0.5rem #ccc", height: '100%'}}>
            <Bar
                data={{
                    labels: labels,
                    datasets: [{
                        label: 'Ticket Gender',
                        data: values,
                        backgroundColor: backgroundColors,
                        borderWidth: 1,
                    }]
                }}
                options={{
                    maintainAspectRatio: false, // ensures chart takes up full height
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    let index = context.dataIndex;
                                    let label = labels[index] || '';
                                    let value = values[index];
                                    let percentage = percentages[index];
                                    if (label) {
                                        label += ': ';
                                    }
                                    label += `${value} (${percentage}%)`;
                                    return label;
                                }
                            }
                        },
                        legend: {
                            display: false,
                            align: 'center',
                            position: 'bottom',
                        },
                    },
                }}
            />
        </div>
    )
}

export default BarChartTicketGender;