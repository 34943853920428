import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import BurguerButton from './BurguerButton'
import {Link} from "react-router-dom";
import logo from '../files/logo.png';
import {SidebarData} from "./SidebarData";
import {useDispatch, useSelector} from "react-redux";
import {selectUser, setMktPermission, setType} from "../redux/userSlice";
import {Button as ButtonBT} from "react-bootstrap";

function Navbar() {

    const [clicked, setClicked] = useState(false)
    const handleClick = () => {
        //cuando esta true lo pasa a false y vice versa
        setClicked(!clicked)
    }

    const changePosition = () => {
        if (window.scrollY > 0) {
            document.getElementById("navbar").style.position = "fixed";
        }
        else {
            document.getElementById("navbar").style.position = "absolute";
        }
    }

    const closeNav = () => {
        setClicked(false);
    }

    useEffect(() => {
        // adding the event when scroll change Logo
        window.addEventListener("scroll", changePosition);
        //add event_listener for width screen
        window.addEventListener("resize", closeNav);

        return () => {
            window.removeEventListener("scroll", changePosition);
        }
    }, []);

    useEffect(() => {
        if (clicked) {
            document.getElementById("navbar").style.position = "static";
            document.getElementById("colSide").style.paddingTop = "0px";
        }
        else {
            document.getElementById("navbar").style.position = "absolute";
            document.getElementById("colSide").style.paddingTop = "60px";
        }
    }, [clicked]);


    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    const [sideData, setSideData] = React.useState([]);

    const fetchData = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/check-user-type`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
                'credentials': 'same-origin',
            }
        });

        const json = await request.json();

        dispatch(setType(json?.user_type));

        const request2 = await fetch(`${process.env.REACT_APP_API_URL}/producer_permissions`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
                'credentials': 'same-origin',
            }
        });

        const json2 = await request2.json();
        dispatch(setMktPermission(json2?.mkt_permission));
    }

    useEffect(() => {
        if (user.token) {
            const requests = setTimeout(() => {
                fetchData();
            }, 300);
            return () => clearTimeout(requests);
        }
    }, [user.token]);

    const sideBar = () => {
        if (user.type === 1 && user.mktPermission) {
            setSideData(SidebarData);
        }
        else if (user.type === 1 && !user.mktPermission) {
            setSideData(SidebarData.filter(item => item.mktPermission === false));
        }
        else if (user.tableAdminPermission && (user.type === 2 || user.type === 3)) {
            setSideData(SidebarData.filter(item => item.tableAdminPermission === true || item.producer === false));
        }
        else if (user.type === 2 || user.type === 3) {
            setSideData(SidebarData.filter(item => item.producer === false));
        }
    }

    useEffect(() => {
        sideBar();
    }, [user.type, user.mktPermission]);

    useEffect(() => {
        if (clicked) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    }, [clicked]);



    return (
        <>
            <NavContainer id="navbar" style={{position: "absolute", left: 0, right: 0, top: 0, zIndex: 1000, height: "60px"}}>
                <Link to={'/'}>
                    <h2><img src={"/logo512.png"} height={40} /> <span>FlashPass</span></h2>
                </Link>
                <div className={`links ${clicked ? 'active' : ''}`}>
                    {sideData.map((item, index) => {
                            return (
                                <Link key={index} onClick={handleClick} to={item.path} className="d-sm-none d-block">{item.title}</Link>
                            );
                        }
                    )}
                </div>
                <div className='burguer'>
                    <BurguerButton clicked={clicked} handleClick={handleClick} />
                </div>
                <BgDiv className={`initial ${clicked ? ' active' : ''}`}></BgDiv>
            </NavContainer>
        </>
    )
}

export default Navbar

const NavContainer = styled.nav`
  h2{
    color: white;
    font-weight: 400;
    span{
      font-weight: bold;
      margin-left: 5px;
    }
  }
  padding: .4rem;
  background-color: #222;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a{
    color: white;
    text-decoration: none;
    margin-right: 1rem;
  }
  .links{
    position: absolute;
    top: -700px;
    left: -2000px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 1001;
    overflow-y: hidden; // Ensures that scrolling is disabled when the menu is not active
    max-height: 0; // Start with a max-height of 0
    transition: max-height 0.5s ease, left 0.5s ease; // Add transition for max-height
    a{
      color: white;
      font-size: 10px;
      display: block;
    }
    @media(min-width: 768px){
      position: initial;
      margin: 0;
      a{
        font-size: 0.7rem;
        color: white;
        display: inline;
      }
      display: block;
    }
  }
  .links.active{
    width: 100%;
    display: block;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 5%;
    left: 0;
    right: 0;
    text-align: center;
    overflow-y: auto; // Enable vertical scrolling
    max-height: 98vh; // Adjust this value based on your needs and testing
    padding-bottom: 50px;
    a{
      font-size: 2rem;
      margin-top: 1rem;
      color: white;
    }
  }
  .burguer{
    @media(min-width: 768px){
      display: none;
    }
  }
  .no-scroll {
    overflow: hidden;
  }
`

const BgDiv = styled.div`
  background-color: #222;
  transition: left .6s ease ;
  position: absolute;
  top: -1000px;
  left: -1000px;
  width: 100%;
  height: 100%;
  z-index: 100;
  
  &.active{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`