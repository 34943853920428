import React, {useEffect, useState} from 'react';
import {Avatar, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import './Profile.css';
import {ImageUpload} from "./ImageUpload";
import ProfilePreview from "./ProfilePreview";
import EventsSwiper from "./EventsSwiper";
import AlertSnackbar from "./AlertSnackbar";
import {useDispatch, useSelector} from "react-redux";
import {selectUser, setType} from "../redux/userSlice";

const Profile = (props) => {

    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    const [values, setValues] = useState({
        name: '',
        description: '',
        userType: '1',
        category: '',
        profilePic: '',
        banner: '/assets/images/Flashpass-Banner-Profile.jpg',
        whatsapp: '',
        instagram: '',
        facebook: '',
        email: '',
        verified: false,
    });

    const [errors, setErrors] = useState({
        name: false,
        description: false,
        userType: false,
        category: false,
        profilePic: false,
        banner: false,
        whatsapp: false,
        instagram: false,
        facebook: false,
        email: false,
    });

    const [errorMessages, setErrorMessages] = useState({
        name: '',
        description: '',
        userType: '',
        category: '',
        profilePic: '',
        banner: '',
        whatsapp: '',
        instagram: '',
        facebook: '',
        email: '',
    });

    const inputValidation = (e, prop) => {
        let value = e.target.value;
        let error = false;
        let errorMessage = '';

        switch (prop) {
            case 'email':
                if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                    error = true;
                    errorMessage = 'El email no es válido';
                }
                break;
            case 'whatsapp':
                if (!value.startsWith('https://wa.me/message/') && !value.startsWith('https://api.whatsapp.com/send?phone=')) {
                    error = true;
                    errorMessage = 'La URL de WhatsApp debe ser una dirección válida: https://wa.me/message/ o https://api.whatsapp.com/send?phone=';
                }
                break;
            case 'instagram':
                if (!value.startsWith('https://www.instagram.com/')) {
                    error = true;
                    errorMessage = 'La URL de Instagram debe ser una dirección válida: https://www.instagram.com/usuario';
                }
                break;
            case 'facebook':
                if (!value.startsWith('https://www.facebook.com/')) {
                    error = true;
                    errorMessage = 'La URL de Facebook debe ser una dirección válida: https://www.facebook.com/usuario';
                }
        }
        if (!value) {
            error = false;
            errorMessage = '';
        }
        setErrors({...errors, [prop]: error});
        setErrorMessages({...errorMessages, [prop]: errorMessage});
    }

    const [roles, setRoles] = useState([]);

    const fetchData = async () => {

        const requestProfile = await fetch(`${process.env.REACT_APP_API_URL}/profile`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });

        const profile = await requestProfile.json();

        if (profile?.profile?.banner === "https://flashpass.com.ar/profiles/banner/") {
            setValues({...values, ...profile.profile, banner: '/assets/images/Flashpass-Banner-Profile.jpg'});
        } else {
            setValues({...values, ...profile.profile});
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-types`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();
        setRoles(json.roles.filter(role => role.id !== "2"));
        setIsLoaded(true);
    }

    const [isLoaded, setIsLoaded] = useState(false);

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);;

    /* Snackbars */

    const [openSnackOk, setOpenSnackOk] = useState(false);
    const [openSnackError, setOpenSnackError] = useState(false);

    const handleCloseOk = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackOk(false);
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackError(false);
    }

    /* Snackbars */

    const handleChange = (prop) => (event) => {
            setValues({ ...values, [prop]: event.target.value });
            inputValidation(event, prop);
            //console.log(values);
    };

    const changeProfilePic = (image) => {
        setValues({...values, profilePic: image});
    }

    const changeBanner = (image) => {
        setValues({...values, banner: image});
    }

    const changeNada = (image) => {
        setValues({...values, nada: image});
    }


    const submitProfile = async (e) => {
        e.preventDefault();
        const errorCheck = Object.values(errors).some((error) => error === true);

        if (!errorCheck) {
            const formData = new FormData();
            formData.append('profilePic', values.profilePic);
            formData.append('banner', values.banner);
            formData.append('name', values.name);
            formData.append('description', values.description);
            formData.append('userType', values.userType);
            formData.append('category', values.category);
            formData.append('whatsapp', values.whatsapp);
            formData.append('instagram', values.instagram);
            formData.append('facebook', values.facebook);
            formData.append('email', values.email);

            const request = await fetch(`${process.env.REACT_APP_API_URL}/profile`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                },
                body: formData,
            });
            const response = await request.json();

            //console.log(response);

            if (response.status === 'success') {
                setOpenSnackOk(true);
                setIsLoaded(false);
                dispatch(setType(parseInt(values.userType)));
            }
            if (response.status === 'error') {
                setOpenSnackError(true);
            }

        }
    }

    useEffect(() => {
        //console.log(values);
    }, [values]);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                    <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <>
            <AlertSnackbar open={openSnackOk} handleClose={handleCloseOk} severity="success" message="Los datos se guardaron correctamente." />
            <AlertSnackbar open={openSnackError} handleClose={handleCloseError} severity="error" message="Hubo un error al guardar los datos." />
            <div className="row mt-3 p-2">
                <div className="col-lg-6 p-0">
                    <ProfilePreview values={values} />
                </div>
                <div className="col-lg-4 offset-lg-2 p-0 pb-4 mt-md-0 mt-5" style={{border: "solid 1px rgba(0,0,0,0.1)", borderRadius: "10px"}}>
                    <div className="container">
                        <div className="row mt-3">
                            <div className="col-12">
                                <h4>Perfil de Creador</h4>
                            </div>
                        </div>
                        <form onSubmit={submitProfile}>
                            <div className={"scrollProfile"}>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Tipo de creador</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={values.userType}
                                                label="Tipo de creador"
                                                onChange={handleChange('userType')}
                                                required
                                            >
                                                {roles.map((role) => (
                                                    <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <TextField required id="outlined-basic" label="Nombre de fantasía" variant="outlined" fullWidth value={values.name} onChange={handleChange('name')} />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <TextField id="outlined-basic" label="Descripción" variant="outlined" multiline rows={4} fullWidth value={values.description} onChange={handleChange('description')} />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <ImageUpload title="Foto de Perfil" figure="circle" onSelectImage={(img) => setValues({...values, profilePic: img})} image={values.profilePic} />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <ImageUpload title="Banner" figure="square" onSelectImage={(img) => setValues({...values, banner: img})} image={values.banner} />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <TextField label="Email de contacto" type="email" value={values.email} onChange={handleChange('email')} className="mb-2" fullWidth variant="outlined" error={errors.email} helperText={errorMessages.email} />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <TextField label="Whatsapp" value={values.whatsapp} onChange={handleChange('whatsapp')} className="mb-2" fullWidth variant="outlined" error={errors.whatsapp} helperText={errorMessages.whatsapp} />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <TextField label="Instagram" value={values.instagram} onChange={handleChange('instagram')} className="mb-2" fullWidth variant="outlined" error={errors.instagram} helperText={errorMessages.instagram} />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <TextField label="Facebook" value={values.facebook} onChange={handleChange('facebook')} className="mb-2" fullWidth variant="outlined" error={errors.facebook} helperText={errorMessages.facebook} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <Button variant="contained" color="primary" type="submit" fullWidth>
                                        Guardar
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
    }
};

export default Profile;
