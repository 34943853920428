import React, {useEffect} from 'react';
import Sidebar from "../components/Sidebar";
import NavB from "../components/Navbar";
import {Container} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";


const Home = (props) => {

    const user = useSelector(selectUser);

    const fetchProfileNew = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/profile`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': `Bearer ${user.token}`
            },
        });
        const response = await request.json();
        //console.log(response);
        }

    useEffect(() => {
        fetchProfileNew();

        // add <meta name="theme-color" content="#ff8000" /> to index.html
        document.querySelector("meta[name=theme-color]").setAttribute("content", "#222");
        document.querySelector("meta[name=viewport]").setAttribute("content", "width=device-width, initial-scale=1.0");
        }, []);

    return (
        <div style={{backgroundColor: "white", height: "100vh"}}>
            <NavB />
            <div className="row" style={{overflowY: "hidden", height: "100%"}}>
                <div className="col-lg-3 col-xl-2 pe-0" style={{backgroundColor: "#300166"}}>
                    <Sidebar />
                </div>
                <div className="col-lg-9 col-xl-10 m-0" id="colSide" style={{overflowY: "scroll", overflowX: "hidden", height:"100%", paddingTop: "60px"}}>
                    {props.padding ? (
                        <Container>
                            {
                             /*
                                 <div className="row text-end">
                                    <div className="col-12">
                                        <a href={"https://flashpass.com.ar/mis_eventos"} target={"_blank"} style={{color: "mediumpurple"}}>Ir a la versión anterior</a>
                                    </div>
                                 </div>
                              */
                            }
                            {props.children}
                        </Container>
                    ) : (
                        props.children
                    )}
                </div>
            </div>
        </div>
    );
};

export default Home;
