import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip,
    useMediaQuery
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";

const TooltipDelete = (props) => {
    const [open, setOpen] = useState(false);

    const user = useSelector(selectUser);


    const handleCloseConfirm = () => {
        setOpen(false);
    }

    const handleConfirm = () => {
        setOpen(true);
    }

    const confirmButton = async () => {

        const response = await fetch(`${process.env.REACT_APP_API_URL}/bank-accounts`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({id: props.id}),
        });
        const json = await response.json();
        if (json.success === '1') {
            props.done();
            handleCloseConfirm();
        }
    }

    return (
        <>
            <div
                className="d-flex justify-content-center align-items-center"
                style={{
                    backgroundColor: "white",
                    boxShadow: "0 3px 5px -2px darkgray",
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                }}
                onClick={props.click}
            >
                {!props.afuera ? (
                        <Tooltip title={props.title} placement="top">
                            <button onClick={handleConfirm} style={{backgroundColor: "transparent", border: "none"}}>
                                {props.icon}
                            </button>
                        </Tooltip>
                ) : (
                    <button disabled style={{backgroundColor: "transparent", border: "none"}}>
                        {props.icon}
                    </button>
                )}
            </div>
            <Dialog
                open={open}
                onClose={handleCloseConfirm}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"¿Estás seguro que deseas eliminar esta cuenta bancaria?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Esta acción no se puede deshacer.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseConfirm}>
                        Cancelar
                    </Button>
                    <Button onClick={confirmButton} autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TooltipDelete;