import {Tooltip} from "@mui/material";

const PanelBarraOrderComponent = (props) => (
    <div className="row mt-3">
        <div className="col-md-11 offset-1" style={{border: "1px solid #000", borderRadius: "5px"}}>
            <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                    <p className="mb-0 font-weight-bold">Pedido: {props.order?.id}</p>
                </div>
                <div className="col text-center">
                    <Tooltip title="Marcar como entregado" placement="top">
                        <button
                            className="btn btn-success mt-1"
                            style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                overflow: "hidden",
                                padding: 0
                            }}
                            onClick={() => props.deliverOrder(props.order?.order_hash)}
                        >
                            <i className="fas fa-check"></i>
                        </button>
                    </Tooltip>
                </div>
                {props.order?.table_number && (
                    <div className="col text-end">
                        <p className="mb-0 font-weight-bold">Mesa: {props.order?.table_number}</p>
                    </div>
                )}
            </div>
            <div className="row mt-2 align-items-center justify-content-between">
                <div className="col-auto">
                    <div className="row">
                        <div className="col-12">
                            <p className="mb-0 text-decoration-underline">Items:</p>
                        </div>
                    </div>
                </div>
            </div>
            {props.order?.products.map((product) => (
                <div className="row mt-2">
                    <div className="col-12">
                        <p className="mb-0"><span className="font-weight-bold">{product.quantity}x ({product?.category_name})</span> {product?.title}</p>
                        {product?.variants?.length > 0 && product?.variants.map((variant) => (
                            <p className="m-0"><small className="text-muted">{variant?.category_name}: {variant?.name}</small></p>
                        ))}
                    </div>
                </div>
            ))}
            {props.order?.observations && (
                <div className="row mt-2 align-items-center justify-content-between">
                    <div className="col-auto">
                        <div className="row">
                            <div className="col-12">
                                <p className="mb-1 text-decoration-underline">Observaciones:</p>
                                <p>{props.order?.observations}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    </div>
);

export default PanelBarraOrderComponent;