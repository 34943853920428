import React, {useState} from 'react';
import {
    Alert,
    Button, CircularProgress, FormControl, InputLabel, MenuItem, Select,
    Snackbar,
    TextField
} from "@mui/material";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";

const WhitelistEvents = (props) => {

    const user = useSelector(selectUser)

    const [values, setValues] = useState({
        events: '',
        role: '',
        promoter_id: props.promoter,
        cortesias: 0,
        cash: 0,
    });

    const navigate = useNavigate();

    const [events, setEvents] = useState([]);
    const [roles, setRoles] = useState([]);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const updatePromoter = async (e) => {
        e.preventDefault();
        setIsLoaded(false);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/promoter-assign`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify(values),
        });

        const json = await response.json();
        if (json.success == 1) {
            setOpenS(true);
        }
        else {
            setOpenE(true);
        }
        setIsLoaded(true);

    }

    const deletePromoter = async () => {
        setIsLoaded(false);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/whitelist-events`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                    id: props.id,
                    event_id: values.events
                })
        });

        const json = await response.json();
        if (json.status === "success") {
            setOpenE(false);
            setOpenS(true);
            setTimeout(() => {
                navigate("/organizations/whitelist");
            }, 2000);
        }
        else {
            setOpenE(true);
        }
        setIsLoaded(true);

    }

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/whitelist-events?id=${props.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();

        //console.log(json);

        if (json.status === "success") {
            setEvents(json.data);
            setIsLoaded(true);
        }
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const [isLoaded, setIsLoaded] = useState(false);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
                <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                    <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                        El colaborador no tiene eventos asignados!
                    </Alert>
                </Snackbar>
            </div>
        )
    }
    else {
        return (
            <div>
                <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                    <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                        Evento actualizado correctamente!
                    </Alert>
                </Snackbar>
                <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                    <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                        Ocurrió un error, intente nuevamente!
                    </Alert>
                </Snackbar>

                <div className="row">
                    <div className="col">
                        <h1>Editar eventos asignados</h1>
                    </div>
                </div>
                <form onSubmit={updatePromoter}>
                    <div className="row mb-2">
                        <div className="col-md-6">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Evento</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.events}
                                    label="Evento"
                                    onChange={(e) => {
                                        setValues({events: e.target.value, role: events.filter(event => event.event_id === e.target.value)[0].role_id, cortesias: events.filter(event => event.event_id === e.target.value)[0].cortesias, cash: events.filter(event => event.event_id === e.target.value)[0].cash});
                                    }}
                                >
                                    {events.map((event, index) => (
                                        <MenuItem key={index} value={event.event_id}>{event.event_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 d-flex justify-content-between">
                            <Button variant="contained" color="error" onClick={deletePromoter}>
                                Eliminar whitelist del evento
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
};

export default WhitelistEvents;
