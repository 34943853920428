import React, {useEffect, useState} from 'react';
import {CircularProgress, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {selectUser} from "../redux/userSlice";
import {useSelector} from "react-redux";
import {Button} from "react-bootstrap";

const DigitalMenuAccounts = (props) => {

    const [isLoaded, setIsLoaded] = useState(false);
    const user = useSelector(selectUser);

    const [accounts, setAccounts] = useState([]);
    const [values, setValues] = useState({
        activeAccount: '',
    });

    const fetchData = async () => {
        setIsLoaded(false)
        const request = await fetch(`${process.env.REACT_APP_API_URL}/mp-menu-link?menu_id=${props.menu}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        const response = await request.json();
        setAccounts(response.data);
        setValues({...values, activeAccount: response.data.filter(account => account?.active)[0]?.mp_link_id});
        setIsLoaded(true);
    }

    const submitData = async (event) => {
        event.preventDefault();
        const request = await fetch(`${process.env.REACT_APP_API_URL}/mp-menu-link`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify(values)
        });

        const response = await request.json();
        if (response.status === 'success') {
            alert('Cuenta activa actualizada');
        }
    }

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    }

    useEffect(() => {
        if (isLoaded) return;
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
        return (
            <div>
                <div className="row mt-2">
                    <div className="col-12">
                        <h2>Cuentas</h2>
                    </div>
                </div>
                <form onSubmit={submitData}>
                    <div className="row mt-3">
                        <div className="col-lg-6">
                            <FormControl fullWidth>
                                <InputLabel
                                    id="demo-simple-select-label">Cuenta activa</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.activeAccount}
                                    label="Cuenta activa"
                                    onChange={handleChange('activeAccount')}
                                    name={'payment_type'}
                                >
                                    {accounts.map((account, key) => (
                                        <MenuItem key={key} value={account.mp_link_id}>{account.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Button variant={"dark"} type={"submit"} className="mt-3">
                                Guardar
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
};

export default DigitalMenuAccounts;