import React, { useEffect, useRef, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Checkbox
} from '@material-ui/core';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useNavigate } from 'react-router';
import DropdownMenu from "./DropdownMenu";

const getColumns = (page) => {
    const baseColumns = [
        { id: 'select', label: '', maxWidth: 30, minWidth: 30 },
        { id: 'id', label: 'Codigo', maxWidth: 10, minWidth: 0 },
        { id: 'name', label: 'Nombre', minWidth: 170 },
        {
            id: 'actions',
            label: 'Acciones',
            minWidth: 170,
            align: 'right',
        },
    ];

    if (page === "products") {
        baseColumns.splice(3, 0, { id: 'available', label: 'Disponible', minWidth: 100 });
    }

    return baseColumns;
};

export default function StickyHeadTable(props) {
    const columns = getColumns(props.page);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const navigate = useNavigate();

    const menuButtonRef = useRef(null);

    const handleClick = (event, row) => {
        setAnchorEl(menuButtonRef.current);
        setSelectedRow(row);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (event, id) => {
        const selectedIndex = selectedItems.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedItems, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedItems.slice(1));
        } else if (selectedIndex === selectedItems.length - 1) {
            newSelected = newSelected.concat(selectedItems.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedItems.slice(0, selectedIndex),
                selectedItems.slice(selectedIndex + 1),
            );
        }

        setSelectedItems(newSelected);
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const newSelecteds = props.rows.map((n) => n.id);
            setSelectedItems(newSelecteds);
            return;
        }
        setSelectedItems([]);
    };

    const handleMassAction = (action) => {
        if (action === 'delete') {
            props.delete(selectedItems);
        } else if (action === 'unlist') {
            props.disableProduct(selectedItems);
        } else if (action === 'list') {
            props.enableProduct(selectedItems);
        }
        setSelectedItems([]);
    };

    useEffect(() => {
        console.log(selectedItems);
    }, [selectedItems])

    return (
        <>
            {selectedItems.length > 0 && (
                <div className="row mb-1">
                    <div className="col-auto mb-2">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleMassAction('delete')}
                        >
                            Eliminar Seleccionados
                        </Button>
                    </div>
                    <div className="col-auto mb-2">
                        <Button
                            variant="contained"
                            color="default"
                            onClick={() => handleMassAction('unlist')}
                        >
                            Desactivar Seleccionados
                        </Button>
                    </div>
                    <div className="col-auto mb-2">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleMassAction('list')}
                        >
                            Activar Seleccionados
                        </Button>
                    </div>
                </div>
            )}
            <TableContainer sx={{ maxHeight: "75vh" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={selectedItems.length > 0 && selectedItems.length < props.rows.length}
                                    checked={props.rows.length > 0 && selectedItems.length === props.rows.length}
                                    onChange={handleSelectAll}
                                    inputProps={{ 'aria-label': 'seleccionar todos' }}
                                />
                            </TableCell>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.rows.map((row) => {
                            const isItemSelected = selectedItems.indexOf(row.id) !== -1;
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={isItemSelected}
                                            onChange={(event) => handleSelect(event, row.id)}
                                        />
                                    </TableCell>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.id === 'actions' ? (
                                                    <>
                                                        <div>
                                                            <DropdownMenu
                                                                anchorEl={
                                                                    <Button
                                                                        ref={menuButtonRef}
                                                                        variant="contained"
                                                                        aria-controls="simple-menu"
                                                                        aria-haspopup="true"
                                                                        onClick={(event) => handleClick(event, row)}
                                                                    >
                                                                        Opciones <IoMdArrowDropdown />
                                                                    </Button>
                                                                }
                                                            >
                                                                <div onClick={() => { navigate(`./${selectedRow.id}/info`); handleClose(); }}>Editar</div>
                                                                {props.page === "menu" ? (
                                                                    <div onClick={() => { window.open(`/menu/${selectedRow.url}`, '_blank'); handleClose(); }}>Ver</div>
                                                                ) : props.page === "products" ? (
                                                                    <>
                                                                        <div onClick={() => { navigate(`./${selectedRow.id}/assign`); handleClose(); }}>Asignar a categoría</div>
                                                                        {selectedRow?.available ? (
                                                                            <div style={{ paddingTop: "10px", cursor: "pointer" }} onClick={() => { props.disableProduct([selectedRow.id]); handleClose(); }}>
                                                                                Desactivar
                                                                            </div>
                                                                        ) : (
                                                                            <div style={{ paddingTop: "10px", cursor: "pointer" }} onClick={() => { props.enableProduct([selectedRow.id]); handleClose(); }}>
                                                                                Activar
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                ) : props.page === "categories" ? (
                                                                    <div onClick={() => { navigate(`./${selectedRow.id}/products`); handleClose(); }}>Productos</div>
                                                                ) : props.page === "balances" ? (
                                                                    <div onClick={() => { navigate(`./${selectedRow.id}/info`); handleClose(); }}>Ver Saldos</div>
                                                                ) : null}
                                                                <div onClick={() => { props.delete(row.id); handleClose(); }}>Eliminar</div>
                                                            </DropdownMenu>
                                                        </div>
                                                    </>
                                                ) : column.format && typeof value === 'number' ? (
                                                    column.format(value)
                                                ) : column.id === 'available' ? (
                                                    <span style={{ color: row.available === 1 ? 'green' : row.available === 0 ? 'red' : 'black' }}>
                                                        {row.available === 1 ? 'Disponible' : row.available === 0 ? 'Desactivado' : 'Sin información'}
                                                    </span>
                                                ) : (
                                                    value
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}