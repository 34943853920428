import React, {useState} from 'react';
import {BsThreeDotsVertical} from "react-icons/bs";
import {Link} from "react-router-dom";
import ThreeDotsMenu from "./ThreeDotsMenu";
import DialogConfirm from "./DialogConfirm";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {TextField} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

const CampaignContainer = (props) => {

    const user = useSelector(selectUser);

    // Inside CampaignContainer component
    const handleDateChange = (newValue) => {
        //console.log('New value:', newValue);
        props.onProgrammedDateChange(newValue, props.campaign.id);
    };

    const buttonOptions = [
        {
            title: "Editar",
            link: "./" + props.campaign.id + "/edit",
            icon: <BsThreeDotsVertical size="24" style={{color: "black"}} />,
            click: () => console.log("Editar"),
            className: "",
            showRRPP: 0,
            disabled: false,
        },
        {
            title: "Estadísticas",
            link: "./" + props.campaign.id + "/analytics",
            icon: <BsThreeDotsVertical size="24" style={{color: "black"}} />,
            click: () => "",
            className: "",
            showRRPP: 0,
            disabled: false,
        },
        {
            title: "Enviar",
            link: "/",
            icon: <BsThreeDotsVertical size="24" style={{color: "black"}} />,
            click: () => console.log("Enviar"),
            className: "",
            showRRPP: 0,
            disabled: props.campaign.campaignSent,
        },
        {
            title: "Programar Envío",
            icon: <BsThreeDotsVertical size="24" style={{color: "black"}} />,
            click: () => {
                props.setDialogData({
                    title: "Programar Envío",
                    content: <div className="p-2">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                renderInput={(props) => <TextField fullWidth {...props} />}
                                label="Fecha y hora de envío"
                                inputFormat={'dd/MM/yyyy HH:mm'}
                                disablePast
                                defaultCalendarMonth={new Date(new Date().setHours(0, 0, 0, 0))}
                                ampm={false}
                                onChange={handleDateChange}
                                desktopModeMediaQuery={'(max-width: 0px)'}
                            />
                        </LocalizationProvider>
                    </div>,
                    button: {
                        text: "Programar",
                        click: () => {
                            //console.log("Programmed date:", props.programmedDate);
                        }
                    }
                });
                props.openDialog(true);
            },
            className: "",
            showRRPP: 0,
            disabled: props.campaign.campaignSent,
        },
        {
            title: "Eliminar",
            link: "",
            icon: <BsThreeDotsVertical size="24" style={{color: "black"}} />,
            click: () => setOpenDelete(true),
            className: "text-danger",
            showRRPP: 0,
            disabled: false,
        }
    ];

    const [openDelete, setOpenDelete] = useState(false);

    const deleteCampaign = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/campaign`, {
            method: 'DELETE',
            headers: {
                "Authorization": `Bearer ${user.token}`,
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                id: props.campaign.id,
            })
        });

        const response = await request.json();
        //console.log(response);
        if (response.status === "success") {
            setOpenDelete(false);
            props.setIsLoaded(false);
        }
    }

    const [openSend, setOpenSend] = useState(false);
    const [sendText, setSendText] = useState("¿Está seguro que desea enviar esta campaña?");

    const sendCampaign = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/send-campaign?id=${props.campaign.id}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${user.token}`,
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
            },
        });

        const response = await request.json();

        if (response.status === "success") {
            setSendText("La campaña se envió correctamente");
            props.setIsLoaded(false);
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="row align-items-start justify-content-between p-1 mt-3" style={{backgroundColor: "whitesmoke", borderRadius: "10px", boxShadow: "0 3px 5px -2px darkgray"}}>
                        <div className="col-3 col-md-auto col-lg-5 col-xl-6" style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                            <h5 className="mb-1">{props.campaign.name}</h5>
                            <p className="mb-1">Estado</p>
                            <p>{props.campaign.eventName}</p>
                        </div>
                        <div className="col-auto">
                            <p>Open Rate</p>
                            <p>{props.campaign.opened}/{props.campaign.sent}</p>
                        </div>
                        <div className="col-auto d-none d-md-block">
                            <p>Click Rate</p>
                            <p>{props.campaign.clicked}/{props.campaign.opened}</p>
                        </div>
                        <div className="col-auto text-end">
                            <ThreeDotsMenu items={buttonOptions} />
                        </div>
                    </div>
                </div>
            </div>
            <DialogConfirm title={"Eliminar Campaña"} text={"¿Está seguro que desea eliminar esta campaña?"} open={openDelete} handleCloseConfirm={() => setOpenDelete(false)} click={deleteCampaign} />
            <DialogConfirm title={"Enviar Campaña"} text={sendText} open={openSend} handleCloseConfirm={() => setOpenDelete(false)} click={sendCampaign} />
        </>
    );
};

export default CampaignContainer;