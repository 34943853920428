import React from 'react';
import '../styles/SidebarMenu.css'
import BurguerButton from "./BurguerButton";
import {GiHamburgerMenu} from "react-icons/gi";
import {Link} from "react-router-dom";


const SidebarMenu = (props) => {

    const [showMenu, setShowMenu] = React.useState(false);

    const menuOptions = [
        {
            name: "Información",
            link: `/organizations/venues/${props.venue}/info`
        },
        {
            name: "Mesas",
            link: `/organizations/venues/${props.venue}/tables`
        },
        {
            name: "Cajas",
            link: `/organizations/venues/${props.venue}/cashiers`
        },
        {
            name: "Inventario",
            link: `/organizations/venues/${props.venue}/inventory`
        },
    ];

    return (
        <>
            <div className="menu-sidebar-organization d-lg-block d-none">
                <div className="row">
                    <div className="col-12">
                        <ul className="sidebar-menu-organization-ul">
                            {menuOptions.map((option, index) => (
                                <li key={index} className="sidebar-menu-item">
                                    <Link className="sidebar-menu-link" to={option.link}>{option.name}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="menu-sidebar-organization-mobile d-lg-none d-block">
                <div className="row">
                    <div className="col-12 align-items-center d-flex">
                        <button onClick={() => setShowMenu(!showMenu)} style={{border: 'none', background: 'none', outline: 'none'}}>
                            <GiHamburgerMenu size={30} />
                        </button>
                        <span className="menu-sidebar-organization-mobile-title ms-3">Menu</span>
                    </div>
                </div>
                <div className={`row ${showMenu ? 'd-block' : "d-none"}`}>
                    <div className="col-12">
                        <ul className="sidebar-menu-organization-ul">
                            {menuOptions.map((option, index) => (
                                <li key={index} className="sidebar-menu-item">
                                    <Link className="sidebar-menu-link" to={option.link}>{option.name}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SidebarMenu;