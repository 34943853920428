import React, {useState} from 'react';
import {Alert, CircularProgress, Snackbar, TextField} from "@mui/material";
import {setEventChange} from "../redux/eventsChangeSlice";
import {Button} from "react-bootstrap";
import {selectUser} from "../redux/userSlice";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";
import MenuContainerAdmin from "./MenuContainerAdmin";
import WaiterOrdersTable from "./WaiterOrdersTable";
import PanelBarraOrderComponent from "./PanelBarraOrderComponent";

const PanelBarra = (props) => {
    const navigate = useNavigate();

    const [orders, setOrders] = React.useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const token = localStorage.getItem('token');

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/mata-menu-tables-orders-kds`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MDkwODE0MzMsImlzcyI6ImZsYXNocGFzcy5jb20uYXIiLCJuYmYiOjE3MDkwODE0MzMsImV4cCI6MTcyNDgwNjIzMywidXNlcklkIjoyMjc5LCJwcm9kdWNlcklkIjoyMjV9.Rtz19n0DkYqTc2-znKAjgv8lblkd06-J3wyijVgW8sk',
            },
        });

        const result = await response.json();

        if (result?.status === 'success') {
            setOrders(result.data.filter((order) => order?.order_status === 'processing'));
            setIsLoaded(true);
        }

    }

    const deliverOrder = async (order_hash) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/menu-orders?order_id=${order_hash}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + token,
            },
        });

        const result = await response.json();
        fetchData();

        return result;
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();

            const interval = setInterval(fetchData, 10000);

            // Clear the interval when the component is unmounted
            return () => clearInterval(interval);
        }, 300);
        return () => clearTimeout(requests);
    }, [token]);

    return (
        <>
            {!isLoaded ? (
                <div className="container" style={{minHeight: "100vh"}}>
                    <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                        <CircularProgress color="secondary" size={100} />
                    </div>
                </div>
            ) : (
                <div className="container" style={{minHeight: "100vh"}}>
                    <div className="row mt-2 justify-content-between align-items-center">
                        <div className="col">
                            <h3>Pedidos</h3>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-4">
                            {orders?.filter((_, index) => index % 3 === 0).map((order, index) => (
                                <PanelBarraOrderComponent key={index} order={order} deliverOrder={deliverOrder} />
                            ))}
                        </div>
                        <div className="col-md-4">
                            {orders?.filter((_, index) => index % 3 === 1).map((order, index) => (
                                <PanelBarraOrderComponent key={index} order={order} deliverOrder={deliverOrder} />
                            ))}
                        </div>
                        <div className="col-md-4">
                            {orders?.filter((_, index) => index % 3 === 2).map((order, index) => (
                                <PanelBarraOrderComponent key={index} order={order} deliverOrder={deliverOrder} />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PanelBarra;