import React, {useEffect, useRef, useState} from 'react';
import {Button, Table} from "react-bootstrap";
import {useNavigate} from "react-router";
import {
    Alert,
    AlertTitle,
    CircularProgress,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {FaDownload} from "react-icons/fa";
import {number_format} from "./functions/numberFormat";
import './EventAttendeesDetails.css';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button as ButtonMUI } from '@mui/material';
import {BsCheck2Circle, BsCheckCircleFill, BsSearch} from "react-icons/bs";
import {RiShareForward2Fill} from "react-icons/ri";
import {TiDeleteOutline} from "react-icons/ti";


const AttendeesList = (props) => {

    const user = useSelector(selectUser)

    let navigate = useNavigate();
    const [attendees, setAttendees] = React.useState([]);
    const [tableData, setTableData] = useState([]);
    const [event_name, setEventName] = useState("");

    const [open, setOpen] = React.useState(false);
    const [currentAttendee, setCurrentAttendee] = React.useState(null);
    const [eventTickets, setEventTickets] = React.useState([]);

    const [prevAttendeesNumber, setPrevAttendeesNumber] = React.useState(0);
    const [totalAttendees, setTotalAttendees] = React.useState([]);

    const [search, setSearch] = React.useState("");

    const searchValueRef = useRef(search);
    const searchRequestRef = useRef(null);

    const [loadingTable, setLoadingTable] = React.useState(false);

    const [page, setPage] = React.useState(1);

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

        // Check if we're within 100 pixels of the bottom edge
        if (!loadingTable && scrollHeight - scrollTop <= clientHeight + 100 && attendees.length > prevAttendeesNumber) {
            setPrevAttendeesNumber(attendees.length);
            loadMoreData();
        }
    };


    const loadMoreData = () => {
        setPage(prevPage => prevPage + 1);
    };




    useEffect(() => {
        {totalAttendees.map((attendee, key) => {
            const data = {
                Nombre: attendee.name.toUpperCase(),
                DNI: attendee.dni,
                Ticket: attendee.ticket_type,
                Precio: attendee.value,
                'Tipo de pago': attendee.payment_type,
                Email: attendee.email,
                Telefono: attendee.phone,
                Colaborador: attendee.promoter,
                Estado: attendee.status_id === 1 ? "Validado" : "Vigente",
            };
            setTableData(prevTableData => [...prevTableData, data]);
        })}
    }, [totalAttendees])

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, `Lista Digital`);
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, `Lista Digital - ${event_name}.xlsx`);
    };

    const fetchTotalAttendees = async () => {
        const requests = await fetch(`${process.env.REACT_APP_API_URL}/attendees-list?event_id=${props.event}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });

        const json = await requests.json();
        setTotalAttendees(json.attendees);
    }

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchTotalAttendees();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);


    const fetchData = async (search, page, signal) => {
        let response;

        const url = `${process.env.REACT_APP_API_URL}/attendees-list?event_id=${props.event}&search=${search}&page=${page}`;

        const headers = {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${user.token}`,
            'credentials': 'same-origin',
        };

        response = await fetch(url, {
            method: 'GET',
            headers,
            signal: signal,
        });

        const json = await response.json();
        //console.log(json);
        setAttendees(json.attendees);
        setEventName(json.event_name);
        setIsLoaded(true);
        setLoadingTable(false);

        const response2 = await fetch(`${process.env.REACT_APP_API_URL}/event-tickets-list?event_id=${props.event}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json2 = await response2.json();

        if (json2?.success === "1") {
            setEventTickets(json2?.tickets);
        }

    }

    const [loadingSearch, setLoadingSearch] = useState(false);

    useEffect(() => {
        if (user.token) {
            searchValueRef.current = search;
            if (searchRequestRef.current) {
                searchRequestRef.current.abort();
            }
            const controller = new AbortController();
            searchRequestRef.current = controller;

            setLoadingSearch(true);

            const requests = setTimeout(() => {
                fetchData(search, 1, controller.signal)
                    .catch((error) => {
                        if (error.name !== 'AbortError') {
                            console.error('Error fetching data:', error);
                        }
                    })
                    .finally(() => {
                        setLoadingSearch(false);
                    });
            }, 300);

            return () => {
                clearTimeout(requests);
                controller.abort();
            };
        }
    }, [search]);

    useEffect(() => {
        if (user.token && !loadingSearch) {
            const controller = new AbortController();
            if (page === 1) {
                setIsLoaded(false);
            } else {
                setLoadingTable(true);
            }
            fetchData(searchValueRef.current, page, controller.signal) // Fetch data with current search query when page changes
                .catch((error) => {
                    if (error.name !== 'AbortError') {
                        console.error('Error fetching data:', error);
                    }
                });
            return () => {
                controller.abort();
            };
        }
    }, [page, user.token]);


    const validateAttendee = (id) => {
        const mySwal = withReactContent(Swal);
        mySwal.fire({
            title: '¿Estás seguro?',
            text: "El ticket quedará validado y no podrá ser utilizado para ingresar al evento.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Validar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                validateAttendeeAPI(id).then((response) => {
                    if (response.status === "success") {
                        mySwal.fire(
                            '¡Validado!',
                            'El ticket ha sido validado.',
                            'success'
                        ).then(() => {
                            setIsLoaded(false);
                            fetchData("", 1)
                        })
                    }
                    else {
                        mySwal.fire(
                            '¡Error!',
                            'Ha ocurrido un error.',
                            'error'
                        )
                    }
                })
            }
        })
    }
    const validateAttendeeAPI = async (id) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/validate-attendee`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': `Bearer ${user.token}`,
            },
            body: JSON.stringify({
                "ticket_id": id,
            }),
        });
        const response = await request.json();
        return response;
    }

    const forwardTicket = async (attendee) => {

        const request = await fetch(`${process.env.REACT_APP_API_URL}/ticket-courtesy`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': `Bearer ${user.token}`,
            },
            body: JSON.stringify({
                "ticket_id": attendee.ticket_id,
                "email": attendee.email,
                "name": attendee.name,
                "dni": attendee.dni,
                "event_ticket_id": attendee.event_ticket_id,
            }),
        });

        const response = await request.json();

        if (response.status === "success") {
            const mySwal = withReactContent(Swal);
            mySwal.fire(
                '¡Enviado!',
                'El ticket ha sido reenviado por email.',
                'success'
            ).then(() => {
                setIsLoaded(false);
                fetchData("", 1)
            })
        }
    }

    const anulateTicket = (id) => {
        const mySwal = withReactContent(Swal);
        mySwal.fire({
            title: '¿Estás seguro?',
            text: "El ticket se anulará y no podrá ser utilizado para ingresar al evento.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Anular',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                anulateTicketAPI(id).then((response) => {
                    if (response.status === "success") {
                        mySwal.fire(
                            '¡Anulado!',
                            'El ticket ha sido anulado.',
                            'success'
                        ).then(() => {
                            setIsLoaded(false);
                            fetchData("", 1)
                        })
                    }
                    else {
                        mySwal.fire(
                            '¡Error!',
                            'Ha ocurrido un error.',
                            'error'
                        )
                    }
                })
            }
        })
    }

    const anulateTicketAPI = async (ticket_id) => {

        const request = await fetch(`${process.env.REACT_APP_API_URL}/ticket-courtesy`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': `Bearer ${user.token}`,
            },
            body: JSON.stringify({
                "ticket_id": ticket_id,
            }),
        });

        const response = await request.json();
        return response;
    }

    function getViewport () {
        // https://stackoverflow.com/a/8876069
        const width = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
        )

        if (width <= 992) return 'md'
        return 'xl'
    }

    function searchData() {
        // Declare variables
        var input, filter, table, tr, i;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        table = document.getElementsByTagName("table")[0];
        tr = table.getElementsByTagName("tr");

        // Loop through all table rows (starting from the first row, i.e., i = 1, not the header), and hide those who don't match the search query
        for (i = 1; i < tr.length; i++) {  // note the change here, starting loop from i=1
            var tds = tr[i].getElementsByTagName("td");
            var showRow = false;

            for (var j = 0; j < tds.length; j++) {
                // skip hidden columns on small viewports
                if (getViewport() == 'md' && tds[j].classList.contains('hide-on-small')) continue;

                var txtValue = tds[j].textContent || tds[j].innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    showRow = true;
                    break;
                }
            }
            tr[i].style.display = showRow ? "" : "none";
        }
    }

    const [isLoaded, setIsLoaded] = useState(false);

    const handleOpenDialog = (attendee) => {
        setCurrentAttendee(attendee);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };


    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
        return (
            <div>
                <div className="row mb-0">
                    <div className="col">
                        <h3>Lista Digital</h3>
                    </div>
                </div>
                {attendees.length > 0 || search ? (
                    <>
                        <div className="row text-center mb-3">
                            <div className="col-12">
                                <Button variant={"outline-dark"} onClick={downloadExcel}>
                                    <FaDownload />
                                    &nbsp;Descargar Lista
                                </Button>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <TextField
                                    label="Buscar por nombre, apellido, DNI o email"
                                    id="outlined-start-adornment"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><BsSearch /></InputAdornment>,
                                    }}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                        </div>

                        {loadingSearch ? (
                            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                                <CircularProgress color="secondary" size={100} />
                            </div>
                        ) : (
                        <div className="row">
                            <div className="col-12" style={{maxHeight: "80vh", overflowY: "scroll"}} onScroll={handleScroll}>
                                <Table striped bordered responsive id="myTable" size="sm">
                                    <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>DNI</th>
                                        <th className="hide-on-small">Ticket</th>
                                        <th className="hide-on-small">Precio</th>
                                        <th>Email</th>
                                        <th className="hide-on-small">Colaborador</th>
                                        <th>Acciones</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {attendees.map((attendee, key) => (
                                        <tr key={key} style={{fontSize: "12px"}}>
                                            <td style={{wordWrap: "break-word", maxWidth: "150px"}}>{attendee.name.toUpperCase()}</td>
                                            <td>{attendee.dni}</td>
                                            <td className="hide-on-small">{attendee.ticket_type}</td>
                                            <td className="hide-on-small">{attendee.value !== 0 ? `$ ${number_format(attendee.value, 0, ',', '.')}` : "Gratuita"}</td>
                                            <td>{attendee.email}</td>
                                            <td className="hide-on-small">{attendee.promoter}</td>
                                            <td>
                                                <div className="row">
                                                    <div className="col">
                                                        {attendee?.online === 1 ? (
                                                            <>
                                                                {attendee.status_id === 1 ? (
                                                                    <Button className="w-100 d-flex align-items-center"  disabled variant={"dark"}>
                                                                        <BsCheckCircleFill className="me-1" />Validado
                                                                    </Button>
                                                                ) : (
                                                                    <Button className="w-100 d-flex align-items-center" variant="danger" onClick={() => validateAttendee(attendee.ticket_id)}>
                                                                        <BsCheck2Circle className="me-1" /> Validar
                                                                    </Button>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {attendee.status_id === 1 ? (
                                                                    <Button className="w-100 d-flex align-items-center"  disabled variant={"dark"}>
                                                                        <BsCheckCircleFill className="me-1" />Validado
                                                                    </Button>
                                                                ) : (
                                                                    <>
                                                                        <Button className="w-100 d-flex align-items-center" variant="danger" onClick={() => validateAttendee(attendee.ticket_id)}>
                                                                            <BsCheck2Circle className="me-1" /> Validar
                                                                        </Button>
                                                                        <Button className="mt-2 w-100 d-flex align-items-center" variant="warning" onClick={() => handleOpenDialog(attendee)}>
                                                                            <RiShareForward2Fill className="me-1" /> Reenviar
                                                                        </Button>
                                                                        <Button className="mt-2 w-100 d-flex align-items-center" variant="dark" onClick={() => anulateTicket(attendee.ticket_id)}>
                                                                            <TiDeleteOutline size={13} className="me-1" /> Anular
                                                                        </Button>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        )}
                    </>
                ) : (
                    <div className="d-flex justify-content-center align-items-start" style={{marginTop: "10vh"}}>
                        <Alert severity="warning">
                            <AlertTitle>Todavía no vendiste tickets</AlertTitle>
                            <p>Cuando vendas el primer ticket se verá reflejado en esta pantalla.</p>
                        </Alert>
                    </div>
                )}
                <Dialog open={open} onClose={handleCloseDialog}>
                    <DialogTitle>Reenviar Ticket</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Por favor confirme los detalles del ticket a reenviar
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Nombre Completo"
                            type="text"
                            fullWidth
                            defaultValue={currentAttendee ? currentAttendee.name : ''}
                            onChange={(e) => setCurrentAttendee({...currentAttendee, name: e.target.value})}
                        />
                        <TextField
                            margin="dense"
                            id="dni"
                            label="DNI"
                            type="text"
                            fullWidth
                            defaultValue={currentAttendee ? currentAttendee.dni : ''}
                            onChange={(e) => setCurrentAttendee({...currentAttendee, dni: e.target.value})}
                        />
                        <TextField
                            margin="dense"
                            id="email"
                            label="Email"
                            type="email"
                            fullWidth
                            defaultValue={currentAttendee ? currentAttendee.email : ''}
                            onChange={(e) => setCurrentAttendee({...currentAttendee, email: e.target.value})}
                        />
                        <FormControl fullWidth className={"mt-2"}>
                            <InputLabel id="demo-simple-select-label">Tipo de ticket</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={currentAttendee ? currentAttendee.event_ticket_id : ''}
                                label="Tipo de ticket"
                                onChange={(e) => setCurrentAttendee({...currentAttendee, event_ticket_id: e.target.value})}
                                required
                            >
                                {eventTickets.map((category, id) => (
                                    <MenuItem key={id} value={category.event_ticket_id}>{category.event_ticket_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <ButtonMUI onClick={handleCloseDialog}>Cancelar</ButtonMUI>
                        <ButtonMUI onClick={() => {
                            forwardTicket(currentAttendee); // Add your resend ticket function here
                            handleCloseDialog();
                        }}>Reenviar</ButtonMUI>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
};

export default AttendeesList;