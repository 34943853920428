import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import EventSidebar from "../components/EventSidebar";
import EventInfo from "../components/EventInfo";
import {Container} from "react-bootstrap";


const EditEvent = (props) => {

    useEffect(() => {
        const div = document.getElementById("colSide");

        if (div) {
            div.scrollTop = 0;
            document.getElementById("colSide").style.overflowY = "hidden";
        }

        return () => {
            if (div) {
                document.getElementById("colSide").style.overflowY = "scroll";
            }
        }
    }, []);

    return (
        <div style={{width: "100%", padding: 0, margin: 0}}>
            <div className="row" style={{ height: "calc(100vh - 60px)", overflowY: "hidden" }}>
            <div
                    className="col-lg-3 col-xl-2 ps-0 eventSidebarCont"
                    style={{
                        backgroundColor: "whitesmoke",
                    }}
                >
                    <EventSidebar event={props.event} />
                </div>
                <div
                    className="col-lg-9 col-xl-10"
                    style={{
                        minHeight: "100vh",
                        overflowX: "hidden",
                    }}
                >
                    <Container
                        id="eventDivContainer"
                        style={{
                            height: "calc(100vh)", // adjust this value to the height of the sidebar
                            overflowY: "scroll",
                        }}
                    >
                        {props.children}
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default EditEvent;
