import React from 'react';
import {BsFacebook, BsWhatsapp, BsTwitter, BsInstagram} from "react-icons/bs";

const SocialMediaContainer = (props) => {
    return (
        <>
            <div className="col-auto p-1 me-3">
                <div className="d-flex align-items-center justify-content-center" style={{borderRadius: "50%", height: "40px", width: "40px", backgroundColor: "whitesmoke"}}>
                    {props.icon === "instagram" && <BsInstagram size={20} />}
                    {props.icon === "whatsapp" && <BsWhatsapp size={20} />}
                    {props.icon === "facebook" && <BsFacebook size={20} />}
                    {props.icon === "twitter" && <BsTwitter size={20} />}
                </div>
            </div>
        </>
    );
};

export default SocialMediaContainer;