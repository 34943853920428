import React from 'react';
import {Link} from "react-router-dom";

const functions = [
    {
        name: "Ordenes",
        icon: "fas fa-clipboard-list",
        link: "./orders",
    },
    {
        name: "Saldos",
        icon: "fas fa-wallet",
        link: "./balances",
    }
]
const MenuSupervisors = () => {
    return (
        <div className="container" style={{backgroundColor: "white", minHeight: "100vh"}}>
            <div className="row mt-3">
                <div className="col-12">
                    <h3>Panel Supervisor</h3>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    <div className="row justify-content-center align-items-center">
                        {functions.map((func, index) => (
                            <div className="col-12 col-md-6 col-lg-4 mt-4 mt-md-0" key={index}>
                                <div className="card" style={{borderRadius: "10px", border: "none"}}>
                                    <div className="card-body">
                                        <div className="row text-center">
                                            <div className="col-12">
                                                <h5 className="card-title">{func.name}</h5>
                                            </div>
                                        </div>
                                        <div className="row text-center">
                                            <div className="col-12">
                                                <i className={func.icon} style={{fontSize: "3rem"}}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <Link to={func.link} className="btn btn-primary btn-block">Ir</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MenuSupervisors;