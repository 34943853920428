import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button, CircularProgress,
    Snackbar,
    TextField
} from "@mui/material";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import MultipleSelectColaborators from "./MultipleSelectColaborators";
import {useNavigate} from "react-router";
import MultipleAutocompleteColaborators from "./MultipleAutocompleteColaborators";

const EventGroupEdit = (props) => {

    const user = useSelector(selectUser);
    const navigate = useNavigate();

    const [values, setValues] = useState({
        id: '',
        name: '',
        events: [],
    });
    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const submitGroup = async (e) => {
        e.preventDefault();
        setIsLoaded(false);

        if (props.group) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/event-groups`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                },
                body: JSON.stringify({
                    "id": props.group,
                    "name": values.name,
                    "events": values.events.map((event) => {
                        return {
                            event_id: event.id,
                            promoter_id: event.promoter,
                        }
                    }),
                }),
            });

            const json = await response.json();

            if (json.status === "success") {
                setOpenS(true);
                setTimeout(() => {
                    navigate('/organizations/events/groups');
                }, 2000);
            }
            else {
                setOpenE(true);
            }
        } else {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/event-groups`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                },
                body: JSON.stringify({
                    "name": values.name,
                    "events": values.events.map((event) => {
                        return {
                            event_id: event.id,
                            promoter_id: event.promoter,
                        }
                    }),
                }),
            });

            const json = await response.json();

            if (json.status === "success") {
                setOpenS(true);
                setTimeout(() => {
                    navigate('/organizations/events/groups');
                }, 2000);
            }
            else {
                setOpenE(true);
            }
        }
        setIsLoaded(true);
    }

    const [events, setEvents] = useState([]);

    const changeEvents = (newEvents) => {
        setValues({...values, events: newEvents});
    }

    const fetchData = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/event-groups?id=${props.group}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await request.json();
        if (json.status === "success") {
            setValues({...values, ...json.event_group});
        }

        const response2 = await fetch(`${process.env.REACT_APP_API_URL}/event-list-mobile`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json2 = await response2.json();
        console.log(json2.events);

        setEvents(
            json2?.events?.map((event) => {
                return {
                    id: event.event_id,
                    name: event.event_name,
                    promoter: event.promoter_id,
                    selected: json?.data?.events?.map((e) => e?.id)?.includes(event?.id)
                }
            })
        );
        setIsLoaded(true);
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const [isLoaded, setIsLoaded] = useState(false);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    Grupo de eventos actualizado con éxito!
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    Ocurrió un error al actualizar el grupo de eventos!
                </Alert>
            </Snackbar>

            <div className="row">
                <div className="col">
                    <h1>Editar grupo de eventos</h1>
                </div>
            </div>
            <form onSubmit={submitGroup}>
                <div className="row">
                    <div className="col-md-6">
                        <TextField label="Nombre" value={values.name} onChange={handleChange('name')} className="mb-3" fullWidth variant="outlined" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <MultipleAutocompleteColaborators tag="Eventos seleccionados" values={values.events} events={events} onChange={changeEvents} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <Button variant="contained" type="submit" color="primary" className="me-2">
                            {
                                props.group ? "Actualizar grupo" : "Crear grupo"
                            }
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
    }
};

export default EventGroupEdit;
