import React, {useEffect} from 'react';
import {Route, Routes, useParams} from "react-router-dom";
import Home from "../pages/Home";
import Dashboard from "../pages/Dashboard";
import Events from "../pages/Events";
import Promoters from "../pages/Promoters";
import PromoterAdd from "./PromoterAdd";
import UserBase from "../pages/UserBase";
import UserBaseDetail from "./UserBaseDetail";
import Marketing from "../pages/Marketing";
import CreateCampaign from "./CreateCampaign";
import Refunds from "./Refunds";
import Profile from "./Profile";
import Whitelist from "./Whitelist";
import WhitelistAdd from "./WhitelistAdd";
import CreatorSettings from "./CreatorSettings";
import Products from "./Products";
import Carro from "./Carro";
import HomePageUsersDark from "../pages/HomePageUsersDark";
import MyTickets from "../pages/MyTickets";
import ProfileUsers from "../pages/ProfileUsers";
import CreateAccount from "../pages/CreateAccount";
import ForgotPassword from "../pages/ForgotPassword";
import Login from "./Login";
import {Navigate, useLocation} from "react-router";
import {AnimatePresence} from "framer-motion";
import store from "../redux/configureStore";
import {login} from "../redux/userSlice";
import EditEvent from "../pages/EditEvent";
import EventInfo from "./EventInfo";
import EditLocation from "./EditLocation";
import EditTickets from "./EditTickets";
import EditTicketInfo from "./EditTicketInfo";
import EventAttendeesDetail from "./EventAttendeesDetail";
import EventAnalytics from "./EventAnalytics";
import EventPublish from "./EventPublish";
import CreateLinkTickets from "./CreateLinkTickets";
import CampaignAnalytics from "./CampaignAnalytics";
import PromoterEdit from "./PromoterEdit";
import PromoterAssign from "./PromoterAssign";
import PromoterEvents from "./PromoterEvents";
import WhitelistEdit from "./WhitelistEdit";
import WhitelistAssign from "./WhitelistAssign";
import WhitelistEvents from "./WhitelistEvents";
import ItemDetail from "./ItemDetail";
import {Items} from "../data/Items";
import EventPreview from "./EventPreview";
import {createTheme, ThemeProvider} from "@mui/material";
import EventPreviewDark from "../pages/EventPreviewDark";
import RenderTicket from "./RenderTicket";
import ResetPassword from "../pages/ResetPassword";
import MenuOrder from "./MenuOrder";
import Menu from "./Menu";
import WhitelistImport from "./WhitelistImport";
import PromoterGroups from "./PromoterGroups";
import PromoterGroupsAdd from "./PromoterGroupsAdd";
import PromoterGroupsEdit from "./PromoterGroupsEdit";
import PromoterGroupsAssign from "./PromoterGroupsAssign";
import PromoterGroupsEvents from "./PromoterGroupsEvents";
import CreateTicketsImport from "./CreateTicketsImport";
import EventColaborators from "./EventColaborators";
import SuccessOrder from "./SuccessOrder";
import GoogleAuthenticate from "./GoogleAuthenticate";
import MyAccount from "./MyAccount";
import AccountMenu from "./AccountMenu";
import MenuDashboard from "./MenuDashboard";
import DigitalMenus from "./DigitalMenus";
import CreateDigitalMenu from "./CreateDigitalMenu";
import CreateMenuComponent from "./CreateMenuComponent";
import ComponentsList from "./ComponentsList";
import DigitalMenuCategories from "./DigitalMenuCategories";
import DigitalMenuCategory from "./DigitalMenuCategory";
import DigitalMenuProducts from "./DigitalMenuProducts";
import DigitalMenuProduct from "./DigitalMenuProduct";
import DigitalMenuProductAssign from "./DigitalMenuProductAssign";
import DigitalMenuCategoryProducts from "./DigitalMenuCategoryProducts";
import Venues from "./Venues";
import VenueInfo from "./VenueInfo";
import VenueDashboard from "./VenueDashboard";
import VenueTables from "./VenueTables";
import VenueTable from "./VenueTable";
import DigitalMenuBalances from "./DigitalMenuBalances";
import DigitalMenuBalance from "./DigitalMenuBalance";
import DigitalMenuBalanceAdd from "./DigitalMenuBalanceAdd";
import DigitalMenuBalanceUpload from "./DigitalMenuBalanceUpload";
import WaiterOrdersList from "./WaiterOrdersList";
import PanelBarra from "./PanelBarra";
import Faq from "./FAQ";
import CreateSingleLinkEvent from "./CreateSingleLinkEvent";
import MenuSupervisors from "../pages/MenuSupervisors";
import MenuSupervisorsOrders from "../pages/MenuSupervisorsOrders";
import MenuSupervisorsOrderId from "../pages/MenuSupervisorsOrderId";
import MenuSupervisorsBalances from "../pages/MenuSupervisorsBalances";
import MenuSupervisorsBalancesId from "../pages/MenuSupervisorsBalancesId";
import EventGroups from "./EventGroups";
import EventGroupEdit from "./EventGroupEdit";
import FlashLinks from "./FlashLinks";
import MenuEventAssign from "./MenuEventAssign";
import DigitalMenuBalanceTransactions from "./DigitalMenuBalanceTransactions";
import DigitalMenuBalanceTransactionAdd from "./DigitalMenuBalanceTransactionAdd";
import DigitalMenuAccounts from "./DigitalMenuAccounts";
import DigitalMenuBalanceTransactionsUpload from "./DigitalMenuBalanceTransactionsUpload";
import MenuOrders from "./MenuOrders";
import DigitalMenuRegisters from "./DigitalMenuRegisters";
import DigitalMenuRegister from "./DigitalMenuRegister";
import DigitalMenuRegisterTransactions from "./DigitalMenuRegisterTransactions";
import DigitalMenuCurrencies from "./DigitalMenuCurrencies";
import DigitalMenuCurrenciesAdd from "./DigitalMenuCurrenciesAdd";

const AnimatedRoutes = () => {

    const location = useLocation();

    const getToken = window.localStorage.getItem('token');

    useEffect(() => {
        if (getToken) {
            store.dispatch(login({
                token: getToken,
            }));
        }
    }, [getToken]);


    function EditEventId(props) {
        let { eventId, ticketId } = useParams();
        switch (props.path) {
            case "info":
                return <EditEvent event={eventId} children={<EventInfo event={eventId} />} />;
            case "location":
                return <EditEvent event={eventId} children={<EditLocation event={eventId} />} />;
            case "tickets":
                return <EditEvent event={eventId} children={<EditTickets event={eventId} />} />;
            case "editTicket":
                return <EditEvent event={eventId} children={<EditTicketInfo event={eventId} ticket={ticketId} />} />;
            case "newTicket":
                return <EditEvent event={eventId} children={<EditTicketInfo event={eventId} />} />;
            case "team":
                return <EditEvent event={eventId} children={<EventColaborators event={eventId} />} />;
            case "attendees":
                return <EditEvent event={eventId} children={<EventAttendeesDetail event={eventId} />} />;
            case "analytics":
                return <EditEvent event={eventId} children={<EventAnalytics event={eventId} />} />;
            case "publish":
                return <EditEvent event={eventId} children={<EventPublish event={eventId} />} />;
            case "link-tickets":
                return <EditEvent event={eventId} children={<CreateLinkTickets event={eventId} />} />;
            case "link-tickets-import":
                return <EditEvent event={eventId} children={<CreateTicketsImport event={eventId} />} />;
            case "single-link":
                return <EditEvent event={eventId} children={<CreateSingleLinkEvent event={eventId} />} />;
        }
    }

    function EditEventGroupId(props) {
        let { id } = useParams();
        switch (props.path) {
            case "edit":
                return <EventGroupEdit group={id} />
            case "new":
                return <EventGroupEdit />
        }
    }

    function CampaignId(props) {
        let { campaignId } = useParams();

        switch (props.path) {
            case "edit":
                return <CreateCampaign campaign={campaignId} />;
            case "analytics":
                return <CampaignAnalytics campaign={campaignId} />;
        }
    }

    function UserBaseId () {
        let { userBaseId } = useParams();

        return <UserBaseDetail id={userBaseId} />;
    }

    function EditPromoterId(props) {
        let { promoterId } = useParams();
        switch (props.path) {
            case "edit":
                return <PromoterEdit promoter={promoterId} />;
            case "assign":
                return <PromoterAssign promoter={promoterId} />;
            case "events":
                return <PromoterEvents promoter={promoterId} />;
        }
    }

    function EditPromoterGroupId(props) {
        let { groupId } = useParams();
        switch (props.path) {
            case "edit":
                return <PromoterGroupsEdit promoter={groupId} />;
            case "assign":
                return <PromoterGroupsAssign promoter={groupId} />;
            case "events":
                return <PromoterGroupsEvents promoter={groupId} />;
        }
    }

    function EditWhitelistId(props) {
        let { whgId } = useParams();
        switch (props.path) {
            case "edit":
                return <WhitelistEdit id={whgId} />;
            case "assign":
                return <WhitelistAssign id={whgId} />;
            case "events":
                return <WhitelistEvents id={whgId} />;
            case "import":
                return <WhitelistImport id={whgId} />;
            case "import-new":
                return <WhitelistImport />;
        }
    }

    const ProductId = () => {
        let {id, menuId, eventId} = useParams();
        return(
            <ItemDetail menu={menuId}  item={id} event={eventId} />
        );
    };

    const OrderId = () => {
        let {id} = useParams();
        return(
            <MenuOrder successPurchase={true} order={id} />
        );
    };

    const EventPreviewId = () => {
        let {id} = useParams();
        return(
            <EventPreview event={id} />
        );
    }

    const EventPreviewIdDark = () => {
        let {eventId, promoterId} = useParams();
        return(
            <ThemeProvider theme={darkTheme}>
                <EventPreviewDark event={eventId} promoter={promoterId} />
            </ThemeProvider>
        );
    }

    const TicketPreviewId = () => {
        let {id} = useParams();
        return(
            <RenderTicket theme={darkTheme} ticket={id} />
        );
    }

    const MenuId = () => {
        let {menuId, eventId} = useParams();
        return(
            <Menu id={menuId} event={eventId} />
        );
    }

    const CarroId = () => {
        let {menuId, eventId} = useParams();
        return(
            <Carro menu={menuId} event={eventId} />
        );
    }

    const ComponentId = () => {
        let {id} = useParams();
        return(
            <CreateMenuComponent component={id} />
        );
    }

    const DigitalMenuId = (props) => {
        let {id, categoryId, productId, eventId, balanceId, transactionId, orderId} = useParams();

        switch (props.path) {
            case "info":
                return <MenuDashboard children={<CreateDigitalMenu menu={id} />} menu={id} />;
            case "categories":
                return <MenuDashboard children={<DigitalMenuCategories menu={id} />} menu={id} />;
            case "categoryId":
                return <MenuDashboard children={<DigitalMenuCategory menu={id} category={categoryId} />} menu={id} />;
            case "categoryIdProducts":
                return <MenuDashboard children={<DigitalMenuCategoryProducts menu={id} category={categoryId} />} menu={id} />;
            case "products":
                return <MenuDashboard children={<DigitalMenuProducts menu={id} />} menu={id} />;
            case "productId":
                return <MenuDashboard children={<DigitalMenuProduct menu={id} product={productId} />} menu={id} />;
            case "productIdAssign":
                return <MenuDashboard children={<DigitalMenuProductAssign menu={id} product={productId} />} menu={id} />;
            case "balances":
                return <MenuDashboard children={<DigitalMenuBalances menu={id} />} menu={id} />;
            case "eventAdd":
                return <MenuDashboard children={<MenuEventAssign menu={id} />} menu={id} />;
            case "balanceId":
                return <MenuDashboard children={<DigitalMenuBalance menu={id} event={eventId} />} menu={id} />;
            case "balanceAdd":
                return <MenuDashboard children={<DigitalMenuBalanceAdd menu={id} event={eventId} />} menu={id} />;
            case "balanceEdit":
                return <MenuDashboard children={<DigitalMenuBalanceAdd menu={id} event={eventId} balance={balanceId} />} menu={id} />;
            case "balanceTransactions":
                return <MenuDashboard children={<DigitalMenuBalanceTransactions balance={balanceId} menu={id} event={eventId} />} menu={id} />;
            case "balanceTransactionAdd":
                return <MenuDashboard children={<DigitalMenuBalanceTransactionAdd balance={balanceId} menu={id} event={eventId} />} menu={id} />;
            case "balanceTransactionEdit":
                return <MenuDashboard children={<DigitalMenuBalanceTransactionAdd transaction={transactionId} balance={balanceId} menu={id} event={eventId} />} menu={id} />;
            case "balanceTransactionsUpload":
                return <MenuDashboard children={<DigitalMenuBalanceTransactionsUpload menu={id} event={eventId} />} menu={id} />;
            case "balanceUpload":
                return <MenuDashboard children={<DigitalMenuBalanceUpload menu={id} event={eventId} />} menu={id} />;
            case "accounts":
                return <MenuDashboard children={<DigitalMenuAccounts menu={id} />} menu={id} />;
            case "currencies":
                return <MenuDashboard children={<DigitalMenuCurrencies menu={id} />} menu={id} />;
            case "addCurrencies":
                return <MenuDashboard children={<DigitalMenuCurrenciesAdd menu={id} />} menu={id} />;
            case "orders":
                return <MenuDashboard children={<MenuOrders menu={id} />} menu={id} />;
            case "orderId":
                return <MenuDashboard children={<MenuSupervisorsOrderId menu={id} id={orderId} />} menu={id} />;
            case "registers":
                return <MenuDashboard children={<DigitalMenuRegisters menu={id} />} menu={id} />;
            case "registerId":
                return <MenuDashboard children={<DigitalMenuRegister menu={id} event={eventId} />} menu={id} />;
            case "registerTransactions":
                return <MenuDashboard children={<DigitalMenuRegisterTransactions balance={balanceId} menu={id} event={eventId} />} menu={id} />;
        }
    }

    const VenueId = (props) => {
        let {id, tableId, productId} = useParams();

        switch (props.path) {
            case "info":
                return <VenueDashboard children={<VenueInfo venue={id} />} venue={id} />;
            case "tables":
                return <VenueDashboard children={<VenueTables venue={id} />} venue={id} />;
            case "tableId":
                return <VenueDashboard children={<VenueTable table={tableId} venue={id} />} venue={id} />;
        }
    }

    const FlashLinkId = () => {
        let {linkId} = useParams();
        return(
            <FlashLinks id={linkId} />
        );
    }

    const MenuOrderSupervisorsIds = () => {
        let {id} = useParams();
        return(
            <MenuSupervisorsOrderId id={id} />
        );
    }

    const MenuSupervisorsBalancesIds = () => {
        let {id} = useParams();
        return(
            <MenuSupervisorsBalancesId id={id} />
        );
    }
    const ResetPasswordId = () => {
        let { token } = useParams();
        return <ResetPassword token={token} theme={darkTheme} />;
    }

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        }
    });

    const lightTheme = createTheme({
        palette: {
            mode: 'light',
        }
    });

    const checkTokenValid = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/validate-jwt`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + getToken,
            }
        });

        if (request.status === 200) {
            return true;
        } else {
            window.localStorage.removeItem('token');
            window.location.href = '/';
        }
    }

    useEffect(() => {
        if (getToken) {
            checkTokenValid();
        }
    }, [getToken]);

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>

                {
                    //<Route path="organizations/create" element={<CreateOrganization />} />
                }

                <Route path="/organizations/dashboard" element={<Home padding={true} children={<Dashboard />} />} />

                <Route exact path="/organizations/events" element={<Home padding={true} children={<Events />} />} />

                <Route exact path="/organizations/events/groups" element={<Home padding={true} children={<EventGroups />} />} />
                <Route exact path="/organizations/events/groups/:id" element={<Home padding={true} children={<EditEventGroupId path={"edit"} />} />} />
                <Route exact path="/organizations/events/groups/new" element={<Home padding={true} children={<EditEventGroupId path={"new"}/>} />} />

                <Route path="organizations/events/:eventId/info" element={<Home padding={false} children={<EditEventId path="info" />} />} />
                <Route path="organizations/events/:eventId/location" element={<Home padding={false} children={<EditEventId path="location" />} />} />
                <Route path="organizations/events/:eventId/tickets" element={<Home padding={false} children={<EditEventId path="tickets" />} />} />
                <Route path="organizations/events/:eventId/tickets/:ticketId" element={<Home padding={false} children={<EditEventId path="editTicket" />} />} />
                <Route path="organizations/events/:eventId/tickets/new" element={<Home padding={false} children={<EditEventId path="newTicket" />} />} />
                <Route path="organizations/events/:eventId/team" element={<Home padding={false} children={<EditEventId path="team" />} />} />
                <Route path="organizations/events/:eventId/attendees" element={<Home padding={false} children={<EditEventId path="attendees" />} />} />
                <Route path="organizations/events/:eventId/analytics" element={<Home padding={false} children={<EditEventId path="analytics" />} />} />
                <Route path="organizations/events/:eventId/publish" element={<Home padding={false} children={<EditEventId path="publish" />} />} />
                <Route path="organizations/events/:eventId/link-tickets" element={<Home padding={false} children={<EditEventId path="link-tickets" />} />} />
                <Route path="organizations/events/:eventId/link-tickets/import" element={<Home padding={false} children={<EditEventId path="link-tickets-import" />} />} />
                <Route path="organizations/events/:eventId/single-link" element={<Home padding={false} children={<EditEventId path="single-link" />} />} />

                <Route path="organizations/team" element={<Home padding={true} children={<Promoters />} />} />
                <Route path="organizations/team/new" element={<Home padding={true} children={<PromoterAdd />} />} />

                <Route path="organizations/team/:promoterId/edit" element={<Home padding={true} children={<EditPromoterId path="edit" />} />} />
                <Route path="organizations/team/:promoterId/assign" element={<Home padding={true} children={<EditPromoterId path="assign" />} />} />
                <Route path="organizations/team/:promoterId/events" element={<Home padding={true} children={<EditPromoterId path="events" />} />} />

                <Route path="organizations/team/groups" element={<Home padding={true} children={<PromoterGroups />} />} />
                <Route path="organizations/team/groups/new" element={<Home padding={true} children={<PromoterGroupsAdd />} />} />

                <Route path="organizations/team/groups/:groupId/edit" element={<Home padding={true} children={<EditPromoterGroupId path="edit" />} />} />
                <Route path="organizations/team/groups/:groupId/assign" element={<Home padding={true} children={<EditPromoterGroupId path="assign" />} />} />
                <Route path="organizations/team/groups/:groupId/events" element={<Home padding={true} children={<EditPromoterGroupId path="events" />} />} />

                <Route path="organizations/userbase" element={<Home padding={true} children={<UserBase />} />} />
                <Route path="organizations/userbase/edit/:userBaseId" element={<Home padding={true} children={<UserBaseId />} />} />
                <Route path="organizations/userbase/new" element={<Home padding={true} children={<UserBaseDetail />} />} />

                <Route path="organizations/marketing" element={<Home padding={true} children={<Marketing />} />} />
                <Route path="organizations/marketing/:campaignId/edit" element={<Home padding={true} children={<CampaignId path={"edit"} />} />} />
                <Route path="organizations/marketing/:campaignId/analytics" element={<Home padding={true} children={<CampaignId path={"analytics"} />} />} />
                <Route path="organizations/marketing/new" element={<Home padding={true} children={<CreateCampaign />} />} />

                <Route path="organizations/refunds" element={<Home padding={true} children={<Refunds />} />} />

                <Route path="organizations/profile" element={<Home padding={true} children={<Profile />} />} />

                <Route exact path="organizations/whitelist" element={<Home padding={true} children={<Whitelist />} />} />
                <Route exact path="organizations/whitelist/new" element={<Home padding={true} children={<WhitelistAdd />} />} />
                <Route exact path="organizations/whitelist/new/import" element={<Home padding={true} children={<EditWhitelistId path={"import-new"} />} />} />
                <Route exact path="organizations/whitelist/:whgId/edit" element={<Home padding={true} children={<EditWhitelistId path={"edit"} />} />} />
                <Route exact path="organizations/whitelist/:whgId/import" element={<Home padding={true} children={<EditWhitelistId path={"import"} />} />} />
                <Route exact path="organizations/whitelist/:whgId/assign" element={<Home padding={true} children={<EditWhitelistId path={"assign"} />} />} />
                <Route exact path="organizations/whitelist/:whgId/events" element={<Home padding={true} children={<EditWhitelistId path={"events"} />} />} />

                <Route path="organizations/settings" element={<Home padding={true} children={<CreatorSettings />} />} />

                <Route path="organizations/create" element={<Profile />} />

                <Route path="organizations/digital-menus" element={<Home padding={true} children={<DigitalMenus />} />} />
                <Route path="organizations/digital-menus/add" element={<Home padding={true} children={<CreateDigitalMenu />} />} />

                <Route path="organizations/digital-menus/:id/info" element={<Home padding={false} children={<DigitalMenuId path={"info"} />} />} />

                <Route path="organizations/digital-menus/:id/categories" element={<Home padding={false} children={<DigitalMenuId path={"categories"} />} />} />
                <Route path="organizations/digital-menus/:id/categories/add" element={<Home padding={false} children={<DigitalMenuId path={"categoryId"} />} />} />
                <Route path="organizations/digital-menus/:id/categories/:categoryId/info" element={<Home padding={false} children={<DigitalMenuId path={"categoryId"} />} />} />
                <Route path="organizations/digital-menus/:id/categories/:categoryId/products" element={<Home padding={false} children={<DigitalMenuId path={"categoryIdProducts"} />} />} />

                <Route path="organizations/digital-menus/:id/products" element={<Home padding={false} children={<DigitalMenuId path={"products"} />} />} />
                <Route path="organizations/digital-menus/:id/products/:productId/info" element={<Home padding={false} children={<DigitalMenuId path={"productId"} />} />} />
                <Route path="organizations/digital-menus/:id/products/:productId/assign" element={<Home padding={false} children={<DigitalMenuId path={"productIdAssign"} />} />} />
                <Route path="organizations/digital-menus/:id/products/add" element={<Home padding={false} children={<DigitalMenuId path={"productId"} />} />} />

                <Route exact path="organizations/digital-menus/:id/balances" element={<Home padding={false} children={<DigitalMenuId path={"balances"} />} />} />
                <Route exact path="organizations/digital-menus/:id/balances/add" element={<Home padding={false} children={<DigitalMenuId path={"eventAdd"} />} />} />
                <Route exact path="organizations/digital-menus/:id/balances/:eventId/info" element={<Home padding={false} children={<DigitalMenuId path={"balanceId"} />} />} />
                <Route exact path="organizations/digital-menus/:id/balances/:eventId/info/add" element={<Home padding={false} children={<DigitalMenuId path={"balanceAdd"} />} />} />
                <Route exact path="organizations/digital-menus/:id/balances/:eventId/info/:balanceId" element={<Home padding={false} children={<DigitalMenuId path={"balanceEdit"} />} />} />
                <Route exact path="organizations/digital-menus/:id/balances/:eventId/info/:balanceId/transactions" element={<Home padding={false} children={<DigitalMenuId path={"balanceTransactions"} />} />} />
                <Route exact path="organizations/digital-menus/:id/balances/:eventId/info/:balanceId/transactions/add" element={<Home padding={false} children={<DigitalMenuId path={"balanceTransactionAdd"} />} />} />
                <Route exact path="organizations/digital-menus/:id/balances/:eventId/info/:balanceId/transactions/:transactionId" element={<Home padding={false} children={<DigitalMenuId path={"balanceTransactionEdit"} />} />} />
                <Route exact path="organizations/digital-menus/:id/balances/:eventId/info/upload" element={<Home padding={false} children={<DigitalMenuId path={"balanceUpload"} />} />} />
                <Route exact path="organizations/digital-menus/:id/balances/:eventId/info/transactions/upload" element={<Home padding={false} children={<DigitalMenuId path={"balanceTransactionsUpload"} />} />} />

                <Route exact path="organizations/digital-menus/:id/orders" element={<Home padding={false} children={<DigitalMenuId path={"orders"} />} />} />
                <Route exact path="organizations/digital-menus/:id/orders/:orderId" element={<Home padding={false} children={<DigitalMenuId path={"orderId"} />} />} />
                <Route exact path="organizations/digital-menus/:id/registers" element={<Home padding={false} children={<DigitalMenuId path={"registers"} />} />} />
                <Route exact path="organizations/digital-menus/:id/registers/:eventId/info" element={<Home padding={false} children={<DigitalMenuId path={"registerId"} />} />} />
                <Route exact path="organizations/digital-menus/:id/registers/:eventId/info/:balanceId/transactions" element={<Home padding={false} children={<DigitalMenuId path={"registerTransactions"} />} />} />
                <Route exact path="organizations/digital-menus/:id/currencies" element={<Home padding={false} children={<DigitalMenuId path={"currencies"} />} />} />
                <Route exact path="organizations/digital-menus/:id/currencies/add" element={<Home padding={false} children={<DigitalMenuId path={"addCurrencies"} />} />} />
                <Route exact path="organizations/digital-menus/:id/accounts" element={<Home padding={false} children={<DigitalMenuId path={"accounts"} />} />} />

                <Route path="organizations/components" element={<Home padding={true} children={<ComponentsList />} />} />
                <Route path="organizations/components/add" element={<Home padding={true} children={<CreateMenuComponent />} />} />
                <Route path="organizations/components/:id/info" element={<Home padding={true} children={<ComponentId />} />} />

                <Route path="organizations/venues" element={<Home padding={true} children={<Venues />} />} />
                <Route path="organizations/venues/add" element={<Home padding={true} children={<VenueInfo />} />} />

                <Route path="organizations/venues/:id/info" element={<Home padding={false} children={<VenueId path={"info"} />} />} />

                <Route path="organizations/venues/:id/tables" element={<Home padding={false} children={<VenueId path={"tables"} />} />} />
                <Route path="organizations/venues/:id/tables/add" element={<Home padding={false} children={<VenueId path={"tableId"} />} />} />
                <Route path="organizations/venues/:id/tables/:tableId/info" element={<Home padding={false} children={<VenueId path={"tableId"} />} />} />

                {<>
                <Route path="/menu/:menuId" element={<MenuId />} />
                <Route exact path="/menu/:menuId/:eventId" element={<MenuId />} />
                <Route path='/menu/:menuId/item/:id' element={<ProductId />} />
                <Route exact path='/menu/:menuId/:eventId/item/:id' element={<ProductId />} />
                <Route path="/menu/:menuId/carro" element={<CarroId />} />
                <Route exact path="/menu/:menuId/:eventId/carro" element={<CarroId />} />
                <Route exact path="/order/:id" element={<OrderId />} />
                <Route exact path="/my-account" element={<AccountMenu />} />

                <Route exact path="/mata-club-waiter-orders" element={<WaiterOrdersList tables={[14]} />} />
                <Route exact path="/mata-club-waiter-orders-2" element={<WaiterOrdersList tables={[3]} />} />
                <Route exact path="/mata-club-panel-barra" element={<PanelBarra />} />

                <Route exact path="/oasis-supervisors" element={<MenuSupervisors />} />
                <Route exact path="/oasis-supervisors/orders" element={<MenuSupervisorsOrders />} />
                <Route exact path="/oasis-supervisors/order/:id" element={<MenuOrderSupervisorsIds />} />

                <Route exact path="/oasis-supervisors/balances" element={<MenuSupervisorsBalances />} />
                <Route exact path="/oasis-supervisors/balances/:id" element={<MenuSupervisorsBalancesIds />} />
                </>}

                {/*
                <Route exact path="/event/:id/light" element={<EventPreviewId />} />

                <Route exact path="/index/light" element={<HomePageUsers />} />
                */}

                <Route exact path="/" element={<HomePageUsersDark theme={darkTheme} />} />

                <Route exact path="/event/:eventId" element={<EventPreviewIdDark />} />
                <Route path="/event/:eventId/:promoterId" element={<EventPreviewIdDark />} />

                <Route exact path="/links/:linkId" element={<FlashLinkId />} />

                <Route path="/success-order" element={<SuccessOrder />} />

                <Route exact path="/tickets" element={<MyTickets theme={darkTheme} />} />
                <Route exact path="/ticket/:id" element={<TicketPreviewId theme={darkTheme} />} />

                <Route exact path="/my-profile" element={<ProfileUsers theme={darkTheme} />} />
                <Route exact path="/register" element={<CreateAccount theme={darkTheme} />} />
                <Route exact path="/forgot-password" element={<ForgotPassword theme={darkTheme} />} />
                <Route exact path="/password-reset/:token" element={<ResetPasswordId />} />

                <Route exact path="/faq" element={<Faq theme={darkTheme} />} />

                <Route path="/login" element={<Login theme={darkTheme} />} />
                <Route path="/google_auth" element={<GoogleAuthenticate />} />


                <Route path="/google_auth" element={<GoogleAuthenticate />} />
                <Route path="*" element={<Navigate to="/" />} />


            </Routes>
        </AnimatePresence>
    );
};

export default AnimatedRoutes;