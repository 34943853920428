import React, {useState} from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import './KPI.css';
import {Link} from "react-router-dom";
import ThreeDotsMenu from "./ThreeDotsMenu";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import AlertSnackbar from "./AlertSnackbar";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar
} from "@mui/material";
import {setEventChange} from "../redux/eventsChangeSlice";
import {number_format} from "./functions/numberFormat";
import {useNavigate} from "react-router";

const EventContainer = (props) => {

    const user = useSelector(selectUser);

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleOpenConfirm = () => setOpenConfirm(true);
    const handleCloseConfirm = () => setOpenConfirm(false);

    const navigate = useNavigate();

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const deleteEvent = async (id) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/event-details?id=${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await request.json();

        if (json.status === "success") {
            setSuccessMessage(json.message);
            setOpenS(true);
            setTimeout(() => {
                props.setIsLoaded(false);
            }, 1500);
        } else {
            setErrorMessage(json.message);
            setOpenE(true);
        }
    }

    /* Snackbars */

    const [openSnackOk, setOpenSnackOk] = useState(false);
    const [openSnackError, setOpenSnackError] = useState(false);

    const handleCloseOk = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackOk(false);
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackError(false);
    }

    /* Snackbars */

    function updateClipboard() {
        document.getElementById("text").type = "text";

        document.getElementById("text").value = `https://flashpass.com.ar/events/${props.event?.event_url}`;

        var text = document.getElementById("text");
        text.select();
        text.setSelectionRange(0, 99999); /*For mobile devices*/

        navigator.clipboard.writeText(text.value);

        text.type = 'hidden';

        document.activeElement.blur();

        setOpenSnackOk(true);
    }

    const duplicateEvent = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/event-duplicate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.token,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                event_id: props.event.event_id,
            })
        });

        const response = await request.json();

        if (response.status === 'success') {
            navigate(`/organizations/events/${response.event_id}/info`);
        }
    }

    const buttonOptions = [
        {
            title: "Administrar",
            link: (props.event.validator === 0 && [1,2,4].includes(props.event.event_active)) ? `/organizations/events/${props.event.event_id}/info` : `/organizations/events/${props.event.event_id}/attendees`,
            icon: <BsThreeDotsVertical size="24" style={{color: "black"}} />,
            click: () => "",
            className: "",
            showRRPP: 0,
        },
        {
            title: "Ver",
            link: "",
            icon: <BsThreeDotsVertical size="24" style={{color: "black"}} />,
            click: () => window.open(`/event/${props.event.event_url}`, '_blank'),
            className: "",
            showRRPP: 0,
        },
        {
            title: "Copiar URL",
            link: ``,
            icon: <BsThreeDotsVertical size="24" style={{color: "black"}} />,
            click: () => updateClipboard(),
            className: "",
            showRRPP: 0,
        },
        {
            title: "Duplicar",
            link: ``,
            icon: <BsThreeDotsVertical size="24" style={{color: "black"}} />,
            click: () => duplicateEvent(),
            className: "",
            showRRPP: 0,
        },
        {
            title: "Eliminar",
            link: "",
            icon: <BsThreeDotsVertical size="24" style={{color: "black"}} />,
            click: () => handleOpenConfirm(),
            className: "text-danger",
            showRRPP: props.event.validator,
        }
    ];

    const fecha = new Date(props.event.event_date);

    return (
        <>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            <AlertSnackbar open={openSnackOk} handleClose={handleCloseOk} severity="success" message="Link copiado al portapapeles." />
            <AlertSnackbar open={openSnackError} handleClose={handleCloseError} severity="error" message="Hubo un error al guardar los datos." />
            <div className="row align-items-start justify-content-between p-0 m-0 mt-3" style={{backgroundColor: "whitesmoke", borderRadius: "10px", boxShadow: "0 3px 5px -2px darkgray", overflow: "hidden"}}>
                <div className="col-auto p-0">
                    <img src={props.event?.portada} alt={props.event?.event_name} style={{width: "140px", height: "140px", display: "block"}} />
                </div>
                <div className="col-xl-9 col-lg-8 col-md-8 col-sm-7 col-5 p-0 mt-1" style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "normal"}}>
                    <div className="row align-items-start">
                        <div className="col-12 col-md-6">
                            <h3 className="titleEvent mb-1">{props.event?.event_name}</h3>
                            <p className="textEvent mb-2">{props.event?.estado}</p>
                            <p className="textEvent text mb-1">{fecha.toLocaleDateString()}</p>
                            <p className="textEvent d-md-block d-none" style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{props.event?.event_zone}</p>
                        </div>
                        <div className="col-3 d-none d-md-block">
                            <p className="titleLabel">Tickets Vendidos</p>
                            <p className="textEvent">
                                {props.event?.tickets_sold}
                                {(props.event?.validator === 0 || props.event?.role_id === 5) &&
                                    <>
                                        /{props.event?.tickets_limits}
                                    </>
                                }
                            </p>
                        </div>
                        <div className="col-3 d-none d-md-block">
                            <p className="titleLabel">Recaudado</p>
                            <p className="textEvent">$ {number_format(props.event?.facturado, 0, ',','.')}</p>
                        </div>
                    </div>
                </div>
                <div className="col-1 d-flex justify-content-end text-end m-0 p-1">
                    <ThreeDotsMenu items={buttonOptions} />
                </div>
            </div>
            <input type="hidden" id="text" />
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"¿Estás seguro que querés eliminar este evento?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Esta acción no se puede deshacer.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{color: "gray"}} autoFocus className="me-2" onClick={handleCloseConfirm}>
                        Cancelar
                    </Button>
                    <Button color={"error"} onClick={() => {
                        deleteEvent(props.event?.event_id);
                        handleCloseConfirm();
                    }} autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EventContainer;
