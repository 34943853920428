import React, {useState} from 'react';
import {
    Alert,
    Button, CircularProgress, FormControl, InputLabel, MenuItem, Select,
    Snackbar,
    TextField
} from "@mui/material";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";

const PromoterGroupsEvents = (props) => {

    const user = useSelector(selectUser)

    const [values, setValues] = useState({
        events: '',
        role: '',
        id: props.promoter,
        cortesias: 0,
        cash: 0,
    });

    const navigate = useNavigate();

    const [events, setEvents] = useState([]);
    const [roles, setRoles] = useState([]);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const updatePromoter = async (e) => {
        e.preventDefault();
        setIsLoaded(false);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/promoter-group-events`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify(values),
        });

        const json = await response.json();

        if (json.status === "success") {
            setIsLoaded(true);
            setOpenS(true);
            setSuccessMessage(json.message);
            setTimeout(() => {
                navigate('/organizations/team/groups/', {replace: true})
            }, 2000);
        }
        else {
            setOpenE(true);
            setErrorMessage(json.message);
        }
    }

    const deletePromoter = async () => {
        setIsLoaded(false);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/promoter-group-events`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify(values),
        });

        const json = await response.json();

        if (json.status === "success") {
            setOpenS(true);
            setSuccessMessage(json.message);
            setTimeout(() => {
                navigate('/organizations/team/groups/', {replace: true})
            }, 2000);
        }
        else {
            setOpenE(true);
            setErrorMessage(json.message);
        }

    }

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/promoter-group-events?id=${props.promoter}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });

        const json = await response.json();

        //console.log(json);

        if (json.status === "success") {
            setEvents(json.events);
            setRoles(json.roles);
            setValues({...values, events: json.events[0].event_id, role: json.events[0].role, cortesias: json.events[0].cortesias, cash: json.events[0].cash});
            setIsLoaded(true);
        }
        else {
            navigate('/organizations/team/groups/', {replace: true})
        }
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const [isLoaded, setIsLoaded] = useState(false);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
                <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                    <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                        El colaborador no tiene eventos asignados!
                    </Alert>
                </Snackbar>
            </div>
        )
    }
    else {
        return (
            <div>
                <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                    <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
                <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                    <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>

                <div className="row">
                    <div className="col">
                        <h1>Editar eventos asignados</h1>
                    </div>
                </div>
                <form onSubmit={updatePromoter}>
                    <div className="row mb-2">
                        <div className="col-md-6">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Evento</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.events}
                                    label="Evento"
                                    onChange={(e) => {
                                        setValues({...values, events: e.target.value, role: events.filter(event => event.event_id === e.target.value)[0].role, cortesias: events.filter(event => event.event_id === e.target.value)[0].cortesias, cash: events.filter(event => event.event_id === e.target.value)[0].cash});
                                    }}
                                >
                                    {events.map((event, index) => (
                                        <MenuItem key={index} value={event.event_id}>{event.event_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row mt-3 mb-2">
                        <div className="col-md-6">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Rol</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.role}
                                    label="Rol"
                                    onChange={handleChange('role')}
                                >
                                    {roles.map((role, index) => (
                                        <MenuItem key={index} value={role.role_id}>{role.role_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <small className="text-muted">
                                {values.role == 1 && (
                                    <>
                                        Permisos de administrador para crear eventos, establecer precios, crear promociones, etc.
                                        <br />
                                        Acceso a la información de venta de sus propios eventos.
                                        <br />
                                        Sin embargo, no tiene permisos de administrador a nivel de empresa y no puede acceder a la información de venta de otros eventos.
                                    </>
                                )}

                                {values.role == 2 && (
                                    <>
                                        Permisos para crear y administrar su propio link de venta.
                                        <br />
                                        Acceso a la información de venta de sus propios boletos vendidos.
                                        <br />
                                        Sin permisos para editar eventos o acceder a la información de venta de otros eventos.
                                    </>
                                )}

                                {values.role == 3 && (
                                    <>
                                        Permisos para crear y administrar su propio link de venta.
                                        <br />
                                        Permisos para asignar sub-RRPPs que puedan vender boletos a través de su propio link.
                                        <br />
                                        Acceso a la información de venta de sus propios boletos vendidos y los boletos vendidos por sus sub-RRPPs.
                                        <br />
                                        Sin permisos para editar eventos o acceder a la información de venta de otros eventos o sub-RRPPs asignados por otros RRPPs.
                                    </>
                                )}

                                {values.role == 5 && (
                                    <>
                                        Permisos para acceder a las estadísticas de ventas de los eventos asignados, como el número de tickets vendidos, los ingresos totales, los patrones de compra y otros datos relevantes.
                                        <br />
                                        Permisos para acceder a la lista digital de los asistentes del evento asignado.
                                        <br />
                                        Sin permisos para editar o modificar la información del evento.
                                    </>
                                )}
                            </small>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <TextField label="Tickets de cortesía disponibles" value={values.cortesias} onChange={handleChange('cortesias')} className="mb-3" type="number" onWheel={(e) => e.preventDefault()}  fullWidth variant="outlined" />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-6">
                            <TextField label="Tickets en efectivo disponibles" value={values.cash} onChange={handleChange('cash')} className="mb-3" type="number" onWheel={(e) => e.preventDefault()}  fullWidth variant="outlined" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 d-flex justify-content-between">
                            <Button variant="contained" type="submit" color="primary" className="me-2">
                                Guardar
                            </Button>
                            <Button variant="contained" color="error" onClick={deletePromoter}>
                                Eliminar del evento
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
};

export default PromoterGroupsEvents;
