import React, {useEffect, useState} from 'react';
import {
    Alert,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {selectEventChange, setEventChange} from "../redux/eventsChangeSlice";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const EditLocation = (props) => {

    const user = useSelector(selectUser);
    const eventChange = useSelector(selectEventChange);
    const dispatch = useDispatch();

    const [venues, setVenues] = useState([]);
    const [provinceList, setProvinceList] = React.useState([]);
    const [cityList, setCityList] = React.useState([]);
    const [values, setValues] = React.useState({
        event_country: '',
        event_province: '',
        event_department: '',
        event_zone: '',
    });

    const [expandedAccordion, setExpandedAccordion] = React.useState(false);
    const [activeVenue, setActiveVenue] = React.useState(null);

    const handleSelectVenue = (venue) => {
        setActiveVenue(venue.id);
        setValues({
            ...values,
            event_country: venue.country_id,
            event_province: venue.province_id,
            event_department: venue.department_id,
            event_zone: venue.address,
        });
        dispatch(setEventChange(true));
        setTimeout(() => {
            handleChangeAccordion();
        },100);
    }

    const handleChangeAccordion = () => {
        setExpandedAccordion(!expandedAccordion);
        document.getElementById("venueAccordion").style.paddingBottom = expandedAccordion ? "0px" : "10px";
    }

    const fetchData = async () => {

        //Fetch province list
        const response = await fetch(`${process.env.REACT_APP_API_URL}/provinces?country=1`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();
        setProvinceList(json);

        //Fetch city list
        const responseCity = await fetch(`${process.env.REACT_APP_API_URL}/departments`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const jsonCity = await responseCity.json();
        setCityList(jsonCity);

        const responseData = await fetch(`${process.env.REACT_APP_API_URL}/event-details-location?event_id=${props.event}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const jsonData = await responseData.json();

        if (jsonData.event_zone !== null) {
            setValues({...values, ...jsonData});
            setActiveVenue(jsonData.venue_id);
        }

        setIsLoaded(true);
    }

    const fetchVenues = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/venues`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
                'credentials': 'same-origin',
            }
        });

        const response = await request.json();

        setVenues(response.data);
    }

    const [isLoaded, setIsLoaded] = useState(false);

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
            fetchVenues();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        setActiveVenue(null);
        dispatch(setEventChange(true));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const request = await fetch(`${process.env.REACT_APP_API_URL}/event-details-location`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                event_id: props.event,
                event_country: 1,
                event_province: values.event_province,
                event_department: values.event_department,
                event_zone: values.event_zone,
                venue_id: activeVenue,
            })
        });
        const json = await request.json();

        if (json.status === 'success') {
            dispatch(setEventChange(false));
            setSuccessMessage(json.message);
            setOpenS(true);
            setIsLoaded(false);
        }
        else {
            setErrorMessage(json.message);
            setOpenE(true);
        }
    }

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
        return (
            <div>
                <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                    <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
                <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                    <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
                <div className="row">
                    <div className="col">
                        <h1>Ubicación</h1>
                    </div>
                </div>
                {venues.length > 0 ? (
                <div className="row mb-4 mt-2">
                    <div className="col">
                        <Accordion id={"venueAccordion"} expanded={expandedAccordion} onChange={handleChangeAccordion} style={{backgroundColor: "whitesmoke"}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <h5>Establecimientos favoritos</h5>
                            </AccordionSummary>
                            <AccordionDetails style={{maxHeight: "300px", overflowY: "scroll"}}>
                                {venues.map((venue, key) => (
                                    <div key={key} className="card mb-2">
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <h5 className="card-title">{venue.name}</h5>
                                                </div>
                                                <div className="col-auto">
                                                    {activeVenue === venue.id ? (
                                                            <button onClick={() => handleSelectVenue(venue)} className ="btn btn-primary disabled float-end">Elegido</button>
                                                        ) : (
                                                            <button onClick={() => handleSelectVenue(venue)} className="btn btn-primary float-end">Elegir</button>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col">
                                                    <p className="card-text">Dirección: {venue.address}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="card-text">Localidad: {venue.department}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="card-text">Provincia: {venue.province}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="card-text">País: {venue.country}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
                ) : null}
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <FormControl fullWidth className="mb-3">
                                <InputLabel id="demo-simple-select-label">País</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value="1"
                                    label="País"
                                    disabled
                                >
                                    <MenuItem value="1">Argentina</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <FormControl fullWidth className="mb-3">
                                <InputLabel id="demo-simple-select-label">Provincia</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.event_province}
                                    label="Provincia"
                                    required
                                    onChange= {(event) => {
                                        setActiveVenue(null);
                                        setValues({ ...values, event_province: event.target.value, event_department: '' });
                                    }}
                                >
                                    {provinceList.map((province, key) => (
                                        <MenuItem key={key} value={province.province_id}>{province.province_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <FormControl fullWidth className="mb-3">
                                <InputLabel id="demo-simple-select-label">Localidad</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.event_department}
                                    label="Localidad"
                                    onChange= {handleChange('event_department')}
                                    required
                                >
                                    {cityList.filter(city => city.province_id === values.event_province).map((city, key) => (
                                        <MenuItem key={key} value={city.department_id}>{city.department_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <TextField label="Dirección" value={values.event_zone} onChange={handleChange('event_zone')} className="mb-3" fullWidth variant="outlined" required />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-primary" onClick={() => {}}>Guardar</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
};

export default EditLocation;