import React, {useEffect, useState} from 'react';
import NavB from "./Navbar";
import {Breadcrumb, Button, Card, Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import {addItem, removeItem, selectCarro, substractItem} from "../redux/carroSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, Navigate, useLocation} from "react-router";
import {selectUser} from "../redux/userSlice";
import MenuHeader from "./MenuHeader";
import NavCategories from "./NavCategories";
import CategoriesContainer from "./CategoriesContainer";
import {motion} from "framer-motion";
import {BsTrash} from "react-icons/bs";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import {number_format} from "./functions/numberFormat";
import {Skeleton} from "@mui/lab";
import ProductVariantSelector from "./ProductVariantSelector";
import {Alert, Radio, TextField} from "@mui/material";
import {withStyles} from "@mui/styles";
import {selectMenu} from "../redux/menuSlice";

const BlackRadio = withStyles({
    checked: {
        color: '#303139 !important',
    },
})(Radio);


const ItemDetail = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const items = useSelector(selectCarro);
    const menu = useSelector(selectMenu);

    const [item, setItem] = useState();
    const [quantity, setQuantity] = useState(1);
    const [menuCategories, setMenuCategories] = useState([]);
    const [disabledAddToCart, setDisabledAddToCart] = useState(false);
    const [ordersInProcess, setOrdersInProcess] = useState(0);
    const [warningMessage, setWarningMessage] = useState("");

    const [isLoaded, setIsLoaded] = useState(false);

    const [values, setValues] = useState({
        variants: [],
    });

    const allVariantsSelected = () => {
        if (!item?.variant_categories) return true;  // if there are no variant categories, return true
        return values?.variants?.every(variant => variant?.value !== null);
    };

    const checkMixedCategories = () => {
        // Check if the current item belongs to a unique category
        const isCurrentItemUnique = item?.unique_in_order;

        // Check if there are items in the cart from a unique category
        const hasUniqueItemsInCart = items.items.some(itemInCart => {
            const category = menuCategories.find(c => c.id === itemInCart.category);
            return category?.unique_in_order;
        });

        // Check if there are items in the cart from a different category than the current item
        const hasDifferentCategoryItemsInCart = items.items.some(itemInCart => itemInCart.category !== item?.category_id);

        // Set the disabled state for Add to Cart button
        if ((isCurrentItemUnique && hasDifferentCategoryItemsInCart) || (hasUniqueItemsInCart && hasDifferentCategoryItemsInCart)) {
            setDisabledAddToCart(true);
        } else {
            setDisabledAddToCart(false);
        }
    };

    const getMenuCategories = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-categories?menu_id=${props.menu}`);
        const response = await request.json();
        setMenuCategories(response.categories);
    }

    useEffect(() => {
        setValues({
            variants: //only the variant_categories ids
                item?.variant_categories?.map((variant_category) => {
                    return {
                        category_id: variant_category?.id,
                        name: variant_category?.title,
                        value: null
                    }
                })
        })
    }, [item]);

    useEffect(() => {
        checkMixedCategories();

        if (ordersInProcess > 0 && item?.price <= 0) {
            setDisabledAddToCart(true);
        }

        if (item?.variant_categories?.length === 1 && item?.variant_categories[0]?.variants?.length === 1) {
            setValues({
                variants: [
                    {
                        category_id: item?.variant_categories[0]?.id,
                        name: item?.variant_categories[0]?.title,
                        value: item?.variant_categories[0]?.variants[0]?.id
                    }
                ]
            });
        }
    }, [item, menuCategories]);

    const fetchData = async () => {
        setIsLoaded(false);
        const request = await fetch(`${process.env.REACT_APP_API_URL}/item-details?item_id=${props.item}&event_id=${props.event}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }});
        const response = await request.json();
        setItem(response);
        setOrdersInProcess(response.orders_in_progress);
        setIsLoaded(true);
    }

    const addToCart = () => {
        dispatch(addItem({
            item: {
                id: props.item.id,
                price: props.item.price,
                variants: values.variants,
                observations: values.observations,
                category: props.item.category_id,
                max_per_order: props.item.max_units_per_order
            }
        }));
    }

    const addToCartButton = () => {
        for (let i = 0; i < quantity; i++) {
            dispatch(addItem({
                item: {
                    id: item.id,
                    price: +calculateTotalPrice(),
                    variants: values.variants,
                    observations: values.observations,
                    category: item.category_id,
                    max_per_order: item.max_units_per_order
                }
            }));
        }

        props.event ? navigate(`/menu/${props.menu}/${props.event}`) : navigate(`/menu/${props.menu}`);
    }

    const substractFromCart = () => {
        dispatch(substractItem({
            item: {
                id: props.item.id,
                price: props.item.price,
                variants: values.variants,
                observations: values.observations,
                category: props.item.category_id,
                max_per_order: props.item.max_units_per_order
            }
        }));
    }

    const removeFromCart = () => {
        dispatch(removeItem({
            item: {
                id: props.item.id,
                price: props.item.price,
                variants: values.variants,
                observations: values.observations,
                category: props.item.category_id,
                max_per_order: props.item.max_units_per_order
            }
        }));
    }

    const user = useSelector(selectUser);

    function calculateTotalPrice() {
        const basePrice = parseFloat(item?.price || 0);

        const variantsPrice = item?.variant_categories?.reduce((acc, curr) => {
            const selectedVariantId = values?.variants?.find(variante => variante?.category_id === curr?.id)?.value;
            const variant = curr?.variants?.find(v => v?.id === selectedVariantId);
            return acc + (parseFloat(variant?.price || 0));
        }, 0) || 0;

        const total = basePrice + variantsPrice;

        return total;
    }

    useEffect(() => {

        // Check if any item in the cart is free
        const hasFreeItem = items.items.some(i => i.price === 0);
        let maxAllowed = item?.max_units_per_order;

        if (hasFreeItem && item?.price <= 0) {
            // If there's a free item, set the quantity of the current item to 0
            setQuantity(0);
        } else {
            // Existing logic to handle quantity constraints based on max units per order
            const countOfThisItem = items.items.filter(i => i.id === item?.id).length;
            maxAllowed = item?.max_units_per_order - countOfThisItem;

            if (quantity >= maxAllowed) {
                setQuantity(maxAllowed < 0 ? 0 : maxAllowed);
            }
        }

        if ((hasFreeItem && item?.price <= 0) || (ordersInProcess > 0 && item?.price <= 0) || (quantity > maxAllowed && maxAllowed)) {
            setWarningMessage("Llegaste al límite de unidades permitidas de este producto.");
            if ((ordersInProcess > 0 && item?.price <= 0)) {
                setWarningMessage("Llegaste al límite de unidades permitidas de este producto. Tenes productos pendientes de entrega.");
            }
        }
    }, [quantity, item, items]);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
        getMenuCategories();
    }, [user.token]);

    return (
            <div className="menu-container" id="menu-container">
                <div className="menu-header" style={{overflow: "hidden"}}>
                    <MenuHeader id={props.menu} event={props.event} />
                </div>
                <motion.div
                    initial={{
                        x: location.state?.direction === "left" ? "-100vw" : "100vw",
                    }}
                    animate={{ x: 0 }}
                    exit={{ x: "100vw" }}
                    transition={{ duration: 0.2 }}
                >
                <div className="menu-body" id="menu-body" style={{overflowY: "scroll", overflowX: "hidden"}}>
                    {isLoaded ? (
                    <div>
                        <img className="img-fluid" src={item?.image} />
                        <div style={{padding: "0 1.6rem"}}>
                            <div className="row align-items-center d-flex">
                                <div className="col-6" style={{marginLeft: 0, paddingLeft: 0}}>
                                    <p className="item-title">{item?.title}</p>
                                    <p className="item-price">
                                        {item?.price == 0 ? "Gratuito" : "$ " + number_format(item?.price, 2, ',', '.')}
                                    </p>
                                </div>
                                <div className="col-6">
                                    <div className="row align-items-top menu-item-quantity-selector">
                                        {quantity > 1 ? (
                                                <div className="col-4 p-0 m-0 text-center"><button onClick={() => setQuantity(quantity -1)} style={{background: "none", color: "inherit", border: "none", padding: 0, font: "inherit", cursor: "pointer", outline: "inherit"}}><AiOutlineMinus /></button></div>
                                        ) : (
                                                <div className="col-4 p-0 m-0 text-center"><button disabled style={{background: "none", color: "inherit", border: "none", padding: 0, font: "inherit", cursor: "pointer", outline: "inherit", opacity: 0.3}}><AiOutlineMinus /></button></div>
                                        )}
                                        <div className="col-4 p-0 m-0 text-center">{quantity}</div>
                                        <div className="col-4 p-0 m-0 text-center"><button disabled={quantity >= item?.max_units_per_order} onClick={() => setQuantity(quantity + 1)} style={{background: "none", color: "inherit", border: "none", padding: 0, font: "inherit", cursor: "pointer", outline: "inherit"}}><AiOutlinePlus /></button></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12" style={{margin: 0, padding: 0}}>
                                    {item?.variant_categories && item?.variant_categories?.map((variant_category, index) => (
                                        <>
                                            <div key={index} className={"row mb-2 mt-3"}>
                                                <div className="col-12">
                                                    <p style={{fontWeight: "bold", fontSize: "1.1rem", margin: 0}}>{variant_category?.title}</p>
                                                    {variant_category?.is_unique && (
                                                        <p className="text-muted mb-0"><small>Elegí una sola opción</small></p>
                                                    )}
                                                </div>
                                            </div>
                                            {variant_category?.variants?.map((variant, index) => (
                                            <>
                                                <div className="row align-items-center justify-content-between">
                                                    <div className="col-10">
                                                        <p style={{ margin: 0, fontWeight: "bold" }}>{variant?.title}</p>
                                                        {variant?.price > 0 ? (
                                                            <p className="mb-2 mt-1" style={{fontWeight: "normal"}}>+ $ {number_format(variant?.price, 2, ',', '.')}</p>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-2 d-flex justify-content-end">
                                                        <BlackRadio
                                                            checked={values?.variants?.find((variante) => variante?.category_id === variant_category?.id)?.value === variant?.id}
                                                            onChange={() => {
                                                                const updatedVariants = values.variants.map((v) => {
                                                                    if (v?.category_id === variant_category?.id) {
                                                                        return {
                                                                            ...v,
                                                                            value: variant?.id
                                                                        };
                                                                    }
                                                                    return v;
                                                                });

                                                                setValues({
                                                                    ...values,
                                                                    variants: updatedVariants
                                                                });
                                                            }}
                                                            value={variant.id}
                                                            name={`radio-buttons-${variant_category?.id}`}
                                                        />
                                                    </div>
                                                </div>
                                                <hr style={{margin: 0}} />
                                            </>
                                            ))}
                                        </>
                                    ))}
                                </div>
                            </div>
                            {warningMessage && <Alert severity="warning" style={{marginTop: "1rem"}}>{warningMessage}</Alert>}
                            <div className="row mt-3 justify-content-center">
                                <div className="col-12 w-100 p-0">
                                    <Button style={{backgroundColor: menu?.header_color, padding: "1rem 0.6rem"}} className="btn-block" onClick={addToCartButton} disabled={!allVariantsSelected() || disabledAddToCart || quantity <= 0}>
                                    <div style={{display: "flex", justifyContent: "space-between"}}>
                                            <span>
                                            Agregar al carrito
                                        </span>
                                        <span>
                                            {(() => {
                                                const basePrice = parseFloat(item?.price || 0) * quantity;

                                                const variantsPrice = item?.variant_categories?.reduce((acc, curr) => {
                                                    const selectedVariantId = values?.variants?.find(variante => variante?.category_id === curr?.id)?.value;
                                                    const variant = curr?.variants?.find(v => v?.id === selectedVariantId);
                                                    return acc + (parseFloat(variant?.price || 0) * quantity);
                                                }, 0) || 0;

                                                const total = basePrice + variantsPrice;

                                                // Assuming number_format-like function is available:
                                                return total === 0 ? "Gratuito" : "$" + number_format(total, 2, ',', '.');
                                            })()}
                                        </span>
                                    </div>
                                    </Button>
                                </div>
                            </div>
                            <div className="row mt-5 text-muted">
                                <div className="col-12" style={{whiteSpace: 'pre-wrap'}}>
                                    <p>{item?.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    ) : (
                        <Card>
                            <Skeleton className={"mt-3"} variant="rectangular" height={250} />
                            <Card.Body>
                                <div className="row align-items-center d-flex">
                                    <div className="col-6">
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={"40%"} />
                                    </div>
                                    <div className="col-6">
                                        <div className="row align-items-top menu-item-quantity-selector">
                                            <Skeleton variant="rectangular" height={30} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3 justify-content-center">
                                    <div className="col-12 w-100 p-0">
                                        <Skeleton variant="rectangular" height={40} />
                                    </div>
                                </div>
                                <div className="row mt-5 text-muted">
                                    <div className="col-12">
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    )}
                </div>
                </motion.div>
            </div>
    );
};

export default ItemDetail;