// OpenRateAndCTRChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';

const OpenRateAndCTRChart = ({ data }) => {
    const chartData = {
        labels: data.map((d) => d.date),
        datasets: [
            {
                label: 'Open Rate',
                data: data.map((d) => d.open_rate),
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 2,
                fill: false,
            },
            {
                label: 'Click Rate',
                data: data.map((d) => d.click_rate),
                borderColor: 'rgba(255, 159, 64, 1)',
                borderWidth: 2,
                fill: false,
            },
            {
                label: 'Open to Click Rate',
                data: data.map((d) => d.click_to_open_rate),
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 2,
                fill: false,
            },
        ],
    };

    return <Line data={chartData} />;
};

export default OpenRateAndCTRChart;

