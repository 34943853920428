import React from 'react';
import {BsThreeDotsVertical} from "react-icons/bs";
import {Menu, MenuItem} from "@mui/material";
import {useNavigate} from "react-router";

const ThreeDotsMenu = (props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();


    return (
        <>
            <div className="threeDots d-flex align-items-center justify-content-center p-0 m-0" style={{height: 32, width: 32}}>
                <button
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    style={{backgroundColor: "transparent", border: "none"}}
                >
                    <BsThreeDotsVertical size="24" style={{color: "black"}} />
                </button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {props.items.filter((option) => option.showRRPP === 0).map((item, index) => (
                        <MenuItem
                            className={item.className}
                            key={index}
                            onClick={
                                item.link ?
                                    () => navigate(item.link) :
                                    () => {
                                        handleClose();
                                        item.click();
                                    }}
                            disabled={item.disabled}
                        >
                            {item.title}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        </>
    );
};

export default ThreeDotsMenu;