import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    TableContainer
} from "@mui/material";
import {IoClose} from "react-icons/io5";


function preventDefault(event) {
  event.preventDefault();
}

export default function Orders(props) {
    const orderData = props.orderData;

    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);



    return (
        <>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <div style={{position: "absolute", right: 0, top: 0, padding: "1rem"}}>
                        <button style={{backgroundColor: "transparent", border: 0}} onClick={handleClose} type="button">
                            <IoClose size={30} />
                        </button>
                    </div>
                    <div className="pe-3 ps-3">
                        <DialogTitle id="scroll-dialog-title">Últimas órdenes</DialogTitle>
                        <DialogContent dividers={scroll === 'paper'} style={{padding: 0}}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Fecha</TableCell>
                                        <TableCell>Evento</TableCell>
                                        <TableCell>Nombre</TableCell>
                                        <TableCell align="right">Monto</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orderData.map((row, key) => (
                                        <TableRow key={key}>
                                            <TableCell>{row.fecha}</TableCell>
                                            <TableCell>{row.evento}</TableCell>
                                            <TableCell>{row.nombre}</TableCell>
                                            <TableCell align="right">{`$${row.venta}`}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cerrar</Button>
                        </DialogActions>
                    </div>
                </Dialog>
            </div>
            <TableContainer component={Paper} style={{borderRadius: "1rem", overflow: "hidden", boxShadow: "0 0 0.5rem #ccc"}}>
                <div className="p-2 bg-white">
                    <Title color={"black"}>Últimas órdenes</Title>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Fecha</TableCell>
                                <TableCell>Evento</TableCell>
                                <TableCell>Nombre</TableCell>
                                <TableCell align="right">Monto</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderData.slice(0,5).map((row, key) => (
                                <TableRow key={key}>
                                    <TableCell>{row.fecha}</TableCell>
                                    <TableCell>{row.evento}</TableCell>
                                    <TableCell>{row.nombre}</TableCell>
                                    <TableCell align="right">{`$${row.venta}`}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Button color="primary" onClick={handleClickOpen('paper')} sx={{ mt: 1 }}>
                        + Ver todas las ventas
                    </Button>
                </div>
            </TableContainer>
        </>
  );
}
