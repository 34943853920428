import React, {useEffect, useState} from 'react';
import './ImageUpload.css';
import Badge from "@mui/material/Badge";
import {Avatar, Button} from "@mui/material";
import SocialMediaContainer from "./SocialMediaContainer";
import {styled} from "@mui/material/styles";
import './profilePreview.css';

const ProfilePreview = (props) => {

    const [previewProfilePic, setPreviewProfilePic] = useState();
    const [previewBanner, setPreviewBanner] = useState();

    useEffect(() => {
        if (!props.values.profilePic) {
            setPreviewProfilePic(undefined)
            return
        }

        if (typeof props.values.profilePic === 'string') {
            setPreviewProfilePic(props.values.profilePic)
        }
        else {
        const objectUrl = URL.createObjectURL(props.values.profilePic)
        setPreviewProfilePic(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
        }
    }, [props.values.profilePic])

    useEffect(() => {
        if (!props.values.banner) {
            setPreviewBanner(undefined)
            return
        }

        if (typeof props.values.banner === 'string') {
            setPreviewBanner(props.values.banner)
        }
        else {
            const objectUrl = URL.createObjectURL(props.values.banner)
            setPreviewBanner(objectUrl)

            // free memory when ever this component is unmounted
            return () => URL.revokeObjectURL(objectUrl)
        }

    }, [props.values.banner]);

    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 25,
        height: 25,
        border: `2px solid ${theme.palette.background.paper}`,
        backgroundColor: 'white',
    }));

    const userTypeMap = {
        "1": "Productora de Eventos",
        "2": "Artista",
        "3": "Promotor",
    }

    return (
        <div className="p-0 pb-4 profilePreview" style={{border: "solid 1px rgba(0,0,0,0.1)", borderRadius: "10px", overflow: "hidden"}}>
            <div className="row">
                <div className="col-12" style={{position: "relative"}}>
                    <div className="img-profile" style={{display: "inline-block", overflow: "hidden", maxHeight: "150px", minHeight: "100px", width: "100%"}}>
                    {previewBanner ? <img className="img-fluid" src={previewBanner} alt="Banner" /> : <img className="img-fluid" src="/assets/images/Flashpass-Banner-Profile.jpg" alt="Banner" />}
                    </div>
                    <div className="img-profile ms-3" style={{position: "absolute", bottom: "-12px"}}>
                        {props.values.verified ? (
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    <SmallAvatar alt="Remy Sharp" src="/assets/images/bluecheck.png" />
                                }
                            >
                                <Avatar style={{height: "100px", width: "100px"}} alt="Travis Howard" src={previewProfilePic} />
                            </Badge>
                        ) : (
                            <Avatar style={{height: "100px", width: "100px", left: "10px"}} alt="Travis Howard" src={previewProfilePic} />
                        )}
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <h1>{props.values.name}</h1>
                            </div>

                            {
                                /* TODO: Add follow button */
                                /*
                                <div className="col-auto d-flex align-items-center justify-content-end">
                                    <Button variant="outlined" style={{
                                        borderRadius: "20px",
                                        color: "#1DA1F2",
                                        borderColor: "#1DA1F2",
                                        marginLeft: "10px"
                                    }}>+ Seguir</Button>
                                </div>
                                 */
                            }
                        </div>
                        <p className="m-0" style={{fontSize: "18px"}}><strong>
                            {userTypeMap[props.values.userType]}
                        </strong></p>

                        {
                            /* TODO: Add followers count */
                            //<p className="m-0">528 seguidores</p>
                        }
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <p>
                            {props.values.description}
                        </p>
                    </div>
                </div>
                <div className="row mt-1 align-items-center justify-content-center">
                    <div className="col-12">
                        <div className="row align-items-center justify-content-center">
                            {props.values.facebook && <SocialMediaContainer icon="facebook" link={props.values.facebook} />}
                            {props.values.instagram && <SocialMediaContainer icon="instagram" link={props.values.instagram} />}
                            {props.values.twitter && <SocialMediaContainer icon="twitter" link={props.values.twitter} />}
                            {props.values.whatsapp && <SocialMediaContainer icon="whatsapp" link={props.values.whatsapp} />}
                        </div>
                    </div>
                </div>
                {props.values.email && (
                <div className="row mt-3 align-items-center justify-content-center">
                    <div className="col-12">
                        <div className="row align-items-center justify-content-center">
                            <Button variant="text" style={{color: "#1DA1F2", borderColor: "#1DA1F2"}}>Enviar Mensaje</Button>
                        </div>
                    </div>
                </div>
                )}
            </div>
        </div>
    );
};

export default ProfilePreview;