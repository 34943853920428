import React, { useState, useEffect } from 'react';
import { CircularProgress, TextField, Button as ButtonMUI } from "@mui/material";
import { useNavigate } from "react-router";
import MenuItemsTable from "./MenuItemsTable";
import { Button } from "react-bootstrap";

const DigitalMenuProducts = (props) => {
    const navigate = useNavigate();
    const [menus, setMenus] = useState([]);
    const [filteredMenus, setFilteredMenus] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const token = localStorage.getItem('token');

    const fetchData = async () => {
        setIsLoaded(false);
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-product?menu_id=${props.menu}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        const data = await request.json();

        if (data.status === "success") {
            setMenus(data.products);
            setFilteredMenus(data.products);
            setIsLoaded(true);
        }
    }

    const deleteProduct = async (id) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-product`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                menu_id: props.menu,
                product_id: id
            }),
        });

        const response = await request.json();

        if (response.status === 'success') {
            fetchData();
        }
    }

    const disableProduct = async (ids) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-product-disable`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                ids: ids
            }),
        });

        const response = await request.json();

        if (response.status === 'success') {
            fetchData();
        }
    }

    const enableProduct = async (ids) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-product-activate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                ids: ids
            }),
        });

        const response = await request.json();

        if (response.status === 'success') {
            fetchData();
        }
    }

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [token]);

    useEffect(() => {
        const results = menus.filter(menu =>
            menu.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredMenus(results);
    }, [searchTerm, menus]);

    return (
        <>
            {!isLoaded ? (
                <div className="d-flex justify-content-center align-items-center" style={{ marginTop: "25vh" }}>
                    <CircularProgress color="secondary" size={100} />
                </div>
            ) : (
                <div>
                    <div className="row mt-2 justify-content-between align-items-center">
                        <div className="col">
                            <h3>Productos</h3>
                        </div>
                        <div className="col-auto">
                            <div className="row justify-content-end">
                                <div className="col-12">
                                    <Button variant="dark" onClick={() => navigate("./add")} className="float-right">+ Crear producto</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <TextField
                                fullWidth
                                label="Buscar productos"
                                variant="outlined"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                margin="normal"
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            {filteredMenus.length > 0 ? (
                                <MenuItemsTable
                                    enableProduct={enableProduct}
                                    disableProduct={disableProduct}
                                    delete={deleteProduct}
                                    page={"menu"}
                                    rows={filteredMenus}
                                    page={"products"}
                                />
                            ) : (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-info" role="alert">
                                            No hay productos creados.
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DigitalMenuProducts;