import React, {useEffect, useState} from 'react';
import {CircularProgress, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import SnackbarAlert from "./SnackbarAlert";
import {useNavigate} from "react-router";

const EventPublish = (props) => {

    const user = useSelector(selectUser);
    const navigate = useNavigate();

    const [publishType, setPublishType] = React.useState("public");
    const [eventStatus, setEventStatus] = React.useState(4);
    const [disabledButton, setDisabledButton] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snack, setSnack] = useState({
        severity: 'success',
        message: 'Usuario creado con éxito'
    });
    const [isLoaded, setIsLoaded] = useState(false);

    const fetchData = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/event-publish?event_id=${props.event}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + user.token,
            }
        });

        const response = await request.json();

        if (response.status === 'success') {
            setPublishType(response.data.publish_type);
            setEventStatus(response.data.status);
            setIsLoaded(true);
        }
    }

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    const handleChange = (event) => {
        setPublishType(event.target.value);
    }

    const publishEvent = async () => {
        setDisabledButton(true);
        const request = await fetch(`${process.env.REACT_APP_API_URL}/event-publish`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                "event_id": props.event,
                "publish_type": publishType,
            })
        });
        const json = await request.json();

        if (json.status === "success") {
            setSnack({
                severity: 'success',
                message: json.message
            });
            setSnackOpen(true);
            setTimeout(() => {
                navigate('/organizations/events', {replace: true});
            }, 1000);
            setDisabledButton(false);
        }
        else {
            setSnack({
                severity: 'error',
                message: json.message
            });
            setSnackOpen(true);
            setDisabledButton(false);
        }
    }

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <>
            <SnackbarAlert message={snack.message} severity={snack.severity} open={snackOpen} setOpen={setSnackOpen} />
            <div className="row mt-3">
                <div className="col-12">
                    <h2>{eventStatus === 1 || eventStatus === 2 ? "Visibilidad del evento" : "Publicar evento"}</h2>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Visibilidad de evento</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={publishType}
                            label="Visibilidad de evento"
                            onChange={handleChange}
                            required
                        >
                            <MenuItem value={"public"}>Publico</MenuItem>
                            <MenuItem value={"private"}>Privado</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            {eventStatus === 1 || eventStatus === 2 ? null : (
                <div className="mb-3">
                    <small className="text-muted">Al publicar el evento estas aceptando nuestros <a href={"https://flashpass.com.ar/terminos-y-condiciones-productor"} target={"_blank"}
                                                                                                        className="text-decoration-underline text-muted">términos y condiciones para el productor.</a></small>
                </div>
            )}
            <div className="row mt-3">
                <div className="col-12">
                    <button className="btn btn-primary" onClick={publishEvent} disabled={disabledButton}>{eventStatus === 1 || eventStatus === 2 ? "Guardar" : "Publicar"}</button>
                </div>
            </div>
        </>
    );
    }
};

export default EventPublish;