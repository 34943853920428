import React from 'react';
import '../styles/BestTimeSales.css';
import {Tooltip, tooltipClasses} from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';


const weekDays = ["LUN", "MAR", "MIE", "JUE", "VIE", "SAB", "DOM"];
const weekDaysComplete = ["LUNES", "MARTES", "MIERCOLES", "JUEVES", "VIERNES", "SABADO", "DOMINGO"];
const hours = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];

const size = 25; // size of the squares in pixels

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const BestSalesTimeChart = (props) => {

    const maxValue = Math.max(...props.bestSalesTimes.map(time => time.value));


    return (
        <div className="p-3" style={{backgroundColor: "white", overflowX: "hidden", borderRadius: "1rem", boxShadow: "0 0 0.5rem #ccc"}}>
            <div className="row">
                <div className="col-12">
                    <h5 className="text-center">Gráfico de ventas por hora y día de la semana</h5>
                </div>
            </div>
            <div className="row">
                <div className="col-6 col-md-3">
                    <div className="degradeBox">
                        <span className="degradeText">0</span>
                        <div className="degradeBar"></div>
                        <span className="degradeText">{maxValue}</span>
                    </div>
                </div>
                <div className="col-6">
                    <span></span>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-12">
                    <div className="salesMapContainer" style={{overflowX: "scroll", overflowY: "hidden", whiteSpace: "nowrap"}}>
                        {[...Array(8)].map((_, i) => (
                            <div key={i} className="row" style={{width: "1000px"}}>
                                {[...Array(25)].map((_, j) => (
                                    <div key={j} className="col text-center" style={{ width: "40px", height: "30px", display: "flex", alignItems: "center"}}>
                                        {j === 0 && weekDays[i]}
                                        {i === 7 && j > 0 && hours[j - 1]}
                                        {props.bestSalesTimes.map(time =>
                                            time.day === i && time.hour === j - 1 ? (
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography color="inherit" fontWeight={"bold"}>{weekDaysComplete[i]}</Typography>
                                                            <Typography color="inherit">{hours[j - 1]}:00</Typography>
                                                            <Typography color="inherit" style={{color: "rgba(138, 43, 226)"}}>{time.value} tickets</Typography>
                                                        </React.Fragment>
                                                    }
                                                    key={i + j}
                                                >
                                                    <div
                                                        key={`${time.day}-${time.hour}`}
                                                        className="col heatMapBox text-center"
                                                        style={{
                                                            backgroundColor: `rgba(138, 43, 226, ${time.value / maxValue})`
                                                        }}
                                                    />
                                                </HtmlTooltip>
                                            ) : null
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>


        </div>
    );
};

export default BestSalesTimeChart;