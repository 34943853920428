import React, {useState} from 'react';
import {
    Alert,
    Button, CircularProgress, FormControl, InputLabel, MenuItem, Select,
    Snackbar,
    TextField
} from "@mui/material";
import {useNavigate} from "react-router";
import MultipleSelect from "./MultipleSelect";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";

const UserBaseDetail = (props) => {

    const user = useSelector(selectUser)

    const [values, setValues] = useState({
        name: '',
        events: [],
    });

    const navigate = useNavigate();

    const [events, setEvents] = useState([]);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const submitGroup = async (e) => {
        e.preventDefault();
        setIsLoaded(false);
        let response;

        if (props.id) {
            response = await fetch(`${process.env.REACT_APP_API_URL}/campaign-groups`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                },
                body: JSON.stringify(values),
            });
        }
        else {
            response = await fetch(`${process.env.REACT_APP_API_URL}/campaign-groups`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                },
                body: JSON.stringify(values),
            });
        }

        const json = await response.json();
        //console.log(json);
        if (json.status === "success") {
            setOpenS(true);
            navigate('../organizations/userbase');
        }
        else {
            setOpenE(true);
        }
        setIsLoaded(true);

    }

    const changeEvents = (e) => {
        setValues({...values, events: e.target.value});
    }

    const fetchData = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/events-campaign-groups`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const response = await request.json();
        //console.log(response);
        if (response.status === "success") {
            setEvents(response.events);

            const requestId = await fetch(`${process.env.REACT_APP_API_URL}/campaign-groups?id=${props.id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                },
            });
            const jsonResponse = await requestId.json();
            //console.log(jsonResponse);
            if (jsonResponse.status === "success") {
                setValues(jsonResponse.group);
                setIsLoaded(true);
            }
        }
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        //console.log(values);
    };

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const [isLoaded, setIsLoaded] = useState(false);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
                <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                    <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                        No hay eventos disponibles para asignar!
                    </Alert>
                </Snackbar>
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    Grupo creado con exito!
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    No se pudo crear el grupo!
                </Alert>
            </Snackbar>

            <div className="row">
                <div className="col-md-6">
                    <h1 className="mb-1">{props.id ? "Editar" : "Crear"} grupos de usuarios</h1>
                </div>
            </div>
            <div className="row mb-2 mt-0">
                <div className="col-md-6">
                    <small className="text-muted">Todos los compradores de los eventos que selecciones formarán parte del grupo de compradores.</small>
                </div>
            </div>
            <form onSubmit={submitGroup}>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <TextField fullWidth id="outlined-basic" label="Nombre" variant="outlined" value={values.name} required onChange={handleChange('name')} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <MultipleSelect tag="Eventos seleccionados" values={values.events} events={events} onChange={changeEvents} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Button variant="contained" type="submit" color="primary" className="me-2">
                            {props.id ? "Editar" : "Crear"} grupo
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
    }
};

export default UserBaseDetail;
