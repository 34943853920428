import React, {useState} from 'react';
import {Alert, CircularProgress, Snackbar, TextField} from "@mui/material";
import {setEventChange} from "../redux/eventsChangeSlice";
import {Button} from "react-bootstrap";
import {selectUser} from "../redux/userSlice";
import {useSelector} from "react-redux";

const VenueTable = (props) => {

    const user = useSelector(selectUser);

    const [values, setValues] = useState({
        id: props.table,
        name: '',
        capacity: '',
        table_number: '',
        table_type: '',
        location: '',
        venue_id: props.venue,
        min_spend: null,
    });

    const [isLoaded, setIsLoaded] = useState(false);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const fetchData = async () => {
        if (props.table) {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/tables?id=${props.table}&venue_id=${props.venue}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                    'Content-Type': 'application/json',
                }
            });

            const json = await request.json();

            if (json.status === "success") {
                setValues(json.data);
                setIsLoaded(true);
            }
        } else {
            setIsLoaded(true);
        }
    }

    React.useEffect(() => {
        if (isLoaded) return;
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        let response;

        if (props.table) {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/tables`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'credentials': 'same-origin',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify(values),
            });

            response = await request.json();
        } else {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/tables`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'credentials': 'same-origin',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify(values),
            });

            response = await request.json();
        }

        if (response.status === "success") {
            setSuccessMessage(response.message);
            setOpenS(true);
        } else {
            setErrorMessage(response.message);
            setOpenE(true);
        }
    }

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            <div className="row mt-2">
                <div className="col-12">
                    <h4>{
                        props.table ? "Editar mesa " : "Crear mesa"
                    }</h4>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-lg-6">
                    <form onSubmit={handleSubmit}>
                        <TextField label="Nro de mesa" value={values.table_number} onChange={handleChange('table_number')} type={"number"} required className="mb-3" fullWidth variant="outlined" />
                        <TextField label="Nombre" value={values.name} onChange={handleChange('name')} className="mb-3" fullWidth variant="outlined" />
                        <TextField label="Capacidad" value={values.capacity} onChange={handleChange('capacity')} className="mb-3" fullWidth variant="outlined" />
                        <TextField label="Tipo" value={values.table_type} onChange={handleChange('table_type')} className="mb-3" fullWidth variant="outlined" />
                        <TextField label="Consumo predeterminado" value={values.min_spend} onChange={handleChange('min_spend')} className="mb-3" fullWidth variant="outlined" />
                        <Button variant="dark" type="submit">
                            {
                                props.table ? "Editar mesa" : "Crear mesa"
                            }
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
    }
};

export default VenueTable;