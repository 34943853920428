import React, {useState} from 'react';
import {Button, Table} from "react-bootstrap";
import {Alert, Snackbar, TextField} from "@mui/material";
import {useNavigate} from "react-router";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {money_format} from "../components/functions/numberFormat";
import swal from "sweetalert2";

const MenuSupervisorsOrderId = (props) => {

    const token = localStorage.getItem('token');

    const [order, setOrder] = useState(null);
    const navigate = useNavigate();

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const fetchData = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-orders-supervisor?order_id=${props.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        const response = await request.json();


        setOrder(response);
    }

    const refundOrder = () => {
        const id = order?.id;

        const mySwal = withReactContent(Swal);
        mySwal.fire({
            title: '¿Estás seguro?',
            text: "Se le devolveran los fondos al comprador.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Reembolsar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                refundRequest(id).then((response) => {
                    if (response.status === "success") {
                        mySwal.fire(
                            '¡Reembolsado!',
                            'El reembolso se ha realizado con éxito.',
                            'success'
                        ).then(() => {
                            fetchData();
                        })
                    }
                    else {
                        mySwal.fire(
                            '¡Error!',
                            'Ha ocurrido un error.',
                            'error'
                        )
                    }
                })
            }
        })
    }

    const refundRequest = async (id) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-orders-supervisor`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                order_id: id
            }),
        });

        const response = await request.json();

        if (response.status === 'success') {
            const mySwal = withReactContent(Swal);
            mySwal.fire(
                '¡Reembolsado!',
                'El reembolso se ha realizado con éxito.',
                'success'
            ).then(() => {
                fetchData();
            })
        } else {
            setErrorMessage(response.message);
            setOpenE(true);
        }
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [token]);

    return (
        <div className="container" style={{backgroundColor: "white", minHeight: "100vh"}}>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            <div className="row mt-3">
                <div className="col-12">
                    <h3>{`Orden #${order?.id}`}</h3>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="order-detail">
                                <div className="order-detail-title">
                                    <p>Información de la orden</p>
                                </div>
                                <div className="order-detail-body">
                                    <div className="order-detail-body-item">
                                        <p className="order-detail-body-item-p mb-0">Punto de
                                            Venta: {order?.pos_name}</p>
                                    </div>
                                    <div className="order-detail-body-item">
                                        <p className="order-detail-body-item-p mb-0">Monto: {money_format(order?.order_total)}</p>
                                    </div>
                                    {order?.table_number ? (
                                        <div className="order-detail-body-item">
                                            <p className="order-detail-body-item-p mb-0">Mesa: {order?.table_number}</p>
                                        </div>
                                    ) : null}
                                    <div className="order-detail-body-item">
                                        <p className="order-detail-body-item-p mb-0">Estado: {order?.order_status}</p>
                                    </div>
                                    {order?.delivery_dttm ? (
                                        <div className="order-detail-body-item">
                                            <p className="order-detail-body-item-p mb-0">Fecha de
                                                entrega: {order?.delivery_dttm}</p>
                                        </div>
                                    ) : null}
                                    {order?.delivered_by ? (
                                        <div className="order-detail-body-item">
                                            <p className="order-detail-body-item-p mb-0">Entregado
                                                por: {order?.delivered_by}</p>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="order-detail">
                                <div className="order-detail-title">
                                    <p>Información del cliente:</p>
                                </div>
                                <div className="order-detail-body">
                                    <div className="order-detail-body-item">
                                        <p className="order-detail-body-item-p mb-0">Email: {order?.client_email}</p>
                                    </div>
                                    <div className="order-detail-body-item">
                                        <p className="order-detail-body-item-p mb-0">Fecha: {order?.order_date}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="order-detail">
                                <div className="order-detail-title">
                                    <p>Items del pedido:</p>
                                </div>
                                <div className="order-detail-body">
                                    {order?.products ? order.products.map((product, index) => {
                                        return (
                                            <>
                                                <div className="order-detail-body-item" key={index}>
                                                    <p className="order-detail-body-item-p mb-0">{product.quantity}x {product.title}</p>
                                                </div>
                                                {product?.variants ? product.variants.map((variant, index) => {
                                                    return (
                                                        <p className="order-detail-body-item-p ms-3 mt-1"
                                                           style={{fontSize: "0.85rem"}}
                                                           key={index}>- {variant.category_name}: {variant.name}</p>
                                                    )
                                                }) : null
                                                }
                                            </>
                                        )
                                    }) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="order-detail">
                                <div className="order-detail-title">
                                    <p>Pagos:</p>
                                </div>
                                <div className="order-detail-body">
                                    {order?.payments ? order.payments.map((payment, index) => {
                                        return (
                                            <>
                                                <div className="order-detail-body-item" key={index}>
                                                    <p className="order-detail-body-item-p mb-0">
                                                        <b>{payment.payment_id}: {payment.status.toUpperCase()} {money_format(payment.transaction_amount)}</b> - {payment.payment_processor} - {payment.payment_method}
                                                    </p>
                                                </div>
                                            </>
                                        )
                                    }) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <Button disabled={order?.order_status === "Reembolsada"} onClick={refundOrder} variant={"danger"} className="w-100">
                                {order?.order_status === "Reembolsada" ? "Orden Reembolsada" : "Reembolsar orden"}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MenuSupervisorsOrderId;