import React, { useEffect, useState } from 'react';
import {
    Alert, AlertTitle,
    CircularProgress,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Snackbar,
    TextField
} from "@mui/material";
import { Button } from "react-bootstrap";
import { selectUser } from "../redux/userSlice";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Grid from "@mui/material/Grid";
import { Button as ButtonMUI } from '@mui/material';
import { useNavigate } from "react-router";
import Swal from 'sweetalert2';
import {money_format} from "./functions/numberFormat";
import moment from "moment-timezone";
import Chip from "@mui/material/Chip";
import {MdOutlineDone} from "react-icons/md";

const DigitalMenuBalanceAdd = (props) => {
    const user = useSelector(selectUser);
    const navigate = useNavigate();

    const [amountLoaded, setAmountLoaded] = useState(true);
    const [menuUserGroups, setMenuUserGroups] = useState([]);
    const [chipTableLabel, setChipTableLabel] = useState('No ingresada');

    const [values, setValues] = useState({
        table: '',
        amount: 0,
        original_amount: 0,
        amountAccredited: 0,
        percentageAccredited: 100,
        email: '',
        payment_type: '',
        userGroup: '',
        rules: [],
    });

    useEffect(() => {
        if (values.table !== '' && !props.balance) {
            const table = tables.find(table => table.table_id === values.table);
            if (table) {
                setValues(prevValues => ({
                    ...prevValues,
                    original_amount: table.min_spend,
                    percentageAccredited: 100,
                }));
            }
        } else if (props.balance && !values.amount) {
            setValues(prevValues => ({
                ...prevValues,
                original_amount: values.original_amount,
                amount: values.amount,
                percentageAccredited: 100,
            }));
        }
    }, [values.table]);

    const [eventDates, setEventDates] = useState({
        start: '',
        end: '',
    });

    const addNewRule = () => {
        setValues(prevValues => ({
            ...prevValues,
            rules: [...prevValues.rules, { type: 'end', amount: 100, amountType: '%', datetime: new Date() }],
        }));
    };

    const removeRule = (index) => {
        const newRules = [...values.rules];
        newRules.splice(index, 1);
        setValues(prevValues => ({
            ...prevValues,
            rules: newRules,
        }));
    };

    const [paymentTypes, setPaymentTypes] = useState([]);

    const updateRule = (index, key, value) => {
        const newRules = [...values.rules];
        newRules[index][key] = value;

        // Ordenar las reglas por fecha para simplificar la validación
        newRules.sort((a, b) => a.datetime - b.datetime);

        // Validar que las reglas no se superpongan
        let overlap = false;
        for (let i = 0; i < newRules.length - 1; i++) {
            if (newRules[i].datetime >= newRules[i + 1].datetime) {
                overlap = true;
                break;
            }
        }

        if (!overlap) {
            setValues(prevValues => ({
                ...prevValues,
                rules: newRules,
            }));
        } else {
            // Aquí puedes manejar el caso cuando hay una superposición.
            // Por ejemplo, podrías mostrar un mensaje de error.
        }
    };

    const getMinDateTimeForRule = (index) => {
        // Si es la primera regla, la fecha mínima será la fecha de inicio del evento
        if (index === 0) {
            return eventDates.start;
        }
        // De lo contrario, la fecha mínima será la fecha de la regla anterior + 1 minuto (o cualquier otro intervalo que desees)
        const previousDateTime = values.rules[index - 1].datetime;
        const minDateTime = new Date(previousDateTime.getTime() + 60 * 1000); // +1 minuto en milisegundos
        return minDateTime;
    };

    const getMaxDateTimeForRule = (index) => {
        // Si es la última regla, la fecha máxima será la fecha de fin del evento
        if (index === values.rules.length - 1) {
            return eventDates.end;
        }
        // De lo contrario, la fecha máxima será la fecha de la próxima regla - 1 minuto (o cualquier otro intervalo que desees)
        const nextDateTime = values.rules[index + 1].datetime;
        const maxDateTime = new Date(nextDateTime.getTime() - 60 * 1000); // -1 minuto en milisegundos
        return maxDateTime;
    };

    const [tables, setTables] = useState([]);

    const [isLoaded, setIsLoaded] = useState(false);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const fetchPaymentMethods = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/fp_payment_methods?from=menu_user_transactions`);
        const response = await request.json();

        if (response.status === "success") {
            setPaymentTypes(response.data);
        }
    };

    const fetchUserGroups = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_V2_URL}/menus/users/groups/${props.menu}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${user.token}`,
                'credentials': 'same-origin',
            }
        });
        const response = await request.json();

        if (response.status === "success") {
            setMenuUserGroups(response.data);
        }
    };

    const fetchData = async () => {
        const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        const moment = require('moment-timezone');
        const gmtOffset = moment.tz(timeZone).utcOffset();

        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-balance?menu_id=${props.menu}&event_id=${props.event}&id=${props.balance}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${user.token}`,
                'credentials': 'same-origin',
                'Content-Type': 'application/json',
            }
        });

        const json = await request.json();

        if (json.status === "success") {
            setTables(json?.tables);

            if (json?.data) {
                const entranceDateTime = new Date(json.data?.table_entrance_timestamp);
                entranceDateTime.setMinutes(entranceDateTime.getMinutes() + gmtOffset);
                entranceDateTime.setSeconds(0);

                const now = new Date();
                const entranceDate = entranceDateTime.getDate();
                const entranceMonth = entranceDateTime.getMonth();
                const entranceYear = entranceDateTime.getFullYear();

                const nowDate = now.getDate();
                const nowMonth = now.getMonth();
                const nowYear = now.getFullYear();

                let formattedDate = '';

                if (entranceYear === nowYear && entranceMonth === nowMonth && entranceDate === nowDate) {
                    // If the timestamp is today, show only hour and minutes
                    formattedDate = `a las ${entranceDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
                } else if (
                    entranceYear === nowYear &&
                    entranceMonth === nowMonth &&
                    entranceDate === nowDate - 1
                ) {
                    // If the timestamp is yesterday, show "ayer" and the time
                    formattedDate = `ayer a las ${entranceDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
                } else if (entranceYear === nowYear) {
                    // If the timestamp is from this year but not today or yesterday
                    formattedDate = `a las ${entranceDateTime.getDate()} de ${entranceDateTime.toLocaleString('es-ES', { month: 'long' })} a las ${entranceDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
                } else {
                    // If the timestamp is from another year
                    formattedDate = `a las ${entranceDateTime.getDate()} de ${entranceDateTime.toLocaleString('es-ES', { month: 'long' })} de ${entranceYear} a las ${entranceDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
                }

                setValues(prevValues => ({
                    ...prevValues,
                    ...json.data,
                    table_entrance_timestamp_formatted: formattedDate
                }));

                json.data.rules.forEach((rule, index) => {
                    const ruleDate = new Date(rule.datetime);
                    ruleDate.setMinutes(ruleDate.getMinutes() + gmtOffset);
                    ruleDate.setSeconds(0);
                    json.data.rules[index].datetime = ruleDate;
                });
            }



            const eventDateEnd = new Date(json?.event_dates.event_date_end);
            eventDateEnd.setMinutes(eventDateEnd.getMinutes() + gmtOffset);
            eventDateEnd.setSeconds(0);

            const eventDateStart = new Date(json?.event_dates.event_date_start);
            eventDateStart.setMinutes(eventDateStart.getMinutes() + gmtOffset);
            eventDateStart.setSeconds(0);

            setEventDates({
                start: eventDateStart,
                end: eventDateEnd,
            });

            await fetchPaymentMethods();
            await fetchUserGroups();
            setIsLoaded(true);
        }
    };

    useEffect(() => {
        if (isLoaded) return;
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const paymentTypeLabel = paymentTypes.find(paymentType => paymentType.id === values.payment_type)?.label || '';
        const saldoInfo = () => {
            if (values.table) {
                return `
                Monto de la mesa: ${money_format(values.original_amount)}<br>
                Saldo actual: ${money_format(values.amount)}<br>
                `
            } else if(!values.table && !props.balance) {
                return `
                Monto pagado: <b>${money_format(values.original_amount)}</b><br>
                ${paymentTypeLabel ? `Método de pago: <b>${paymentTypeLabel}</b><br>` : ''}
                Porcentaje Acreditado: <b>${values.percentageAccredited}%</b><br>
                Monto acreditado: <b>${money_format(values.amountAccredited)}</b><br>
            `;
            } else {
                return `
                Saldo actual: ${money_format(values.amount)}<br>
            `;
            }
        }

        const result = await Swal.fire({
            title: 'Confirmar carga',
            html: `¿Está seguro de que desea ${props.balance ? 'editar' : 'cargar'} el saldo con la siguiente información?<br><br>
               ${values?.table ? '<b>' + tables.find(table => table.table_id === values.table).table_name + '</b><br>' : ''}
               Email: <b>${values.email}</b><br>
               ${saldoInfo()}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            let response;

            if (props.balance) {
                //const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-balance`, {
                const request = await fetch(`${process.env.REACT_APP_API_V2_URL}/menus/users/balances`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'credentials': 'same-origin',
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    },
                    body: JSON.stringify({
                        attendees: [values],
                        menu_id: props.menu,
                        event_id: props.event,
                        id: props.balance,
                        menuId: props.menu,
                        eventId: props.event
                    }),
                });

                response = await request.json();

                if (response.status === "success") {
                    setSuccessMessage(response.message);
                    setOpenS(true);
                } else {
                    setErrorMessage(response.message);
                    setOpenE(true);
                }

            } else {
                //const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-balance`, {
                const request = await fetch(`${process.env.REACT_APP_API_V2_URL}/menus/users/balances`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'credentials': 'same-origin',
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    },
                    body: JSON.stringify({
                        attendees: [values],
                        menuId: props.menu,
                        eventId: props.event,
                        menu_id: props.menu,
                        event_id: props.event,
                    }),
                });

                response = await request.json();
            }

            if (response.status === "success") {
                setSuccessMessage(response.message);
                setOpenS(true);
            } else {
                setErrorMessage(response.message);
                setOpenE(true);
            }
        }
    };

    const enterTable = async () => {
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: 'Se marcará el horario actual como horario de ingreso de la mesa.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            Swal.fire({
                title: 'Cargando...',
                text: 'Por favor, espera mientras se procesa la solicitud.',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            try {
                const request = fetch(`${process.env.REACT_APP_API_V2_URL}/menus/users/balances/tables/enter`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.token}`,
                        'credentials': 'same-origin',
                    },
                    body: JSON.stringify({
                        tableId: values.table,
                        eventId: props.event,
                        menuUserId: props.balance,
                        menuId: props.menu,
                    }),
                });

                const response = await request;
                const data = await response.json();

                Swal.close();

                if (response.ok) {
                    Swal.fire(
                        '¡Éxito!',
                        data.message || 'La mesa ha sido ingresada correctamente.',
                        'success'
                    );
                    fetchData();
                } else {
                    Swal.fire(
                        '¡Error!',
                        data.message || 'Hubo un problema al ingresar la mesa.',
                        'error'
                    );
                }
            } catch (error) {
                Swal.close();
                Swal.fire(
                    '¡Error!',
                    'Ocurrió un error inesperado.',
                    'error'
                );
            }
        }
    };

    useEffect(() => {
        if (values && values.percentageAccredited != null) {
            const { percentageAccredited, original_amount } = values;

            if (percentageAccredited >= 0 || isNaN(percentageAccredited)) {
                const amountAccredited = Math.round(original_amount * (isNaN(percentageAccredited) ? 0 : percentageAccredited / 100));

                setValues(prevValues => ({
                    ...prevValues,
                    amountAccredited: amountAccredited,
                    percentageAccredited: percentageAccredited
                }));
            }
        }
        setAmountLoaded(true);
    }, [values.percentageAccredited, values.original_amount]);

    const handleAmountChange = (e) => {
        setAmountLoaded(false);
        const value = Math.round(parseFloat(e.target.value));
        setValues(prevValues => ({
            ...prevValues,
            amount: value
        }));
    };

    const handleOriginalAmountChange = (e) => {
        setAmountLoaded(false);
        const value = Math.round(parseFloat(e.target.value));
        setValues(prevValues => ({
            ...prevValues,
            original_amount: value
        }));
    };

    const handlePercentageChange = (e) => {
        setAmountLoaded(false);
        const value = Math.round(parseFloat(e.target.value));
        setValues(prevValues => ({
            ...prevValues,
            percentageAccredited: value
        }));
    };

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ marginTop: "25vh" }}>
                <CircularProgress color="secondary" size={100} />
            </div>
        );
    } else {
        return (
            <div>
                <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                    <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
                <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                    <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>

                <div className="row mt-2 justify-content-between align-items-center">
                    {!props.balance ? (
                        <>
                            <div className="col">
                                <h4>Cargar saldo</h4>
                            </div>
                            {props.balance ? null : (
                                <div className="col-auto">
                                    <div className="row justify-content-end">
                                        <div className="col-12">
                                            <Button variant="dark" onClick={() => navigate(`/organizations/digital-menus/${props.menu}/balances/${props.event}/info/upload`)} className="float-right">Cargar desde excel</Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="col">
                            <h4>Editar saldo</h4>
                        </div>
                    )}
                </div>

                <div className="row mt-3">
                    <div className="col-lg-6">
                        <form onSubmit={handleSubmit}>
                            {tables.length > 0 ? (
                                <>
                                    <div className="row align-items-center">
                                        <div
                                            className={`${(values.table && props.balance && values?.balance_table_edit) ? "col-7 col-md-8 col-lg-9" : "col-12"}`}>
                                            <FormControl fullWidth variant="outlined" className="me-3">
                                                <InputLabel id={`tables-select-label`}>Nro de Mesa</InputLabel>
                                                <Select
                                                    labelId={`tables-select-label`}
                                                    label={"Nro de Mesa"}
                                                    id={`tables-select-label`}
                                                    value={values.table}
                                                    onChange={handleChange('table')}
                                                    fullWidth
                                                >
                                                    <MenuItem value="">
                                                        <em>Ninguno</em>
                                                    </MenuItem>
                                                    {tables.map((table) => (
                                                        <MenuItem
                                                            value={table.table_id}>{table.table_number} - {table.table_name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {values.table && props.balance && values?.balance_table_edit ? (
                                            <div className="col-5 col-md-4 col-lg-3 p-0 m-0">
                                                {values?.table_entered ? (
                                                    <Chip size={"small"} variant="outlined" color="success"
                                                          icon={<MdOutlineDone/>} label={"Ingresada"}/>
                                                ) : (
                                                    <Chip size={"small"}
                                                          variant="outlined"
                                                          color="warning"
                                                          label={chipTableLabel}
                                                          onMouseEnter={() => setChipTableLabel('Ingresar')}
                                                          onMouseLeave={() => setChipTableLabel('No ingresada')}
                                                          onClick={enterTable}
                                                    />
                                                )}

                                                {values?.amount_due <= 0 ? (
                                                    <Chip size={"small"} className="mt-1" variant="outlined"
                                                          color="success"
                                                          icon={<MdOutlineDone/>} label={"Pagada"}/>
                                                ) : (
                                                    <Chip size={"small"} className="mt-1" variant="outlined"
                                                          color="error"
                                                          label={"Saldo pendiente"}/>
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                    {values?.table_entrance_timestamp && values?.table_entered ? (
                                        <small><b>Ingresada {values.table_entrance_timestamp_formatted}</b></small>
                                    ) : null}
                                </>
                            ) : null}
                            <div className="row mt-2">
                                <div className="col-12">
                                    <TextField type={"email"} required fullWidth label="Email" variant="outlined"
                                               value={values.email} onChange={handleChange('email')}/>
                                </div>
                            </div>
                            {values.table || !props.balance ? (
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <FormControl required fullWidth>
                                            <InputLabel
                                                htmlFor="outlined-adornment-amount">{values.table ? "Monto de la mesa" : "Monto pagado"}</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-amount"
                                                value={values.original_amount}
                                                onChange={handleOriginalAmountChange}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                label={values.table ? "Monto de la mesa" : "Monto pagado"}
                                                type="number"
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            ) : null}
                            {values.table || props.balance ? (
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <FormControl required fullWidth>
                                            <InputLabel htmlFor="outlined-adornment-amount">Saldo actual</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-amount"
                                                value={values.amount}
                                                onChange={handleAmountChange}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                label="Saldo actual"
                                                type="number"
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            ) : null}
                            {!values.table && !props.balance ? (
                                <>
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <FormControl fullWidth>
                                                <InputLabel
                                                    id="demo-simple-select-label">Método de pago</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={values.payment_type}
                                                    label="Método de pago"
                                                    onChange={handleChange('payment_type')}
                                                    name={'payment_type'}
                                                    required
                                                >
                                                    {paymentTypes.map((payment_type, key) => (
                                                        <MenuItem key={key}
                                                                  value={payment_type.id}>{payment_type.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <FormControl required fullWidth>
                                                <InputLabel htmlFor="outlined-adornment-amount">Porcentaje a
                                                    acreditar</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-amount"
                                                    value={values.percentageAccredited}
                                                    onChange={handlePercentageChange}
                                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                    label="Porcentaje a acreditar"
                                                    type="number"
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="col-6">
                                            <FormControl required fullWidth>
                                                <InputLabel htmlFor="outlined-adornment-amount">Monto a
                                                    acreditar</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-amount"
                                                    value={values.amountAccredited}
                                                    onChange={handleChange('amountAccredited')}
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                    label="Monto a acreditar"
                                                    type="number"
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                    {values.amountAccredited != values.percentageAccredited * values.original_amount / 100 && amountLoaded ? (
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <Alert severity="warning">
                                                    <AlertTitle>
                                                        El monto a acreditar no coincide con el porcentaje ingresado.
                                                    </AlertTitle>
                                                    Se ignorará el porcentaje y se acreditará el monto a acreditar.
                                                </Alert>
                                            </div>
                                        </div>
                                    ) : null}
                                </>
                            ) : null}

                            <div className="row mt-3">
                                <div className="col-12">
                                    <FormControl fullWidth>
                                        <InputLabel
                                            id="demo-simple-select-label">Grupo de usuarios</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={values.userGroup}
                                            label="Grupo de usuarios"
                                            onChange={handleChange('userGroup')}
                                            name={'userGroup'}
                                        >
                                            <MenuItem value={null}>
                                                <em>Ninguno</em>
                                            </MenuItem>
                                            {menuUserGroups.map((mug, key) => (
                                                <MenuItem key={key}
                                                          value={mug.id}>{mug.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>


                            {values.table ? (
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h5>Reglas</h5>
                                            </div>
                                            <div className="col-auto">
                                                <ButtonMUI variant="text" onClick={addNewRule}>Agregar regla</ButtonMUI>
                                            </div>
                                        </div>
                                        {values.rules.map((rule, index) => (
                                            <Grid className={"mt-2"} container spacing={2} key={index}>
                                                <Grid item xs={5}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id={`rule-type-label-${index}`}>Tipo de
                                                            regla</InputLabel>
                                                        <Select
                                                            labelId={`rule-type-label-${index}`}
                                                            label="Tipo de regla"
                                                            value={rule.type}
                                                            onChange={(e) => updateRule(index, 'type', e.target.value)}
                                                        >
                                                            <MenuItem value={"start"}>Valido desde:</MenuItem>
                                                            <MenuItem value={"end"}>Valido hasta:</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={7}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DateTimePicker
                                                            renderInput={(props) => <TextField fullWidth {...props} />}
                                                            label="Fecha y hora"
                                                            value={rule.datetime}
                                                            inputFormat={'dd/MM/yyyy HH:mm'}
                                                            ampm={false}
                                                            onChange={(newValue) => {
                                                                newValue.setSeconds(0);
                                                                updateRule(index, 'datetime', newValue);
                                                            }}
                                                            minDateTime={getMinDateTimeForRule(index)}
                                                            maxDateTime={getMaxDateTimeForRule(index)}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <OutlinedInput
                                                            startAdornment={
                                                                <InputAdornment className="noBorderForThisSelect"
                                                                                position="start"
                                                                                style={{marginRight: 0}}>
                                                                    <Select
                                                                        value={rule.amountType}
                                                                        onChange={(e) => updateRule(index, 'amountType', e.target.value)}
                                                                        style={{
                                                                            minWidth: 50,
                                                                            background: 'white',
                                                                            marginLeft: '-15px'
                                                                        }}
                                                                        MenuProps={{
                                                                            anchorOrigin: {
                                                                                vertical: "bottom",
                                                                                horizontal: "left"
                                                                            },
                                                                            transformOrigin: {
                                                                                vertical: "top",
                                                                                horizontal: "left"
                                                                            },
                                                                            getContentAnchorEl: null
                                                                        }}
                                                                    >
                                                                        <MenuItem value={'$'}>$</MenuItem>
                                                                        <MenuItem value={'%'}>%</MenuItem>
                                                                    </Select>
                                                                </InputAdornment>
                                                            }
                                                            type="number"
                                                            value={rule.amount}
                                                            onChange={(e) => updateRule(index, 'amount', e.target.value)}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                {/* Delete Rule Button */}
                                                <Grid item xs={12}>
                                                    <ButtonMUI variant="text" color="error"
                                                               onClick={() => removeRule(index)}>Eliminar
                                                        regla</ButtonMUI>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </div>
                                </div>
                            ) : null}

                            <div className="row mb-4">
                                <div className="col-12">
                                    <Button variant={"dark"} type={"submit"} className="mt-3">
                                        {
                                            props.balance ? "Editar saldo" : "Cargar saldo"
                                        }
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
};

export default DigitalMenuBalanceAdd;
