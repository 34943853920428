import React from 'react';
import SidebarMenu from "./SidebarMenu";
import {Container} from "react-bootstrap";

const MenuDashboard = (props) => {

    return (
        <div>
            <div className="row" style={{maxHeight: "calc(100vh - 60px)", overflowY: "hidden"}}>
                <div className="col-lg-auto col-12" style={{margin: 0, padding: 0}}>
                    <SidebarMenu menu={props.menu}  />
                </div>
                <div className="col" style={{overflowY: "auto", height: "100vh", paddingBottom: "150px"}}>
                    <Container>
                        {props.children}
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default MenuDashboard;