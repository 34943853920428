import React, {useEffect, useState} from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart } from 'chart.js';
import 'chartjs-adapter-date-fns';


const DoughnutChart = (props) => {

    const { x1, x2 } = props;
    const percentage = (x1 / (x1 + x2)) * 100;

    return (
        <div className="p-3" style={{position: "relative", borderRadius: ".5rem", boxShadow: "0 0 0.5rem #ccc"}}>
            <span style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontSize: "1.5rem"}}>{percentage.toFixed(0)}%</span>
            <Doughnut
                data={{
                    labels: props.labels,
                    datasets: [{
                        data: [props.x1, props.x2],
                        backgroundColor: [
                            'rgba(138, 43, 226, 0.2)',
                            'rgba(255, 85, 255, 0.2)',
                        ],
                        borderColor: [
                            'rgba(138, 43, 226, 1)',
                            'rgba(255, 85, 255, 1)',
                        ],
                        borderWidth: 1,
                }]
                }}
                options={{
                    plugins: {
                        legend: {
                            display: false,
                            align: 'center',
                            position: 'bottom',
                        },
                    },
                    cutout: '80%',
                }}
            />
        </div>
    )
}

export default DoughnutChart;
