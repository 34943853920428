import React, {useEffect, useState} from 'react';
import {
    Alert, Button as ButtonMUI,
    Button, CircularProgress, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select,
    Snackbar,
    TextField
} from "@mui/material";
import './Whitelist.css';
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {Table} from "react-bootstrap";
import ButtonContainer from "./ButtonContainer";
import {RiDeleteBin6Line, RiFileEditLine, RiFileExcel2Line} from "react-icons/ri";
import {useNavigate} from "react-router";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import FileInput from "./FileInput";
import {IoClose} from "react-icons/io5";
import {FaUpload} from "react-icons/fa";
import {AiOutlinePicture} from "react-icons/ai";
import Grid from "@mui/material/Grid";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";

const DigitalMenuBalanceUpload = (props) => {

    const user = useSelector(selectUser);
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);

    const [values, setValues] = useState({
        rules: [],
    });

    const [eventDates, setEventDates] = useState({
        start: '',
        end: '',
    });

    const [tables, setTables] = useState([]);

    const [auxEmail, setAuxEmail] = useState('');
    const [edit, setEdit] = useState(false);

    const [tickets, setTickets] = useState([]);
    const [link, setLink] = useState("");
    const [owner, setOwner] = useState(false);
    const [validData, setValidData] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);

    const addNewRule = () => {
        setValues({
            ...values,
            rules: [...values.rules, { type: 'end', amount: 100, amountType: '%', datetime: new Date() }],
        });
    };

    const removeRule = (index) => {
        const newRules = [...values.rules];
        newRules.splice(index, 1);
        setValues({
            ...values,
            rules: newRules,
        });
    };

    const updateRule = (index, key, value) => {
        const newRules = [...values.rules];
        newRules[index][key] = value;

        // Ordenar las reglas por fecha para simplificar la validación
        newRules.sort((a, b) => a.datetime - b.datetime);

        // Validar que las reglas no se superpongan
        let overlap = false;
        for (let i = 0; i < newRules.length - 1; i++) {
            if (newRules[i].datetime >= newRules[i + 1].datetime) {
                overlap = true;
                break;
            }
        }

        if (!overlap) {
            setValues({
                ...values,
                rules: newRules,
            });
        } else {
            // Aquí puedes manejar el caso cuando hay una superposición.
            // Por ejemplo, podrías mostrar un mensaje de error.
        }
    };


    const getMinDateTimeForRule = (index) => {
        // Si es la primera regla, la fecha mínima será la fecha de inicio del evento
        if (index === 0) {
            //return eventDates.start;
            return eventDates.start;
        }
        // De lo contrario, la fecha mínima será la fecha de la regla anterior + 1 minuto (o cualquier otro intervalo que desees)
        const previousDateTime = values.rules[index - 1].datetime;
        const minDateTime = new Date(previousDateTime.getTime() + 60 * 1000); // +1 minuto en milisegundos
        return minDateTime;
    };

    const getMaxDateTimeForRule = (index) => {
        // Si es la última regla, la fecha máxima será la fecha de fin del evento
        if (index === values.rules.length - 1) {
            return eventDates.end;
        }
        // De lo contrario, la fecha máxima será la fecha de la próxima regla - 1 minuto (o cualquier otro intervalo que desees)
        const nextDateTime = values.rules[index + 1].datetime;
        const maxDateTime = new Date(nextDateTime.getTime() - 60 * 1000); // -1 minuto en milisegundos
        return maxDateTime;
    };

    const categories = [
        {
            value: 'free',
            label: 'Cortesía',
        },
        {
            value: 'paid',
            label: 'Venta en efectivo',
        },
    ];

    const [manualInput, setManualInput] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [emails, setEmails] = useState([]);

    const swalAlert = (attendee) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, borrarlo!'
        }).then((result) => {
            if (result.isConfirmed) {
                //console.log("Borrando");
            }
        })
    }

    function getViewport () {
        // https://stackoverflow.com/a/8876069
        const width = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
        )

        if (width <= 992) return 'md'
        return 'xl'
    }
    function searchData() {
        // Declare variables
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();

        if (getViewport() == 'xl') {
            table = document.getElementsByTagName("table")[0];

            tr = table.getElementsByTagName("tr");

            // Loop through all table rows, and hide those who don't match the search query
            for (i = 0; i < tr.length; i++) {
                var td0 = tr[i].getElementsByTagName("td")[0];

                if (td0) {
                    var txtValue0 = td0.textContent || td0.innerText;
                    if (txtValue0.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                    } else {
                        tr[i].style.display = "none";
                    }
                }
            }
        } else {
            table = document.getElementsByTagName("table")[1];

            tr = table.getElementsByTagName("tr");

            // Loop through all table rows, and hide those who don't match the search query
            for (i = 0; i < tr.length; i++) {
                var td0 = tr[i].getElementsByTagName("td")[0];
                if (td0) {
                    var txtValue0 = td0.textContent || td0.innerText;
                    if (txtValue0.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                    } else {
                        tr[i].style.display = "none";
                    }
                }
            }
        }

    }

    const fetchData = async () => {

        // Retrieve client's timezone identifier
        const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Retrieve client's time difference in minutes and its direction
        const moment = require('moment-timezone');
        const gmtOffset = moment.tz(timeZone).utcOffset();


        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-balance?menu_id=${props.menu}&event_id=${props.event}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${user.token}`,
                'credentials': 'same-origin',
                'Content-Type': 'application/json',
            }
        });

        const json = await request.json();

        if (json.status === "success") {
            setTables(json.tables);

            if (json?.data) {
                setValues(json.data);

                json.data.rules.forEach((rule, index) => {
                    const ruleDate = new Date(rule.datetime);
                    ruleDate.setMinutes(ruleDate.getMinutes() + gmtOffset);
                    ruleDate.setSeconds(0); // Set seconds to 0

                    json.data.rules[index].datetime = ruleDate;
                });
            }

            const eventDateEnd = new Date(json?.event_dates.event_date_end);
            eventDateEnd.setMinutes(eventDateEnd.getMinutes() + gmtOffset);
            eventDateEnd.setSeconds(0); // Set seconds to 0

            const eventDateStart = new Date(json?.event_dates.event_date_start);
            eventDateStart.setMinutes(eventDateStart.getMinutes() + gmtOffset);
            eventDateStart.setSeconds(0); // Set seconds to 0

            setEventDates({
                start: eventDateStart,
                end: eventDateEnd,
            });

            setIsLoaded(true);
        }
    }

    React.useEffect(() => {
        if (isLoaded) return;
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [SnackbarSuccessMessage, setSnackbarSuccessMessage] = useState('');
    const [SnackbarErrorMessage, setSnackbarErrorMessage] = useState('');

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const handleUpload = (event) => {
        setMessage('');
        setErrorMessage('');

        let file = event.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: 'array' });
            let firstSheet = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[firstSheet];
            let newData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });

            let validData = [];
            let errorData = [];

            for (let row of newData) {
                // Continue to next row if current row is empty
                if (row.length === 0) continue;

                let tableNumber = row[0] || null;  // Set to null if it's 0 or not set
                let email = row[1] ? row[1].trim() : "";  // Remove leading and trailing spaces and handle empty email
                let rawAmount = row[2] || 0;  // Set to null if it's 0 or not set
                let quantity = row[3] || 0;  // Set to null if it's 0 or not set
                let original_amount = 0;

                if (rawAmount) {
                    let amountString = rawAmount.toString();
                    // Remove any currency symbols
                    amountString = amountString.replace(/[^\d,.-]/g, '');

                    // Detect and convert using the correct decimal separator
                    let lastDotIndex = amountString.lastIndexOf('.');
                    let lastCommaIndex = amountString.lastIndexOf(',');
                    if (lastCommaIndex > lastDotIndex) {
                        // Comma is used as decimal separator
                        amountString = amountString.replace(/\./g, '').replace(',', '.');
                    } else {
                        // Dot is used as decimal separator
                        amountString = amountString.replace(/,/g, '');
                    }
                    let amount = parseFloat(amountString);

                    // Validate amount
                    if (!(amount > 0)) {
                        errorData.push({ tableNumber, email, amount, quantity });
                        continue;
                    }

                    // Validate email
                    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (emailRegex.test(email) && (quantity >= 0 && quantity <= 20) && (amount >= 0)) {
                        original_amount = amount;
                        amount = 0;
                        validData.push({ tableNumber, email, amount, quantity, original_amount });
                    } else {
                        errorData.push({ tableNumber, email, amount, quantity });
                    }
                } else {
                    errorData.push({ tableNumber, email, amount: null, quantity });
                }
            }

            setValidData([...validData]);
            setMessage(`Se agregarán ${validData.length} nuevos registros.`);

            if (errorData.length > 0) {
                setErrorMessage(`${errorData.length} registros no válidos: ${errorData.map(record => record.email).join(", ")}`);
            }
        };
        reader.readAsArrayBuffer(file);
    };




    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        handleUpload(event);
    };

    const addAttendees = async (e) => {
        e.preventDefault();
        setDisabledButton(true);

        //const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-balance`, {
        const request = await fetch(`${process.env.REACT_APP_API_V2_URL}/menus/users/balances`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                attendees: validData.map(row => ({
                    ...row,
                    rules: [...values.rules]
                })),
                menu_id: props.menu,
                event_id: props.event,
                menuId: props.menu,
                eventId: props.event,
            })
        });

        const response = await request.json();

        //console.log(response);

        if (response.status === 'success') {
            setOpenS(true);
            setSnackbarSuccessMessage(response.message);
        } else {
            setOpenE(true);
            setSnackbarErrorMessage(response.message);
        }
        setDisabledButton(false);

    }

   useEffect(() => {
       //console.log(validData);
   }, [validData]);

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, []);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {SnackbarSuccessMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {SnackbarErrorMessage}
                </Alert>
            </Snackbar>

            <div className="row">
                <div className="col-12">
                    <h3>Cargar lista por archivo</h3>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-12">
                            <p>Sube tu base de datos en formato Excel, es muy importante que tenga <b>tres columnas</b> que contengan los datos de <b>Nro de mesa</b>, <b>Email</b>, <b>Monto</b> y <b>Cantidad de tickets asociados</b> de cada usuario de mesa y <b>sin ningún encabezado</b>.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-2">
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h5>Reglas</h5>
                                            </div>
                                            <div className="col-auto">
                                                <ButtonMUI variant="text" onClick={addNewRule}>Agregar regla</ButtonMUI>
                                            </div>
                                        </div>
                                        {values.rules.map((rule, index) => (
                                            <Grid className={"mt-2"} container spacing={2} key={index}>
                                                <Grid item xs={5}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id={`rule-type-label-${index}`}>Tipo de regla</InputLabel>
                                                        <Select
                                                            labelId={`rule-type-label-${index}`}
                                                            label="Tipo de regla"
                                                            value={rule.type}
                                                            onChange={(e) => updateRule(index, 'type', e.target.value)}
                                                        >
                                                            <MenuItem value={"start"}>Valido desde:</MenuItem>
                                                            <MenuItem value={"end"}>Valido hasta:</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={7}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DateTimePicker
                                                            renderInput={(props) => <TextField fullWidth {...props} />}
                                                            label="Fecha y hora"
                                                            value={rule.datetime}
                                                            inputFormat={'dd/MM/yyyy HH:mm'}
                                                            ampm={false}
                                                            onChange={(newValue) => {
                                                                newValue.setSeconds(0);
                                                                updateRule(index, 'datetime', newValue);
                                                            }}
                                                            minDateTime={getMinDateTimeForRule(index)}
                                                            maxDateTime={getMaxDateTimeForRule(index)}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <OutlinedInput
                                                            startAdornment={
                                                                <InputAdornment className="noBorderForThisSelect" position="start" style={{marginRight: 0}}>
                                                                    <Select
                                                                        value={rule.amountType}
                                                                        onChange={(e) => updateRule(index, 'amountType', e.target.value)}
                                                                        style={{
                                                                            minWidth: 50,
                                                                            background: 'white',
                                                                            marginLeft: '-15px'
                                                                        }}
                                                                        MenuProps={{
                                                                            anchorOrigin: {
                                                                                vertical: "bottom",
                                                                                horizontal: "left"
                                                                            },
                                                                            transformOrigin: {
                                                                                vertical: "top",
                                                                                horizontal: "left"
                                                                            },
                                                                            getContentAnchorEl: null
                                                                        }}
                                                                    >
                                                                        <MenuItem value={'$'}>$</MenuItem>
                                                                        <MenuItem value={'%'}>%</MenuItem>
                                                                    </Select>
                                                                </InputAdornment>
                                                            }
                                                            type="number"
                                                            value={rule.amount}
                                                            onChange={(e) => updateRule(index, 'amount', e.target.value)}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                {/* Delete Rule Button */}
                                                <Grid item xs={12}>
                                                    <ButtonMUI variant="text" color="error" onClick={() => removeRule(index)}>Eliminar regla</ButtonMUI>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <label className="h6">Lista en Excel:</label>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 d-flex justify-content-center">
                                        <input
                                            style={{ display: 'none' }}
                                            type="file"
                                            id="hiddenFileInput"
                                            accept=".xlsx"
                                            onChange={onFileChange}
                                        />
                                        <label htmlFor={`hiddenFileInput`} style={{width: "100%", backgroundColor: "whitesmoke"}}>
                                            <Button type="button" style={{width: "100%", height: "200px", border: "solid grey 0.01px"}} variant="raised" component="span">
                                                <div>
                                                    <div className="row text-center">
                                                        <div className="col-12">
                                                            <RiFileExcel2Line size={50} color="grey" />
                                                        </div>
                                                    </div>
                                                    <div className="row text-center">
                                                        <div className="col-12">
                                                            <p style={{color: "grey"}} className="mb-0">Subir archivo</p>
                                                            <small style={{color: "grey"}}>Formato aceptado: xlsx</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Button>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <small>{message}</small>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <small className="text-danger">{errorMessage}</small>
                                    </div>
                                </div>
                                {validData.length > 0 &&
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <h5>Lista de asistentes</h5>
                                    </div>
                                    <div className="col-12 table-sm" style={{maxHeight: "500px", overflowY: "scroll"}}>
                                        <Table striped bordered responsive id="myTable" size="sm">
                                            <thead>
                                            <tr>
                                                <th>Mesa</th>
                                                <th>Email</th>
                                                <th>Monto</th>
                                                <th>Tickets Asociados</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {validData.map((attendee, key) => (
                                                <tr key={key} style={{fontSize: "12px"}}>
                                                    <td style={{
                                                        wordWrap: "break-word",
                                                        maxWidth: "150px"
                                                    }}>{attendee?.tableNumber}</td>
                                                    <td>{attendee?.email}</td>
                                                    <td>{attendee?.amount === 0 ? attendee?.original_amount ?? 0 : attendee?.amount}</td>
                                                    <td>{attendee?.quantity}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                }
                                <div className="row mt-5 mb-5">
                                    <div className="col-md-6">
                                        <Button variant="contained" type="button" color="primary" className="me-2" onClick={addAttendees} disabled={validData.length <= 0 || disabledButton}>
                                            Cargar saldos
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 text-center">
                    <img src={"/assets/images/example_upload_balances.png"} alt={"Excel-Example-Tickets"} className="img-fluid" />
                </div>
            </div>

        </div>
    );
    }
};

export default DigitalMenuBalanceUpload;
