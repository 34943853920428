import React, {useState} from 'react';
import {Table} from "react-bootstrap";
import {useNavigate} from "react-router";
import {Alert, CircularProgress, TextField, Button} from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { HiPlusCircle } from "react-icons/hi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TiUserDelete } from "react-icons/ti";
import { FaUserEdit } from "react-icons/fa";
import ButtonContainer from "../components/ButtonContainer";
import '../styles/Marketing.css';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {Button as ButtonBT} from "react-bootstrap";

const PromoterGroups = (props) => {

    const user = useSelector(selectUser)

    let navigate = useNavigate();
    const [tickets, setTickets] = React.useState([]);

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/promoter-groups`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();
        //console.log(json.events);
        setTickets(json.data);
        setIsLoaded(true);
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);

    function getViewport () {
        // https://stackoverflow.com/a/8876069
        const width = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
        )

        if (width <= 992) return 'md'
        return 'xl'
    }

    const deletePromoter = async (promoter_id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/promoter-groups`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                id: promoter_id
            }),
        });
        const json = await response.json();
        //console.log(json);
        return json.status;
    }

    const swalAlert = (promoter_id) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, borrarlo!'
        }).then((result) => {
            if (result.isConfirmed) {
                deletePromoter(promoter_id).then((success) => {
                 if (success === "success") {
                     //console.log(success);
                        MySwal.fire(
                            'Borrado!',
                            'El grupo de colaboradores ha sido borrado.',
                            'success'
                        )
                     setTickets(tickets.filter((ticket) => ticket.id !== promoter_id));
                 }
                });
            }
        })
    }

    function searchData() {
        // Declare variables
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();

        if (getViewport() == 'xl') {
            table = document.getElementsByTagName("table")[0];

            tr = table.getElementsByTagName("tr");

            // Loop through all table rows, and hide those who don't match the search query
            for (i = 0; i < tr.length; i++) {
                var td0 = tr[i].getElementsByTagName("td")[0];
                var td1 = tr[i].getElementsByTagName("td")[1];
                var td2 = tr[i].getElementsByTagName("td")[2];
                if (td0 || td1 || td2) {
                    var txtValue0 = td0.textContent || td0.innerText;
                    var txtValue1 = td1.textContent || td1.innerText;
                    var txtValue2 = td2.textContent || td2.innerText;
                    if (txtValue0.toUpperCase().indexOf(filter) > -1 || txtValue1.toUpperCase().indexOf(filter) > -1 || txtValue2.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                    } else {
                        tr[i].style.display = "none";
                    }
                }
            }
        } else {
            table = document.getElementsByTagName("table")[1];

            tr = table.getElementsByTagName("tr");

            // Loop through all table rows, and hide those who don't match the search query
            for (i = 0; i < tr.length; i++) {
                var td0 = tr[i].getElementsByTagName("td")[0];
                if (td0) {
                    var txtValue0 = td0.textContent || td0.innerText;
                    if (txtValue0.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                    } else {
                        tr[i].style.display = "none";
                    }
                }
            }
        }

    }

    const [isLoaded, setIsLoaded] = useState(false);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
        return (
            <div>
                <div className="row mt-2 mb-2">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                        <h3 className="me-5">Grupos de colaboradores</h3>
                        <div className="text-center">
                            <ButtonBT variant="outline-dark" className="mktButton" onClick={() => navigate(`./new`)}>Agregar</ButtonBT>
                        </div>
                    </div>
                </div>
                {tickets.length > 0 ? (
                <div className="row mb-3">
                    <div className="col">
                        <TextField id="myInput" fullWidth onKeyUp={searchData} label="Buscar por nombre" variant="outlined" />
                    </div>
                </div>
                ) : null}

                <div className="row">
                    {tickets.length > 0 ? (
                        <>
                            <div className="col-12 d-none d-md-block table-sm" style={{maxHeight: "80vh", overflowY: "scroll"}}>
                                <Table striped bordered responsive id="myTable">
                                    <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Cantidad</th>
                                        <th>Eventos Asociados</th>
                                        <th style={{maxWidth: "80px", minWidth: "80px"}}>Acciones</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tickets.map((attendee, key) => (
                                        <tr key={key} style={{fontSize: "12px"}}>
                                            <td>{attendee.name.toUpperCase()}</td>
                                            <td>{attendee.cantidad}</td>
                                            <td>{attendee.events}</td>
                                            <td style={{maxWidth: "80px", minWidth: "80px"}}>
                                                <div className="row align-items-center justify-content-center">
                                                    <ButtonContainer title="Editar" link={`./${attendee.id}/edit`} icon={<FaUserEdit size={15} />} />
                                                    <ButtonContainer title={"Asignar a evento"} link={`./${attendee.id}/assign`} icon={<HiPlusCircle size={18} />} />
                                                    <ButtonContainer title="Eventos asociados" link={`./${attendee.id}/events`} afuera={!attendee.events} icon={<FiEdit size={15} />} />
                                                    <ButtonContainer click={() => swalAlert(attendee.id)} title={"Eliminar"} link={`#`} icon={<TiUserDelete size={20} />} />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>

                            <div className="col-12 d-block d-md-none" style={{maxHeight: "80vh", overflowY: "scroll"}}>
                                <Table striped responsive bordered size="sm" id="myTable">
                                    <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Eventos</th>
                                        <th>Acciones</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tickets.map((attendee, key) => (
                                        <tr key={key} style={{fontSize: "10px"}}>
                                            <td style={{wordWrap: "break-word", maxWidth: "150px"}}>{attendee.name.toUpperCase()}</td>
                                            <td>{attendee.events}</td>
                                            <td style={{wordWrap: "break-word", minWidth: "180px"}}>
                                                <div className="row align-items-center justify-content-center">
                                                    <ButtonContainer title="Editar" link={`./${attendee.id}/edit`} icon={<FaUserEdit size={15} />} />
                                                    <ButtonContainer title={"Asignar a evento"} link={`./${attendee.id}/assign`} icon={<HiPlusCircle size={18} />} />
                                                    <ButtonContainer title="Eventos asociados" link={`./${attendee.id}/events`} afuera={!attendee.events} icon={<FiEdit size={15} />} />
                                                    <ButtonContainer click={() => swalAlert(attendee.id)} title={"Eliminar"} link={`#`} icon={<TiUserDelete size={20} />} />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>
                        </>
                        ) : (
                        <div className="col-12 d-flex justify-content-center mt-3">
                            <Alert variant="standard" severity="info">
                                No hay grupos de colaboradores registrados
                            </Alert>
                        </div>
                        )}
                </div>
            </div>
        );
    }
};

export default PromoterGroups;