import React, {useState} from 'react';
import {Alert, CircularProgress, Snackbar, TextField} from "@mui/material";
import {setEventChange} from "../redux/eventsChangeSlice";
import {Button} from "react-bootstrap";
import {selectUser} from "../redux/userSlice";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";
import MenuContainerAdmin from "./MenuContainerAdmin";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button as ButtonMUI, Menu, MenuItem } from '@material-ui/core';
import { IoMdArrowDropdown } from 'react-icons/io';

const DigitalMenuCategoryProducts = (props) => {
    const navigate = useNavigate();

    const [menus, setMenus] = React.useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const token = localStorage.getItem('token');

    const fetchData = async () => {
        setIsLoaded(false);
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-categories?menu_id=${props.menu}&category_id=${props.category}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        const data = await request.json();

        if (data.status === "success") {
            setMenus(data.category.products);
            setIsLoaded(true);
        }

    }

    const deleteProduct = async (id) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-product-assign`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                menu_id: props.menu,
                product_id: id,
                category_id: props.category,
            }),
        });

        const response = await request.json();

        if (response.status === 'success') {
            fetchData();
        }
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [token]);

    return (
        <>
            {!isLoaded ? (
                <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                    <CircularProgress color="secondary" size={100} />
                </div>
            ) : (
                <div>
                    <div className="row mt-2 justify-content-between align-items-center">
                        <div className="col">
                            <h3>Productos</h3>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            {menus?.length > 0 ? (
                                <StickyHeadTable menu={props.menu} delete={deleteProduct} rows={menus} />
                            ) : (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-info" role="alert">
                                            No hay productos asignados.
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DigitalMenuCategoryProducts;

const columns = [
    { id: 'id', label: 'Codigo', maxWidth: 10, minWidth: 0 },
    { id: 'name', label: 'Nombre', minWidth: 170 },
    {
        id: 'actions',
        label: 'Acciones',
        minWidth: 170,
        align: 'right',
    },
];

function StickyHeadTable(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const navigate = useNavigate();

    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <TableContainer sx={{ maxHeight: "75vh" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.rows.map((row) => (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                            {column.id === 'actions' ? (
                                                <>
                                                    <div className="d-none d-md-inline">
                                                        <ButtonMUI className={"me-2"} variant="text" color="primary" onClick={() => navigate(`/organizations/digital-menus/${props.menu}/products/${row.id}/info`)}>Editar</ButtonMUI>
                                                        <ButtonMUI className={"me-2"} variant="text" color="secondary" onClick={() => props.delete(row.id)}>Eliminar</ButtonMUI>
                                                    </div>
                                                    <div className="d-inline d-md-none">
                                                        <ButtonMUI
                                                            variant="contained"
                                                            aria-controls="simple-menu"
                                                            aria-haspopup="true"
                                                            onClick={(event) => handleClick(event, row)}
                                                        >
                                                            Opciones <IoMdArrowDropdown />
                                                        </ButtonMUI>
                                                        <Menu
                                                            id="simple-menu"
                                                            anchorEl={anchorEl}
                                                            keepMounted
                                                            open={Boolean(anchorEl)}
                                                            onClose={handleClose}
                                                            getContentAnchorEl={null}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >
                                                            <MenuItem onClick={() => { navigate(`/organizations/digital-menus/${props.menu}/products/${row.id}/info`); handleClose(); }}>Editar</MenuItem>
                                                            <MenuItem onClick={() => { props.delete(row.id); handleClose(); }}>Eliminar</MenuItem>
                                                        </Menu>
                                                    </div>
                                                </>
                                            ) : column.format && typeof value === 'number' ? (
                                                column.format(value)
                                            ) : (
                                                value
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}