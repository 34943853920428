import React, {useRef, useState} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Menu, MenuItem } from '@material-ui/core';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useNavigate } from 'react-router';
import DropdownMenu from "./DropdownMenu";

const columns = [
    { id: 'id', label: 'Codigo', maxWidth: 10, minWidth: 0 },
    { id: 'name', label: 'Nombre', minWidth: 170 },
    {
        id: 'actions',
        label: 'Acciones',
        minWidth: 170,
        align: 'right',
    },
];

export default function StickyHeadTable(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const navigate = useNavigate();

    const menuButtonRef = useRef(null);


    const handleClick = (event, row) => {
        setAnchorEl(menuButtonRef.current);
        setSelectedRow(row);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <TableContainer sx={{ maxHeight: "75vh" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.rows.map((row) => (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                            {column.id === 'actions' ? (
                                                <>
                                                    <div className="d-none d-md-inline">
                                                        <Button className={"me-2"} variant="text" color="primary" onClick={() => navigate(`./${row.id}/info`)}>Editar</Button>
                                                        {props.page === "menu" ? (
                                                            <Button className={"me-2"} variant="text" color="default" onClick={() => window.open(`/menu/${row.url}`, '_blank')}>Ver</Button>
                                                        ) : props.page === "products" ? (
                                                            <Button className={"me-2"} variant="text" color="default" onClick={() => navigate(`./${row.id}/assign`)}>Asignar a categoría</Button>
                                                        ) : props.page === "categories" ? (
                                                            <Button className={"me-2"} variant="text" color="default" onClick={() => navigate(`./${row.id}/products`)}>Productos</Button>
                                                        ) : props.page === "balances" ? (
                                                            <Button className={"me-2"} variant="text" color="default" onClick={() => navigate(`./${row.id}/info`)}>Ver Saldos</Button>
                                                        ) : null}
                                                        <Button className={"me-2"} variant="text" color="secondary" onClick={() => props.delete(row.id)}>Eliminar</Button>
                                                    </div>
                                                    <div className="d-inline d-md-none">
                                                        <DropdownMenu
                                                            anchorEl={
                                                                <Button
                                                                    ref={menuButtonRef}
                                                                    variant="contained"
                                                                    aria-controls="simple-menu"
                                                                    aria-haspopup="true"
                                                                    onClick={(event) => handleClick(event, row)}
                                                                >
                                                                    Opciones <IoMdArrowDropdown />
                                                                </Button>
                                                            }
                                                        >
                                                            {props.page === "menu" ? (
                                                                <div onClick={() => { navigate(`./${selectedRow.id}/info`); handleClose(); }}>Gestionar</div>
                                                            ) : (
                                                                <div onClick={() => { navigate(`./${selectedRow.id}/info`); handleClose(); }}>Editar</div>
                                                            )}
                                                            {props.page === "menu" ? (
                                                                <div onClick={() => { window.open(`/menu/${selectedRow.url}`, '_blank'); handleClose(); }}>Ver</div>
                                                            ) : props.page === "products" ? (
                                                                <div onClick={() => { navigate(`./${selectedRow.id}/assign`); handleClose(); }}>Asignar a categoría</div>
                                                            ) : props.page === "categories" ? (
                                                                <div onClick={() => { navigate(`./${selectedRow.id}/products`); handleClose(); }}>Productos</div>
                                                            ) : props.page === "balances" ? (
                                                                <div onClick={() => { navigate(`./${selectedRow.id}/info`); handleClose(); }}>Ver</div>
                                                            ) : null}
                                                            <div onClick={() => { props.delete(row.id); handleClose(); }}>Eliminar</div>
                                                        </DropdownMenu>
                                                    </div>
                                                </>
                                            ) : column.format && typeof value === 'number' ? (
                                                column.format(value)
                                            ) : (
                                                value
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}