import React from 'react';
import SidebarMenu from "./SidebarMenu";
import {Container} from "react-bootstrap";
import SidebarVenue from "./SidebarVenue";

const VenueDashboard = (props) => {

    return (
        <div>
            <div className="row">
                <div className="col-lg-auto col-12" style={{margin: 0, padding: 0}}>
                    <SidebarVenue venue={props.venue}  />
                </div>
                <div className="col">
                    <Container>
                        {props.children}
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default VenueDashboard;