import React, {useState} from 'react';
import {Tooltip} from "@mui/material";
import DialogBankForm from "./DialogBankForm";

const TooltipButton = (props) => {
    const [openEdit, setOpenEdit] = useState(false);

    const handleCloseEdit = () => {
        setOpenEdit(false);
    }

    const handleEdit = () => {
        setOpenEdit(true);
    }

    return (
        <>
            <div
                className="d-flex justify-content-center align-items-center"
                style={{
                    backgroundColor: "white",
                    boxShadow: "0 3px 5px -2px darkgray",
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                }}
                onClick={props.click}
            >
                {!props.afuera ? (
                        <Tooltip title={props.title} placement="top">
                            <button onClick={handleEdit} style={{backgroundColor: "transparent", border: "none"}}>
                                {props.icon}
                            </button>
                        </Tooltip>
                ) : (
                    <button disabled style={{backgroundColor: "transparent", border: "none"}}>
                        {props.icon}
                    </button>
                )}
            </div>
            <DialogBankForm done={props.done} type="edit" values={props.values} open={openEdit} handleClose={handleCloseEdit} />
        </>
    );
};

export default TooltipButton;