import React, {useState} from 'react';
import {
    Button, CircularProgress, Dialog,
    DialogContent,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem, Radio,
    RadioGroup,
    Select,
    TextField, useMediaQuery
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";

const DialogBankForm = (props) => {

    const user = useSelector(selectUser);

    const [bankValues, setBankValues] = useState({
        razonSocial: '',
        country: '1',
        currency: '1',
        method: '1',
        bankType: '1',
        bankName: '',
        bankCBU: '',
        cuit: '',
    });

    const [errors, setErrors] = useState({
        cuit: false,
        email: false,
        phone: false,
        razonSocial: false,
        country: false,
        currency: false,
        method: false,
        bankType: false,
        bankName: false,
        bankCBU: false,
    });

    const [errorMessages, setErrorMessages] = useState({
        cuit: '',
        email: '',
        phone: '',
        razonSocial: '',
        country: '',
        currency: '',
        method: '',
        bankType: '',
        bankName: '',
        bankCBU: '',
    });

    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('sx'));

    const inputValidation = (prop, value) => {

        switch (prop) {
            case 'cuit':
                if (value.length === 0) {
                    setErrors({...errors, cuit: true});
                    setErrorMessages({...errorMessages, cuit: 'Este campo es obligatorio'});
                }
                else if (value.length < 11) {
                    setErrors({...errors, cuit: true});
                    setErrorMessages({...errorMessages, cuit: 'El CUIT debe tener 11 caracteres'});
                }
                else if (value.length > 11) {
                    return false;
                }
                else {
                    setErrors({...errors, cuit: false});
                    setErrorMessages({...errorMessages, cuit: ''});
                }
                break;
            case 'email':
                if (value.length === 0) {
                    setErrors({...errors, email: true});
                    setErrorMessages({...errorMessages, email: 'Este campo es obligatorio'});
                }
                else if (!value.includes('@')) {
                    setErrors({...errors, email: true});
                    setErrorMessages({...errorMessages, email: 'El email debe tener un @'});
                }
                else {
                    setErrors({...errors, email: false});
                    setErrorMessages({...errorMessages, email: ''});
                }
                break;
            case 'phone':
                if (value.length === 0) {
                    setErrors({...errors, phone: true});
                    setErrorMessages({...errorMessages, phone: 'Este campo es obligatorio'});
                }
                else {
                    setErrors({...errors, phone: false});
                    setErrorMessages({...errorMessages, phone: ''});
                }
                break;
            case 'razonSocial':
                if (value.length === 0) {
                    setErrors({...errors, razonSocial: true});
                    setErrorMessages({...errorMessages, razonSocial: 'Este campo es obligatorio'});
                }
                else {
                    setErrors({...errors, razonSocial: false});
                    setErrorMessages({...errorMessages, razonSocial: ''});
                }
                break;
            case 'country':
                if (value.length === 0) {
                    setErrors({...errors, country: true});
                    setErrorMessages({...errorMessages, country: 'Este campo es obligatorio'});
                }
                else {
                    setErrors({...errors, country: false});
                    setErrorMessages({...errorMessages, country: ''});
                }
                break;
            case 'currency':
                if (value.length === 0) {
                    setErrors({...errors, currency: true});
                    setErrorMessages({...errorMessages, currency: 'Este campo es obligatorio'});
                }
                else {
                    setErrors({...errors, currency: false});
                    setErrorMessages({...errorMessages, currency: ''});
                }
                break;
            case 'method':
                if (value.length === 0) {
                    setErrors({...errors, bankType: true});
                    setErrorMessages({...errorMessages, bankType: 'Este campo es obligatorio'});
                }
                else {
                    setErrors({...errors, bankType: false});
                    setErrorMessages({...errorMessages, bankType: ''});
                }
                break;
            case 'bankName':
                if (value.length === 0) {
                    setErrors({...errors, bankName: true});
                    setErrorMessages({...errorMessages, bankName: 'Este campo es obligatorio'});
                }
                else {
                    setErrors({...errors, bankName: false});
                    setErrorMessages({...errorMessages, bankName: ''});
                }
                break;
            case 'bankCBU':
                if (value.length === 0) {
                    setErrors({...errors, bankCBU: true});
                    setErrorMessages({...errorMessages, bankCBU: 'Este campo es obligatorio'});
                }
                else if (value.length < 22) {
                    setErrors({...errors, bankCBU: true});
                    setErrorMessages({...errorMessages, bankCBU: 'El CBU debe tener 22 caracteres'});
                }
                else if (value.length > 22) {
                    return false;
                }
                else {
                    setErrors({...errors, bankCBU: false});
                    setErrorMessages({...errorMessages, bankCBU: ''});
                }
                break;
            default:
                break;
        }

    }

    const handleChange = (prop) => (event) => {

        if (inputValidation(prop, event.target.value) !== false) {
            setBankValues({...bankValues, [prop]: event.target.value});
        }
    }

    const submitForm = async (e) => {
        e.preventDefault();

        const errorCheck = Object.values(errors).some((error) => error === true);

        if (!errorCheck) {
            //console.log('Formulario enviado');

            if (props.type === 'edit') {
                const request = await fetch(`${process.env.REACT_APP_API_URL}/bank-accounts`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${user.token}`,
                        'credentials': 'same-origin',
                    },
                    body: JSON.stringify(bankValues)
                });
                const response = await request.json();
                if (response.success === "1") {
                    //console.log(response);
                    props.done();
                    props.handleClose();
                }
            }
            if (props.type === 'add') {
                const request = await fetch(`${process.env.REACT_APP_API_URL}/bank-accounts`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${user.token}`,
                        'credentials': 'same-origin',
                    },
                    body: JSON.stringify(bankValues)
                });
                const response = await request.json();

                if (response.success === "1") {
                    //console.log(response);
                    props.done();
                    props.handleClose();
                }
            }
    }
    }

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/producer-data`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();
        setBankValues({...bankValues, ...json.producer});

        setBankValues({...bankValues, ...props.values});
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);

        return (
            <>
                <Dialog
                    fullScreen={fullScreen}
                    open={props.open}
                    onClose={props.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent>
                        <form onSubmit={submitForm}>
                            <div className="row p-3">
                                <div className="col-12 d-flex align-items-start justify-content-center">
                                    <h5 className="card-title">{props.type === "edit" ? "Editar cuenta bancaria" : "Agregar cuenta bancaria"}</h5>
                                </div>
                            </div>
                            <hr className="mt-0"/>
                            <div className="row p-3">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6>País y divisa</h6>
                                        </div>
                                    </div>
                                    <div className="row mt-0 mt-md-3">
                                        <div className="col-md-4 mt-3 mt-md-0">
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">País</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={bankValues.country}
                                                    label="País"
                                                    onChange={handleChange('country')}
                                                    disabled
                                                >
                                                    <MenuItem value={1}>Argentina</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-4 mt-3 mt-md-0">
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Divisa</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={bankValues.currency}
                                                    label="Divisa"
                                                    onChange={handleChange('currency')}
                                                    disabled
                                                >
                                                    <MenuItem value={1}>ARS</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-4 mt-3 mt-md-0">
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Método</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={bankValues.method}
                                                    label="Método"
                                                    onChange={handleChange('method')}
                                                    disabled
                                                >
                                                    <MenuItem value={1}>Transferencia bancaria</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="row p-3">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6>Información del titular de la cuenta</h6>
                                        </div>
                                    </div>
                                    <div className="row mt-0 mt-md-3">
                                        <div className="col-md-6 mt-3 mt-md-0">
                                            <TextField label="Nombre Completo" variant="outlined" fullWidth
                                                       value={bankValues.razonSocial} disabled/>
                                        </div>
                                        <div className="col-md-6 mt-3 mt-md-0">
                                            <TextField label="CUIT" variant="outlined" fullWidth
                                                       value={bankValues.cuit} type="number" onWheel={(e) => e.preventDefault()}
                                                       onChange={handleChange('cuit')} error={errors.cuit}
                                                       helperText={errorMessages.cuit} disabled/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="row p-3">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6>Información de la cuenta bancaria</h6>
                                        </div>
                                    </div>
                                    <div className="row mt-0 mt-md-3">
                                        <div className="col-12 mt-3 mt-md-0">
                                            <FormControl>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={bankValues.bankType}
                                                    onChange={handleChange('bankType')}
                                                >
                                                    <FormControlLabel value="1" control={<Radio
                                                        style={{marginLeft: 0, paddingLeft: 0}}/>}
                                                                      label="Cuenta Corriente"/>
                                                    <FormControlLabel value="2" control={<Radio
                                                        style={{marginLeft: 0, paddingLeft: 0}}/>}
                                                                      label="Caja de Ahorro"/>
                                                    <FormControlLabel value="4" control={<Radio
                                                        style={{marginLeft: 0, paddingLeft: 0}}/>}
                                                                      label="Cuenta Virtual"/>
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="row mt-0 mt-md-3">
                                        <div className="col-md-6 mt-3 mt-md-0">
                                            <TextField label="Nombre del banco" required type="text" variant="outlined"
                                                       fullWidth value={bankValues.bankName}
                                                       onChange={handleChange('bankName')} error={errors.bankName}
                                                       helperText={errorMessages.bankName}/>
                                        </div>
                                        <div className="col-md-6 mt-3 mt-md-0">
                                            <TextField label="CBU" variant="outlined" required type="number" onWheel={(e) => e.preventDefault()}  fullWidth
                                                       value={bankValues.bankCBU} onChange={handleChange('bankCBU')}
                                                       error={errors.bankCBU} helperText={errorMessages.bankCBU}/>
                                        </div>
                                    </div>
                                    <div className="row mt-0 mt-md-3 text-end justify-content-end">
                                        <div className="col-12 mt-3 mt-md-0">
                                            <Button className="me-3" variant="text" color="primary"
                                                    onClick={props.handleClose}>Cancelar</Button>
                                            <Button variant="contained" color="primary" type="submit">Guardar</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </DialogContent>
                </Dialog>
            </>
        );
};

export default DialogBankForm;