import React, {useState, useEffect} from 'react';
import {BsFillTrashFill} from "react-icons/bs";
import "./ImageUpload.css";
import {Avatar, Button} from "@mui/material";
import {AiOutlinePicture} from "react-icons/ai";

export const ImageUpload = (props) => {
    const [selectedFile, setSelectedFile] = useState(null)
    const [preview, setPreview] = useState()
    const [error, setError] = useState()
    const [errorMessage, setErrorMessage] = useState()

    const id = Math.random().toString(36).substring(7);


    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        if (typeof selectedFile === 'string') {
            setPreview(selectedFile)
        }
        else {
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
        }
    }, [selectedFile])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        if (e.target.files[0].size < 1000000) {
            setSelectedFile(e.target.files[0]);
            setError(false);
            setErrorMessage("");
        }
        else {
            setSelectedFile(undefined);
            setError(true);
            setErrorMessage("El archivo debe pesar como máximo 1MB");
        }
        //props.onSelectImage({image: e.target.files[0]})
    }

    useEffect(() => {
        props.onSelectImage(selectedFile);
        //console.log(props.onSelectImage);
    }, [selectedFile])

    useEffect(() => {
        if (props.image) {
            setSelectedFile(props.image);
        }
    }, []);

    return (
        <>
            {!selectedFile &&
            <div className="mb-2">
                {props.title &&
                    <div className="row">
                        <div className="col-12">
                            <label className="h6">{props.title}</label>
                        </div>
                    </div>
                }
                <div className="row mt-2">
                    <div className="col-12 d-flex justify-content-center">
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id={`raised-button-file-${id}`}
                            type="file"
                            onChange={onSelectFile}
                        />
                        <label htmlFor={`raised-button-file-${id}`} style={{width: "100%", backgroundColor: "whitesmoke"}}>
                            <Button type="button" style={{width: "100%", height: "200px", border: "solid grey 0.01px"}} variant="raised" component="span">
                                <div>
                                    <div className="row text-center">
                                        <div className="col-12">
                                            <AiOutlinePicture size={50} color="grey" />
                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        <div className="col-12">
                                            <p style={{color: "grey"}} className="mb-0">Subir imagen</p>
                                            <small style={{color: "grey"}}>Formatos aceptados: jpg, png, jpeg</small>
                                        </div>
                                    </div>
                                </div>
                            </Button>
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <small className="text-danger">{errorMessage}</small>
                    </div>
                </div>
            </div>
            }
            {selectedFile &&
                <>
                {props.title &&
                <label className="h6" htmlFor="image">{props.title}</label>
                }
                <div className="d-flex align-items-center justify-content-center cont mt-3 mb-2" style={{position: "relative"}}>
                    {props.figure === "circle" &&
                        <Avatar src={preview} style={{height: "10rem", width: "10rem"}} />
                    }
                    {props.figure === "square" &&
                    <img className="img-fluid" src={preview} style={{maxHeight: "400px" }} />
                    }
                        <div className="justify-content-center align-items-center deleteButton">
                            <button onClick={()=> setSelectedFile(null)} style={{backgroundColor: "transparent", border: "none"}}>
                                <BsFillTrashFill size={30} />
                            </button>
                        </div>
                </div>
                </>
            }

        </>
    )
}