import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import {IoPersonCircle, IoStorefront} from "react-icons/io5";
import {IoIosSettings} from "react-icons/io";
import {MdLocalBar, MdMenuBook} from "react-icons/md";
import {HiReceiptRefund} from "react-icons/hi";
import {GoChecklist} from "react-icons/go";
import {MDBIcon} from "mdb-react-ui-kit";

export const SidebarData = [
    {
        title: 'Resumen',
        path: '/organizations/dashboard',
        icon: <AiIcons.AiFillHome size="18" />,
        cName: 'nav-text',
        producer: true,
        mktPermission: false,
        tableAdminPermission: false,
    },
    {
        title: 'Eventos',
        path: '/organizations/events',
        icon: <IoIcons.IoIosPaper size="18" />,
        cName: 'nav-text',
        producer: false,
        mktPermission: false,
        tableAdminPermission: false,
    },
    {
        title: 'Colaboradores',
        path: '/organizations/team',
        icon: <IoIcons.IoMdPeople size="18" />,
        cName: 'nav-text',
        producer: false,
        mktPermission: false,
        tableAdminPermission: false,
    },
    {
        title: 'Marketing',
        path: '/organizations/marketing',
        icon: <FaIcons.FaEnvelopeOpenText size="18" />,
        cName: 'nav-text',
        producer: true,
        mktPermission: true,
        tableAdminPermission: false,
    },
    {
        title: 'Devoluciones',
        path: '/organizations/refunds',
        icon: <HiReceiptRefund size="18" />,
        cName: 'nav-text',
        producer: true,
        mktPermission: false,
        tableAdminPermission: false,
    },
    {
        title: 'Whitelist',
        path: '/organizations/whitelist',
        icon: <GoChecklist size="18" />,
        cName: 'nav-text',
        producer: false,
        mktPermission: false,
        tableAdminPermission: false,
    },
    {
        title: 'Cartas digitales',
        path: '/organizations/digital-menus',
        icon: <MdMenuBook size="18" />,
        cName: 'nav-text',
        producer: true,
        mktPermission: true,
        tableAdminPermission: true,
    },
    {
        title: 'Componentes',
        path: '/organizations/components',
        icon: <MdLocalBar size="18" />,
        cName: 'nav-text',
        producer: true,
        mktPermission: true,
    },
    {
        title: 'Establecimientos',
        path: '/organizations/venues',
        icon: <IoStorefront size="18" />,
        cName: 'nav-text',
        producer: true,
        mktPermission: true,
        tableAdminPermission: true,
    },
    {
        title: 'Perfil',
        path: '/organizations/profile',
        icon: <IoPersonCircle size="18" />,
        cName: 'nav-text',
        producer: false,
        mktPermission: false,
        tableAdminPermission: false,
    },
    {
        title: 'Ajustes',
        path: '/organizations/settings',
        icon: <IoIosSettings size="18" />,
        cName: 'nav-text',
        producer: true,
        mktPermission: false,
        tableAdminPermission: false,
    }
];