import React from 'react';
import '../styles/SidebarMenu.css'
import BurguerButton from "./BurguerButton";
import {GiHamburgerMenu} from "react-icons/gi";
import {Link} from "react-router-dom";


const SidebarMenu = (props) => {

    const [showMenu, setShowMenu] = React.useState(false);

    const menuOptions = [
        {
            name: "Información Básica",
            link: `/organizations/digital-menus/${props.menu}/info`
        },
        {
            name: "Categorías",
            link: `/organizations/digital-menus/${props.menu}/categories`
        },
        {
            name: "Productos",
            link: `/organizations/digital-menus/${props.menu}/products`
        },
        {
            name: "Saldos",
            link: `/organizations/digital-menus/${props.menu}/balances`
        },
        {
            name: "Analíticas",
            link: `/organizations/digital-menus/${props.menu}/analytics`
        },
        {
            name: "Ordenes",
            link: `/organizations/digital-menus/${props.menu}/orders`
        },
        {
            name: "Cajas",
            link: `/organizations/digital-menus/${props.menu}/registers`
        },
        {
            name: "Monedas",
            link: `/organizations/digital-menus/${props.menu}/currencies`
        },
        {
            name: "Cuenta MercadoPago",
            link: `/organizations/digital-menus/${props.menu}/accounts`
        },
    ];

    return (
        <>
            <div className="menu-sidebar-organization d-lg-block d-none">
                <div className="row">
                    <div className="col-12">
                        <ul className="sidebar-menu-organization-ul">
                            {menuOptions.map((option, index) => (
                                <li key={index} className="sidebar-menu-item">
                                    <Link className="sidebar-menu-link" to={option.link}>{option.name}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="menu-sidebar-organization-mobile d-lg-none d-block">
                <div className="row">
                    <div className="col-12 align-items-center d-flex">
                        <button onClick={() => setShowMenu(!showMenu)} style={{border: 'none', background: 'none', outline: 'none'}}>
                            <GiHamburgerMenu size={30} />
                        </button>
                        <span className="menu-sidebar-organization-mobile-title ms-3">Menu</span>
                    </div>
                </div>
                <div className={`row sidebar-menu-organization-mobile-items ${showMenu ? 'active' : ""}`}>
                    <div className="col-12">
                        <ul className="sidebar-menu-organization-ul">
                            {menuOptions.map((option, index) => (
                                <li key={index} className="sidebar-menu-item">
                                    <Link className="sidebar-menu-link" to={option.link}>{option.name}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SidebarMenu;