import React from 'react';
import {Link} from "react-router-dom";
import {Tooltip} from "@mui/material";
const ButtonContainer = (props) => {
    return (
        <div className="col-auto p-1">
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        backgroundColor: props.clicked ? "lightgray" : "white",
                        boxShadow: "0 3px 5px -2px darkgray",
                        borderRadius: "50%",
                        height: "30px",
                        width: "30px",
                    }}
                >
                    {!props.afuera ? (
                    <Tooltip title={props.title} placement="top">
                        {props.click ? (
                        <button type={"button"} onClick={props.click} style={{backgroundColor: "transparent", border: "none", color: "black"}}>
                            {props.icon}
                        </button>
                        ) : (
                        <Link to={props.link} style={{textDecoration: 'none', color: "black"}}>
                            {props.icon}
                        </Link>
                        )}
                    </Tooltip>
                    ) : (
                    <button disabled style={{backgroundColor: "transparent", border: "none"}}>
                        {props.icon}
                    </button>
                    )}
                </div>
        </div>
);
};

export default ButtonContainer;