import React, {useEffect, useState} from 'react';
import {
    Alert, AlertTitle,
    Button,
    CircularProgress,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FilledInput,
    FormControl,
    FormControlLabel,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select, Snackbar,
    TextField, Tooltip
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {selectEventChange} from "../redux/eventsChangeSlice";
import IconButton from "@mui/material/IconButton";
import {CopyAll} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {IoClose} from "react-icons/io5";
import {Button as ButtonBT} from "react-bootstrap";
import {FaUpload} from "react-icons/fa";
import {useNavigate} from "react-router";


const CreateLinkTickets = (props) => {

    const user = useSelector(selectUser);
    const eventChange = useSelector(selectEventChange);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [disabledButton, setDisabledButton] = useState(false);

    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const categories = [
        {
            value: 'free',
            label: 'Cortesía',
        },
        {
            value: 'paid',
            label: 'Venta en efectivo',
        },
    ];

    const registerType = [
        {
            value: 'link',
            label: 'Link de Tickets',
        },
        {
            value: 'manual',
            label: 'Ingreso Manual',
        },
    ];

    const [values, setValues] = useState({
        registerType: 'link',

        type: 'free',
        ticketType: "",
        quantity: "1",

        email: "",
        name: "",
        DNI: "",
    });

    function updateClipboard(text) {
        document.getElementById("text").type = "text";

        document.getElementById("text").value = text;

        var text = document.getElementById("text");
        text.select();
        text.setSelectionRange(0, 99999); /*For mobile devices*/

        navigator.clipboard.writeText(text.value);

        text.type = 'hidden';

        document.activeElement.blur();
    }

    const [tickets, setTickets] = useState([]);
    const [link, setLink] = useState("");
    const [owner, setOwner] = useState(false);

    const [limits, setLimits] = useState({
        paid: 0,
        free: 0
    })

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleOpenConfirm = () => setOpenConfirm(true);
    const handleCloseConfirm = () => setOpenConfirm(false);

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/event-tickets-list?event_id=${props.event}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();
        //console.log(json);
        setTickets(json?.tickets.filter(ticket => ticket.available === 1));
        setOwner(json?.owner);
        setLimits(json?.limits);
        setValues({
            ...values,
            ticketType: json?.tickets.filter(ticket => ticket.available === 1)[0]?.event_ticket_id
        });
        setIsLoaded(true);
    }

    const [isLoaded, setIsLoaded] = useState(false);

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const changeImage = (image) => {
        setValues({...values, portada: image});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setDisabledButton(true);

        const request = await fetch(`${process.env.REACT_APP_API_URL}/generar-link-tickets`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                event_ticket: values.ticketType,
                total: values.quantity,
                type: values.type,
                registerType: values.registerType,
                email: values.email,
                name: values.name,
                dni: values.DNI,
                owner: owner,
            })
            });

        const response = await request.json();

        if (response.type === "link") {
            if (response.status === "success") {
                setLink(response.link);
                const newLimits = { ...limits };
                newLimits[values.type] = parseInt(limits[values.type]) - parseInt(values.quantity);
                setLimits(newLimits);
                handleOpenConfirm();
            } else {
                setOpenE(true);
            }
        }

        else if (response.type === "manual") {
            if (response.status === "success") {
                const newLimits = { ...limits };
                newLimits[values.type] = parseInt(limits[values.type]) - parseInt(values.quantity);
                setLimits(newLimits);
                setOpenS(true);
            }
            else {
                setOpenE(true);
            }
        }

        setDisabledButton(false);

    }

    const Quantity = () => {
        return (
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Cantidad</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.quantity}
                    label="Cantidad"
                    onChange={handleChange('quantity')}
                    required
                >
                    {(values.type === "free" && limits.free > 0) || (values.type === "paid" && limits.paid > 0) ? (
                        <MenuItem value={1}>1</MenuItem>
                    ) : (
                        <MenuItem value={0} disabled>No hay tickets disponibles</MenuItem>
                    )}
                    {(values.type === "free" && limits.free > 1) || (values.type === "paid" && limits.paid > 1) ? (
                        <MenuItem value={2}>2</MenuItem>
                    ) : null}
                    {(values.type === "free" && limits.free > 2) || (values.type === "paid" && limits.paid > 2) ? (
                        <MenuItem value={3}>3</MenuItem>
                    ) : null}
                    {(values.type === "free" && limits.free > 3) || (values.type === "paid" && limits.paid > 3) ? (
                        <MenuItem value={4}>4</MenuItem>
                    ) : null}
                    {(values.type === "free" && limits.free > 4) || (values.type === "paid" && limits.paid > 4) ? (
                        <MenuItem value={5}>5</MenuItem>
                    ) : null}
                    {(values.type === "free" && limits.free > 5) || (values.type === "paid" && limits.paid > 5) ? (
                        <MenuItem value={6}>6</MenuItem>
                    ) : null}
                    {(values.type === "free" && limits.free > 6) || (values.type === "paid" && limits.paid > 6) ? (
                        <MenuItem value={7}>7</MenuItem>
                    ) : null}
                    {(values.type === "free" && limits.free > 7) || (values.type === "paid" && limits.paid > 7) ? (
                        <MenuItem value={8}>8</MenuItem>
                    ) : null}
                    {(values.type === "free" && limits.free > 8) || (values.type === "paid" && limits.paid > 8) ? (
                        <MenuItem value={9}>9</MenuItem>
                    ) : null}
                    {(values.type === "free" && limits.free > 9) || (values.type === "paid" && limits.paid > 9) ? (
                        <MenuItem value={10}>10</MenuItem>
                    ) : null}
                </Select>
            </FormControl>
        )
    }

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    Ticket enviado con éxito!
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    Ocurrió un error al enviar el ticket!
                </Alert>
            </Snackbar>
            <div className="row mb-3 mt-2 align-items-center">
                <div className="col-6">
                    <h2>Enviar Tickets</h2>
                </div>
                <div className="col justify-content-end d-flex text-end ms-2">
                    <ButtonBT variant="outline-dark" onClick={() => {navigate(`./import`)}}>
                        <FaUpload />
                        &nbsp; Cargar desde Excel
                    </ButtonBT>
                </div>
            </div>
            {!owner && (
                <div className="row mb-4 mt-2 justify-content-center">
                    <div className="col">
                        <Alert severity="warning">
                            <AlertTitle>Límites</AlertTitle>
                            <span>Podes registrar <strong>{limits.paid}</strong> tickets de pago y <strong>{limits.free}</strong> tickets de cortesía.</span>
                        </Alert>
                    </div>
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <div className="row justify-content-center">
                    <div className="col-12">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Tipo de registro</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.registerType}
                                label="Tipo de registro"
                                onChange={handleChange('registerType')}
                                required
                            >
                                {registerType.map((category, id) => (
                                    <MenuItem key={id} value={category.value}>{category.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 mt-3">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.type}
                                label="Tipo"
                                onChange={handleChange('type')}
                                required
                            >
                                {categories.map((category, id) => (
                                    <MenuItem key={id} value={category.value}>{category.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 mt-3">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Tipo de ticket</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.ticketType}
                                label="Tipo de ticket"
                                onChange={handleChange('ticketType')}
                                required
                            >
                                {tickets.map((category, id) => (
                                    <MenuItem key={id} value={category.event_ticket_id}>{category.event_ticket_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                {values.registerType === "link" ? (
                    <>
                        <div className="row justify-content-center">
                            <div className="col-12 mt-3">
                                <Quantity />
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3 mb-3">
                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        <Button disabled={
                                            //when the limit of the values.type is 0 or the values.quantity is 0
                                            (values.type === "free" ? (limits.free <= 0) : (limits.paid <= 0)) || values.quantity === 0 || disabledButton
                                        } variant="contained" color="primary" className="me-2" type="submit">
                                            Generar link
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="row justify-content-center">
                            <div className="col-12 mt-3">
                                <TextField label="Email" value={values.email} onChange={handleChange('email')} fullWidth type={"email"} variant="outlined" required />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 mt-3">
                                <TextField label="Nombre Completo" value={values.name} onChange={handleChange('name')} fullWidth type={"text"} variant="outlined" required />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 mt-3">
                                <TextField label="DNI" value={values.DNI} onChange={handleChange('DNI')} fullWidth type={"number"} variant="outlined" required />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 mt-3">
                                <Quantity />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 mt-3">
                                <Button disabled={
                                    (values.type === "free" ? (limits.free <= 0) : (limits.paid <= 0)) || values.quantity === 0 || disabledButton
                                } variant="contained" color="primary" className="me-2" type="submit">
                                    Enviar ticket
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </form>

            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
            >
                <DialogTitle id="responsive-dialog-title">
                    <div className="closeIconDark p-1 align-items-center d-flex justify-content-center">
                        <IoClose size={25} color={"rgba(0, 0, 0)"} onClick={handleCloseConfirm}/>
                    </div>
                    {"Link generado"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Box component="form" noValidate autoComplete="off">
                            <FormControl variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={"text"}
                                    value={link}
                                    disabled={true}
                                    fullWidth={true}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    updateClipboard(link);
                                                    handleTooltipOpen();
                                                }}
                                                edge="end"
                                            >
                                                <Grid item>
                                                    <ClickAwayListener onClickAway={handleTooltipClose}>
                                                        <div>
                                                            <Tooltip
                                                                onClose={handleTooltipClose}
                                                                open={open}
                                                                disableFocusListener
                                                                disableHoverListener
                                                                disableTouchListener
                                                                title="Copiado"
                                                                placement="top"
                                                            >
                                                                <CopyAll />
                                                            </Tooltip>
                                                        </div>
                                                    </ClickAwayListener>
                                                </Grid>
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <input type="hidden" id="text" />
        </div>
    );
    }
};

export default CreateLinkTickets;
