import React, {useEffect, useState} from 'react';
import {Button, Table} from "react-bootstrap";
import {useNavigate} from "react-router";
import {Alert, AlertTitle, CircularProgress, Snackbar, TextField, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {FaDownload} from "react-icons/fa";
import {number_format} from "./functions/numberFormat";
import {AiOutlineCheck, AiOutlineClose, AiOutlineLink} from "react-icons/ai";


const EventColaborators = (props) => {

    const user = useSelector(selectUser)

    const navigate = useNavigate();
    const [attendees, setAttendees] = React.useState([]);
    const [tableData, setTableData] = useState([]);
    const [event_name, setEventName] = useState("");

    const [selectedAttendees, setSelectedAttendees] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };


    useEffect(() => {
        {attendees.map((attendee, key) => {
            const data = {
                Nombre: attendee?.name?.toUpperCase(),
                DNI: attendee?.dni,
                Ticket: attendee?.ticket_type,
                Precio: attendee?.value,
                Email: attendee?.email,
                Telefono: attendee?.phone,
                Colaborador: attendee?.promoter,
                Estado: attendee?.status_id === 1 ? "Validado" : "Vigente",
            };
            setTableData(prevTableData => [...prevTableData, data]);
        })}
    }, [attendees])

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, `Lista Digital`);
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, `Lista Digital - ${event_name}.xlsx`);
    };


    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/promoter-event-list?event_id=${props.event}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();
        //console.log(json);
        setAttendees(json.data);
        setEventName(json.event_name);
        setIsLoaded(true);
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);


    const validateAttendee = (id) => {
        const mySwal = withReactContent(Swal);
        mySwal.fire({
            title: '¿Estás seguro?',
            text: "El ticket quedará validado y no podrá ser utilizado para ingresar al evento.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Validar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                validateAttendeeAPI(id).then((response) => {
                    if (response.status === "success") {
                        mySwal.fire(
                            '¡Validado!',
                            'El ticket ha sido validado.',
                            'success'
                        ).then(() => {
                            setIsLoaded(false);
                            fetchData();
                        })
                    }
                    else {
                        mySwal.fire(
                            '¡Error!',
                            'Ha ocurrido un error.',
                            'error'
                        )
                    }
                })
            }
        })
    }
    const validateAttendeeAPI = async (id) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/validate-attendee`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': `Bearer ${user.token}`,
            },
            body: JSON.stringify({
                "ticket_id": id,
            }),
        });
        const response = await request.json();
        return response;
    }

    const handleSelectAll = () => {
        if (!selectAll) {
            const newSelectedAttendees = attendees.map((attendee) => attendee.id);
            setSelectedAttendees(newSelectedAttendees);
        } else {
            setSelectedAttendees([]);
        }
        setSelectAll(!selectAll);
    };


    function getViewport () {
        const width = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
        )

        if (width <= 992) return 'md'
        return 'xl'
    }

    function searchData() {
        // Declare variables
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();

        if (getViewport() == 'xl') {
            table = document.getElementsByTagName("table")[0];

            tr = table.getElementsByTagName("tr");

            // Loop through all table rows, and hide those who don't match the search query
            for (i = 0; i < tr.length; i++) {
                var td0 = tr[i].getElementsByTagName("td")[0];
                var td1 = tr[i].getElementsByTagName("td")[1];
                var td2 = tr[i].getElementsByTagName("td")[2];
                var td3 = tr[i].getElementsByTagName("td")[4];
                if (td0 || td1 || td2 || td3) {
                    var txtValue0 = td0.textContent || td0.innerText;
                    var txtValue1 = td1.textContent || td1.innerText;
                    var txtValue2 = td2.textContent || td2.innerText;
                    var txtValue3 = td3.textContent || td3.innerText;
                    if (txtValue0.toUpperCase().indexOf(filter) > -1 || txtValue1.toUpperCase().indexOf(filter) > -1 || txtValue2.toUpperCase().indexOf(filter) > -1 || txtValue3.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                    } else {
                        tr[i].style.display = "none";
                    }
                }
            }
        } else {
            table = document.getElementsByTagName("table")[1];

            tr = table.getElementsByTagName("tr");

            // Loop through all table rows, and hide those who don't match the search query
            for (i = 0; i < tr.length; i++) {
                var td0 = tr[i].getElementsByTagName("td")[0];
                if (td0) {
                    var txtValue0 = td0.textContent || td0.innerText;
                    if (txtValue0.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                    } else {
                        tr[i].style.display = "none";
                    }
                }
            }
        }

    }

    const [isLoaded, setIsLoaded] = useState(false);

    const deactivateAttendee = async (id) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/promoter-event-status`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            method: 'DELETE',
            body: JSON.stringify({
                "promoter_id": id,
                "event_id": props.event,
            })
        });

        const response = await request.json();

        if (response.status === "success") {
            setIsLoaded(false);
            fetchData();
        }
    }

    const activateAttendee = async (id) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/promoter-event-status`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            method: 'POST',
            body: JSON.stringify({
                "promoter_id": id,
                "event_id": props.event,
            })
        });

        const response = await request.json();

        if (response.status === "success") {
            setIsLoaded(false);
            fetchData();
        }
    }

    useEffect(() => {
        //console.log(selectedAttendees);
    }, [selectedAttendees])

    function copyToClipboard(link) {
        document.getElementById("text").type = "text";

        document.getElementById("text").value = link;

        var text = document.getElementById("text");
        text.select();
        text.setSelectionRange(0, 99999); /*For mobile devices*/

        navigator.clipboard.writeText(text.value);

        text.type = 'hidden';

        document.activeElement.blur();

        setSuccessMessage("Link copiado al portapapeles");
        setOpenS(true);

    }

    const handleCheckboxChange = (attendeeId, isChecked) => {
        if (isChecked) {
            setSelectedAttendees((prevState) => [...prevState, attendeeId]);
        } else {
            setSelectedAttendees((prevState) => prevState.filter((id) => id !== attendeeId));
        }
    };


    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <style>
                {`
                .custom-table th,
                .custom-table td {
                  border-left: none;
                  border-right: none;
                  text-align: center;
                  vertical-align: middle; /* Add this line */
                }
                
                .custom-table thead th {
                  border-bottom: 1px solid #dee2e6;
                }
                
                .custom-table tbody td {
                  border-top: 1px solid #dee2e6;
                }
                .square-button {
                    width: 40px;
                    height: 40px;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                }


                `
                }
            </style>
            <div className="row mb-3">
                <div className="col">
                    <h1>Colaboradores</h1>
                </div>
            </div>
            {attendees.length > 0 ? (
            <>
            {selectedAttendees.length > 0 ? (
                <div className="row mb-3">
                    <div className="col">
                        <Button variant={"danger"} onClick={() => {deactivateAttendee(selectedAttendees)}}>Desactivar Sel.</Button>
                        <Button variant={"success"} onClick={() => {activateAttendee(selectedAttendees)}} style={{marginLeft: "10px"}}>Activar Sel.</Button>
                    </div>
                </div>
            ) : null}
            <div className="row mb-3">
                <div className="col">
                    <TextField id="myInput" fullWidth onKeyUp={searchData} label="Buscar por nombre, email o rol" variant="outlined" />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Table responsive bordered size="sm" id="myTable" className="custom-table">
                    <thead>
                        <tr>
                            <th className="text-center" style={{ whiteSpace: "nowrap", paddingLeft: "8px", paddingRight: "8px" }}>
                                <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                                <span style={{ marginLeft: "4px" }}>Sel. todos</span>
                            </th>
                            <th className="text-center">Nombre</th>
                            <th className="text-center">Email</th>
                            <th className="text-center">Link Venta</th>
                            <th className="text-center">Rol</th>
                            <th className="text-center">Estado</th>
                        </tr>
                        </thead>

                        <tbody>
                        {attendees.map((attendee, key) => (
                            <tr key={key} style={{fontSize: "10px"}}>
                                <td className="text-center" style={{ whiteSpace: "nowrap", paddingLeft: "8px", paddingRight: "8px" }}>
                                    <input
                                        type="checkbox"
                                        checked={selectedAttendees.includes(attendee.id)}
                                        onChange={(e) => handleCheckboxChange(attendee.id, e.target.checked)}
                                    />
                                </td>
                                <td className="text-center" style={{wordWrap: "break-word"}}>{attendee?.name?.toUpperCase()}</td>
                                <td className="text-center">{attendee?.email}</td>
                                <td>
                                    <div className="row">
                                        <div className="col">
                                            <Tooltip title="Copiar link de venta">
                                                <Button
                                                    className="square-button"
                                                    variant="outline-dark"
                                                    onClick={() => copyToClipboard(attendee?.event_url)}
                                                >
                                                    <AiOutlineLink size={20} />
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </td>
                                <td className="text-center">{attendee?.role?.toUpperCase()}</td>
                                <td>
                                    {attendee.active ? (
                                        <Tooltip title="Activo">
                                            <button
                                                className="btn btn-success btn-sm square-button"
                                                onClick={() => deactivateAttendee([attendee?.id])}
                                            >
                                                <AiOutlineCheck size={20} />
                                            </button>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Inactivo">
                                            <button
                                                className="btn btn-danger btn-sm square-button"
                                                onClick={() => activateAttendee([attendee?.id])}
                                            >
                                                <AiOutlineClose size={20} />
                                            </button>
                                        </Tooltip>
                                    )}
                                </td>

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <input type="hidden" id="text" />
                </div>
            </div>
            </>
            ) : (
                <div className="d-flex justify-content-center align-items-start" style={{marginTop: "10vh"}}>
                    <Alert severity="warning">
                        <AlertTitle>Todavía no hay colaboradores asignados</AlertTitle>
                        <p>Para asignar colaboradores, haz click en el botón <strong>+</strong> de la sección <strong>Colaboradores</strong> en la barra lateral.</p>
                    </Alert>
                </div>
                )}
        </div>
    );
    }
};

export default EventColaborators;