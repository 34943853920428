import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Menu, MenuItem } from '@material-ui/core';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useNavigate } from 'react-router';

const columns = [
    { id: 'table_number', label: 'Mesa' },
    { id: 'id', label: 'Orden' },
    { id: 'order_status', label: 'Estado' },
    {
        id: 'actions',
        label: 'Acciones',
        align: 'right',
    },
];

export default function StickyHeadTable(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const navigate = useNavigate();

    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <TableContainer sx={{ maxHeight: "75vh" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.rows.map((row) => (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                            {column.id === 'actions' ? (
                                                <>
                                                    <Button className={"me-2"} variant="text" color="default" onClick={() => navigate(`/order/${row.order_hash}`)}>Ver</Button>
                                                </>
                                            ) : column.format && typeof value === 'number' ? (
                                                column.format(value)
                                            ) : column.id === 'order_status' ? (
                                                <span className={`${value === "processing" ? "font-weight-bold" : ""} ${value === "processing" ? "text-danger" : "text-success"}`}>
                                                    {
                                                        value === "processing" ? "Pendiente" :
                                                        value === "delivered" ? "Entregado" :
                                                        value
                                                    }
                                                </span>
                                            ) : (
                                                value
                                            )
                                            }
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}