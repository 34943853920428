import React, {useEffect, useState} from 'react';
import {CircularProgress, Paper} from "@mui/material";
import KPI from "../components/KPI";
import BarChart from "../components/BarChart";
import RRPPList from "../components/RRPPList";
import Orders from "../components/Orders";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";

const Dashboard = () => {

    const user = useSelector(selectUser)

    const [KPIData, setKPIData] = useState(null);
    const [orderData, setOrderData] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [salesData, setSalesData] = useState([]);

    const fetchData = async () => {
        const response1 = await fetch(`${process.env.REACT_APP_API_URL}/kpi-data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json1 = await response1.json();
        //console.log(json1);
        setKPIData(json1);
        //---------------------------------
        const response2 = await fetch(`${process.env.REACT_APP_API_URL}/barchart-data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                "event_id": 77,
            }),
        });
        const json2 = await response2.json();
        setSalesData(json2);
        //---------------------------------
        const response3 = await fetch(`${process.env.REACT_APP_API_URL}/rrpp-producer-sales`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json3 = await response3.json();
        ////console.log(json3);
        setRows(json3);
        //---------------------------------
        const response4 = await fetch(`${process.env.REACT_APP_API_URL}/last-sales`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json4 = await response4.json();
        ////console.log(json4);
        setOrderData(json4);

        setIsLoaded(true);
    }

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);

    const [isLoaded, setIsLoaded] = useState(false);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <small className="text-muted">Informe del {
                        //date a month ago till today in gmt-3
                        new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleDateString("es-AR", {timeZone: "America/Argentina/Buenos_Aires"}) + " al " + new Date().toLocaleDateString("es-AR", {timeZone: "America/Argentina/Buenos_Aires"})
                    }</small>
                </div>
            </div>
            <div className="row align-items-start">
                <div className="col-lg-4 pt-3 pb-3">
                    <KPI
                        ticketVariation={-124}
                        salesVariation={-30}
                        conflictVariation={10}
                        KPIData={KPIData}
                    />
                </div>
                <div className="col-lg-8 pt-3 pb-3">
                    <Paper style={{borderRadius: "1rem", overflow: "hidden", boxShadow: "0 0 0.5rem #ccc"}}>
                        <div className="p-2 bg-white">
                            <BarChart salesData={salesData} />
                        </div>
                    </Paper>
                </div>
            </div>
            <div className="row align-items-start">
                <div className="col-lg-4 pt-3 pb-3">
                    <div className="bg-white">
                        <RRPPList rows={rows} />
                    </div>
                </div>
                <div className="col-lg-8 pt-3 pb-3">
                    <Orders orderData={orderData} />
                </div>
            </div>
        </div>
    );
    }
};

export default Dashboard;