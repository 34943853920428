import React, {useState} from 'react';
import {Button, Container} from "react-bootstrap";
import {useNavigate} from "react-router";
import {CircularProgress} from "@mui/material";
import MenuContainerAdmin from "./MenuContainerAdmin";

const DigitalMenus = () => {
    const navigate = useNavigate();

    const [menus, setMenus] = React.useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const token = localStorage.getItem('token');

    const fetchData = async () => {
        setIsLoaded(false);
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        const data = await request.json();

        if (data.status === "success") {
            setMenus(data.menus);
            setIsLoaded(true);
        }

    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [token]);

    return (
        <>
            {!isLoaded ? (
                <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                    <CircularProgress color="secondary" size={100} />
                </div>
            ) : (
                    <div>
                        <div className="row mt-2 justify-content-between align-items-center">
                            <div className="col">
                                <h3>Cartas digitales</h3>
                            </div>
                            <div className="col-auto">
                                <div className="row justify-content-end">
                                    <div className="col-12">
                                        <Button variant="dark" onClick={() => navigate("./add")} className="float-right">+ Crear carta</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                {menus?.length > 0 ? (
                                    <MenuContainerAdmin page={"menu"} rows={menus} />
                                ) : (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="alert alert-info" role="alert">
                                                No hay cartas digitales creadas.
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
        </>
    );
};

export default DigitalMenus;