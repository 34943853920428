import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button, CircularProgress, FormControl, InputLabel, MenuItem, Select,
    Snackbar,
    TextField
} from "@mui/material";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {RiDeleteBinLine} from "react-icons/ri";

const PromoterEvents = (props) => {

    const user = useSelector(selectUser)

    const [values, setValues] = useState({
        events: '',
        role: '',
        promoter_id: props.promoter,
        cortesias: 0,
        cash: 0,
        table_id: [''],
    });

    const navigate = useNavigate();

    const [events, setEvents] = useState([]);
    const [tables, setTables] = useState([]);
    const [roles, setRoles] = useState([]);
    const [availableRoles, setAvailableRoles] = useState([]);
    const [hideFreeTickets, setHideFreeTickets] = useState(false);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const updatePromoter = async (e) => {
        e.preventDefault();
        setIsLoaded(false);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/promoter-assign`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify(values),
        });

        const json = await response.json();
        if (json.success == 1) {
            setOpenS(true);
            setTimeout(() => {
                navigate("/organizations/team");
            }, 2000);
        }
        else {
            setOpenE(true);
        }
        setIsLoaded(true);

    }

    const deletePromoter = async () => {
        setIsLoaded(false);
        //console.log(values, props.promoter);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/promoter-assign`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify(values),
        });

        const json = await response.json();
        //console.log(json);

        if (json.success == 1) {
            setOpenS(true);
            setTimeout(() => {
                navigate("/organizations/team");
            }, 2000);
        }
        else {
            setOpenE(true);
        }
        setIsLoaded(true);

    }

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/promoter-assign?promoter_id=${props.promoter}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const status = await response.status;

        if (status == 401) {
            setOpenE(true);
            setTimeout(() => {
                navigate("/organizations/team");
            }, 2000);
        }
        const json = await response.json();

        //console.log(json);

        if (json.success == 1) {
            setEvents(json.events.filter(event => event.assigned !== 0));
            if (json.events.filter(event => event.assigned !== 0).length > 0) {
                setRoles(json.roles);
                setValues({...values, events: json.events.filter(event => event.assigned !== 0)[0].event_id, role: json.events.filter(event => event.assigned !== 0)[0].role_id, cortesias: json.events.filter(event => event.assigned !== 0)[0].cortesias, cash: json.events.filter(event => event.assigned !== 0)[0].cash, table_id: json.events.filter(event => event.assigned !== 0)[0].table_id});
                setIsLoaded(true);
            }
            else {
                setOpenE(true);
                setTimeout(() => {
                    navigate("/organizations/team");
                }, 2000);
            }
        }
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);

    useEffect(() => {
        if (events.filter(event => event.event_id === values.events)[0]?.rrpp === 1) {
            setAvailableRoles(roles.filter(role => role.role_id == 4));
            setValues({...values, cash: 0, cortesias: 0});
            setHideFreeTickets(true);
        }
        else {
            setAvailableRoles(roles.filter(role => role.role_id != 4));
            setHideFreeTickets(false);
        }
    }, [values.events, values.role]);

    useEffect(() => {
        //if values.role is not in the available roles, set it to the first available role
        if (!availableRoles.some(role => role?.role_id == values?.role)) {
            setValues({...values, role: availableRoles[0]?.role_id});
        }
    }, [availableRoles]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    useEffect(() => {
        // Check if values.role is one of 6, 7, or 8
        if ([7, 8, 9].includes(values.role)) {
            setValues(prevValues => ({ ...prevValues, cash: 0, cortesias: 0 }));
            setHideFreeTickets(true);
        }
        else if (values.role !== 9) {
            setValues(prevValues => ({ ...prevValues, table_id: '' }));
        }

        if (values.role === 9) {
            setTables(events?.filter(event => event.event_id === values.events)[0]?.tables);
        }
    }, [values.role, events]);

    const addTable = () => {
        setValues(prevValues => ({ ...prevValues, table_id: [...prevValues.table_id, ''] }));
    }

    const [isLoaded, setIsLoaded] = useState(false);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
                <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                    <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                        El colaborador no tiene eventos asignados!
                    </Alert>
                </Snackbar>
            </div>
        )
    }
    else {
        return (
            <div>
                <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                    <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                        Colaborador actualizado con éxito!
                    </Alert>
                </Snackbar>
                <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                    <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                        No se pudo actualizar el colaborador!
                    </Alert>
                </Snackbar>

                <div className="row">
                    <div className="col">
                        <h1>Editar eventos asignados</h1>
                    </div>
                </div>
                <form onSubmit={updatePromoter}>
                    <div className="row mb-2">
                        <div className="col-md-6">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Evento</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.events}
                                    label="Evento"
                                    onChange={(e) => {
                                        setValues({...values, events: e.target.value, role: events.filter(event => event.event_id === e.target.value)[0].role_id, cortesias: events.filter(event => event.event_id === e.target.value)[0].cortesias, cash: events.filter(event => event.event_id === e.target.value)[0].cash});
                                    }}
                                >
                                    {events.map((event, index) => (
                                        <MenuItem key={index} value={event.event_id}>{event.event_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row mt-3 mb-2">
                        <div className="col-md-6">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Rol</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.role}
                                    label="Rol"
                                    onChange={handleChange('role')}
                                >
                                    {availableRoles.map((role, index) => (
                                        <MenuItem key={index} value={role.role_id}>{role.role_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <small className="text-muted">
                                {values.role == 1 && (
                                    <>
                                        Permisos de administrador para crear eventos, establecer precios, crear promociones, etc.
                                        <br />
                                        Acceso a la información de venta de sus propios eventos.
                                        <br />
                                        Sin embargo, no tiene permisos de administrador a nivel de empresa y no puede acceder a la información de venta de otros eventos.
                                    </>
                                )}

                                {values.role == 2 && (
                                    <>
                                        Permisos para crear y administrar su propio link de venta.
                                        <br />
                                        Acceso a la información de venta de sus propios boletos vendidos.
                                        <br />
                                        Sin permisos para editar eventos o acceder a la información de venta de otros eventos.
                                    </>
                                )}

                                {values.role == 3 && (
                                    <>
                                        Permisos para crear y administrar su propio link de venta.
                                        <br />
                                        Permisos para asignar sub-RRPPs que puedan vender boletos a través de su propio link.
                                        <br />
                                        Acceso a la información de venta de sus propios boletos vendidos y los boletos vendidos por sus sub-RRPPs.
                                        <br />
                                        Sin permisos para editar eventos o acceder a la información de venta de otros eventos o sub-RRPPs asignados por otros RRPPs.
                                    </>
                                )}

                                {values.role == 4 && (
                                    <>
                                        Permisos para crear y administrar su propio link de venta.
                                        <br />
                                        Permisos para asignar sub-RRPPs que puedan vender boletos a través de su propio link.
                                        <br />
                                        Acceso a la información de venta de sus propios boletos vendidos y los boletos vendidos por sus sub-RRPPs.
                                        <br />
                                        Sin permisos para editar eventos o acceder a la información de venta de otros eventos o sub-RRPPs asignados por otros RRPPs.
                                    </>
                                )}

                                {values.role == 5 && (
                                    <>
                                        Permisos para acceder a las estadísticas de ventas de los eventos asignados, como el número de tickets vendidos, los ingresos totales, los patrones de compra y otros datos relevantes.
                                        <br />
                                        Permisos para acceder a la lista digital de los asistentes del evento asignado.
                                        <br />
                                        Sin permisos para editar o modificar la información del evento.
                                    </>
                                )}
                            </small>
                        </div>
                    </div>
                    {!hideFreeTickets && (
                        <>
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <TextField label="Tickets de cortesía disponibles" value={values.cortesias} onChange={handleChange('cortesias')} className="mb-3" type="number" onWheel={(e) => e.preventDefault()}  fullWidth variant="outlined" />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-6">
                                    <TextField label="Tickets en efectivo disponibles" value={values.cash} onChange={handleChange('cash')} className="mb-3" type="number" onWheel={(e) => e.preventDefault()}  fullWidth variant="outlined" />
                                </div>
                            </div>
                        </>
                    )}
                    {values.role === 9 && (
                        <>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <Button variant="text" color="primary" onClick={addTable}>
                                        + Agregar mesa asignada
                                    </Button>
                                </div>
                            </div>
                            {values?.table_id?.map((tableId, index) => (
                                <div className="row mb-2" key={index}>
                                    <div className="col-md-6">
                                        <div
                                            className="row align-items-center"> {/* This ensures vertical alignment for all children */}
                                            <div className="row mb-2" key={index}>
                                                <div className="col">
                                                    <FormControl fullWidth>
                                                        <InputLabel id={`table-select-label-${index}`}>Mesa
                                                            asignada</InputLabel>
                                                        <Select
                                                            labelId={`table-select-label-${index}`}
                                                            id={`table-select-${index}`}
                                                            value={tableId}
                                                            label="Mesa asignada"
                                                            onChange={(event) => {
                                                                const newTableIds = [...values.table_id];
                                                                newTableIds[index] = event.target.value;
                                                                setValues({...values, table_id: newTableIds});
                                                            }}
                                                            required
                                                        >
                                                            {tables.map((table, idx) => (
                                                                <MenuItem key={idx}
                                                                          value={table.table_id}>{table.table_name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div
                                                    className="col-auto d-flex align-items-center"> {/* Additional alignment for the button */}
                                                    <Button
                                                        variant="outlined"
                                                        color="error"
                                                        onClick={() => {
                                                            const newTableIds = values?.table_id?.filter((_, idx) => idx !== index);
                                                            setValues({...values, table_id: newTableIds});
                                                        }}
                                                    >
                                                        <RiDeleteBinLine />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                    <div className="row">
                        <div className="col-md-6 d-flex justify-content-between">
                            <Button variant="contained" type="submit" color="primary" className="me-2">
                                Guardar
                            </Button>
                            <Button variant="contained" color="error" onClick={deletePromoter}>
                                Eliminar del evento
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
};

export default PromoterEvents;
