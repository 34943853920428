import React, {useState} from 'react';
import {
    Alert,
    CircularProgress,
    FormControl,
    InputAdornment,
    InputLabel, MenuItem,
    OutlinedInput, Select,
    Snackbar,
    TextField
} from "@mui/material";
import {setEventChange} from "../redux/eventsChangeSlice";
import {Button} from "react-bootstrap";
import {selectUser} from "../redux/userSlice";
import {useSelector} from "react-redux";
import {ImageUpload} from "./ImageUpload";
import {ca} from "date-fns/locale";

const DigitalMenuProductAssign = (props) => {

    const user = useSelector(selectUser);

    const [categories, setCategories] = useState([]);

    const [values, setValues] = useState({
        category_id: '',
    });

    const [errors, setErrors] = useState({
        name: false,
        description: false,
        image: false,
        price: false,
    });

    const [errorMessages, setErrorMessages] = useState({
        name: '',
        description: '',
        image: '',
        price: '',
    });

    const [isLoaded, setIsLoaded] = useState(false);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const fetchData = async () => {
        if (props.product) {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-product-assign?menu_id=${props.menu}&product_id=${props.product}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                    'Content-Type': 'application/json',
                }
            });

            const json = await request.json();

            if (json.status === "success") {
                setCategories(json.categories);
                setIsLoaded(true);
                setValues({category_id: json.actual_category.id});
            }
        } else {
            setIsLoaded(true);
        }
    }

    React.useEffect(() => {
        if (isLoaded) return;
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    const changeImage = (image) => {
        setValues({...values, image: image});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let response;

        if (props.product) {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-product-assign`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'credentials': 'same-origin',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify({
                    menu_id: props.menu,
                    product_id: props.product,
                    category_id: values.category_id,
                }),
            });

            response = await request.json();
        } else {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-product-assign`, {
                method: 'POST',
                headers: {
                    'credentials': 'same-origin',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify({
                    menu_id: props.menu,
                    product_id: props.product,
                    category_id: values.category_id,
                }),
            });

            response = await request.json();
        }

        if (response.status === "success") {
            setSuccessMessage(response.message);
            setOpenS(true);
        } else {
            setErrorMessage(response.message);
            setOpenE(true);
        }
    }

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            <div className="row mt-2">
                <div className="col-12">
                    <h4>
                        Asignar a categoría
                    </h4>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-lg-6">
                    <form onSubmit={handleSubmit}>
                        <FormControl className={"mb-3"} fullWidth>
                            <InputLabel id="demo-simple-select-label">Categoría</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.category_id}
                                label="Categoría"
                                onChange={handleChange('category_id')}
                                required
                            >
                                {categories.map((category, index) => (
                                    <MenuItem key={index} value={category.id}>{category.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Button variant="dark" type="submit" className={"mb-4"}>
                            Asignar
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
    }
};

export default DigitalMenuProductAssign;