import React, {useState} from 'react';
import {Link} from "react-router-dom";
import CampaignContainer from "../components/CampaignContainer";
import {
    Alert,
    AlertTitle,
    Button,
    CircularProgress, Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import CreateCampaign from "../components/CreateCampaign";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import '../styles/Marketing.css';
import {IoClose} from "react-icons/io5";

const Marketing = () => {

    const user = useSelector(selectUser)
    const [events, setEvents] = React.useState([]);
    // Add the programmedDate state to the Marketing component
    const [programmedDate, setProgrammedDate] = useState(new Date());
    const [selectedCampaign, setSelectedCampaign] = useState(null);

    const handleProgrammedDateChange = (newValue, campaignId) => {
        setSelectedCampaign(campaignId);
        setProgrammedDate(newValue);
    };

    const [dialogContent, setDialogContent] = React.useState({
        title: "",
        content: "",
        button: {
            text: "",
            click: () => navigate("/organizations/settings"),
        }
    });

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleOpenConfirm = () => setOpenConfirm(true);
    const handleCloseConfirm = () => setOpenConfirm(false);

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/campaign`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();
        if (json.status === "success") {
            //console.log(json);
            setEvents(json.campaigns);
            setIsLoaded(true);
        }
        else {
            //console.log(json);
            setIsLoaded(true);
        }
    }

    const [isLoaded, setIsLoaded] = useState(false);

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    const navigate = useNavigate();

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <>
            <div className="row mt-2">
                <div className="col-12 d-flex align-items-center justify-content-between">
                    <h1 className="me-5">Marketing</h1>
                    <div className="text-center">
                        <Button className="me-3 mb-2 mb-md-0 mktButton" variant="contained" color="secondary" onClick={() => navigate("/organizations/userbase")}>Grupos</Button>
                        <Button className="mktButton" variant="contained" onClick={()=> navigate("./new")} color="primary">Crear campaña</Button>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12">
                    {events?.length > 0 ? (events.map((event, key) => (
                        <CampaignContainer
                            openDialog={handleOpenConfirm}
                            setDialogData={setDialogContent}
                            key={key}
                            campaign={event}
                            setIsLoaded={setIsLoaded}
                            onProgrammedDateChange={handleProgrammedDateChange}
                        />
                    ))) : (
                        <div className="d-flex justify-content-center align-items-start" style={{marginTop: "10vh"}}>
                            <Alert severity="warning">
                                <AlertTitle>No se encontraron campañas</AlertTitle>
                                No tenes campañas creadas — <strong><Link style={{textDecoration: "none", color: "indianred"}} to={"./new"}>Creá tu primer campaña</Link></strong>
                            </Alert>
                        </div>
                    )}

                </div>
            </div>
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="responsive-dialog-title"
            >
                <div className="closeIconLight p-1 align-items-center d-flex justify-content-center">
                    <IoClose size={25} color={"rgba(0, 0, 0, 0.38)"} onClick={handleCloseConfirm}/>
                </div>
                <DialogTitle id="responsive-dialog-title">
                    {dialogContent.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className="row">
                            <div className="col-12">
                                <p>{dialogContent.content}</p>
                            </div>
                        </div>
                        <div className="row text-center mt-2">
                            <div className="col-12">
                                <Button variant="contained" color="secondary" onClick={dialogContent.button.click}>{dialogContent.button.text}</Button>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
    }
};

export default Marketing;
