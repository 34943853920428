import React, {useEffect, useState} from 'react';
import {
    Alert, AlertTitle,
    CircularProgress,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FilledInput,
    FormControl,
    FormControlLabel,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select, Snackbar,
    TextField, Tooltip
} from "@mui/material";
import {Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {selectEventChange} from "../redux/eventsChangeSlice";
import IconButton from "@mui/material/IconButton";
import {CopyAll} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {IoClose} from "react-icons/io5";
import {Button as ButtonBT} from "react-bootstrap";
import {FaUpload} from "react-icons/fa";
import {useNavigate} from "react-router";


const CreateSingleLinkEvent = (props) => {

    const user = useSelector(selectUser);
    const eventChange = useSelector(selectEventChange);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [disabledButton, setDisabledButton] = useState(false);

    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const categories = [
        {
            value: 'free',
            label: 'Cortesía',
        },
        {
            value: 'paid',
            label: 'Venta en efectivo',
        },
    ];

    const registerType = [
        {
            value: 'link',
            label: 'Link de Tickets',
        },
        {
            value: 'manual',
            label: 'Ingreso Manual',
        },
    ];

    const [values, setValues] = useState({
        registerType: 'link',

        type: 'free',
        ticketType: "",
        quantity: "1",

        email: "",
        name: "",
        DNI: "",
    });

    function updateClipboard(text) {
        document.getElementById("text").type = "text";

        document.getElementById("text").value = text;

        var text = document.getElementById("text");
        text.select();
        text.setSelectionRange(0, 99999); /*For mobile devices*/

        navigator.clipboard.writeText(text.value);

        text.type = 'hidden';

        document.activeElement.blur();
    }

    const [tickets, setTickets] = useState([]);
    const [link, setLink] = useState("");
    const [owner, setOwner] = useState(false);

    const [limits, setLimits] = useState({
        paid: 0,
        free: 0
    })

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleOpenConfirm = () => setOpenConfirm(true);
    const handleCloseConfirm = () => setOpenConfirm(false);

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/event-tickets-list?event_id=${props.event}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();
        //console.log(json);
        setTickets(json?.tickets);
        setOwner(json?.owner);
        setLimits(json?.limits);
        setValues({
            ...values,
            ticketType: json?.tickets[0]?.event_ticket_id,
        });
        setIsLoaded(true);
    }

    const [isLoaded, setIsLoaded] = useState(false);

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const changeImage = (image) => {
        setValues({...values, portada: image});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setDisabledButton(true);

        const request = await fetch(`${process.env.REACT_APP_API_URL}/generar-single-link-events`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                event_id: props.event,
                owner: owner,
            })
            });

        const response = await request.json();

        if (response.type === "link") {
            if (response.status === "success") {
                setLink(response.link);
                handleOpenConfirm();
            } else {
                setOpenE(true);
            }
        }

        setDisabledButton(false);

    }

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    Ticket enviado con éxito!
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    Ocurrió un error al enviar el ticket!
                </Alert>
            </Snackbar>
            <div className="row mb-3 mt-2 align-items-center">
                <div className="col-6">
                    <h2>Crear link de único uso</h2>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <>
                    <div className="row mt-3">
                        <div className="col-md-8">
                            <strong>¿Qué son los Links Únicos de Eventos?</strong>
                            <p>Un link único de evento es un enlace particular que habilita la compra de entradas para un evento específico. Este link es de <b>uso único</b>. Es decir, una vez que el destinatario concreta su compra, el enlace se desactiva automáticamente, impidiendo que otras personas adquieran tickets a través del mismo.</p>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3 mb-3">
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <Button variant="dark" className="me-2" type="submit">
                                        Generar link único uso
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </form>

            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
            >
                <DialogTitle id="responsive-dialog-title">
                    <div className="closeIconDark p-1 align-items-center d-flex justify-content-center">
                        <IoClose size={25} color={"rgba(0, 0, 0)"} onClick={handleCloseConfirm}/>
                    </div>
                    {"Link generado"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Box component="form" noValidate autoComplete="off">
                            <FormControl variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={"text"}
                                    value={link}
                                    disabled={true}
                                    fullWidth={true}
                                    multiline={true}
                                    rows={3}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    updateClipboard(link);
                                                    handleTooltipOpen();
                                                }}
                                                edge="end"
                                            >
                                                <Grid item>
                                                    <ClickAwayListener onClickAway={handleTooltipClose}>
                                                        <div>
                                                            <Tooltip
                                                                onClose={handleTooltipClose}
                                                                open={open}
                                                                disableFocusListener
                                                                disableHoverListener
                                                                disableTouchListener
                                                                title="Copiado"
                                                                placement="top"
                                                            >
                                                                <CopyAll />
                                                            </Tooltip>
                                                        </div>
                                                    </ClickAwayListener>
                                                </Grid>
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <input type="hidden" id="text" />
        </div>
    );
    }
};

export default CreateSingleLinkEvent;
