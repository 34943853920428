import React, {useEffect, useState} from 'react';
import {
    Alert,
    CircularProgress, FilledInput,
    FormControl, FormControlLabel,
    InputAdornment,
    InputLabel, MenuItem,
    OutlinedInput, Select,
    Snackbar,
    TextField
} from "@mui/material";
import {setEventChange} from "../redux/eventsChangeSlice";
import {Button} from "react-bootstrap";
import{Button as ButtonMUI} from "@mui/material";
import {selectUser} from "../redux/userSlice";
import {useSelector} from "react-redux";
import {ImageUpload} from "./ImageUpload";
import '../styles/DigitalMenuProduct.css';
import {BsTrash} from "react-icons/bs";
import {GiWaterBottle} from "react-icons/gi";
import {GrValidate} from "react-icons/gr";
import {TiUserDelete} from "react-icons/ti";
import ButtonContainer from "./ButtonContainer";
import {useNavigate} from "react-router";
import Checkbox from "@mui/material/Checkbox";

const DigitalMenuProduct = (props) => {

    const user = useSelector(selectUser);
    const navigate = useNavigate();

    const [values, setValues] = useState({
        name: '',
        description: '',
        image: null,
        price: 0,
        external_product_id: '',
        components: [],
        variant_categories: [],
    });
    const [showComponentForm, setShowComponentForm] = useState(false);

    const [errors, setErrors] = useState({
        name: false,
        description: false,
        image: false,
        price: false,
    });

    const [errorMessages, setErrorMessages] = useState({
        name: '',
        description: '',
        image: '',
        price: '',
    });

    const [components, setComponents] = useState([
    ]);


    const [isLoaded, setIsLoaded] = useState(false);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const handleChange = (prop, index = null) => (event) => {
        if (index !== null) {
            const newComponents = [...values.components];
            newComponents[index][prop] = event.target.value;
            newComponents[index].unit_measure = components.find((c) => c.id === newComponents[index].id)?.unit_measure;
            setValues({ ...values, components: newComponents });
        } else {
            setValues({ ...values, [prop]: event.target.value });
        }
    };

    const addComponentForm = () => {
        setValues({ ...values, components: [...values.components, { id: '', quantity: 0 }] });
        setShowComponentForm(true);
    };

    const addVariantCategoryForm = () => {
        setValues({ ...values, variant_categories: [...values.variant_categories, { title: '', is_unique: false, variants: [{ title: '', price: 0, components: [] }] }] });
    };

    const addVariantForm = (index) => {
        const newCategories = [...values.variant_categories];
        newCategories[index].variants.push({ title: '', price: 0, components: [] });
        setValues({ ...values, variant_categories: newCategories });
    }

    const addVariantComponentForm = (category, variant) => {
        const newCategories = [...values.variant_categories];
        newCategories[category]?.variants[variant]?.components?.push({ id: '', quantity: 0 });
        setValues({ ...values, variant_categories: newCategories });
    }

    const fetchData = async () => {
        if (props.product) {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-product?menu_id=${props.menu}&product_id=${props.product}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                    'Content-Type': 'application/json',
                }
            });

            const json = await request.json();

            if (json.status === "success") {
                setValues(json.product);
            }
        } else {
            setIsLoaded(true);
        }

        const fetchComponents = await fetch(`${process.env.REACT_APP_API_URL}/component`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${user.token}`,
                'credentials': 'same-origin',
                'Content-Type': 'application/json',
            },
        });

        const jsonComponents = await fetchComponents.json();

        if (jsonComponents.status === "success") {
            setComponents(jsonComponents.components);
            setIsLoaded(true);
        }
    }

    React.useEffect(() => {
        if (isLoaded) return;
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    const changeImage = (image) => {
        setValues({...values, image: image});
    }

    const handleAddComponent = (selectedComponent, amount) => {
        const newComponents = [...components];
        const existingComponent = newComponents.find(
            (component) => component.id === selectedComponent.id
        );

        if (existingComponent) {
            existingComponent.amount += amount;
        } else {
            newComponents.push({ ...selectedComponent, amount });
        }

        setComponents(newComponents);
    };

    const handleDeleteComponent = (index) => {
        const newComponents = [...values.components];
        newComponents.splice(index, 1);
        setValues({ ...values, components: newComponents });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let response;

        if (props.product) {
            const formData = new FormData();
            formData.append('menu_id', props.menu);
            formData.append('product_id', props.product);
            formData.append('name', values.name);
            formData.append('description', values.description);
            formData.append('price', values.price);
            formData.append('image', values.image);
            formData.append('components', JSON.stringify(values.components));
            formData.append('external_product_id', values.external_product_id);
            formData.append('variant_categories', JSON.stringify(values.variant_categories));

            const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-product-edit`, {
                method: 'POST',
                headers: {
                    'credentials': 'same-origin',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: formData,
            });


            response = await request.json();
        } else {
            const formData = new FormData();
            formData.append('menu_id', props.menu);
            formData.append('name', values.name);
            formData.append('description', values.description);
            formData.append('price', values.price);
            formData.append('image', values.image);
            formData.append('components', JSON.stringify(values.components));
            formData.append('external_product_id', values.external_product_id);
            formData.append('variant_categories', JSON.stringify(values.variant_categories));

            const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-product`, {
                method: 'POST',
                headers: {
                    'credentials': 'same-origin',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: formData,
            });

            response = await request.json();
        }

        if (response.status === "success") {
            setSuccessMessage(response.message);
            setOpenS(true);
        } else {
            setErrorMessage(response.message);
            setOpenE(true);
        }
    }

    useEffect(() => {
        console.log(values);
    }, [values]);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            <div className="row mt-2">
                <div className="col-12">
                    <h4>{
                        props.product ? "Editar producto " : "Crear producto"
                    }</h4>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col">
                    <h5>Información del producto</h5>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-lg-8">
                    <form onSubmit={handleSubmit}>
                        <TextField label="Nombre del producto" value={values.name} onChange={handleChange('name')} className="mb-3" fullWidth variant="outlined" />
                        <TextField label="Descripción del producto" value={values.description} rows={4} multiline onChange={handleChange('description')} className="mb-3" fullWidth variant="outlined" />
                        <div className="row">
                            <div className="col-lg-6">
                                <FormControl className="mb-3" error={errors.price} fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-amount">Precio Unitario</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-amount"
                                        value={values.price}
                                        onChange={handleChange('price')}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        label="Precio Unitario"
                                        type="number" onWheel={(e) => e.preventDefault()}
                                    />
                                    <small className="text-danger" style={{fontSize: "12px"}}>{errorMessages.price}</small>
                                </FormControl>
                            </div>
                            <div className="col-lg-6">
                                <TextField label="ID Externo Producto" value={values.external_product_id} onChange={handleChange('external_product_id')} className="mb-3" fullWidth variant="outlined" />
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col">
                                <div className="row mb-2 align-items-center justify-content-between">
                                    <div className="col">
                                        <h5>Componentes</h5>
                                    </div>
                                    <div className="col text-end">
                                        <ButtonMUI variant="text" onClick={addComponentForm}>Añadir</ButtonMUI>
                                    </div>
                                </div>
                                {values.components.map((component, index) => (
                                    <div key={index} className="mb-3 d-flex">
                                        <FormControl variant="outlined" className="me-3" style={{ width: '45%' }}>
                                            <InputLabel id={`component-select-label-${index}`}>Componente</InputLabel>
                                            <Select
                                                labelId={`component-select-label-${index}`}
                                                label={"componente"}
                                                id={`component-select-${index}`}
                                                value={component.id}
                                                onChange={handleChange('id', index)}
                                            >
                                                {components.map((component) => (
                                                    <MenuItem value={component.id}>{component.name} - {component.quantity_per_unit} {component.unit_measure}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            label={`Cantidad ${components.find((c) => c.id === component.id)?.unit_measure}`}
                                            type="number" onWheel={(e) => e.preventDefault()}
                                            value={component.quantity}
                                            onChange={handleChange('quantity', index)}
                                            variant="outlined"
                                            style={{ width: '45%' }}
                                        />
                                        <ButtonMUI
                                            variant="text"
                                            color="error"
                                            onClick={() => handleDeleteComponent(index)}
                                            style={{ marginLeft: '10px' }}
                                        >
                                            Eliminar
                                        </ButtonMUI>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12">
                                <div className="row mb-2 align-items-center justify-content-between">
                                    <div className="col">
                                        <h5>Variantes</h5>
                                    </div>
                                    <div className="col text-end">
                                        <ButtonMUI variant="text" onClick={addVariantCategoryForm}>Añadir
                                            categoría</ButtonMUI>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {values.variant_categories.map((category, index) => (
                                            <div key={index} className="category-container">
                                                <div className="row align-items-center mb-2">
                                                    <div className="col-8 pe-0">
                                                        <TextField
                                                            label={`Categoría ${index + 1}`}
                                                            value={category.title}
                                                            onChange={(e) => {
                                                                const newCategories = [...values.variant_categories];
                                                                newCategories[index].title = e.target.value;
                                                                setValues({ ...values, variant_categories: newCategories });
                                                            }}
                                                            variant="outlined"
                                                            className="category-label"
                                                            fullWidth
                                                        />
                                                    </div>
                                                    <div className="col-4">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={category.is_unique}
                                                                    onChange={(e) => {
                                                                        const newCategories = [...values.variant_categories];
                                                                        newCategories[index].is_unique = e.target.checked;
                                                                        setValues({ ...values, variant_categories: newCategories });
                                                                    }}
                                                                />
                                                            }
                                                            label="Único"
                                                        />
                                                    </div>
                                                </div>
                                                {category.variants.map((variant, vIndex) => (
                                                    <>
                                                        <div className="row align-items-center">
                                                            <div className="col-6 col-md-5 pe-0">
                                                                <div key={vIndex} className="variant">
                                                                    <TextField
                                                                        label={`Variante ${vIndex + 1}`}
                                                                        value={variant.title}
                                                                        onChange={(e) => {
                                                                            const newCategories = [...values.variant_categories];
                                                                            newCategories[index].variants[vIndex].title = e.target.value;
                                                                            setValues({ ...values, variant_categories: newCategories });
                                                                        }}
                                                                        variant="filled"
                                                                        fullWidth
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col ps-1">
                                                                <FormControl fullWidth variant="filled">
                                                                    <InputLabel>Precio adicional</InputLabel>
                                                                    <FilledInput
                                                                        value={variant.price}
                                                                        onChange={(e) => {
                                                                            const newCategories = [...values.variant_categories];
                                                                            newCategories[index].variants[vIndex].price = e.target.value;
                                                                            setValues({ ...values, variant_categories: newCategories });
                                                                        }}
                                                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                        label="Precio adicional"
                                                                        type="number" onWheel={(e) => e.preventDefault()}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-auto">
                                                                <div className="row">
                                                                    <ButtonContainer click={
                                                                        () => addVariantComponentForm(index, vIndex)
                                                                    } title={"Agregar componente"} link={`#`}
                                                                                     icon={<GiWaterBottle/>}/>
                                                                    <ButtonContainer click={
                                                                        () => {
                                                                            const newCategories = [...values.variant_categories];
                                                                            newCategories[index].variants.splice(vIndex, 1);
                                                                            setValues({ ...values, variant_categories: newCategories });
                                                                        }
                                                                    } title={"Eliminar"} link={`#`} icon={<BsTrash/>}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {variant.components ? variant.components.map((component, cIndex) => (
                                                            <div key={cIndex} className="row align-items-center">
                                                                <div className="col-12">
                                                                    <div className="component">
                                                                        <div className="row align-items-center">
                                                                            <div className="col-5 col-md-5 col-lg-5 col-xl-5 pe-0">
                                                                                <FormControl variant="filled" fullWidth>
                                                                                    <InputLabel
                                                                                        id={`component-select-label-${index}`}>Componente</InputLabel>
                                                                                    <Select
                                                                                        labelId={`component-select-label-${index}`}
                                                                                        id={`component-select-${index}`}
                                                                                        value={component.id}
                                                                                        onChange={(e) => {
                                                                                            const newCategories = [...values.variant_categories];
                                                                                            newCategories[index].variants[vIndex].components[cIndex].id = e.target.value;
                                                                                            newCategories[index].variants[vIndex].components[cIndex].unit_measure = components.find((c) => c.id === e.target.value)?.unit_measure;
                                                                                            setValues({ ...values, variant_categories: newCategories });
                                                                                        }}
                                                                                        fullWidth
                                                                                    >
                                                                                        {components.map((component) => (
                                                                                            <MenuItem
                                                                                                value={component.id}>
                                                                                                {component.name} - {component.quantity_per_unit} {component.unit_measure}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </div>
                                                                            <div className="col-5 col-md-5 col-lg-5 col-xl-5 ps-1">
                                                                                <TextField
                                                                                    label={`Cantidad ${components.find((c) => c.id === component.id)?.unit_measure}`}
                                                                                    type="number"
                                                                                    onWheel={(e) => e.preventDefault()}
                                                                                    value={component.quantity}
                                                                                    onChange={(e) => {
                                                                                        const newCategories = [...values.variant_categories];
                                                                                        newCategories[index].variants[vIndex].components[cIndex].quantity = e.target.value;
                                                                                        setValues({ ...values, variant_categories: newCategories });
                                                                                    }}
                                                                                    variant="filled"
                                                                                    fullWidth
                                                                                />
                                                                            </div>
                                                                            <div className="col-2 col-md-2 col-lg-2 col-xl-2">
                                                                                <ButtonContainer
                                                                                    click={() => {
                                                                                        const newCategories = [...values.variant_categories];
                                                                                        newCategories[index].variants[vIndex].components.splice(cIndex, 1);
                                                                                        setValues({ ...values, variant_categories: newCategories });
                                                                                    }}
                                                                                    title={"Eliminar"}
                                                                                    link={"#"}
                                                                                    icon={<BsTrash/>}
                                                                                    style={{width: '100%'}}  // Ensure the button uses the full column width
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )) : null}
                                                    </>
                                                ))}
                                                <ButtonMUI className="mt-2 mb-2" variant="text"
                                                           onClick={() => addVariantForm(index)}>Añadir
                                                    variante</ButtonMUI>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col">
                                <h5>Imágenes</h5>
                            </div>
                        </div>
                        <h6 className="mt-1 mb-0">Imágen del producto</h6>
                        <ImageUpload required figure="square" onSelectImage={changeImage} image={values.image}/>

                        <div className="pb-5 pb-md-0">
                            <Button variant="dark" type="submit" className={"mb-5"}>
                                {
                                    props.product ? "Editar producto" : "Crear producto"
                                }
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
    }
};

export default DigitalMenuProduct;