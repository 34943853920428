import React, {useEffect, useState} from 'react';
import {
    Alert,
    CircularProgress,
    FormControl, InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Snackbar,
    TextField
} from "@mui/material";
import {setEventChange} from "../redux/eventsChangeSlice";
import {Button} from "react-bootstrap";
import {selectUser} from "../redux/userSlice";
import {useSelector} from "react-redux";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import Grid from "@mui/material/Grid";
import { Button as ButtonMUI } from '@mui/material';
import {useNavigate} from "react-router";
import {money_format} from "./functions/numberFormat";
import Swal from "sweetalert2";

const DigitalMenuBalanceTransactionAdd = (props) => {

    const user = useSelector(selectUser);
    const navigate = useNavigate();

    const [values, setValues] = useState({
        date: new Date().toISOString().split('T')[0],
        concept: "",
        currency: "ARS",
        amount: 0,
        payment_type: 4,
        additionalData: {
            payer_id: "",
            payer_name: "",
        },
        file: null,
        balanceId: props.balance,
        transactionId: props.transaction,
    });

    const [currencies, setCurrencies] = useState([]);

    const [paymentTypes, setPaymentTypes] = useState([
        { id: 'cash', name: 'Efectivo' },
        { id: 'credit_card', name: 'Tarjeta de crédito' },
        { id: 'debit_card', name: 'Tarjeta de débito' },
        { id: 'bank_transfer', name: 'Transferencia bancaria' },
    ]);

    useEffect(() => {
        console.log(values);
    }, [values]);

    const [isLoaded, setIsLoaded] = useState(false);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const handleChange = (prop) => (event) => {
        const { name, value, files, type } = event.target;
        if (prop.includes('.')) {
            // Handle nested properties such as "additionalData.payer_id"
            const [parent, child] = prop.split('.');
            setValues(prevValues => ({
                ...prevValues,
                [parent]: {
                    ...prevValues[parent],
                    [child]: type === 'file' ? files[0] : value
                }
            }));
        } else {
            // Handle top-level properties such as "amount", "concept"
            setValues(prevValues => ({
                ...prevValues,
                [prop]: type === 'file' ? files[0] : value
            }));
        }
    };

    const fetchCurrencies = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-balance-currencies`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        });

        const result = await request.json();

        if (result?.status === 'success') {
            setCurrencies(result.data);
        }
    }

    const fetchTransaction = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_V2_URL}/menus/users/balances/transactions/${props.transaction}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        const response = await request.json();

        if (response.status === 'success') {
            setValues({
                ...values,
                ...response.data
            });
        }
    }



    const fetchData = async () => {

        // Retrieve client's timezone identifier
        const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Retrieve client's time difference in minutes and its direction
        const moment = require('moment-timezone');
        const gmtOffset = moment.tz(timeZone).utcOffset();


        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-balance?menu_id=${props.menu}&event_id=${props.event}&id=${props.balance}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${user.token}`,
                'credentials': 'same-origin',
                'Content-Type': 'application/json',
            }
        });

        const json = await request.json();

        if (json.status === "success") {

            if (json?.data) {

                json.data.rules.forEach((rule, index) => {
                    const ruleDate = new Date(rule.datetime);
                    ruleDate.setMinutes(ruleDate.getMinutes() + gmtOffset);
                    ruleDate.setSeconds(0); // Set seconds to 0

                    json.data.rules[index].datetime = ruleDate;
                });
            }

            await fetchPaymentMethods();
            await fetchCurrencies();
            await fetchTransaction();

            setIsLoaded(true);
        }
    }

    const fetchPaymentMethods = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/fp_payment_methods?from=menu_user_transactions`);

        const response = await request.json();

        if (response.status === "success") {
            setPaymentTypes(response.data);
        }
    }

    React.useEffect(() => {
        if (isLoaded) return;
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const paymentTypeLabel = paymentTypes.find(paymentType => paymentType.id === values.payment_type)?.label || '';
        const saldoInfo = () => {
            if (values.additionalData.payer_name) {
                return `
                Fecha: <b>${values?.date}</b><br>
                Método de pago: <b>${paymentTypeLabel}</b><br>
                Monto pagado: <b>${money_format(values.amount)}</b><br>
                Nombre: ${values?.additionalData?.payer_name}<br>
                DNI: ${values?.additionalData?.payer_id}<br>
                `
            } else {
                return `
                Fecha: <b>${values?.date}</b><br>
                Método de pago: <b>${paymentTypeLabel}</b><br>
                Monto pagado: <b>${money_format(values.amount)}</b><br>
            `;
            }
        }

        const result = await Swal.fire({
            title: 'Confirmar transacción',
            html: `¿Está seguro de que desea ${props.transaction ? 'editar' : 'cargar'} la transacción con la siguiente información?<br><br>
               Concepto: <b>${values.concept}</b><br>
               Moneda: <b>${values.currency}</b><br>
               ${saldoInfo()}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            let response;

            const formData = new FormData();
            formData.append('file', values.file);
            formData.append('data', JSON.stringify(values));

            if (props.transaction) {
                const request = await fetch(`${process.env.REACT_APP_API_V2_URL}/menus/users/balances/transactions`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'credentials': 'same-origin',
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    },
                    body: JSON.stringify({
                        ...values,
                        transaction_id: Number(props.transaction),
                        additional_data: JSON.stringify(values.additionalData),
                        timestamp: values.date,
                        payment_method: values.payment_type
                    }),
                });

                response = await request.json();

                if (response.status === "success") {
                    setSuccessMessage(response.message);
                    setOpenS(true);
                } else {
                    setErrorMessage(response.message);
                    setOpenE(true);
                }

            } else {

                const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-user-transactions`, {
                    method: 'POST',
                    headers: {
                        'credentials': 'same-origin',
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    },
                    body: formData
                });

                response = await request.json();
            }

            if (response.status === "success") {
                setSuccessMessage(response.message);
                setOpenS(true);
            } else {
                setErrorMessage(response.message);
                setOpenE(true);
            }
        }
    };

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            <div className="row mt-2 justify-content-between align-items-center">
                {!props.transaction ? (
                    <div className="col">
                        <h4>Agregar transacción</h4>
                    </div>
                ) : (
                    <div className="col">
                        <h4>Editar transacción</h4>
                    </div>
                )}
            </div>

            <div className="row mt-3">
                <div className="col-12">
                    <form onSubmit={handleSubmit}>
                        <div className="row mt-3">
                            <div className="col-lg-6">
                                <TextField label="Fecha de emisión" value={values.date} onChange={handleChange('date')}
                                           name={"date"} fullWidth variant="outlined"
                                           type={"date"}/>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-6">
                                <TextField label="Concepto" value={values.concept}
                                           onChange={handleChange('concept')} name={"concept"} fullWidth
                                           variant="outlined"/>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-6">
                                <FormControl fullWidth>
                                    <InputLabel
                                        id="demo-simple-select-label">Moneda</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={values.currency}
                                        label="Moneda"
                                        onChange={handleChange('currency')}
                                        name={'currency'}
                                    >
                                        {currencies.map((currency, key) => (
                                            <MenuItem key={key} value={currency.value}>{currency.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-6">
                                <FormControl fullWidth>
                                    <InputLabel
                                        htmlFor="outlined-adornment-amount">Monto</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-amount"
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        label="Monto"
                                        onChange={handleChange('amount')}
                                        name={'amount'}
                                        type={"number"}
                                        value={values.amount}
                                    />
                                </FormControl>
                                {values.currency === 'USD' && (
                                    <p>Se cargarán {money_format(values.amount * parseFloat(currencies.find((curr) => curr.label === 'USD')?.exchangeRate || 0))} ARS</p>
                                )}
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-6">
                                <FormControl fullWidth>
                                    <InputLabel
                                        id="demo-simple-select-label">Método de pago</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={values.payment_type}
                                        label="Método de pago"
                                        onChange={handleChange('payment_type')}
                                        name={'payment_type'}
                                        required
                                    >
                                        {paymentTypes.map((payment_type, key) => (
                                            <MenuItem key={key} value={payment_type.id}>{payment_type.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        {[4, 8].includes(values.payment_type) && (
                            <>
                                <div className="row mt-3">
                                    <div className="col-lg-6">
                                        <TextField label="DNI/CUIT" value={values?.additionalData?.payer_id}
                                                   onChange={handleChange('additionalData.payer_id')}
                                                   name={"payer_id"} fullWidth variant="outlined"/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-6">
                                        <TextField label="Nombre/Razón Social"
                                                   value={values?.additionalData?.payer_name}
                                                   onChange={handleChange('additionalData.payer_name')}
                                                   name={"payer_name"} fullWidth variant="outlined"/>
                                    </div>
                                </div>
                                {values.payment_type === 8 && (
                                    <div className="row mt-4">
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col">
                                                    <h5>Subir comprobante</h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <input
                                                        accept={"image/*, application/pdf"}
                                                        type="file"
                                                        name={"file"}
                                                        onChange={handleChange('file')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                        <div className="row mt-4 mb-5">
                            <div className="col-lg-6">
                                <Button variant="dark" type={"submit"} fullWidth>
                                    {props.transaction ? 'Editar' : 'Agregar'} transacción
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
    }
};

export default DigitalMenuBalanceTransactionAdd;