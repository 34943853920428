import React, {useEffect, useState} from 'react'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto';
import 'chartjs-adapter-date-fns';


const BarChart = (props) => {

    function number_format (number, decimals, dec_point, thousands_sep) {
        // Strip all characters but numerical ones.
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    const salesData = props.salesData;

    return (
        <div>
            <Line
                data={{
                    datasets: [{
                        //label: 'Ingresos por ventas',
                        borderColor: '#300166',
                        fill: true,
                        tension: 0.4,
                        data: salesData,
                        //add a gradient line to the background color starting at 0.5 opacity and ending at 0
                        backgroundColor: (context) => {
                            const chart = context.chart;
                            if (!chart.chartArea || !chart.chartArea.top || !chart.chartArea.bottom) {
                                return;
                            }
                            const {ctx, chartArea: {top, bottom}} = chart;
                            if (!chart.gradient) {
                                chart.gradient = ctx.createLinearGradient(0, top, 0, bottom);
                                chart.gradient.addColorStop(0, 'rgba(48, 1, 102, 0.3)');
                                chart.gradient.addColorStop(1, 'rgba(48, 1, 102, 0)');
                            }
                            return chart.gradient;
                        }
                    }]
                }}
                options={{
                    plugins: {
                        title: {
                            display: true,
                            text: 'Ingresos por ventas',
                        },
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                title: context => {
                                    const d = new Date(context[0].raw.x);
                                    d.setHours(d.getHours() + 3);
                                    const formattedDate = d.toLocaleDateString([], {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    });
                                    return formattedDate;
                                },
                                label: context => {
                                    return `$${number_format(context.raw.y, 0, ',', '.')}`;
                                }
                            }
                        },
                    },
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'day',
                            },
                            grid: {
                                display: false,
                            }
                        },
                        y: {
                            title: {
                                display: true,
                                text: 'Ventas ($)'
                            },
                            ticks: {
                                callback: function(value, index, values) {
                                    return '$' + number_format(value, 0, ',', '.');
                                }
                            },
                            grid: {
                                display: false,
                            }
                        },
                    }
                }}
            />
        </div>
    )
}

export default BarChart
