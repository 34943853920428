import React, {useState} from 'react';
import {BsThreeDotsVertical} from "react-icons/bs";
import {Link} from "react-router-dom";
import ThreeDotsMenu from "./ThreeDotsMenu";
import DialogConfirm from "./DialogConfirm";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";

const UserBaseContainer = (props) => {

    const user = useSelector(selectUser);

    const buttonOptions = [
        {
            title: "Editar",
            link: "./edit/" + props.group.id,
            icon: <BsThreeDotsVertical size="24" style={{color: "black"}} />,
            click: () => "",
            className: "",
        },
        {
            title: "Eliminar",
            link: "",
            icon: <BsThreeDotsVertical size="24" style={{color: "black"}} />,
            click: () => setOpenDelete(true),
            className: "text-danger",
        }
    ];

    const [openDelete, setOpenDelete] = useState(false);

    const deleteGroup = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/campaign-groups`, {
            method: 'DELETE',
            headers: {
                "Authorization": `Bearer ${user.token}`,
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                id: props.group.id,
            })
        });

        const response = await request.json();
        //console.log(response);
        if (response.status === "success") {
            setOpenDelete(false);
            props.setIsLoaded(false);
        }
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="row align-items-start justify-content-between p-1 mt-3" style={{backgroundColor: "whitesmoke", borderRadius: "10px", boxShadow: "0 3px 5px -2px darkgray"}}>
                        <div className="col-3 col-md-auto col-lg-5 col-xl-6" style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                            <h5 className="mb-1">{props.group.name}</h5>
                            <p>{`${props.group.emails} emails`}</p>
                        </div>
                        <div className="col-auto text-end">
                            <ThreeDotsMenu items={buttonOptions} />
                        </div>
                    </div>
                </div>
            </div>
            <DialogConfirm title={"Eliminar Grupo"} text={"¿Está seguro que desea eliminar este grupo?"} open={openDelete} handleCloseConfirm={() => setOpenDelete(false)} click={deleteGroup} />
        </div>
    );
};

export default UserBaseContainer;