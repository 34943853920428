import React, {useEffect, useState} from 'react';
import {Alert, CircularProgress, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField} from "@mui/material";
import {setEventChange} from "../redux/eventsChangeSlice";
import {Button} from "react-bootstrap";
import {selectUser} from "../redux/userSlice";
import {useSelector} from "react-redux";

const VenueInfo = (props) => {

    const user = useSelector(selectUser);

    const [provinceList, setProvinceList] = React.useState([]);
    const [cityList, setCityList] = React.useState([]);
    const [values, setValues] = React.useState({
        name: '',
        description: '',
        country_id: 1,
        province_id: '',
        department_id: '',
        address: '',
        capacity: null,
    });

    const [isLoaded, setIsLoaded] = useState(false);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const fetchData = async () => {
        if (props.venue) {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/venues?id=${props.venue}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                    'Content-Type': 'application/json',
                }
            });

            const json = await request.json();

            if (json.status === "success") {
                setValues({...values, ...json.data});
                setIsLoaded(true);
            }
        } else {
            setIsLoaded(true);
        }
    }

    const fetchLocationParam = async () => {

        //Fetch province list
        const response = await fetch(`${process.env.REACT_APP_API_URL}/provinces?country=1`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();
        setProvinceList(json);

        //Fetch city list
        const responseCity = await fetch(`${process.env.REACT_APP_API_URL}/departments`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const jsonCity = await responseCity.json();
        setCityList(jsonCity);

        setIsLoaded(true);
    }

    React.useEffect(() => {
        if (isLoaded) return;
        const requests = setTimeout(() => {
            fetchLocationParam();
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        let response;

        if (props.venue) {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/venues`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'credentials': 'same-origin',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify({
                    ...values,
                    venue_id: props.venue,
                }),
            });

            response = await request.json();
        } else {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/venues`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'credentials': 'same-origin',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify(values),
            });

            response = await request.json();
        }

        if (response.status === "success") {
            setSuccessMessage(response.message);
            setOpenS(true);
        } else {
            setErrorMessage(response.message);
            setOpenE(true);
        }
    }

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            <div className="row mt-2">
                <div className="col-12">
                    <h4>{
                        props.venue ? "Editar establecimiento" : "Crear establecimiento"
                    }</h4>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <h5>Información</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <TextField label="Nombre del establecimiento" value={values.name} onChange={handleChange('name')} className="mb-3" required fullWidth variant="outlined" />
                                <TextField label="Descripción" value={values.description} rows={4} multiline onChange={handleChange('description')} className="mb-3" fullWidth variant="outlined" />
                                <TextField label="Capacidad" value={values.capacity} onChange={handleChange('capacity')} className="mb-3" fullWidth type={"number"} variant="outlined" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h5>Ubicación</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col">
                                        <FormControl fullWidth className="mb-3">
                                            <InputLabel id="demo-simple-select-label">País</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value="1"
                                                label="País"
                                                disabled
                                            >
                                                <MenuItem value="1">Argentina</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <FormControl fullWidth className="mb-3">
                                            <InputLabel id="demo-simple-select-label">Provincia</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={values.province_id}
                                                label="Provincia"
                                                required
                                                onChange= {(event) => {
                                                    setValues({ ...values, province_id: event.target.value, department_id: '' });
                                                }}
                                            >
                                                {provinceList.map((province, key) => (
                                                    <MenuItem key={key} value={province.province_id}>{province.province_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <FormControl fullWidth className="mb-3">
                                            <InputLabel id="demo-simple-select-label">Localidad</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={values.department_id}
                                                label="Localidad"
                                                onChange= {handleChange('department_id')}
                                                required
                                            >
                                                {cityList.filter(city => city.province_id === values.province_id).map((city, key) => (
                                                    <MenuItem key={key} value={city.department_id}>{city.department_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <TextField label="Dirección" value={values.address} onChange={handleChange('address')} className="mb-3" fullWidth variant="outlined" required />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button variant="dark" type="submit">
                            {
                                props.venue ? "Editar establecimiento" : "Crear establecimiento"
                            }
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
    }
};

export default VenueInfo;