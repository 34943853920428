import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select, Snackbar,
    TextField
} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ImageInput from "./ImageInput";
import {ImageUpload} from "./ImageUpload";
import Checkbox from "@mui/material/Checkbox";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {selectEventChange, setEventChange} from "../redux/eventsChangeSlice";


const EventInfo = (props) => {

    const user = useSelector(selectUser);
    const eventChange = useSelector(selectEventChange);
    const dispatch = useDispatch();

    const [types, setTypes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);

    const [values, setValues] = useState({
        event_name: '',
        event_limitage: '',
        event_description: '',
        event_country: '',
        event_province: '',
        event_department: '',
        event_zone: '',
        type: '',
        category: '',
        subcategory: '',
        event_date: null,
        event_date_end: null,
        portada: null,
    });

    const [errors, setErrors] = useState({
        event_name: false,
        event_limitage: false,
        event_description: false,
        event_country: false,
        event_province: false,
        event_department: false,
        event_zone: false,
        type: false,
        category: false,
        subcategory: false,
        event_date: false,
        event_date_end: false,
        portada: false,
    });

    const [errorMessages, setErrorMessages] = useState({
        event_name: '',
        event_limitage: '',
        event_description: '',
        event_country: '',
        event_province: '',
        event_department: '',
        event_zone: '',
        type: '',
        category: '',
        subcategory: '',
        event_date: '',
        event_date_end: '',
        portada: '',
    });

    const inputValidation = (e, prop) => {
        let value = e.target.value;
        let error = false;
        let errorMessage = '';

        switch (prop) {
            case 'event_name':
                if (value.length < 3) {
                    error = true;
                    errorMessage = 'El nombre debe tener al menos 3 caracteres';
                }
                break;
            case 'portada':
                if (value === null) {
                    error = true;
                    errorMessage = 'Debe seleccionar una imagen';
                }
                break;
            case 'event_date_end':
                if (value < values.event_date) {
                    error = true;
                    errorMessage = 'La fecha de finalización debe ser mayor a la de inicio';
                }
                break;
        }
        if (!value) {
            error = false;
            errorMessage = '';
        }
        setErrors({...errors, [prop]: error});
        setErrorMessages({...errorMessages, [prop]: errorMessage});
    }

    const handleLimitageCheck = (e) => {
        setValues({...values, event_limitage: !e.target.checked ? 18 : ''});
        document.getElementById("limitage").disabled = e.target.checked;
    }

    const fetchData = async () => {

        // Retrieve client's timezone identifier
        const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Retrieve client's time difference in minutes and its direction
        const moment = require('moment-timezone');
        const gmtOffset = moment.tz(timeZone).utcOffset();


        const response = await fetch(`${process.env.REACT_APP_API_URL}/event-details?event_id=${props.event}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();

        //console.log(json);

        let event_date = new Date();
        let event_date_end = new Date();
        if (json?.event_date) {
            event_date = new Date(json.event_date);
            event_date.setMinutes(event_date.getMinutes() + gmtOffset);
        }

        if (json?.event_date_end) {
            event_date_end = new Date(json.event_date_end);
            event_date_end.setMinutes(event_date_end.getMinutes() + gmtOffset);
        }


        setValues({
            ...values,
            ...json,
            event_date: event_date,
            event_date_end: event_date_end,
        });


        const requestTypes = await fetch(`${process.env.REACT_APP_API_URL}/event-types`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            }
        });
        const types = await requestTypes.json();
        if (types.status === "success") {
            setTypes(types.types);
            setCategories(types.categories);
            setSubcategories(types.subcategories);
        }
        setIsLoaded(true);
    }

    const [isLoaded, setIsLoaded] = useState(false);

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        inputValidation(event, prop);
        dispatch(setEventChange(true));
    };

    const changeImage = (image) => {
        setValues({...values, portada: image});
    }

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const checkErrors = Object.values(errors).some((error) => error === true);
        //console.log(checkErrors)

        if (checkErrors) {
            //console.log(errors, errorMessages);
        }

        values.portada === null ? setErrors({...errors, portada: true}) : setErrors({...errors, portada: false});

        if (!checkErrors && values.portada !== null) {
            if (props.event) {
                const formData = new FormData();
                formData.append('event_name', values.event_name);
                formData.append('event_limitage', values.event_limitage);
                formData.append('event_description', values.event_description);
                formData.append('type', values.type);
                formData.append('category', values.category);
                formData.append('subcategory', values.subcategory);
                formData.append('event_date', Date.parse(values.event_date));
                formData.append('event_date_end', Date.parse(values.event_date_end));
                formData.append('portada', values.portada);
                formData.append('event_id', props.event);

                const request = await fetch(`${process.env.REACT_APP_API_URL}/edit-event`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${user.token}`,
                        'credentials': 'same-origin',
                    },
                    body: formData
                });
                const json = await request.json();
                //console.log(json);

                if (json.status === "success") {
                    dispatch(setEventChange(false));
                    setSuccessMessage(json.message);
                    setOpenS(true);
                    setIsLoaded(false);
                }
                else {
                    setErrorMessage(json.message);
                    setOpenE(true);
                }
            }
        }
    }

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                    <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <div className="row justify-content-center">
                <div className="col">
                    <h1>Información Básica</h1>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <TextField label="Nombre del evento" value={values.event_name} onChange={handleChange('event_name')} className="mb-3" fullWidth variant="outlined" required error={errors.event_name} helperText={errorMessages.event_name} />
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-7">
                                <TextField label="Edad mínima" value={values.event_limitage} type="number" onWheel={(e) => e.preventDefault()}  onChange={handleChange('event_limitage')} className="mb-3" fullWidth variant="outlined" id="limitage" />
                            </div>
                            <div className="col-5 mt-1">
                                <FormControlLabel control={<Checkbox checked={!(values.event_limitage !== 0 && values.event_limitage !== '')} onChange={handleLimitageCheck} />} label="Sin mínimo" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col">
                        <TextField label="Descripción del evento" value={values.event_description} rows={4} multiline onChange={handleChange('event_description')} className="mb-3" fullWidth variant="outlined" />
                    </div>
                </div>
                {/*
                <div className="row justify-content-center">
                    <div className="col-md-4">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.type}
                                label="Tipo"
                                onChange={handleChange('type')}
                                required
                            >
                                {types.map((type) => (
                                    <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-4 mt-3 mt-md-0">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.category}
                                label="Categoria"
                                onChange={handleChange('category')}
                            >
                                {categories.filter((category) => category.type_id == values.type).map((category) => (
                                    <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-4 mt-3 mt-md-0">
                        <FormControl fullWidth>
                            <InputLabel  id="demo-simple-select-label">Categoria secundaria</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.subcategory}
                                label="Categoria secundaria"
                                onChange={handleChange('subcategory')}
                            >
                                {subcategories.filter((subcategory) => subcategory.category_id == values.category).map((subcategory) => (
                                    <MenuItem key={subcategory.id} value={subcategory.id}>{subcategory.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                */}
                <div className="row justify-content-center mt-3">
                    <div className="col">
                        <h3>Fecha y Hora</h3>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                renderInput={(props) => <TextField required fullWidth {...props} />}
                                label="El evento comienza el"
                                value={values.event_date}
                                inputFormat={'dd/MM/yyyy HH:mm'}
                                disablePast
                                ampm={false}
                                onChange={(newValue) => {
                                    setValues({...values, event_date: newValue});
                                }}
                                onError={() => setErrors({...errors, event_date: true})}
                                onAccept={() => setErrors({...errors, event_date: false})}
                                desktopModeMediaQuery={'(max-width: 0px)'}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                renderInput={(props) => <TextField required fullWidth {...props} />}
                                label="El evento termina el"
                                value={values.event_date_end}
                                inputFormat={'dd/MM/yyyy HH:mm'}
                                minDateTime={values.event_date}
                                defaultCalendarMonth={values.event_date}
                                ampm={false}
                                onChange={(newValue) => {
                                    setValues({...values, event_date_end: newValue});
                                }}
                                onError={() => setErrors({...errors, event_date_end: true})}
                                onAccept={() => setErrors({...errors, event_date_end: false})}
                                desktopModeMediaQuery={'(max-width: 0px)'}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col">
                        <h3>Imágenes</h3>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col">
                        <h5 className="mt-2 mb-0">Imágen principal del evento</h5>
                        <small>Esta es la primera imagen que los participantes van a ver en la página del evento.</small>
                        <ImageUpload required figure="square" onSelectImage={changeImage} image={values.portada} />
                        {errors.portada && <span className="text-danger">La imagen es requerida</span>}
                    </div>
                </div>
                <div className="row justify-content-center mt-3 mb-3">
                    <div className="col">
                        <div className="row">
                            <div className="col">
                                <Button variant="contained" color="primary" className="me-2" type="submit">
                                    Guardar
                                </Button>
                                <Button variant="contained" color="secondary">
                                    Cancelar
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
    }
};

export default EventInfo;
