import React, {useState} from 'react';
import {Button, Table} from "react-bootstrap";
import {TextField} from "@mui/material";
import {useNavigate} from "react-router";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {number_format} from "../components/functions/numberFormat";

const MenuSupervisorsBalancesId = (props) => {

    const token = localStorage.getItem('token');

    const [order, setOrder] = useState(null);
    const navigate = useNavigate();

    const fetchData = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-balances-supervisor?id=${props.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        const response = await request.json();


        setOrder(response.data);
    }

    const refundOrder = () => {
        const id = order?.id;

        const mySwal = withReactContent(Swal);
        mySwal.fire({
            title: '¿Estás seguro?',
            text: "Se le devolveran los fondos al comprador.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Reembolsar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                refundRequest(id).then((response) => {
                    if (response.status === "success") {
                        mySwal.fire(
                            '¡Reembolsado!',
                            'El reembolso se ha realizado con éxito.',
                            'success'
                        ).then(() => {
                            fetchData();
                        })
                    }
                    else {
                        mySwal.fire(
                            '¡Error!',
                            'Ha ocurrido un error.',
                            'error'
                        )
                    }
                })
            }
        })
    }

    const refundRequest = async (id) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-balances-supervisor`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                id: id
            }),
        });

        const response = await request.json();

        if (response.status === 'success') {
            fetchData();
        }
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [token]);

    return (
        <div className="container" style={{backgroundColor: "white", minHeight: "100vh"}}>
            <div className="row mt-3">
                <div className="col-12">
                    <h3>{`Transaccion ${order?.id}`}</h3>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            <h5>Información de la orden</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="order-detail">
                                <div className="order-detail-title">
                                    <p>Información del cliente:</p>
                                </div>
                                <div className="order-detail-body">
                                    <div className="order-detail-body-item">
                                        <p className="order-detail-body-item-p mb-0">Email: {order?.email}</p>
                                    </div>
                                    <div className="order-detail-body-item">
                                        <p className="order-detail-body-item-p mb-0">Mesa: {order?.table_name}</p>
                                    </div>
                                    <div className="order-detail-body-item">
                                        <p className="order-detail-body-item-p mb-0">Estado: {order?.status}</p>
                                    </div>
                                    <div className="order-detail-body-item">
                                        <p className="order-detail-body-item-p mb-0">Fecha: {order?.timestamp}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="order-detail">
                                <div className="order-detail-title">
                                    <p>Carga de saldo:</p>
                                </div>
                                <div className="order-detail-body">
                                    <div className="order-detail-body-item">
                                        <p className="order-detail-body-item-p mb-0">Tipo de transaccion: {order?.transaction_type}</p>
                                    </div>
                                    <div className="order-detail-body-item">
                                        <p className="order-detail-body-item-p mb-0">Metodo de pago: {order?.payment_method}</p>
                                    </div>
                                    <div className="order-detail-body-item">
                                        <p className="order-detail-body-item-p mb-0">Monto: $ {number_format(order?.amount, 2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {order?.cancelled === 0 ? (
                    <div className="row mt-3">
                        <div className="col-12">
                            <Button onClick={refundOrder} variant={"danger"} className="w-100">
                                Anular carga
                            </Button>
                        </div>
                    </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default MenuSupervisorsBalancesId;