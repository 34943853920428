import React, {useEffect, useState} from 'react';
import NavB from "./Navbar";
import {Breadcrumb, Button, Card, Image, Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import {addItem, removeItem, substractItem, selectCarro, replaceItems} from "../redux/carroSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, Navigate, useHref, useLocation} from "react-router";
import {login, selectUser} from "../redux/userSlice";
import CarroItems from "./CarroItems";
import {BsTrash} from "react-icons/bs";
import {motion} from "framer-motion";
import MenuHeader from "./MenuHeader";
import {money_format, number_format} from "./functions/numberFormat";
import {
    Alert,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl, InputAdornment, InputLabel,
    Modal, OutlinedInput,
    TextField
} from "@mui/material";
import {IoClose} from "react-icons/io5";
import { GoogleLogin } from 'react-google-login';
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import '../styles/GoogleIniciarSesion.css';
import { styled } from '@mui/system';
import {Skeleton} from "@mui/lab";
import {selectMenu, usertTypeSelected} from "../redux/menuSlice";
import MenuAuthentication from "./MenuAuthentication";


const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: '10px',
    },
}));


const Carro = (props) => {

    const items = useSelector(selectCarro);
    const user = useSelector(selectUser);
    const location = useLocation();
    const navigation = useNavigate();
    const menu = useSelector(selectMenu);
    const userType = useSelector(usertTypeSelected);

    const [isLoaded, setIsLoaded] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [directToPayment, setDirectToPayment] = useState(false);
    const [userBalance, setUserBalance] = useState(0);
    const [ordersInProgress, setOrdersInProgress] = useState([]);

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    }
    const handleCloseConfirm = () => setOpenConfirm(false);

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    // Helper function to get a unique key for each item based on its ID and variants
    const getItemKey = (item) => {
        const itemId = item.id;
        const itemVariants = item.variants ? JSON.stringify(item.variants) : "noVariants";
        return `${itemId}-${itemVariants}`;
    };


    useEffect(() => {
        if (items.items.length > 0) {
            window.localStorage.removeItem("orderDetails");
        } else {
            if (window.localStorage.getItem("orderDetails")) {
                if (props.menu !== JSON.parse(window.localStorage.getItem("orderDetails"))?.menu_id) {
                    window.localStorage.removeItem("orderDetails");
                }
                else if (props.menu === JSON.parse(window.localStorage.getItem("orderDetails"))?.menu_id && items.items.length === 0) {
                    dispatch(replaceItems({
                        items: JSON.parse(window.localStorage.getItem("orderDetails"))?.items,
                    }));
                    setDirectToPayment(true);
                }
            }
        }
    }, []);

    const mpLink = async () => {
        setButtonDisabled(true);
        window.localStorage.removeItem("orderDetails");
        const request = await fetch(`${process.env.REACT_APP_API_URL}/payment-mp-products`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`,
                "credentials": "same-origin"
                },
                body: JSON.stringify({
                        "items": items.items,
                        "menu_id": props.menu,
                        "event_id": props.event,
                        "observations": values.observations,
                    })
            })

        const response = await request.json();

        setButtonDisabled(false);
        return response;
    }

    const [itemsList, setItemsList] = React.useState([]);

    const fetchData = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-items?menu_id=${props.menu}&event_id=${props.event}`);
        const response = await request.json();
        setItemsList(response?.products);
        setOrdersInProgress(response?.orders_in_progress);
    }

    const fetchUserBalance = async () => {
        try {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/user-menu-balance?menu=${props.menu}&event=${props.event}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${user.token}`,
                    "credentials": "same-origin"
                }
            });

            const response = await request.json();

            if (response.status === "success") {
                setUserBalance(response.balance);
            }
        } catch (error) {

        }
        setIsLoaded(true);
    }

    useEffect(() => {
        setIsLoaded(false);
        const requests = setTimeout(() => {
            fetchData();
            fetchUserBalance();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //login data

    const [values, setValues] = useState({
        email: '',
        password: '',
        showPassword: false,
        observations: '',
    });

    const [errors, setErrors] = useState({
        email: false,
        password: false,
    });

    const [errorMessages, setErrorMessages] = useState({
        email: '',
        password: '',
    });

    const [loginError, setLoginError] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const handleValues = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        inputValidation(event, prop);
    };

    const inputValidation = (e, prop) => {
        let value = e.target.value;
        let error = false;
        let errorMessage = '';

        switch (prop) {
            case 'email':
                if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                    error = true;
                    errorMessage = 'El email no es válido';
                }
                break;
        }
        if (!value) {
            error = false;
            errorMessage = '';
        }

        setErrors({...errors, [prop]: error});
        setErrorMessages({...errorMessages, [prop]: errorMessage});
    }

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const submitOrder = async () => {
        window.localStorage.setItem("orderDetails", JSON.stringify({
            "items": items.items,
            "menu_id": props.menu,
        }));

        if (user.token) {
            const orderResponse = await mpLink();
            if (userType === "waiter" && items.items.reduce((total, item) => {return total + item.price}, 0) + (userBalance > items.items.reduce((total, item) => {return total + item.price}, 0) ? -1* items.items.reduce((total, item) => {return total + item.price}, 0) : -1*userBalance) > 0) {
                submitPaymentIntent(orderResponse.order_id);
            } else {
                if (orderResponse.status === "success") {
                    window.location.href = orderResponse.url;
                }
            }
        } else {
            handleOpenConfirm();
        }
    }

    const submitPaymentIntent = async (orderId) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-payment-intent`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`,
                "credentials": "same-origin"
            },
            body: JSON.stringify({
                "total": items.items.reduce((total, item) => {return total + item.price}, 0) + (userBalance > items.items.reduce((total, item) => {return total + item.price}, 0) ? -1* items.items.reduce((total, item) => {return total + item.price}, 0) : -1*userBalance),
                "menu_id": props.menu,
                "event_id": props.event,
                "order_id": orderId
            })
        });

        const response = await request.json();
        if (response.status === "success") {
            window.location.href = "https://app.flashpass.com.ar/payment-intent/success?transactionId=" + response.payment_intent_id;
        }
    }

    const login_api = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            var details = JSON.stringify({
                "username": values.email,
                "password": values.password,
                "login_user": true
            });

            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/authentication`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'credentials': 'same-origin',
                    },
                    body: details,
                }
            );

            if (response.ok) {
                const auth = await response.json();

                setLoginError(false);

                dispatch(login({
                    username: "",
                    password: "",
                    token: auth.auth
                }));

                window.localStorage.setItem('token', auth.auth);

                handleCloseConfirm();
                //setDirectToPayment(true);
            }
            else {
                setLoginError(true);
                setValues({...values, password: ''});
            }
            setLoading(false);

        } catch (error) {
        }
    };

    useEffect(() => {
        if (directToPayment) {
            const response = mpLink();
            if (response.status === "success") {
                window.location.href = response.url;
            }
        }
    }, [directToPayment, user.token]);

    return (
        <div className="menu-container" id="menu-container">
            <MenuHeader id={props.menu} event={props.event} />
            <motion.div
                initial={{
                    x: location.state?.direction === "left" ? "-100vw" : "100vw",
                }}
                animate={{ x: 0 }}
                exit={{ x: "100vw" }}
                transition={{ duration: 0.2 }}
            >
            <Container className={"mb-4"}>
                <div className="row align-items-center p-2 mb-2 p-0" style={{backgroundColor: "white", boxShadow: "0 3px 5px -2px darkgray"}}>
                    <div className="col-3">
                        {isLoaded ? (
                        <img src={menu?.image} alt={"venue"} className="img-fluid" height="60px" width="60px" style={{borderRadius: "5px", border: "1px solid lightgrey"}} />
                        ) : (
                        <Skeleton variant={"rectangular"} style={{borderRadius: "5px"}} height={60} width={60} />
                        )}
                    </div>
                    <div className="col-9">
                        {isLoaded ? (
                            <span style={{fontSize: "24px", color: "black"}}>{menu?.name}</span>
                        ) : (
                            <Skeleton variant={"text"} style={{borderRadius: "5px"}} height={50} width={200} />
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {isLoaded ? (
                            <p className="font-weight-bold" style={{fontSize: "20px", color: "black"}}>Estás llevando:</p>
                        ) : (
                            <Skeleton variant={"text"} style={{borderRadius: "5px", marginBottom: "10px"}} height={40} width={200} />
                        )}
                    </div>
                </div>
                {items.items.length > 0 ? (
                    <>
                        {items.items.length > 0 && isLoaded ?
                            [...new Set(items.items.map(getItemKey))].map((uniqueKey, index) => {
                                const itemDetails = uniqueKey.split('-');
                                const itemId = itemDetails[0];
                                const itemVariants = itemDetails[1] !== "noVariants" ? JSON.parse(itemDetails[1]) : null;

                                //console.log(uniqueKey);

                                return (
                                    <CarroItems
                                        key={index}
                                        item={itemsList.find(i => i.id == itemId)}
                                        ammount={items.items.filter(i => getItemKey(i) === uniqueKey).length}
                                        // Add variants if needed in CarroItems component
                                        variants={itemVariants}
                                        ordersInProgress={ordersInProgress}
                                    />
                                );
                            }) :
                            Array.from(Array(2).keys()).map((item, index) => (
                                <CarroItems
                                    key={index}
                                    ammount={0}
                                />
                            ))
                        }
                        {items.items.length > 0 && isLoaded ? (
                            <>
                                <div className="row mt-0 mb-2 mt-4 m-1 p-3 pe-2 ps-2" style={{backgroundColor: "white", borderRadius: "8px", boxShadow: "0 3px 5px -2px darkgray"}}>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col">
                                                <span style={{fontSize: "20px", color: "black", margin: 0}}>Productos</span>
                                            </div>
                                            <div className="col-auto text-end">
                                                <span style={{fontSize: "20px", color: "black"}}>{money_format(items.items.reduce((total, item) => {return total + item.price}, 0), 2, ',', '.')}</span>
                                            </div>
                                        </div>
                                        {// Saldo disponible
                                            userBalance > 0 ?
                                                <>
                                                    <div className="row mt-3" style={{lineHeight: 0.7}}>
                                                        <div className="col">
                                                            <p style={{fontSize: "18px", color: "black", margin: 0}}>Saldo a utilizar</p>
                                                        </div>
                                                        <div className="col-auto text-end">
                                                            <p style={{fontSize: "18px", color: "black", margin: 0}}>{money_format(userBalance > items.items.reduce((total, item) => {return total + item.price}, 0) ? -1* items.items.reduce((total, item) => {return total + item.price}, 0) : -1*userBalance, 2, ',', '.')}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-0">
                                                        <div className="col-12">
                                                            <small style={{fontSize: "14px"}}>
                                                                {userBalance > items.items.reduce((total, item) => {return total + item.price}, 0) ?
                                                                    `Te quedan ${money_format(userBalance - items.items.reduce((total, item) => {return total + item.price}, 0),2)} hasta agotar tu saldo.` :
                                                                    `Con esta compra agotas tu saldo.`
                                                                }
                                                            </small>
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }
                                        <hr className="mt-2 mb-2" />
                                        <div className="row">
                                            <div className="col">
                                                <b><span style={{fontSize: "20px", color: "black", margin: 0}}>Total a pagar</span></b>
                                            </div>
                                            <div className="col-auto text-end">
                                                <b><span style={{fontSize: "20px", color: "black"}}>{money_format(items.items.reduce((total, item) => {return total + item.price}, 0) + (userBalance > items.items.reduce((total, item) => {return total + item.price}, 0) ? -1* items.items.reduce((total, item) => {return total + item.price}, 0) : -1*userBalance), 2, ',', '.')}</span></b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <TextField multiline={true} fullWidth={true} label={"Observaciones"} rows={3} variant="outlined" value={values.observations} onChange={(e) => setValues({...values, observations: e.target.value})} />
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-3">
                                    <div className="col-12">
                                        {
                                            !userType && items.items.reduce((total, item) => {return total + item.price}, 0) + (userBalance > items.items.reduce((total, item) => {return total + item.price}, 0) ? -1* items.items.reduce((total, item) => {return total + item.price}, 0) : -1*userBalance) > 0 ?
                                                <Button disabled={items.items.length < 1 || buttonDisabled} className="w-100 p-3" onClick={() => submitOrder()} style={{borderRadius: "0.5rem", backgroundColor: menu?.header_color}}>Pagar</Button>
                                            : userType === "waiter" && items.items.reduce((total, item) => {return total + item.price}, 0) + (userBalance > items.items.reduce((total, item) => {return total + item.price}, 0) ? -1* items.items.reduce((total, item) => {return total + item.price}, 0) : -1*userBalance) > 0 ?
                                                <Button disabled={items.items.length < 1 || buttonDisabled} className="w-100 p-3" onClick={() => submitOrder()} style={{borderRadius: "0.5rem", backgroundColor: menu?.header_color}}>Ir a caja</Button>
                                            :
                                                <Button disabled={items.items.length < 1 || buttonDisabled} className="w-100 p-3" onClick={() => submitOrder()} style={{borderRadius: "0.5rem", backgroundColor: menu?.header_color}}>Confirmar Orden</Button>
                                        }
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="row mt-0 mb-2 mt-4 m-1 p-3 pe-2 ps-2" style={{backgroundColor: "white", borderRadius: "8px", boxShadow: "0 3px 5px -2px darkgray"}}>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-8">
                                                <Skeleton variant={"text"} style={{borderRadius: "5px"}} height={20} width={200} />
                                            </div>
                                            <div className="col-4 text-end">
                                                <Skeleton variant={"text"} style={{borderRadius: "5px"}} height={20} width={100} />
                                            </div>
                                        </div>
                                        <>
                                            <div className="row mt-3" style={{lineHeight: 0.7}}>
                                                <div className="col-8">
                                                    <Skeleton variant={"text"} style={{borderRadius: "5px"}} height={20} width={200} />
                                                </div>
                                                <div className="col-4 text-end">
                                                    <Skeleton variant={"text"} style={{borderRadius: "5px"}} height={20} width={100} />
                                                </div>
                                            </div>
                                            <div className="row mt-0">
                                                <div className="col-12">
                                                    <Skeleton variant={"text"} style={{borderRadius: "5px"}} height={12} width={200} />
                                                </div>
                                            </div>
                                        </>
                                        <hr className="mt-2 mb-2" />
                                        <div className="row">
                                            <div className="col-8">
                                                <b><Skeleton variant={"text"} style={{borderRadius: "5px"}} height={20} width={200} /></b>
                                            </div>
                                            <div className="col-4 text-end">
                                                <b><Skeleton variant={"text"} style={{borderRadius: "5px"}} height={20} width={100} /></b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-3">
                                    <div className="col-12">
                                        <Skeleton variant={"rectangular"} style={{borderRadius: "5px"}} height={50} width={"100%"} />
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <div className="row">
                        <div className="col-12">
                            <p style={{fontSize: "20px", color: "black"}}>No hay productos en el carro</p>
                        </div>
                    </div>
                )}
            </Container>
            <MenuAuthentication openConfirm={openConfirm} handleCloseConfirm={handleCloseConfirm} id={props.menu} event={props.event} />
            </motion.div>
        </div>
    );
};

export default Carro;