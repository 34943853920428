import React, {useEffect, useState} from 'react';
import {
    Alert,
    CircularProgress,
    FormControl,
    FormControlLabel, FormHelperText, InputAdornment,
    InputLabel,
    MenuItem, OutlinedInput,
    Select, Snackbar,
    TextField
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {selectEventChange, setEventChange} from "../redux/eventsChangeSlice";
import {ImageUpload} from "./ImageUpload";
import {Button} from 'react-bootstrap';


const unitMeasures = [
    {
        value: 'ml',
        label: 'Mililitros',
    },
    {
        value: 'L',
        label: 'Litros',
    },
    {
        value: 'g',
        label: 'Gramos',
    },
    {
        value: 'kg',
        label: 'Kilogramos',
    },
];


const CreateMenuComponent = (props) => {

    const user = useSelector(selectUser);
    const eventChange = useSelector(selectEventChange);
    const dispatch = useDispatch();

    const [types, setTypes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);

    const [values, setValues] = useState({
        name: '',
        description: '',
        quantity_per_unit: '',
        unit_measure: 'ml',
        external_id: '',
        image: '',
    });

    const [errors, setErrors] = useState({
        name: false,
        description: false,
        quantity_per_unit: false,
        unit_measure: false,
        image: false,
    });

    const [errorMessages, setErrorMessages] = useState({
        name: '',
        description: '',
        quantity_per_unit: '',
        unit_measure: '',
        image: '',
    });

    const inputValidation = (e, prop) => {
        let value = e.target.value;
        let error = false;
        let errorMessage = '';

        switch (prop) {
            case 'name':
                if (value.length < 3) {
                    error = true;
                    errorMessage = 'El nombre debe tener al menos 3 caracteres';
                }
                break;
            case 'unit_measure':
                if (value.length < 1) {
                    error = true;
                    errorMessage = 'Debe seleccionar una unidad de medida';
                }
                break;
            case 'quantity_per_unit':
                if (value.length < 1) {
                    error = true;
                    errorMessage = 'Debe ingresar una cantidad por unidad';
                }
        }
        if (!value) {
            error = false;
            errorMessage = '';
        }
        setErrors({...errors, [prop]: error});
        setErrorMessages({...errorMessages, [prop]: errorMessage});
    }

    const handleLimitageCheck = (e) => {
        setValues({...values, event_limitage: !e.target.checked ? 18 : ''});
        document.getElementById("limitage").disabled = e.target.checked;
    }

    const fetchData = async () => {
        if (props.component) {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/component?component_id=${props.component}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                    'Content-Type': 'application/json',
                }
            });

            const json = await request.json();

            if (json.status === "success") {
                setValues({
                    name: json.component.name,
                    description: json.component.description,
                    quantity_per_unit: json.component.quantity_per_unit,
                    unit_measure: json.component.unit_measure,
                    image: json.component.image,
                    external_id: json.component.external_id,
                });
                setIsLoaded(true);
            }
        } else {
            setIsLoaded(true);
        }
    }

    const [isLoaded, setIsLoaded] = useState(false);

    React.useEffect(() => {
        if (isLoaded) return;
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        inputValidation(event, prop);
        dispatch(setEventChange(true));
    };

    const changeImage = (image) => {
        setValues({...values, image: image});
    }

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        //console.log(values, errorMessages, errors);

        const checkErrors = Object.values(errors).some((error) => error === true);

        if (!checkErrors) {
            if (props.component) {
                const formData = new FormData();
                formData.append('name', values.name);
                formData.append('description', values.description);
                formData.append('quantity_per_unit', values.quantity_per_unit);
                formData.append('unit_measure', values.unit_measure);
                formData.append('image', values.image);
                formData.append('component_id', props.component);
                formData.append('external_id', values.external_id);

                const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-component-edit`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${user.token}`,
                        'credentials': 'same-origin',
                    },
                    body: formData
                });
                const json = await request.json();
                //console.log(json);

                if (json.status === "success") {
                    dispatch(setEventChange(false));
                    setSuccessMessage(json.message);
                    setOpenS(true);
                    setIsLoaded(false);
                }
                else {
                    setErrorMessage(json.message);
                    setOpenE(true);
                }
            } else {
                const formData = new FormData();
                formData.append('name', values.name);
                formData.append('description', values.description);
                formData.append('quantity_per_unit', values.quantity_per_unit);
                formData.append('unit_measure', values.unit_measure);
                formData.append('image', values.image);
                formData.append('external_id', values.external_id);

                const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-component`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${user.token}`,
                        'credentials': 'same-origin',
                    },
                    body: formData
                });
                const json = await request.json();
                //console.log(json);

                if (json.status === "success") {
                    dispatch(setEventChange(false));
                    setSuccessMessage(json.message);
                    setOpenS(true);
                }
                else {
                    setErrorMessage(json.message);
                    setOpenE(true);
                }
            }
        }
    }

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                    <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <div className="row justify-content-center">
                <div className="col">
                    <h1>{
                        props.component ? "Editar componente" : "Crear componente"
                    }</h1>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-6">
                        <TextField label="Nombre del componente" value={values.name} onChange={handleChange('name')}
                                   className="mb-3" fullWidth variant="outlined" required error={errors.name}
                                   helperText={errorMessages.name}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <TextField label="Descripción del componente" value={values.description} rows={4} multiline
                                   onChange={handleChange('description')} className="mb-3" fullWidth
                                   variant="outlined"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 mt-3 mt-lg-0">
                        <FormControl variant="outlined" fullWidth required>
                            <InputLabel htmlFor="outlined-adornment-weight">Cantidad por unidad</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                value={values.quantity_per_unit}
                                error={errors.quantity_per_unit}
                                label={"Cantidad por unidad"}
                                required
                                helperText={errorMessages.quantity_per_unit}
                                onChange={handleChange('quantity_per_unit')}
                                endAdornment={<InputAdornment position="end">{values.unit_measure}</InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                    'aria-label': 'Cantidad por unidad',
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="col-lg-3 mt-3 mt-lg-0">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Unidad de medida</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.unit_measure}
                                label="Unidad de medida"
                                onChange={handleChange('unit_measure')}
                            >
                                {unitMeasures.map((unitMeasure) => (
                                    <MenuItem key={unitMeasure.value}
                                              value={unitMeasure.value}>{unitMeasure.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <TextField label="ID Externo Componente" value={values.external_id} onChange={handleChange('external_id')}
                                   className="mt-3" fullWidth variant="outlined" />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <h3>Imágenes</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <h5 className="mt-2 mb-0">Imágen del componente</h5>
                        <ImageUpload required figure="square" onSelectImage={changeImage} image={values.image}/>
                    </div>
                </div>
                <div className="row justify-content-center mt-3 mb-3">
                    <div className="col">
                        <div className="row">
                            <div className="col">
                                <Button variant={"dark"} className="me-2" type="submit">
                                    {
                                        props.component ? "Editar componente" : "Crear componente"
                                    }
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
    }
};

export default CreateMenuComponent;
