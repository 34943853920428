import React, {useState} from 'react';
import {Link} from "react-router-dom";
import CampaignContainer from "../components/CampaignContainer";
import {Alert, AlertTitle, Button, CircularProgress} from "@mui/material";
import CreateCampaign from "../components/CreateCampaign";
import {useNavigate} from "react-router";
import UserBaseContainer from "../components/UserBaseContainer";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";

const UserBase = () => {

    const user = useSelector(selectUser)
    const [groups, setGroups] = useState([]);

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/campaign-groups`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });

        const json = await response.json();

        if (json.success === "1") {
            //console.log(json);
            setGroups(json.groups);
            setIsLoaded(true);
        }
        else {
            setIsLoaded(true);
        }
    }

    const [isLoaded, setIsLoaded] = useState(false);

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    const navigate = useNavigate();

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <>
            <div className="row mt-2">
                <div className="col-12 d-flex align-items-center justify-content-between">
                    <h1>Grupos de Usuarios</h1>
                    <div>
                        <Button variant="contained" onClick={()=> navigate("./new")} color="primary">Crear grupos de usuarios</Button>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12">
                    {groups?.length > 0 ? (groups.map((event, key) => (
                        <UserBaseContainer key={key} group={event} setIsLoaded={setIsLoaded} />
                    ))) : (
                        <div className="d-flex justify-content-center align-items-start" style={{marginTop: "10vh"}}>
                            <Alert severity="warning">
                                <AlertTitle>No se encontraron grupos</AlertTitle>
                                No tenes grupos creados — <strong><Link style={{textDecoration: "none", color: "indianred"}} to={"./new"}>Creá tu primer grupo</Link></strong>
                            </Alert>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
    }
};

export default UserBase;
