import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button, CircularProgress,
    Snackbar,
    TextField
} from "@mui/material";
import './Whitelist.css';
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {Table} from "react-bootstrap";
import ButtonContainer from "./ButtonContainer";
import {RiDeleteBin6Line, RiFileEditLine, RiFileExcel2Line} from "react-icons/ri";
import {useNavigate} from "react-router";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import FileInput from "./FileInput";
import {IoClose} from "react-icons/io5";
import {FaUpload} from "react-icons/fa";
import {AiOutlinePicture} from "react-icons/ai";

const WhitelistImport = (props) => {

    const user = useSelector(selectUser);
    const navigate = useNavigate();

    const [values, setValues] = useState({
        name: '',
        email: '',
    });

    const [auxEmail, setAuxEmail] = useState('');
    const [edit, setEdit] = useState(false);

    const [manualInput, setManualInput] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [emails, setEmails] = useState([]);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const swalAlert = (attendee) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, borrarlo!'
        }).then((result) => {
            if (result.isConfirmed) {
                //console.log("Borrando");
            }
        })
    }

    function getViewport () {
        // https://stackoverflow.com/a/8876069
        const width = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
        )

        if (width <= 992) return 'md'
        return 'xl'
    }
    function searchData() {
        // Declare variables
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();

        if (getViewport() == 'xl') {
            table = document.getElementsByTagName("table")[0];

            tr = table.getElementsByTagName("tr");

            // Loop through all table rows, and hide those who don't match the search query
            for (i = 0; i < tr.length; i++) {
                var td0 = tr[i].getElementsByTagName("td")[0];

                if (td0) {
                    var txtValue0 = td0.textContent || td0.innerText;
                    if (txtValue0.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                    } else {
                        tr[i].style.display = "none";
                    }
                }
            }
        } else {
            table = document.getElementsByTagName("table")[1];

            tr = table.getElementsByTagName("tr");

            // Loop through all table rows, and hide those who don't match the search query
            for (i = 0; i < tr.length; i++) {
                var td0 = tr[i].getElementsByTagName("td")[0];
                if (td0) {
                    var txtValue0 = td0.textContent || td0.innerText;
                    if (txtValue0.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                    } else {
                        tr[i].style.display = "none";
                    }
                }
            }
        }

    }

    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const editEmail = (email) => {
        setAuxEmail(email);
        setValues({ ...values, email: email });
        setEdit(true);
        setManualInput(true);
        try {
            document.getElementById("whitelist-email-input-1").focus();
        } catch (error) {

        }
    }

    const deleteEmail = (email) => {
        setEmails(emails.filter(item => item !== email));
    }

    const editWhitelist = async (e) => {
        e.preventDefault();

        const request = await fetch(`${process.env.REACT_APP_API_URL}/whitelist-group`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                id: props.id,
                name: values.name,
                emails: emails,
            })
        })

        const response = await request.json();

        if (response.status === 'success') {
            setOpenS(true);
            setTimeout(() => {
                navigate('/organizations/whitelist');
            }, 2000);
        }
    }

    const fetchData = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/whitelist-group?id=${props.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        })

        const response = await request.json();

        if (response.status === 'success') {
            setValues({ ...values, name: response.data.name });
            setEmails(response.data.emails);
            setIsLoaded(true);
        }

    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const handleUpload = (event) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: 'array' });
            let firstSheet = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[firstSheet];
            let newEmails = XLSX.utils.sheet_to_csv(worksheet).split('\n');
            newEmails = newEmails.filter((email) => email !== '');
            let validEmails = [];
            let errorEmails = [];
            for (let email of newEmails) {
                let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (emailRegex.test(email)) {
                    validEmails.push(email);
                } else {
                    errorEmails.push(email);
                }
            }
            setEmails([
                ...emails,
                ...validEmails.map((email) => ({
                    whe_email: email.toLowerCase(),
                    promoter: null,
                }))
            ]);
            setMessage(`Se agregarán ${validEmails.length} nuevos emails.`);
            if (errorEmails.length > 0) {
                setErrorMessage(`${errorEmails.length} emails no válidos: ${errorEmails.join(", ")}`);
            } else {
                setErrorMessage("");
            }
        };
        reader.readAsArrayBuffer(file);
    };



    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        handleUpload(event);
    };

    const addToWhitelist = async (e) => {
        e.preventDefault();

        if (props.id) {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/whitelist-group`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                },
                body: JSON.stringify({
                    id: props.id,
                    emails: emails.map((email) => email.whe_email),
                })
            });

            const response = await request.json();

            if (response.status === 'success') {
                setOpenS(true);
                setTimeout(() => {
                    navigate('/organizations/whitelist');
                }, 2000);
            }
        } else {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/whitelist-group`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                },
                body: JSON.stringify({
                    name: values.name,
                    emails: emails,
                })
            })

            const response = await request.json();

            //console.log(response);

            if (response.status === 'success') {
                setOpenS(true);
                setTimeout(() => {
                    navigate('/organizations/whitelist');
                }, 2000);
            }
        }
    }

    useEffect(() => {
        try {
            document.getElementById("whitelist-email-input-1").focus();
        } catch (error) {

        }
    }, [manualInput]);

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, []);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {`Lista ${props.id ? "actualizada" : "creada"} con éxito!`}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {`Hubo un error al ${props.id ? "actualizar" : "crear"} la lista!`}
                </Alert>
            </Snackbar>

            <div className="row">
                <div className="col-12">
                    <h3>Cargar lista por archivo</h3>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-12">
                            <p>Sube tu base de datos en formato Excel, es muy importante que tenga <b>una sola columna</b> que contenga los <b>emails</b> y <b>sin ningún encabezado</b>.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-2">
                                {!props.id && (
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <TextField label="Nombre de la lista" value={values.name} onChange={handleChange('name')} fullWidth variant="filled" />
                                    </div>
                                </div>
                                )}
                                <div className="row">
                                    <div className="col-12">
                                        <label className="h6">Lista en Excel:</label>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 d-flex justify-content-center">
                                        <input
                                            style={{ display: 'none' }}
                                            type="file"
                                            id="hiddenFileInput"
                                            accept=".xlsx"
                                            onChange={onFileChange}
                                        />
                                        <label htmlFor={`hiddenFileInput`} style={{width: "100%", backgroundColor: "whitesmoke"}}>
                                            <Button type="button" style={{width: "100%", height: "200px", border: "solid grey 0.01px"}} variant="raised" component="span">
                                                <div>
                                                    <div className="row text-center">
                                                        <div className="col-12">
                                                            <RiFileExcel2Line size={50} color="grey" />
                                                        </div>
                                                    </div>
                                                    <div className="row text-center">
                                                        <div className="col-12">
                                                            <p style={{color: "grey"}} className="mb-0">Subir archivo</p>
                                                            <small style={{color: "grey"}}>Formato aceptado: xlsx</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Button>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <small>{message}</small>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <small className="text-danger">{errorMessage}</small>
                                    </div>
                                </div>
                                <div className="row mt-5 mb-5">
                                    <div className="col-md-6">
                                        <Button variant="contained" type="button" color="primary" className="me-2" onClick={addToWhitelist}>
                                            Agregar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 text-center">
                    <img src={"/assets/images/Excel-Example-Wh.jpg"} alt={"Excel-Example-Wh"} className="img-fluid" />
                </div>
            </div>

        </div>
    );
    }
};

export default WhitelistImport;
