import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectCarro} from "../redux/carroSlice";
import {selectUser} from "../redux/userSlice";
import {useLocation, useNavigate} from "react-router";
import {motion} from "framer-motion";
import MenuHeader from "./MenuHeader";
import NavCategories from "./NavCategories";
import {Container} from "react-bootstrap";
import CategoriesContainer from "./CategoriesContainer";
import CarroButton from "./CarroButton";
import Products from "./Products";
import {selectMenu, setMenu, setLogin} from "../redux/menuSlice";

const Menu = (props) => {
    const items = useSelector(selectCarro);
    const user = useSelector(selectUser);
    const navigate = useNavigate();
    const location = useLocation();
    const [categories, setCategories] = useState([]);
    const menu = useSelector(selectMenu);
    const dispatch = useDispatch();

    const query = new URLSearchParams(location.search);
    const login = query.get('login');


    const [search, setSearch] = useState('');

    const [isLoaded, setIsLoaded] = useState(false);


    const [filteredCategories, setFilteredCategories] = useState(categories);

    const fetchData = async () => {
        setIsLoaded(false);
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu?menu_id=${props.id}&event=${props.event}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "credentials": "same-origin",
                "Authorization": `Bearer ${user.token}`,
        }});

        const response = await request.json();
        if (response.status === "success") {
            dispatch(setMenu(response.menu));
            setCategories(response.menu.categories);
            setIsLoaded(true);
        }
    }

    const fetchActiveEvents = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-event-redirection?menu=${props.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + user.token,
            }
        });

        const response = await request.json();

        if (response.status === "success") {
            navigate(response.url);
        }
    }

    const filterProducts = (search) => {
        let filteredProducts = [];
        categories.forEach((category) => {
            const filteredCategory = {
                ...category,
                products: category.products.filter((product) => {
                    return product.title.toLowerCase().includes(search.toLowerCase());
                }),
            };
            if (filteredCategory.products.length > 0) {
                filteredProducts.push(filteredCategory);
            }
        });

        setFilteredCategories(filteredProducts);
    };

    useEffect(() => {
        filterProducts(search);
    }, [search]);

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);

    useEffect(() => {
        setFilteredCategories(categories);
        categories.length > 0 ? setActiveCategory(categories[0].id) : setActiveCategory(0);
    }, [categories]);

    useEffect(() => {
        if (login) {
            dispatch(setLogin(login));
        }
    }, [login]);

    const [activeCategory, setActiveCategory] = useState();

    const handleScroll = () => {
        const menuBody = document.getElementById("menu-body");
        const scrollY = window.scrollY;
        const categories = document.querySelectorAll(".items-categ-container");

        let matchedCategories = [];  // Array to store data when condition is met

        categories.forEach((category, index) => {
            const categoryTop = category.offsetTop;
            const categoryHeight = category.offsetHeight;

            if (scrollY > categoryTop - 107) {
                matchedCategories.push({
                    categoryIdLastPart: categories[index].id.split("-").pop(),  // Get the last part after splitting by "-"
                    categoryId: categories[index].id,
                    categoryTop: categoryTop,
                    categoryHeight: categoryHeight
                });
            }
        });

        if (matchedCategories.length > 0) {
            const lastCategory = matchedCategories[matchedCategories.length - 1];  // Get the last matched category
            const indexToSetActive = parseInt(lastCategory.categoryIdLastPart);  // Convert the last part of the ID to a number

            setActiveCategory(indexToSetActive);
        }
    };

    useEffect(() => {
        if (!props.event) {
            fetchActiveEvents();
        }
    }, []);

    useEffect(() => {

        // add <meta name="theme-color" content="#ff8000" /> to index.html
        document.querySelector("meta[name=theme-color]").setAttribute("content", menu?.header_color);

        const menuBody = document.getElementById("menu-body");
        let isListenerRemoved = false;
        let addAgainTimeout = null;

        window.addEventListener("scroll", handleScroll);

        const navContainer = document.getElementById("nav-categories-container");

        window.addEventListener("click", () => {
            if (!isListenerRemoved) {
                window.removeEventListener("scroll", handleScroll);
                isListenerRemoved = true;
                addAgainTimeout = setTimeout(() => {
                    window.addEventListener("scroll", handleScroll);
                    isListenerRemoved = false;
                }, 1600);
            }
        });

        return () => {
            window.removeEventListener("scroll", handleScroll);
            clearTimeout(addAgainTimeout);
        };
}, []);


    return (
        <motion.div
            key={location.pathname}
        >
            <div className="menu-container" id="menu-container">
                <div className="menu-header" style={{overflow: "hidden"}}>
                    <MenuHeader id={props.id} event={props.event} search={search} setSearch={setSearch} />
                    <NavCategories menu={menu} search={search} setSearch={setSearch} activeCategory={activeCategory} setActiveCategory={setActiveCategory} categories={categories} />
                </div>
                <div className="menu-body categories" id="menu-body" style={{overflowY: "scroll", overflowX: "hidden"}}>
                    <Products categories={filteredCategories} search={search} items={items} />
                    <CarroButton menu={menu} items={items} />
                </div>
            </div>
        </motion.div>
    );
};

export default Menu;