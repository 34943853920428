import React, {useState} from 'react';
import DoughnutChart from "./DoughnutChart";
import {Container, Table} from "react-bootstrap";
import BasicTable from "./BasicTable";
import {Button, CircularProgress, Paper, Tooltip} from "@mui/material";
import './KPI.css';
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import BestSalesTimeChart from "./BestSalesTimeChart";
import BarChart from "./BarChart";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {MdCloudDownload} from "react-icons/md";
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import DoughnutChartTicketGender from "./DoughnutChartTicketGender";
import BarChartEventAnalytics from "./BarChartEventAnalytics";
import moment from "moment-timezone";

const EventAnalytics = (props) => {

    const user = useSelector(selectUser)

    const [analyticsData, setAnalyticsData] = React.useState({});
    const [permissions, setPermissions] = React.useState(0);
    const [selectedTab, setSelectedTab] = React.useState("tickets");

    const [eventName, setEventName] = React.useState('');

    const sanitizeFilename = (name) => {
        const validName = name.toLowerCase().replace(/%20/g, " ").replace(/[^a-z0-9_\s]/g, "").replace(/\s+/g, '_');
        return validName;
    };


    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/event-analytics?event_id=${props.event}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();

        // Order by Hombres, Mujeres, Otros, Todos
        const order = ['Hombres', 'Mujeres', 'Otros', 'Todos'];
        json.tickets_gender.sort((a, b) => {
            return order.indexOf(a.Genero) - order.indexOf(b.Genero);
        });

        setAnalyticsData(json);
        setEventName(sanitizeFilename(json?.event_name));
        setIsLoaded(true);
    }

    const fetchPermisions = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/event-permissions?event_id=${props.event}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });

        const json = await response.json();
        //console.log(json)

        if (json.status === "success") {
            setPermissions(json?.events?.validator);
        }
    }

    function number_format (number, decimals, dec_point, thousands_sep) {
        // Strip all characters but numerical ones.
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    const downloadColaborators = async () => {
        let totalCantOnline = 0;
        let totalCantCash = 0;
        let totalCantCortesia = 0;
        let totalCantTotal = 0;
        let totalRevOnline = 0;
        let totalRevCash = 0;
        let totalRevTotal = 0;

        const processedData = analyticsData?.rrpp_sales.map(item => {
            const cantOnline = Math.round(item.CantOnline * 100) / 100;
            const cantCash = Math.round(item.CantCash * 100) / 100;
            const cantCortesia = Math.round(item.CantCortesia * 100) / 100;
            const cantTotal = cantOnline + cantCash + cantCortesia;

            const revOnline = Math.round(item.RevOnline * 100) / 100;
            const revCash = Math.round(item.RevCash * 100) / 100;
            const revTotal = revOnline + revCash;

            totalCantOnline += cantOnline;
            totalCantCash += cantCash;
            totalCantCortesia += cantCortesia;
            totalCantTotal += cantTotal;
            totalRevOnline += revOnline;
            totalRevCash += revCash;
            totalRevTotal += revTotal;

            return {
                Promotor: item.Promotor,
                'Cantidad (Online)': cantOnline,
                'Cantidad (Cash)': cantCash,
                'Cantidad (Cortesia)': cantCortesia,
                'Cantidad (Total)': cantTotal,
                'Recaudacion (Online)': revOnline,
                'Recaudacion (Cash)': revCash,
                'Recaudacion (Total)': revTotal,
            };
        });

        processedData.push({
            Promotor: 'Total',
            'Cantidad (Online)': Math.round(totalCantOnline * 100) / 100,
            'Cantidad (Cash)': Math.round(totalCantCash * 100) / 100,
            'Cantidad (Cortesia)': Math.round(totalCantCortesia * 100) / 100,
            'Cantidad (Total)': Math.round(totalCantTotal * 100) / 100,
            'Recaudacion (Online)': Math.round(totalRevOnline * 100) / 100,
            'Recaudacion (Cash)': Math.round(totalRevCash * 100) / 100,
            'Recaudacion (Total)': Math.round(totalRevTotal * 100) / 100,
        });

        const genderData = analyticsData?.rrpp_ticket_gender.map(item => {
            return {
                "Promotor": item.Promotor,
                "Hombres": item.Hombres,
                "Mujeres": item.Mujeres,
            };
        });

        genderData.push({
            "Promotor": "Total",
            "Hombres": genderData.reduce((a, b) => a + b.Hombres, 0),
            "Mujeres": genderData.reduce((a, b) => a + b.Mujeres, 0),
        });

        const ws = utils.json_to_sheet(processedData);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Resumen");

        const ws2 = utils.json_to_sheet(genderData);
        utils.book_append_sheet(wb, ws2, "Genero");

        const wbout = write(wb, {type: 'binary'});
        const buf = new ArrayBuffer(wbout.length);
        const view = new Uint8Array(buf);
        for(let i=0; i<wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
        saveAs(new Blob([buf], {type: 'application/octet-stream'}), 'collaborators_' + eventName + '.xlsx');
    };

    const downloadOrders = async () => {
        let totalCantOnline = 0;
        let totalCantCash = 0;
        let totalCantCortesia = 0;
        let totalCantTotal = 0;
        let totalRevOnline = 0;
        let totalRevCash = 0;
        let totalRevTotal = 0;

        const processedData = analyticsData?.tickets.map(item => {
            const cantOnline = Math.round(item.CantOnline * 100) / 100;
            const cantCash = Math.round(item.CantCash * 100) / 100;
            const cantCortesia = Math.round(item.CantCortesia * 100) / 100;
            const cantTotal = cantOnline + cantCash + cantCortesia;

            const revOnline = Math.round(item.RevOnline * 100) / 100;
            const revCash = Math.round(item.RevCash * 100) / 100;
            const revTotal = revOnline + revCash;

            totalCantOnline += cantOnline;
            totalCantCash += cantCash;
            totalCantCortesia += cantCortesia;
            totalCantTotal += cantTotal;
            totalRevOnline += revOnline;
            totalRevCash += revCash;
            totalRevTotal += revTotal;

            return {
                Ticket: item.Ticket,
                'Cantidad (Online)': cantOnline,
                'Cantidad (Cash)': cantCash,
                'Cantidad (Cortesia)': cantCortesia,
                'Cantidad (Total)': cantTotal,
                'Recaudacion (Online)': revOnline,
                'Recaudacion (Cash)': revCash,
                'Recaudacion (Total)': revTotal,
            };
        });

        processedData.push({
            Ticket: 'Total',
            'Cantidad (Online)': Math.round(totalCantOnline * 100) / 100,
            'Cantidad (Cash)': Math.round(totalCantCash * 100) / 100,
            'Cantidad (Cortesia)': Math.round(totalCantCortesia * 100) / 100,
            'Cantidad (Total)': Math.round(totalCantTotal * 100) / 100,
            'Recaudacion (Online)': Math.round(totalRevOnline * 100) / 100,
            'Recaudacion (Cash)': Math.round(totalRevCash * 100) / 100,
            'Recaudacion (Total)': Math.round(totalRevTotal * 100) / 100,
        });

        // Create the first sheet with the processed ticket data
        const ws1 = utils.json_to_sheet(processedData);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws1, "Vta por ticket");

        // Process the sales and quantity data for the second sheet
        const secondSheetData = analyticsData?.sales.map((item, index) => {
            return {
                Fecha: item.x,
                Cantidad: analyticsData.sales_qty[index]?.y,
                Facturacion: item.y,
            };
        });

        // Create the second sheet with the sales data
        const ws2 = utils.json_to_sheet(secondSheetData);
        utils.book_append_sheet(wb, ws2, "Vta por dia");

        // Convert the workbook to binary and save it
        const wbout = write(wb, {type: 'binary'});
        const buf = new ArrayBuffer(wbout.length);
        const view = new Uint8Array(buf);
        for(let i=0; i<wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
        saveAs(new Blob([buf], {type: 'application/octet-stream'}), 'orders_' + eventName + '.xlsx');
    };


    const downloadValidatedTickets = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/validated-tickets-detail?event_id=${props.event}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
                'credentials': 'same-origin'
            }
        });

        const response = await request.json();

        if (response.status === 'error') {
            alert('Error al descargar los tickets validados');
            return;
        }

        const ws = utils.json_to_sheet(response.tickets);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Sheet1");

        const wbout = write(wb, {type: 'binary'});
        const buf = new ArrayBuffer(wbout.length);
        const view = new Uint8Array(buf);
        for(let i=0; i<wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
        saveAs(new Blob([buf], {type: 'application/octet-stream'}), 'validated_tickets_' + eventName + '.xlsx');
    }



    React.useEffect(() => {
        fetchPermisions();
        fetchData();
    }, [user.token]);

    const [isLoaded, setIsLoaded] = useState(false);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
        return (
            <div>
                <div className="row mt-2">
                    <div className="col">
                        <h2>Analytics</h2>
                        <h5>{analyticsData?.event_name}</h5>
                    </div>
                </div>
                {analyticsData?.orders_sum?.length > 0 ? (
                    <div className="row mb-4">
                        <div className="col-12">
                            <Button variant="contained" color="primary" onClick={() => setSelectedTab("tickets")}>Tickets</Button>
                            <Button className={"ms-2"} variant="contained" color="primary" onClick={() => setSelectedTab("menu")}>Productos</Button>
                        </div>
                    </div>
                ) : null}
                {selectedTab === "tickets" ? (
                    <>
                        <div className="row">
                            <div className="col">
                                <div style={{
                                    boxShadow: "0 0 0.5rem #ccc",
                                    backgroundColor: "whitesmoke",
                                    borderRadius: "1rem",
                                    fontWeight: "bold"
                                }}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="text-center mt-3">Ventas totales</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <p style={{fontSize: "2.5rem"}} className="text-center p-0 m-0">$ {
                                                number_format(analyticsData?.tickets?.reduce((a, b) => a + b.RevTotal, 0), 0, ',', '.')
                                            }</p>
                                        </div>
                                    </div>
                                    <div className="row mt-0">
                                        <div className="col-12">
                                            <p style={{fontSize: "1.75rem"}}
                                               className="text-center">{number_format(analyticsData?.tickets?.reduce((a, b) => a + b?.CantTotal, 0), 0, ',', '.')} tickets</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3 align-items-center">
                            <div className="col-md-3 mt-3 mt-md-0">
                                <div className="p-2" style={{
                                    boxShadow: "0 0 0.5rem #ccc",
                                    backgroundColor: "whitesmoke",
                                    borderRadius: "1rem",
                                    fontWeight: "bold"
                                }}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 className="text-center">Ventas Online</h5>
                                            <p style={{fontSize: "1.25rem"}}
                                               className="text-center p-0 m-0">{number_format(analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.CantOnline), 0), 0, ',', '.')} tickets</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mt-3 mt-md-0">
                                <div className="p-2" style={{
                                    boxShadow: "0 0 0.5rem #ccc",
                                    backgroundColor: "whitesmoke",
                                    borderRadius: "1rem",
                                    fontWeight: "bold"
                                }}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 className="text-center">Recaudado Online</h5>
                                            <p style={{fontSize: "1.25rem"}}
                                               className="text-center p-0 m-0">$ {number_format(analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.RevOnline), 0), 0, ',', '.')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mt-3 mt-md-0">
                                <div className="p-2" style={{
                                    boxShadow: "0 0 0.5rem #ccc",
                                    backgroundColor: "whitesmoke",
                                    borderRadius: "1rem",
                                    fontWeight: "bold"
                                }}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 className="text-center">Órdenes</h5>
                                            <p style={{fontSize: "1.25rem"}}
                                               className="text-center p-0 m-0">{analyticsData?.compras}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mt-3 mt-md-0">
                                <div className="p-2" style={{
                                    boxShadow: "0 0 0.5rem #ccc",
                                    backgroundColor: "whitesmoke",
                                    borderRadius: "1rem",
                                    fontWeight: "bold"
                                }}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 className="text-center">Visitas del evento</h5>
                                            <p style={{fontSize: "1.25rem"}}
                                               className="text-center p-0 m-0">{analyticsData?.event_views}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5 align-items-center mb-2">
                            <div className="col-auto">
                                <h4 className="mb-0">Órdenes de compra</h4>
                            </div>
                            <div className="col-auto justify-content-start">
                                <Tooltip title="Descargar" placement={"top"} style={{padding: 0, margin: 0}}>
                                    <Button className="download_stats_button_icon" variant={"text"} style={{
                                        margin: 0,
                                        padding: 0,
                                        backgroundColor: "transparent",
                                        border: "none",
                                        color: "black"
                                    }}>
                                        <MdCloudDownload onClick={downloadOrders}
                                                         style={{fontSize: "1.2rem", cursor: "pointer"}}/>
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-12">
                                <Paper style={{borderRadius: "1rem", overflow: "hidden", boxShadow: "0 0 0.5rem #ccc"}}>
                                    <div className="p-2 bg-white">
                                        <BarChartEventAnalytics viewsData={analyticsData?.event_views_detail}
                                                                salesData={analyticsData?.sales} quantityData={analyticsData?.sales_qty} />
                                    </div>
                                </Paper>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-12">
                                <BestSalesTimeChart bestSalesTimes={analyticsData?.best_sales_times}/>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12">
                                <h5 className="mb-0">Venta por ticket</h5>
                            </div>
                            <div className="col-12 mt-3">
                                <div style={{
                                    overflowX: "scroll",
                                    borderRadius: ".5rem",
                                    boxShadow: "0 0 0.5rem #ccc",
                                    backgroundColor: "white",
                                    padding: "7px 0"
                                }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow style={{fontSize: '0.8rem'}}>
                                                {/* Headers with reduced font size */}
                                                {['Ticket', 'Online', 'Offline', 'Cash', 'Cortesía', 'Total', 'Precio Unitario', 'Online', 'Offline', 'Cash', 'Total'].map((header, index) => (
                                                    <TableCell key={index} align="center" style={{
                                                        padding: '10px 8px',
                                                        borderLeft: index === 6 ? '1px solid rgba(0, 0, 0, 0.1)' : 'none'
                                                    }}>
                                                        {header}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analyticsData?.tickets?.map((row, index) => (
                                                <TableRow key={index} style={{fontSize: '0.8rem'}}>
                                                    {/* Cells with reduced font size and padding */}
                                                    <TableCell align="center"
                                                               style={{padding: '10px 8px'}}>{row?.Ticket}</TableCell>
                                                    <TableCell align="center"
                                                               style={{padding: '10px 8px'}}>{row?.CantOnline}</TableCell>
                                                    <TableCell align="center"
                                                               style={{padding: '10px 8px'}}>{row?.CantOffline}</TableCell>
                                                    <TableCell align="center"
                                                               style={{padding: '10px 8px'}}>{row?.CantCash}</TableCell>
                                                    <TableCell align="center"
                                                               style={{padding: '10px 8px'}}>{row?.CantCortesia}</TableCell>
                                                    <TableCell align="center"
                                                               style={{padding: '10px 8px'}}>{row?.CantTotal}</TableCell>
                                                    <TableCell align="center" style={{
                                                        padding: '10px 8px',
                                                        borderLeft: '1px solid rgba(0, 0, 0, 0.1)'
                                                    }}>$ {number_format(row?.Precio, 0, ',', '.')}</TableCell>
                                                    <TableCell align="center"
                                                               style={{padding: '10px 8px', minWidth: '100px'}}>
                                                        $ {number_format(row?.RevOnline, 0, ',', '.')}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                               style={{padding: '10px 8px', minWidth: '100px'}}>
                                                        $ {number_format(row?.RevOffline, 0, ',', '.')}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                               style={{padding: '10px 8px', minWidth: '100px'}}>
                                                        $ {number_format(row?.RevCash, 0, ',', '.')}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                               style={{padding: '10px 8px', minWidth: '100px'}}>
                                                        $ {number_format(row?.RevTotal, 0, ',', '.')}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        {/* Footer */}
                                        <TableHead>
                                            <TableRow style={{fontSize: '0.8rem'}}>
                                                <TableCell align="center" style={{padding: '10px 8px'}}>Total</TableCell>
                                                <TableCell align="center"
                                                           style={{padding: '10px 8px'}}>{analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.CantOnline), 0)}</TableCell>
                                                <TableCell align="center"
                                                           style={{padding: '10px 8px'}}>{analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.CantOffline), 0)}</TableCell>
                                                <TableCell align="center"
                                                           style={{padding: '10px 8px'}}>{analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.CantCash), 0)}</TableCell>
                                                <TableCell align="center"
                                                           style={{padding: '10px 8px'}}>{analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.CantCortesia), 0)}</TableCell>
                                                <TableCell align="center"
                                                           style={{padding: '10px 8px'}}>{analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.CantTotal), 0)}</TableCell>
                                                <TableCell align="center" style={{
                                                    padding: '10px 8px',
                                                    borderLeft: '1px solid rgba(0, 0, 0, 0.1)'
                                                }}></TableCell>
                                                <TableCell align="center" style={{padding: '10px 8px', minWidth: '100px'}}>
                                                    $ {number_format(analyticsData?.tickets?.reduce((a, b) => a + b?.RevOnline, 0), 0, ',', '.')}
                                                </TableCell>
                                                <TableCell align="center" style={{padding: '10px 8px', minWidth: '100px'}}>
                                                    $ {number_format(analyticsData?.tickets?.reduce((a, b) => a + b?.RevOffline, 0), 0, ',', '.')}
                                                </TableCell>
                                                <TableCell align="center" style={{padding: '10px 8px', minWidth: '100px'}}>
                                                    $ {number_format(analyticsData?.tickets?.reduce((a, b) => a + b?.RevCash, 0), 0, ',', '.')}
                                                </TableCell>
                                                <TableCell align="center" style={{padding: '10px 8px', minWidth: '100px'}}>
                                                    $ {number_format(analyticsData?.tickets?.reduce((a, b) => a + b?.RevTotal, 0), 0, ',', '.')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5 align-items-center mb-2">
                            <div className="col-auto">
                                <h4 className="mb-0">Tickets validados</h4>
                            </div>
                            <div className="col-auto">
                                <Tooltip title="Descargar" placement={"top"} style={{padding: 0, margin: 0}}>
                                    <Button className="download_stats_button_icon" variant={"text"} style={{
                                        margin: 0,
                                        padding: 0,
                                        backgroundColor: "transparent",
                                        border: "none",
                                        color: "black"
                                    }}>
                                        <MdCloudDownload onClick={downloadValidatedTickets}
                                                         style={{fontSize: "1.2rem", cursor: "pointer"}}/>
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-8">
                                <div style={{
                                    overflowX: "scroll",
                                    borderRadius: ".5rem",
                                    boxShadow: "0 0 0.5rem #ccc",
                                    backgroundColor: "white"
                                }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Ticket</TableCell>
                                                <TableCell align="center">Online</TableCell>
                                                <TableCell align="center">Offline</TableCell>
                                                <TableCell align="center">Cash</TableCell>
                                                <TableCell align="center">Cortesía</TableCell>
                                                <TableCell align="center">Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analyticsData?.tickets?.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                >
                                                    <TableCell align="center">{row?.Ticket}</TableCell>
                                                    <TableCell align="center">{row?.ValOnline}/{row?.CantOnline}</TableCell>
                                                    <TableCell
                                                        align="center">{row?.ValOffline}/{row?.CantOffline}</TableCell>
                                                    <TableCell align="center">{row?.ValCash}/{row?.CantCash}</TableCell>
                                                    <TableCell
                                                        align="center">{row?.ValCortesia}/{row?.CantCortesia}</TableCell>
                                                    <TableCell align="center">{row?.ValTotal}/{row?.CantTotal}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Total</TableCell>
                                                <TableCell
                                                    align="center">{analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.ValOnline), 0)}/{analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.CantOnline), 0)}</TableCell>
                                                <TableCell
                                                    align="center">{analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.ValOffline), 0)}/{analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.CantOffline), 0)}</TableCell>
                                                <TableCell
                                                    align="center">{analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.ValCash), 0)}/{analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.CantCash), 0)}</TableCell>
                                                <TableCell
                                                    align="center">{analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.ValCortesia), 0)}/{analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.CantCortesia), 0)}</TableCell>
                                                <TableCell
                                                    align="center">{analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.ValTotal), 0)}/{analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.CantTotal), 0)}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3 mt-md-0">
                                <DoughnutChart labels={['Validados', 'Vigentes']}
                                               x2={analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.CantTotal), 0) - analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.ValTotal), 0)}
                                               x1={analyticsData?.tickets?.reduce((a, b) => a + parseInt(b?.ValTotal), 0)}/>
                            </div>
                        </div>

                        {analyticsData?.avg_validation_date && (
                            <>
                                <div className="row mt-2 align-items-center mb-2">
                                    <div className="col-auto">
                                        <h5 className="mb-0">Fecha y horario promedio de ingreso</h5>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <div className="text-center" style={{
                                            overflowX: "scroll",
                                            borderRadius: ".5rem",
                                            boxShadow: "0 0 0.5rem #ccc",
                                            backgroundColor: "white",
                                            padding: "1.2rem 0.7rem"
                                        }}>
                                            <p className="mb-0" style={{fontSize: "1.3rem"}}>
                                                <span>{moment(analyticsData?.avg_validation_date).format('DD/MM/YYYY')} </span>
                                                <span
                                                    className="font-weight-bold">{moment(analyticsData?.avg_validation_date).format('HH:mm')}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="row mt-2 align-items-center mb-2">
                            <div className="col-auto">
                                <h5 className="mb-0">Segmentación por género</h5>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-8">
                                <div style={{
                                    overflowX: "scroll",
                                    borderRadius: ".5rem",
                                    boxShadow: "0 0 0.5rem #ccc",
                                    backgroundColor: "white"
                                }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Género</TableCell>
                                                <TableCell align="center">Online</TableCell>
                                                <TableCell align="center">Offline</TableCell>
                                                <TableCell align="center">Cash</TableCell>
                                                <TableCell align="center">Cortesía</TableCell>
                                                <TableCell align="center">Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analyticsData?.tickets_gender?.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                >
                                                    <TableCell align="center">{row?.Genero}</TableCell>
                                                    <TableCell align="center">{row?.CantOnline}</TableCell>
                                                    <TableCell align="center">{row?.CantOffline}</TableCell>
                                                    <TableCell align="center">{row?.CantCash}</TableCell>
                                                    <TableCell align="center">{row?.CantCortesia}</TableCell>
                                                    <TableCell align="center">{row?.CantTotal}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Total</TableCell>
                                                <TableCell
                                                    align="center">{analyticsData?.tickets_gender?.reduce((a, b) => a + parseInt(b?.CantOnline), 0)}</TableCell>
                                                <TableCell
                                                    align="center">{analyticsData?.tickets_gender?.reduce((a, b) => a + parseInt(b?.CantOffline), 0)}</TableCell>
                                                <TableCell
                                                    align="center">{analyticsData?.tickets_gender?.reduce((a, b) => a + parseInt(b?.CantCash), 0)}</TableCell>
                                                <TableCell
                                                    align="center">{analyticsData?.tickets_gender?.reduce((a, b) => a + parseInt(b?.CantCortesia), 0)}</TableCell>
                                                <TableCell
                                                    align="center">{analyticsData?.tickets_gender?.reduce((a, b) => a + parseInt(b?.CantTotal), 0)}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3 mt-md-0">
                                <DoughnutChartTicketGender
                                    data={
                                        analyticsData?.tickets_gender?.map((row) => {
                                                return {
                                                    name: row?.Genero,
                                                    value: row?.CantTotal
                                                }
                                            }
                                        )}
                                />
                            </div>
                        </div>

                        <>
                            <div className="row mt-5 align-items-center mb-2">
                                <div className="col-auto">
                                    <h4 className="mb-0">Colaboradores</h4>
                                </div>
                                <div className="col-auto">
                                    <Tooltip title="Descargar" placement={"top"} style={{padding: 0, margin: 0}}>
                                        <Button className="download_stats_button_icon" variant={"text"} style={{
                                            margin: 0,
                                            padding: 0,
                                            backgroundColor: "transparent",
                                            border: "none",
                                            color: "black"
                                        }}>
                                            <MdCloudDownload onClick={downloadColaborators}
                                                             style={{fontSize: "1.2rem", cursor: "pointer"}}/>
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <div style={{
                                        backgroundColor: "white",
                                        overflowX: "scroll",
                                        borderRadius: ".5rem",
                                        boxShadow: "0 0 0.5rem #ccc"
                                    }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Colaborador</TableCell>
                                                    <TableCell align="center">Online</TableCell>
                                                    <TableCell align="center">Cash</TableCell>
                                                    <TableCell align="center">Cortesía</TableCell>
                                                    <TableCell align="center">Total</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {analyticsData?.rrpp_sales?.map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                    >
                                                        <TableCell align="center">{row?.Promotor}</TableCell>
                                                        <TableCell align="center">{row?.CantOnline}</TableCell>
                                                        <TableCell align="center">{row?.CantCash}</TableCell>
                                                        <TableCell align="center">{row?.CantCortesia}</TableCell>
                                                        <TableCell align="center">{row?.CantTotal}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Total</TableCell>
                                                    <TableCell
                                                        align="center">{analyticsData?.rrpp_sales?.reduce((a, b) => a + parseInt(b?.CantOnline), 0)}</TableCell>
                                                    <TableCell
                                                        align="center">{analyticsData?.rrpp_sales?.reduce((a, b) => a + parseInt(b?.CantCash), 0)}</TableCell>
                                                    <TableCell
                                                        align="center">{analyticsData?.rrpp_sales?.reduce((a, b) => a + parseInt(b?.CantCortesia), 0)}</TableCell>
                                                    <TableCell
                                                        align="center">{analyticsData?.rrpp_sales?.reduce((a, b) => a + parseInt(b?.CantTotal), 0)}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3 mt-md-0">
                                    <div style={{
                                        backgroundColor: "white",
                                        overflowX: "scroll",
                                        borderRadius: ".5rem",
                                        boxShadow: "0 0 0.5rem #ccc"
                                    }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Colaborador</TableCell>
                                                    <TableCell align="center">Online</TableCell>
                                                    <TableCell align="center">Cash</TableCell>
                                                    <TableCell align="center">Total</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {analyticsData?.rrpp_sales?.map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                    >
                                                        <TableCell align="center">{row?.Promotor}</TableCell>
                                                        <TableCell
                                                            align="center">$ {number_format(row?.RevOnline, 0, ',', '.')}</TableCell>
                                                        <TableCell
                                                            align="center">$ {number_format(row?.RevCash, 0, ',', '.')}</TableCell>
                                                        <TableCell
                                                            align="center">$ {number_format(row?.RevTotal, 0, ',', '.')}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Total</TableCell>
                                                    <TableCell
                                                        align="center">$ {number_format(analyticsData?.rrpp_sales?.reduce((a, b) => a + b?.RevOnline, 0), 0, ',', '.')}</TableCell>
                                                    <TableCell
                                                        align="center">$ {number_format(analyticsData?.rrpp_sales?.reduce((a, b) => a + b?.RevCash, 0), 0, ',', '.')}</TableCell>
                                                    <TableCell
                                                        align="center">$ {number_format(analyticsData?.rrpp_sales?.reduce((a, b) => a + b?.RevTotal, 0), 0, ',', '.')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                            {analyticsData?.rrpp_ticket_gender?.length > 0 && (
                                <>
                                    <div className="row mt-4">
                                        <div className="col-md-6">
                                            <h5 className="mb-0">Segmentación por género</h5>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-6">
                                            <div style={{
                                                backgroundColor: "white",
                                                overflowX: "scroll",
                                                borderRadius: ".5rem",
                                                boxShadow: "0 0 0.5rem #ccc"
                                            }}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            {Object.keys(analyticsData.rrpp_ticket_gender[0]).map((colName, index) => (
                                                                <TableCell key={index} align="center">{colName}</TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {analyticsData.rrpp_ticket_gender.map((row, rowIndex) => (
                                                            <TableRow key={rowIndex}>
                                                                {Object.values(row).map((colValue, colIndex) => (
                                                                    <TableCell key={colIndex}
                                                                               align="center">{colValue}</TableCell>
                                                                ))}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center">Total</TableCell>
                                                            {Object.keys(analyticsData.rrpp_ticket_gender[0]).filter(key => key !== 'Promotor').map((key, keyIndex) => (
                                                                <TableCell key={keyIndex}
                                                                           align="center">{analyticsData.rrpp_ticket_gender.reduce((a, b) => a + parseInt(b[key]), 0)}</TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                </Table>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div style={{
                                                backgroundColor: "white",
                                                overflowX: "scroll",
                                                borderRadius: ".5rem",
                                                boxShadow: "0 0 0.5rem #ccc"
                                            }}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            {Object.keys(analyticsData.rrpp_ticket_gender[0]).map((colName, index) => (
                                                                <TableCell key={index}
                                                                           align="center">{colName === "Promotor" ? `${colName}` : `${colName} (%)`}</TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {analyticsData.rrpp_ticket_gender.map((row, rowIndex) => {
                                                            const rowTotal = Object.values(row).reduce((a, b) => a + (isNaN(parseInt(b)) ? 0 : parseInt(b)), 0);
                                                            return (
                                                                <TableRow key={rowIndex}>
                                                                    {Object.entries(row).map(([key, value], colIndex) => (
                                                                        <TableCell key={colIndex} align="center">
                                                                            {key === 'Promotor' ? value : `${number_format(((value / rowTotal) * 100).toFixed(2), 0, ",", ".")}%`}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            )
                                                        })}
                                                    </TableBody>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center">Total</TableCell>
                                                            {Object.keys(analyticsData.rrpp_ticket_gender[0]).filter(key => key !== 'Promotor').map((key, keyIndex) => {
                                                                const columnTotal = analyticsData.rrpp_ticket_gender.reduce((a, b) => a + parseInt(b[key]), 0);
                                                                const allValuesTotal = analyticsData.rrpp_ticket_gender.reduce((a, b) => a + Object.values(b).reduce((c, d) => c + (isNaN(parseInt(d)) ? 0 : parseInt(d)), 0), 0);
                                                                return (
                                                                    <TableCell key={keyIndex} align="center">
                                                                        {number_format(((columnTotal / allValuesTotal) * 100), 0, ",", ".") + '%'}
                                                                    </TableCell>
                                                                )
                                                            })}
                                                        </TableRow>
                                                    </TableHead>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    </>
                ) : (
                    <>
                        <div className="row">
                            <div className="col">
                                <div style={{
                                    boxShadow: "0 0 0.5rem #ccc",
                                    backgroundColor: "whitesmoke",
                                    borderRadius: "1rem",
                                    fontWeight: "bold"
                                }}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="text-center mt-3">Venta total productos</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <p style={{fontSize: "2.5rem"}} className="text-center p-0 m-0">$ {
                                                number_format(analyticsData?.orders_sum?.reduce((a, b) => a + b.Facturacion, 0), 0, ',', '.')
                                            }</p>
                                        </div>
                                    </div>
                                    <div className="row mt-0">
                                        <div className="col-12">
                                            <p style={{fontSize: "1.75rem"}}
                                               className="text-center">{number_format(analyticsData?.orders_sum?.reduce((a, b) => a + b?.Cantidad, 0), 0, ',', '.')} unidades</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12">
                                <h5 className="mb-0">Puntos de venta y Medios de Pago</h5>
                            </div>
                            <div className="col-12 mt-3">
                                <div style={{
                                    overflowX: "scroll",
                                    borderRadius: ".5rem",
                                    boxShadow: "0 0 0.5rem #ccc",
                                    backgroundColor: "white",
                                    padding: "7px 0"
                                }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow style={{fontSize: '0.8rem'}}>
                                                {/* Headers with reduced font size */}
                                                {['POS', 'Sector', 'Procesador', 'Medio de pago', 'Facturación'].map((header, index) => (
                                                    <TableCell key={index} align="center" style={{
                                                        padding: '10px 8px',
                                                        borderLeft: index === 1 ? '1px solid rgba(0, 0, 0, 0.1)' : 'none'
                                                    }}>
                                                        {header}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analyticsData?.payment_methods?.map((row, index) => (
                                                <TableRow key={index} style={{fontSize: '0.8rem'}}>
                                                    {/* Cells with reduced font size and padding */}
                                                    <TableCell align="center"
                                                               style={{padding: '10px 8px'}}>{row?.POS}</TableCell>
                                                    <TableCell align="center"
                                                               style={{
                                                                   padding: '10px 8px',
                                                                   borderLeft: '1px solid rgba(0, 0, 0, 0.1)'
                                                               }}>{row?.Sector}</TableCell>
                                                    <TableCell align="center"
                                                               style={{padding: '10px 8px'}}>{row?.Procesador}</TableCell>
                                                    <TableCell align="center"
                                                               style={{padding: '10px 8px'}}>{row?.payment_method}</TableCell>
                                                    <TableCell align="center"
                                                               style={{padding: '10px 8px', minWidth: '100px'}}>
                                                        $ {number_format(row?.Facturacion, 0, ',', '.')}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        <TableHead>
                                            <TableRow style={{fontSize: '0.8rem'}}>
                                                <TableCell align="center" style={{padding: '10px 8px'}}>Total</TableCell>
                                                <TableCell align="center"
                                                           style={{
                                                               padding: '10px 8px',
                                                               borderLeft: '1px solid rgba(0, 0, 0, 0.1)'
                                                           }}></TableCell>
                                                <TableCell align="center"
                                                           style={{padding: '10px 8px'}}></TableCell>
                                                <TableCell align="center"
                                                           style={{padding: '10px 8px'}}></TableCell>
                                                <TableCell align="center" style={{padding: '10px 8px', minWidth: '100px'}}>
                                                    $ {number_format(analyticsData?.payment_methods?.reduce((a, b) => a + b?.Facturacion, 0), 0, ',', '.')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12">
                                <h5 className="mb-0">Venta por producto</h5>
                            </div>
                            <div className="col-12 mt-3">
                                <div style={{
                                    overflowX: "scroll",
                                    borderRadius: ".5rem",
                                    boxShadow: "0 0 0.5rem #ccc",
                                    backgroundColor: "white",
                                    padding: "7px 0"
                                }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow style={{fontSize: '0.8rem'}}>
                                                {/* Headers with reduced font size */}
                                                {['Producto', 'Sector', 'Cantidad', 'Facturación'].map((header, index) => (
                                                    <TableCell key={index} align="center" style={{
                                                        padding: '10px 8px',
                                                        borderLeft: index === 1 ? '1px solid rgba(0, 0, 0, 0.1)' : 'none'
                                                    }}>
                                                        {header}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analyticsData?.orders_sum?.map((row, index) => (
                                                <TableRow key={index} style={{fontSize: '0.8rem'}}>
                                                    {/* Cells with reduced font size and padding */}
                                                    <TableCell align="center"
                                                               style={{padding: '10px 8px'}}>{row?.Producto}</TableCell>
                                                    <TableCell align="center"
                                                               style={{
                                                                   padding: '10px 8px',
                                                                   borderLeft: '1px solid rgba(0, 0, 0, 0.1)'
                                                               }}>{row?.Sector}</TableCell>
                                                    <TableCell align="center"
                                                               style={{padding: '10px 8px'}}>{row?.Cantidad}</TableCell>
                                                    <TableCell align="center"
                                                               style={{padding: '10px 8px', minWidth: '100px'}}>
                                                        $ {number_format(row?.Facturacion, 0, ',', '.')}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        <TableHead>
                                            <TableRow style={{fontSize: '0.8rem'}}>
                                                <TableCell align="center" style={{padding: '10px 8px'}}>Total</TableCell>
                                                <TableCell align="center"
                                                           style={{
                                                               padding: '10px 8px',
                                                               borderLeft: '1px solid rgba(0, 0, 0, 0.1)'
                                                           }}></TableCell>
                                                <TableCell align="center"
                                                           style={{padding: '10px 8px'}}>{analyticsData?.orders_sum?.reduce((a, b) => a + parseInt(b?.Cantidad), 0)}</TableCell>
                                                <TableCell align="center" style={{padding: '10px 8px', minWidth: '100px'}}>
                                                    $ {number_format(analyticsData?.orders_sum?.reduce((a, b) => a + b?.Facturacion, 0), 0, ',', '.')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
};

export default EventAnalytics;