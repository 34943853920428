import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button, CircularProgress, FormControl, InputLabel, MenuItem, Select,
    Snackbar,
    TextField
} from "@mui/material";
import './Whitelist.css';
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {Table} from "react-bootstrap";
import ButtonContainer from "./ButtonContainer";
import {RiDeleteBin6Line, RiFileEditLine, RiFileExcel2Line} from "react-icons/ri";
import {useNavigate} from "react-router";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import FileInput from "./FileInput";
import {IoClose} from "react-icons/io5";
import {FaUpload} from "react-icons/fa";
import {AiOutlinePicture} from "react-icons/ai";

const CreateTicketsImport = (props) => {

    const user = useSelector(selectUser);
    const navigate = useNavigate();

    const [values, setValues] = useState({
        name: '',
        email: '',
        type: 'free',
    });

    const [auxEmail, setAuxEmail] = useState('');
    const [edit, setEdit] = useState(false);

    const [tickets, setTickets] = useState([]);
    const [link, setLink] = useState("");
    const [owner, setOwner] = useState(false);
    const [validData, setValidData] = useState([]);

    const [limits, setLimits] = useState({
        paid: 0,
        free: 0
    })

    const categories = [
        {
            value: 'free',
            label: 'Cortesía',
        },
        {
            value: 'paid',
            label: 'Venta en efectivo',
        },
    ];

    const [manualInput, setManualInput] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [emails, setEmails] = useState([]);

    const swalAlert = (attendee) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, borrarlo!'
        }).then((result) => {
            if (result.isConfirmed) {
                //console.log("Borrando");
            }
        })
    }

    function getViewport () {
        // https://stackoverflow.com/a/8876069
        const width = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
        )

        if (width <= 992) return 'md'
        return 'xl'
    }
    function searchData() {
        // Declare variables
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value;

        if (getViewport() == 'xl') {
            table = document.getElementsByTagName("table")[0];

            tr = table.getElementsByTagName("tr");

            // Loop through all table rows, and hide those who don't match the search query
            for (i = 0; i < tr.length; i++) {
                var td0 = tr[i].getElementsByTagName("td")[0];

                if (td0) {
                    var txtValue0 = td0.textContent || td0.innerText;
                    if (txtValue0.indexOf(filter) > -1) {
                        tr[i].style.display = "";
                    } else {
                        tr[i].style.display = "none";
                    }
                }
            }
        } else {
            table = document.getElementsByTagName("table")[1];

            tr = table.getElementsByTagName("tr");

            // Loop through all table rows, and hide those who don't match the search query
            for (i = 0; i < tr.length; i++) {
                var td0 = tr[i].getElementsByTagName("td")[0];
                if (td0) {
                    var txtValue0 = td0.textContent || td0.innerText;
                    if (txtValue0.indexOf(filter) > -1) {
                        tr[i].style.display = "";
                    } else {
                        tr[i].style.display = "none";
                    }
                }
            }
        }

    }

    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const editEmail = (email) => {
        setAuxEmail(email);
        setValues({ ...values, email: email });
        setEdit(true);
        setManualInput(true);
        try {
            document.getElementById("whitelist-email-input-1").focus();
        } catch (error) {

        }
    }

    const deleteEmail = (email) => {
        setEmails(emails.filter(item => item !== email));
    }

    const editWhitelist = async (e) => {
        e.preventDefault();

        const request = await fetch(`${process.env.REACT_APP_API_URL}/whitelist-group`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                id: props.event,
                name: values.name,
                emails: emails,
            })
        })

        const response = await request.json();

        if (response.status === 'success') {
            setOpenS(true);
            setTimeout(() => {
                navigate('/organizations/whitelist');
            }, 2000);
        }
    }

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/event-tickets-list?event_id=${props.event}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();
        //console.log(json);
        setTickets(json?.tickets.filter(ticket => ticket?.available === 1));
        setOwner(json?.owner);
        setLimits(json?.limits);
        setValues({
            ...values,
            ticketType: json?.tickets.filter(ticket => ticket?.available === 1)[0]?.event_ticket_id
        });
        setIsLoaded(true);
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [SnackbarSuccessMessage, setSnackbarSuccessMessage] = useState('');
    const [SnackbarErrorMessage, setSnackbarErrorMessage] = useState('');

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const handleUpload = (event) => {
        setMessage('');
        setErrorMessage('');

        let file = event.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: 'array' });
            let firstSheet = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[firstSheet];
            let newData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });

            let validData = [];
            let errorData = [];

            for (let row of newData) {
                // Continue to next row if current row is empty
                if (row.length === 0) continue;

                let name = row[0];
                let dni = row[1];
                let email = row[2] ? row[2].trim() : "";  // Remove leading and trailing spaces and handle empty email

                if (typeof dni === "string") {
                    dni = dni.replace(/\./g, '');
                }
                if (isNaN(dni)) {
                    dni = 0;
                } else {
                    dni = parseInt(dni, 10);
                }

                let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (emailRegex.test(email)) {
                    validData.push({ name, dni, email });
                } else {
                    errorData.push({ name, dni, email });
                }
            }

            setValidData([...validData]);
            setMessage(`Se agregarán ${validData.length} nuevos registros.`);

            if (errorData.length > 0) {
                setErrorMessage(`${errorData.length} registros no válidos: ${errorData.map(record => record.email).join(", ")}`);
            }
        };
        reader.readAsArrayBuffer(file);
    };




    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        handleUpload(event);
    };

    const addAttendees = async (e) => {
        e.preventDefault();

        const request = await fetch(`${process.env.REACT_APP_API_URL}/manual-tickets-import`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                event_ticket_id: values.ticketType,
                attendees: validData,
                type: values.type,
            })
        });

        const response = await request.json();

        //console.log(response);

        if (response.status === 'success') {
            setOpenS(true);
            setSnackbarSuccessMessage(response.message);
            setTimeout(() => {
                navigate('/organizations/events/' + props.event + '/attendees');
            }, 2000);
        } else {
            setOpenE(true);
            setSnackbarErrorMessage(response.message);
        }

    }

   useEffect(() => {
       //console.log(validData);
   }, [validData]);

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, []);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {SnackbarSuccessMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {SnackbarErrorMessage}
                </Alert>
            </Snackbar>

            <div className="row">
                <div className="col-12">
                    <h3>Cargar lista por archivo</h3>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-12">
                            <p>Sube tu base de datos en formato Excel, es muy importante que tenga <b>tres columnas</b> que contengan los datos de <b>Nombre Completo</b>, <b>DNI</b> y <b>Email</b> de cada persona y <b>sin ningún encabezado</b>.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-2">
                                <div className="row justify-content-center">
                                    <div className="col-12 mt-3">
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={values.type}
                                                label="Tipo"
                                                onChange={handleChange('type')}
                                                required
                                            >
                                                {categories.map((category, id) => (
                                                    <MenuItem key={id} value={category.value}>{category.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-12 mt-3">
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Tipo de ticket</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={values.ticketType}
                                                label="Tipo de ticket"
                                                onChange={handleChange('ticketType')}
                                                required
                                            >
                                                {tickets.map((category, id) => (
                                                    <MenuItem key={id} value={category.event_ticket_id}>{category.event_ticket_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <label className="h6">Lista en Excel:</label>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 d-flex justify-content-center">
                                        <input
                                            style={{ display: 'none' }}
                                            type="file"
                                            id="hiddenFileInput"
                                            accept=".xlsx"
                                            onChange={onFileChange}
                                        />
                                        <label htmlFor={`hiddenFileInput`} style={{width: "100%", backgroundColor: "whitesmoke"}}>
                                            <Button type="button" style={{width: "100%", height: "200px", border: "solid grey 0.01px"}} variant="raised" component="span">
                                                <div>
                                                    <div className="row text-center">
                                                        <div className="col-12">
                                                            <RiFileExcel2Line size={50} color="grey" />
                                                        </div>
                                                    </div>
                                                    <div className="row text-center">
                                                        <div className="col-12">
                                                            <p style={{color: "grey"}} className="mb-0">Subir archivo</p>
                                                            <small style={{color: "grey"}}>Formato aceptado: xlsx</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Button>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <small>{message}</small>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <small className="text-danger">{errorMessage}</small>
                                    </div>
                                </div>
                                {validData.length > 0 &&
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <h5>Lista de asistentes</h5>
                                    </div>
                                    <div className="col-12 table-sm" style={{maxHeight: "500px", overflowY: "scroll"}}>
                                        <Table striped bordered responsive id="myTable" size="sm">
                                            <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>DNI</th>
                                                <th>Email</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {validData.map((attendee, key) => (
                                                <tr key={key} style={{fontSize: "12px"}}>
                                                    <td style={{wordWrap: "break-word", maxWidth: "150px"}}>{attendee?.name?.toUpperCase()}</td>
                                                    <td>{attendee?.dni}</td>
                                                    <td>{attendee?.email?.trim()?.toLowerCase()}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                }
                                <div className="row mt-5 mb-5">
                                    <div className="col-md-6">
                                        <Button variant="contained" type="button" color="primary" className="me-2" onClick={addAttendees} disabled={!(values.type && values.ticketType)}>
                                            Agregar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 text-center">
                    <img src={"/assets/images/excel-tickets.jpg"} alt={"Excel-Example-Tickets"} className="img-fluid" />
                </div>
            </div>

        </div>
    );
    }
};

export default CreateTicketsImport;
