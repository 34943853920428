import React, { useState, useRef, useEffect } from 'react';

const DropdownMenu = ({ children, anchorEl }) => {
    const [open, setOpen] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const menuRef = useRef(null);
    const anchorRef = useRef(null);

    useEffect(() => {
        if (anchorRef.current) {
            const { top, left, height } = anchorRef.current.getBoundingClientRect();
            setPosition({ top: top + height + window.scrollY, left: left + window.scrollX });
        }

        // Close menu if clicked outside
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target) && anchorRef.current && !anchorRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    const toggleMenu = () => {
        setOpen(!open);
    };

    // Apply hover effect to each child
    const styledChildren = React.Children.map(children, (child, index) => (
        <div key={index} style={menuItemStyle}>
            {child}
        </div>
    ));

    return (
        <div ref={anchorRef}>
            <div onClick={toggleMenu}>
                {anchorEl}
            </div>
            {open && (
                <div ref={menuRef} style={{ ...dropdownMenuStyle, top: position.top, left: position.left }}>
                    {styledChildren}
                </div>
            )}
        </div>
    );
};

const dropdownMenuStyle = {
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: 'white',
    border: '1px solid #ddd',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
    borderRadius: '5px',
    padding: '10px',
    minWidth: '150px',
    maxHeight: '300px',
    overflowY: 'auto',
    textAlign: 'left',
    fontSize: '1.1rem'
};

const menuItemStyle = {
    padding: '5px 0',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#f2f2f2'
    }
};

export default DropdownMenu;
