import React, {useEffect, useState} from 'react';
import {Table} from "react-bootstrap";
import {useNavigate} from "react-router";
import {
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Button, Alert, AlertTitle, Snackbar, Stack, Pagination, InputLabel, Select, FormControl, MenuItem
} from "@mui/material";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {Link} from "react-router-dom";

const Refunds = (props) => {

    const user = useSelector(selectUser)

    const [attendees, setAttendees] = React.useState([]);
    const [refundFilter, setRefundFilter] = React.useState('Todos');

    const [page, setPage] = React.useState(1);
    const [totalRows, setTotalEvents] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [loadingSearch, setLoadingSearch] = React.useState(false);
    const [defaultRefunds, setDefaultRefunds] = React.useState([]);
    const [initialLoad, setInitialLoad] = useState(true);



    const [open, setOpen] = React.useState(false);
    const [reason, setReason] = React.useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleOpenConfirm = () => setOpenConfirm(true);
    const [activeExternalReference, setActiveExternalReference] = React.useState(null);
    const handleCloseConfirm = () => setOpenConfirm(false);

    const openReason = (reason) => {
        setOpen(true);
        setReason(reason);
    }

    const confirmRefund = (external_reference) => {
        setActiveExternalReference(external_reference);
        setOpenConfirm(true);
    }

    const fetchData = async (signal) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/refunds-list?offset=${page}&search=${search}&refundFilter=${refundFilter}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            signal,
        });
        const json = await response.json();
        //console.log(json);
        setTotalEvents(json.total);
        setAttendees(json.attendees);
        setLoadingSearch(false);
        if (json.attendees.length > 0) {
            setInitialLoad(false);
        }
        setIsLoaded(true);
    }

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        if (loadingSearch) {
            fetchData(signal);
        }

        return () => {
            controller.abort();
        };
    }, [loadingSearch]);

    useEffect(() => {
        setLoadingSearch(true);
    }, [user.token, search, page, refundFilter]);

    const handleRefund = async (external_reference) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/refund-api`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                external_reference
            })
        });

        const response = await request.json();

        //console.log(response);

        if (response.status == 'success') {
            setOpenS(true);
            setSuccessMessage(response.message);
            setIsLoaded(false);
            fetchData();
            handleCloseConfirm();
        }
        else {
            setOpenE(true);
            setErrorMessage(response.message);
            handleCloseConfirm();
        }
    }

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };


    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [isLoaded, setIsLoaded] = useState(false);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <div className="row mb-3">
                <div className="col">
                    <h1>Gestionar devoluciones</h1>
                </div>
            </div>
            {(attendees.length > 0 || !initialLoad) ? (
                <>
                    <div className='row mb-3'>
                        <div className='col'>
                            <TextField
                                id='myInput'
                                fullWidth
                                onChange={handleSearch}
                                value={search}
                                label='Buscar por ID de compra o Nombre'
                                variant='outlined'
                            />
                        </div>
                        <div className='col'>
                            <FormControl fullWidth>
                                <InputLabel id='refund-filter-label'>Estado del reembolso</InputLabel>
                                <Select
                                    labelId='refund-filter-label'
                                    id='refund-filter'
                                    value={refundFilter}
                                    label='Estado del reembolso'
                                    onChange={(e) => setRefundFilter(e.target.value)}
                                >
                                    <MenuItem value='Todos'>Todos</MenuItem>
                                    <MenuItem value='Reembolsadas'>Reembolsados</MenuItem>
                                    <MenuItem value='Pendientes'>No reembolsados</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    {(!loadingSearch) ? (
                        <>
                            {attendees.length > 0 ? (
                                <>
                                    <div className="row">
                                        <div className="col-12 table-sm" style={{maxHeight: "80vh", overflowY: "scroll"}}>
                                            <Table striped bordered responsive id="myTable" size="sm">
                                                <thead>
                                                <tr>
                                                    <th>ID de Compra</th>
                                                    <th>Nombre</th>
                                                    <th>Evento</th>
                                                    <th>Valor total</th>
                                                    <th>Motivo</th>
                                                    <th>Acciones</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {attendees.map((attendee, key) => (
                                                    <tr key={key} style={{fontSize: "12px"}}>
                                                        <td>{attendee.external_reference}</td>
                                                        <td style={{wordWrap: "break-word", maxWidth: "150px"}}>{attendee.name.toUpperCase()}</td>
                                                        <td style={{wordWrap: "break-word", maxWidth: "150px"}}>{attendee.event_name.toUpperCase()}</td>
                                                        <td>$ {attendee.suma}</td>
                                                        <td>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <Button className="w-100" onClick={() => openReason(attendee.reason)} variant="outlined">Ver Motivo</Button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {attendee.refunded === 0 ? (
                                                                <Button onClick={() => confirmRefund(attendee.external_reference)} className="w-100" variant="contained" color="error">Reembolsar</Button>
                                                            ) : (
                                                                <Button disabled className="w-100" variant="contained" color="error">Reembolsado</Button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </>
                            ) : initialLoad ? (
                                <div className="d-flex justify-content-center align-items-start" style={{ marginTop: '10vh' }}>
                                    <Alert severity="warning">
                                        <AlertTitle>No hay solicitudes de devolución</AlertTitle>
                                        Las solicitudes aparecerán en esta sección cuando se generen
                                    </Alert>
                                </div>
                            ) : (
                                <div className="d-flex justify-content-center align-items-start" style={{ marginTop: '10vh' }}>
                                    <Alert severity="warning">
                                        <AlertTitle>No se encontraron resultados</AlertTitle>
                                        No se encontraron resultados para la búsqueda realizada
                                    </Alert>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="row">
                            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                                <CircularProgress color="secondary" size={100} />
                            </div>
                        </div>
                    )}
                    {(attendees.length > 0 && !loadingSearch) && (
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center mb-5">
                                <Stack spacing={2}>
                                    <Pagination count={Math.ceil(totalRows / 10)} variant="outlined" shape="rounded" page={page} onChange={(event, value) => setPage(value)} />
                                </Stack>
                            </div>
                        </div>
                    )}
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            {"Motivo de la solicitud"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {reason}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} autoFocus>
                                Cerrar
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openConfirm}
                        onClose={handleCloseConfirm}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            {"¿Estás seguro de que deseas reembolsar esta compra?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Esta acción no se puede deshacer.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus className="me-2" onClick={handleCloseConfirm}>
                                Cancelar
                            </Button>
                            <Button variant="contained" color="error" onClick={() => handleRefund(activeExternalReference)} autoFocus>
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            ) : (
                <div className="d-flex justify-content-center align-items-start" style={{marginTop: "10vh"}}>
                    <Alert severity="warning">
                        <AlertTitle>No hay solicitudes de devolución</AlertTitle>
                        Las solicitudes aparecerán en esta sección cuando se generen
                    </Alert>
                </div>
            )}
        </div>
    );
    }
};

export default Refunds;