import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button, CircularProgress,
    Snackbar,
    TextField
} from "@mui/material";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import MultipleSelectColaborators from "./MultipleSelectColaborators";
import {useNavigate} from "react-router";
import MultipleAutocompleteColaborators from "./MultipleAutocompleteColaborators";

const PromoterEdit = (props) => {

    const user = useSelector(selectUser);
    const navigate = useNavigate();

    const [values, setValues] = useState({
        id: '',
        name: '',
        colaborators: [],
    });
    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const updatePromoter = async (e) => {
        e.preventDefault();
        setIsLoaded(false);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/promoter-groups`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                "name": values.name,
                "colaborators": values.colaborators.map((colaborator) => colaborator.id),
                "id": values.id
            }),
        });

        const json = await response.json();

        if (json.status === "success") {
            setOpenS(true);
            setTimeout(() => {
                navigate('/organizations/team/groups');
            }, 2000);
        }
        else {
            setOpenE(true);
        }
        setIsLoaded(true);

    }

    const [colaborators, setColaborators] = useState([]);

    const changeColaborators = (newColaborators) => {
        setValues({...values, colaborators: newColaborators});
    }

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/promoter-groups?id=${props.promoter}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();
        setValues({...values, ...json.data});

        const response2 = await fetch(`${process.env.REACT_APP_API_URL}/rrpp-producer-list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json2 = await response2.json();
        //console.log(json.events);
        setColaborators(json2.events);
        setIsLoaded(true);
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const [isLoaded, setIsLoaded] = useState(false);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    Grupo de colaboradores actualizado con éxito!
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    Ocurrió un error al actualizar el grupo de colaboradores!
                </Alert>
            </Snackbar>

            <div className="row">
                <div className="col">
                    <h1>Editar grupo de colaboradores</h1>
                </div>
            </div>
            <form onSubmit={updatePromoter}>
                <div className="row">
                    <div className="col-md-6">
                        <TextField label="Nombre" value={values.name} onChange={handleChange('name')} className="mb-3" fullWidth variant="outlined" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <MultipleAutocompleteColaborators tag="Colaboradores seleccionados" values={values.colaborators} events={colaborators} onChange={changeColaborators} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <Button variant="contained" type="submit" color="primary" className="me-2">
                            Guardar
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
    }
};

export default PromoterEdit;
