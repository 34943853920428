import React, {useEffect, useState, useRef} from 'react';
import Title from "../components/Title";
import EventContainer from "../components/EventContainer";
import {
    Alert,
    Button,
    CircularProgress, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Stack, Pagination, TextField, InputAdornment, Input, FormControl, InputLabel, OutlinedInput, Snackbar
} from "@mui/material";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {setEventChange} from "../redux/eventsChangeSlice";
import {IoClose} from "react-icons/io5";
import {Button as ButtonBT} from "react-bootstrap";
import {BsSearch} from "react-icons/bs";
import {LabelOutlined} from "@mui/icons-material";

const Events = () => {

    const user = useSelector(selectUser);

    const [events, setEvents] = React.useState([]);
    const [producer, setProducer] = React.useState(null);
    const [profileType, setProfileType] = React.useState(null);
    const navigate = useNavigate();

    const [page, setPage] = React.useState(1);
    const [totalEvents, setTotalEvents] = React.useState(0);

    const [search, setSearch] = React.useState("");

    const searchValueRef = useRef(search);
    const searchRequestRef = useRef(null);


    const [dialogContent, setDialogContent] = React.useState({
        title: "Todavía no registraste tu organización",
        content: "Para poder crear eventos, primero tenés que registrar tu organización.",
        button: {
            text: "Registrar organización",
            click: () => navigate("/organizations/settings"),
        }
    });

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleOpenConfirm = () => setOpenConfirm(true);
    const handleCloseConfirm = () => setOpenConfirm(false);

    const fetchData = async (search, page, signal) => {
        let response;
        const url = `${process.env.REACT_APP_API_URL}/event-list?offset=${page}&search=${search}`;
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${user.token}`,
            'credentials': 'same-origin',
        };
        response = await fetch(url, {
            method: 'GET',
            headers,
            signal: signal,
        });
        const json = await response.json();
        setEvents(json.events);
        setProducer(json.producer_id);
        setProfileType(json.profile_type);
        setTotalEvents(json.total);
        setIsLoaded(true);
        setLoadingSearch(false);
    }

    const createEvent = async () => {
        if (producer) {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/event-details`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                },
                body: JSON.stringify({
                    "event_active": 4,
                })
            });
            const json = await request.json();
            //console.log(json);
            setIsLoaded(false);

            if (json.status === "success") {
                navigate(`/organizations/events/${json.event_id}/info`);
            }
        } else if(profileType !== 1) {
            setDialogContent({
                title: "No tenes un perfil de Productora",
                content: "Para poder crear eventos, primero tenés que tener un perfil de Productora.",
                button: {
                    text: "Cambiar Perfil",
                    click: () => navigate("/organizations/profile"),
                }
            })
            setOpenConfirm(true);
        } else {
            setOpenConfirm(true);
        }
    };

    const [isLoaded, setIsLoaded] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);

    useEffect(() => {
        if (user.token) {
            searchValueRef.current = search;
            if (searchRequestRef.current) {
                searchRequestRef.current.abort();
            }
            const controller = new AbortController();
            searchRequestRef.current = controller;

            setLoadingSearch(true);
            setPage(1); // Set page to 1 when a new search is initiated

            const requests = setTimeout(() => {
                fetchData(search, 1, controller.signal)
                    .catch((error) => {
                        if (error.name !== 'AbortError') {
                            console.error('Error fetching data:', error);
                        }
                    })
                    .finally(() => {
                        setLoadingSearch(false);
                    });
            }, 300);

            return () => {
                clearTimeout(requests);
                controller.abort();
            };
        }
    }, [search]);

    useEffect(() => {
        if (user.token && !loadingSearch) {
            const controller = new AbortController();
            setIsLoaded(false);
            fetchData(searchValueRef.current, page, controller.signal) // Fetch data with current search query when page changes
                .catch((error) => {
                    if (error.name !== 'AbortError') {
                        console.error('Error fetching data:', error);
                    }
                });
            return () => {
                controller.abort();
            };
        }
    }, [page, user.token]);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <>
        <div className="row mt-2 align-items-center">
            <div className="col-auto">
                <h1>Eventos</h1>
            </div>
            <div className="col">
                <div className="row justify-content-end">
                    <div className="col-auto">
                        <ButtonBT variant="outline-dark" style={{float: "right"}} type={"button"} onClick={() => navigate("./groups")}>Agrupar eventos</ButtonBT>
                    </div>
                    <div className="col-auto create-event-button">
                        <ButtonBT variant="outline-dark" style={{float: "right"}} type={"button"} onClick={createEvent}>Crear evento</ButtonBT>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <TextField
                    label="Buscar eventos"
                    id="outlined-start-adornment"
                    fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><BsSearch /></InputAdornment>,
                    }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
        </div>
        <div className="row mb-5">
            <div className="col-12">
                {loadingSearch ? (
                        <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                            <CircularProgress color="secondary" size={100} />
                        </div>
                    ) : (
                        <>
                            {events.length > 0 && events.map((event, key) => (
                                <>
                                    <EventContainer key={key} event={event} setIsLoaded={setIsLoaded} />
                                </>
                            ))}
                        </>
                )}
                {(events.length === 0 && isLoaded && !loadingSearch) && (
                    <div className="col-12 d-flex justify-content-center mt-3">
                        <Alert variant="standard" severity="info">
                            {
                                search ? "No se encontraron resultados para la búsqueda: " + search : "No tenés ningún evento creado."
                            }
                        </Alert>
                    </div>
                )}
            </div>
        </div>
        {events.length > 0 && (
            <div className="row">
                <div className="col-12 d-flex justify-content-center mb-5">
                    <Stack spacing={2}>
                        <Pagination count={Math.ceil(totalEvents / 10)} variant="outlined" shape="rounded" page={page} onChange={(event, value) => setPage(value)} />
                    </Stack>
                </div>
            </div>
        )}
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="responsive-dialog-title"
            >
                <div className="closeIconLight p-1 align-items-center d-flex justify-content-center">
                    <IoClose size={25} color={"rgba(0, 0, 0, 0.38)"} onClick={handleCloseConfirm}/>
                </div>
                <DialogTitle id="responsive-dialog-title">
                    {dialogContent.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className="row">
                            <div className="col-12">
                                <p>{dialogContent.content}</p>
                            </div>
                        </div>
                        <div className="row text-center mt-2">
                            <div className="col-12">
                                <Button variant="contained" color="secondary" onClick={dialogContent.button.click}>{dialogContent.button.text}</Button>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
    }
};

export default Events;
