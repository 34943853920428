import React, {useState} from 'react';
import {Alert, CircularProgress, Snackbar, TextField} from "@mui/material";
import {setEventChange} from "../redux/eventsChangeSlice";
import {Button} from "react-bootstrap";
import {selectUser} from "../redux/userSlice";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";
import MenuContainerAdmin from "./MenuContainerAdmin";

const VenueTables = (props) => {
    const navigate = useNavigate();

    const [menus, setMenus] = React.useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const token = localStorage.getItem('token');

    const fetchData = async () => {
        setIsLoaded(false);
        const request = await fetch(`${process.env.REACT_APP_API_URL}/tables?venue_id=${props.venue}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        const data = await request.json();

        if (data.status === "success") {
            setMenus(data.data);
            setIsLoaded(true);
        }

    }

    const deleteTable = async (id) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/venue`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                venue_id: props.venue,
                table_id: id
            }),
        });

        const response = await request.json();

        if (response.status === 'success') {
            fetchData();
        }
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [token]);

    return (
        <>
            {!isLoaded ? (
                <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                    <CircularProgress color="secondary" size={100} />
                </div>
            ) : (
                <div>
                    <div className="row mt-2 justify-content-between align-items-center">
                        <div className="col">
                            <h3>Mesas</h3>
                        </div>
                        <div className="col-auto">
                            <div className="row justify-content-end">
                                <div className="col-12">
                                    <Button variant="dark" onClick={() => navigate("./add")} className="float-right">+ Crear mesa</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            {menus?.length > 0 ? (
                                <MenuContainerAdmin delete={deleteTable} page={"tables"} rows={menus} />
                            ) : (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-info" role="alert">
                                            No hay mesas creadas.
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default VenueTables;