import React, {useState} from 'react';
import {
    Alert,
    CircularProgress, Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    TextField
} from "@mui/material";
import {setEventChange} from "../redux/eventsChangeSlice";
import {Button} from "react-bootstrap";
import {Button as ButtonMUI} from "@mui/material";
import {selectUser} from "../redux/userSlice";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";
import MenuContainerAdmin from "./MenuContainerAdmin";
import MenuBalancesTable from "./MenuBalancesTable";
import {MdUpload} from "react-icons/md";
import {IoClose} from "react-icons/io5";

const DigitalMenuBalance = (props) => {
    const navigate = useNavigate();

    const [menus, setMenus] = React.useState([]);
    const [activeBalance, setActiveBalance] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false);
    const token = localStorage.getItem('token');

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleOpenConfirm = (id) => {
        setOpenConfirm(true);
        setActiveBalance(id);
    }
    const handleCloseConfirm = () => setOpenConfirm(false);

    const fetchData = async () => {
        setIsLoaded(false);
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-events?menu_id=${props.menu}&event_id=${props.event}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        const data = await request.json();

        if (data.status === "success") {
            setMenus(data.balances);
            setIsLoaded(true);
        }

    }


    const deleteBalance = async (id) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-balance`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                menu_id: props.menu,
                balance_id: id
            }),
        });

        const response = await request.json();

        if (response.status === 'success') {
            fetchData();
        }
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [token]);

    return (
        <>
            {!isLoaded ? (
                <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                    <CircularProgress color="secondary" size={100} />
                </div>
            ) : (
                <div>
                    <div className="row mt-2 justify-content-between align-items-center">
                        <div className="col">
                            <h3>Saldos</h3>
                        </div>
                        <div className="col-auto">
                            <div className="row justify-content-end">
                                <div className="col-auto d-none d-md-block">
                                    <Button variant="dark" onClick={() => navigate("./transactions/upload")} className="float-right">
                                        Importar transacciones</Button>
                                </div>
                                <div className="col">
                                    <Button variant="dark" onClick={() => navigate("./add")} className="float-right">+
                                        Cargar saldos</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            {menus?.length > 0 ? (
                                <MenuBalancesTable delete={handleOpenConfirm} rows={menus} page={"balances"} />
                            ) : (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-info" role="alert">
                                            No hay información de saldos.
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"¿Estás seguro que querés eliminar este evento?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Esta acción no se puede deshacer.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonMUI style={{color: "gray"}} autoFocus className="me-2" onClick={handleCloseConfirm}>
                        Cancelar
                    </ButtonMUI>
                    <ButtonMUI style={{color: "#d32f2f"}} onClick={() => {
                        deleteBalance(activeBalance);
                        handleCloseConfirm();
                    }} autoFocus>
                        Confirmar
                    </ButtonMUI>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DigitalMenuBalance;