import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button, CircularProgress,
    Snackbar,
} from "@mui/material";
import './Whitelist.css';
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {Table} from "react-bootstrap";
import {RiFileExcel2Line} from "react-icons/ri";
import {useNavigate} from "react-router";
import * as XLSX from "xlsx";

const DigitalMenuBalanceUpload = (props) => {

    const user = useSelector(selectUser);
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(true);

    const [values, setValues] = useState({
        rules: [],
    });

    const [validData, setValidData] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [SnackbarSuccessMessage, setSnackbarSuccessMessage] = useState('');
    const [SnackbarErrorMessage, setSnackbarErrorMessage] = useState('');

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const handleUpload = (event) => {
        setMessage('');
        setErrorMessage('');

        let file = event.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: 'array' });
            let firstSheet = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[firstSheet];
            let newData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });

            let validData = [];
            let errorData = [];

            for (let row of newData) {
                if (row.length === 0) continue; // Skip empty rows

                let tableNumber = parseInt(row[0], 10);
                let date = row[1] || null;
                let concept = row[2] || null;
                let rawAmount = row[3] || null;
                let paymentMethod = row[4] || null;
                let payerId = row[5] || null;
                let payerName = row[6] || null;

                // Cleanup and convert amount
                if (rawAmount) {
                    let amountString = rawAmount.toString();
                    // Remove any currency symbols
                    amountString = amountString.replace(/[^\d,.-]/g, '');

                    // Detect and convert using the correct decimal separator
                    let lastDotIndex = amountString.lastIndexOf('.');
                    let lastCommaIndex = amountString.lastIndexOf(',');
                    if (lastCommaIndex > lastDotIndex) {
                        // Comma is used as decimal separator
                        amountString = amountString.replace(/\./g, '').replace(',', '.');
                    } else {
                        // Dot is used as decimal separator
                        amountString = amountString.replace(/,/g, '');
                    }
                    let amount = parseFloat(amountString);

                    // Validate table number as a positive integer
                    if (!Number.isInteger(tableNumber) || tableNumber <= 0) {
                        errorData.push({ tableNumber, date, concept, amount, paymentMethod, payerId, payerName });
                        continue;
                    }

                    // Validate date and format it to yyyy-mm-dd
                    let dateRegex = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/(\d{2}|\d{4})$/;
                    let match = date.match(dateRegex);
                    if (match) {
                        let day = match[1];
                        let month = match[2];
                        let year = match[3].length === 2 ? `20${match[3]}` : match[3];
                        date = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
                    } else {
                        errorData.push({ tableNumber, date, concept, amount, paymentMethod, payerId, payerName });
                        continue; // Skip if date does not match the required format
                    }

                    // Validate amount
                    if (!(amount > 0)) {
                        errorData.push({ tableNumber, date, concept, amount, paymentMethod, payerId, payerName });
                        continue;
                    }

                    // Validate payment method
                    const validMethods = ["bank_transfer", "cash", "credit_card", "debit_card", "qr_code"];
                    if (!validMethods.includes(paymentMethod)) {
                        errorData.push({ tableNumber, date, concept, amount, paymentMethod, payerId, payerName });
                        continue;
                    }

                    // Data is valid, add to validData
                    validData.push({ tableNumber, date, concept, amount, paymentMethod, payerId, payerName });
                } else {
                    errorData.push({ tableNumber, date, concept, amount: null, paymentMethod, payerId, payerName });
                }
            }

            setValidData([...validData]);
            setMessage(`Se agregarán ${validData.length} nuevos registros.`);

            if (errorData.length > 0) {
                setErrorMessage(`${errorData.length} registros no válidos.`);
            }
        };
        reader.readAsArrayBuffer(file);
    };



    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        handleUpload(event);
    };

    const addAttendees = async (e) => {
        e.preventDefault();
        setDisabledButton(true);

        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-user-transactions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                transactions: validData.map(row => ({
                    table_number: row.tableNumber,
                    date: row.date,
                    concept: row.concept,
                    amount: row.amount,
                    payment_type: row.paymentMethod,
                    additionalData: {
                        payer_id: row.payerId,
                        payer_name: row.payerName,
                    }
                })),
                insert_method: "upload",
                menu_id: props.menu,
                event_id: props.event,
            })
        });

        try {
            const response = await request?.json();

            //console.log(response);

            if (response?.status === 'success') {
                setOpenS(true);
                setSnackbarSuccessMessage(response.message);
            } else {
                setOpenE(true);
                setSnackbarErrorMessage(response.message);
            }
            setDisabledButton(false);
        } catch (error) {
            setOpenE(true);
            setSnackbarErrorMessage('Error al cargar las transacciones.');
            setDisabledButton(false);
        }
    }

   useEffect(() => {
       //console.log(validData);
   }, [validData]);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{width: '100%'}}>
                    {SnackbarSuccessMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{width: '100%'}}>
                    {SnackbarErrorMessage}
                </Alert>
            </Snackbar>

            <div className="row">
                <div className="col-12">
                    <h3>Cargar transacciones por archivo</h3>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-12">
                            <p>Sube tu base de datos en formato Excel, es muy importante que tenga las primeras <b>cinco
                                columnas</b> que contengan los datos de <b>Nro de
                                mesa</b>, <b>Fecha</b>, <b>Concepto</b>, <b>Monto</b>, <b>Método de pago</b> de cada
                                transacción de
                                mesa y <b>sin ningún encabezado</b>.</p>
                            <p>Se pueden agregar opcionalmente dos columnas
                                adicionales para registrar el <b>DNI</b> y <b>Nombre</b> de quién realizó la
                                transacción.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-2">
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <label className="h6">Lista en Excel:</label>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 d-flex justify-content-center">
                                        <input
                                            style={{display: 'none'}}
                                            type="file"
                                            id="hiddenFileInput"
                                            accept=".xlsx"
                                            onChange={onFileChange}
                                        />
                                        <label htmlFor={`hiddenFileInput`}
                                               style={{width: "100%", backgroundColor: "whitesmoke"}}>
                                            <Button type="button" style={{
                                                width: "100%",
                                                height: "200px",
                                                border: "solid grey 0.01px"
                                            }} variant="raised" component="span">
                                                <div>
                                                    <div className="row text-center">
                                                        <div className="col-12">
                                                            <RiFileExcel2Line size={50} color="grey"/>
                                                        </div>
                                                    </div>
                                                    <div className="row text-center">
                                                        <div className="col-12">
                                                            <p style={{color: "grey"}} className="mb-0">Subir
                                                                archivo</p>
                                                            <small style={{color: "grey"}}>Formato aceptado:
                                                                xlsx</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Button>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <small>{message}</small>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <small className="text-danger">{errorMessage}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 text-center d-none d-lg-block">
                    <img src={"/assets/images/example_upload_transactions.jpg"} alt={"Excel-Example-Transactions"}
                         className="img-fluid"/>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {validData.length > 0 &&
                        <div className="row mt-3">
                            <div className="col-12">
                                <h5>Lista de transacciones</h5>
                            </div>
                            <div className="col-12 table-sm" style={{maxHeight: "500px", overflowY: "scroll"}}>
                                <Table striped bordered responsive id="myTable" size="sm">
                                    <thead>
                                    <tr>
                                        <th>Mesa</th>
                                        <th>Fecha</th>
                                        <th>Concepto</th>
                                        <th>Monto</th>
                                        <th>Método de pago</th>
                                        <th>DNI</th>
                                        <th>Nombre cliente</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {validData.map((attendee, key) => (
                                        <tr key={key} style={{fontSize: "12px"}}>
                                            <td style={{
                                                wordWrap: "break-word",
                                                maxWidth: "150px"
                                            }}>{attendee?.tableNumber}</td>
                                            <td>{attendee?.date}</td>
                                            <td>{attendee?.concept}</td>
                                            <td>{attendee?.amount}</td>
                                            <td>{attendee?.paymentMethod}</td>
                                            <td>{attendee?.payerId}</td>
                                            <td>{attendee?.payerName}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="row mt-5 mb-5">
                <div className="col-md-6">
                    <Button variant="contained" type="button" color="primary" className="me-2" onClick={addAttendees}
                            disabled={validData.length <= 0 || disabledButton}>
                        Cargar Transacciones
                    </Button>
                </div>
            </div>

        </div>
    );
    }
};

export default DigitalMenuBalanceUpload;
