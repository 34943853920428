import React, { useEffect, useState } from 'react';
import { CircularProgress, TextField, InputAdornment } from "@mui/material";
import { Button } from "react-bootstrap";
import Swal from 'sweetalert2';
import {useNavigate} from "react-router";

const DigitalMenuCurrencies = (props) => {

    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const [currencies, setCurrencies] = useState([]);
    const [originalCurrency, setOriginalCurrency] = useState('');
    const token = localStorage.getItem('token');

    const fetchData = async () => {
        setIsLoaded(false);
        try {
            const request = await fetch(`${process.env.REACT_APP_API_V2_URL}/menus/currencies/${props.menu}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            });

            const response = await request.json();
            if (response.status === "success") {
                setCurrencies(response.data);
                setOriginalCurrency(response.baseCurrency);
            }
        } catch (error) {
            console.error("Error fetching currencies:", error);
        } finally {
            setIsLoaded(true);
        }
    };

    const handleInputChange = (id, value) => {
        setCurrencies(currencies.map(currency =>
            currency.id === id ? { ...currency, exchangeRate: value } : currency
        ));
    };

    const submitData = async () => {
        try {
            const request = await fetch(`${process.env.REACT_APP_API_V2_URL}/menus/currencies`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify({ currencies, menuId: props.menu })
            });

            const response = await request.json();
            if (response.status === "success") {
                Swal.fire('Actualizado', response.message, 'success');
            } else {
                Swal.fire('Error', response.message, 'error');
            }
        } catch (error) {
            console.error("Error submitting data:", error);
            Swal.fire('Error', 'Error al enviar los datos', 'error');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        Swal.fire({
            title: '¿Estás seguro?',
            text: "Se actualizarán los valores de las monedas",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, guardar',
            cancelButtonText: 'No, cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                submitData();
            }
        });
    };

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [token]);

    return (
        <>
            {!isLoaded ? (
                <div className="d-flex justify-content-center align-items-center" style={{ marginTop: "25vh" }}>
                    <CircularProgress color="secondary" size={100} />
                </div>
            ) : (
                <div>
                    <div className="row mt-2 justify-content-between align-items-center">
                        <div className="col">
                            <h3>Monedas (Base: {originalCurrency})</h3>
                        </div>
                        <div className="col-auto">
                            <div className="row justify-content-end">
                                <div className="col">
                                    <Button variant="dark" type="button" onClick={() => navigate('./add')}>
                                        + Añadir moneda
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        {currencies.map(currency => (
                            <div className="row mt-3" key={currency.id}>
                                <div className="col-lg-6">
                                    <TextField
                                        fullWidth
                                        label={currency.label}
                                        type="number"
                                        variant="outlined"
                                        value={currency.exchangeRate}
                                        onChange={(e) => handleInputChange(currency.id, e.target.value)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{originalCurrency}</InputAdornment>,
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                        <div className="row">
                            <div className="col-12">
                                <Button variant="dark" type="submit" className="mt-3">
                                    Actualizar
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};

export default DigitalMenuCurrencies;
