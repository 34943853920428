import React, {useState} from 'react';
import {
    Button,
    CircularProgress,
    TextField
} from "@mui/material";
import './inputNumber.css';
import {BsBank} from "react-icons/bs";
import DialogBankForm from "./DialogBankForm";
import AlertSnackbar from "./AlertSnackbar";
import {AiOutlineEdit} from "react-icons/ai";
import Chip from "@mui/material/Chip";
import './creatorSettings.css'
import {FaUserEdit} from "react-icons/fa";
import ButtonContainer from "./ButtonContainer";
import TooltipButton from "./TooltipButton";
import {MdDeleteOutline} from "react-icons/md";
import TooltipDelete from "./TooltipDelete";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";

const CreatorSettings = () => {

    const user = useSelector(selectUser)

    const [values, setValues] = useState({
        cuit: '',
        email: '',
        phone: '',
        razonSocial: '',
        banks: [],
    });

    const [cuitProfile, setCuitProfile] = useState(true);

    /* Snackbars */

    const [openSnackOk, setOpenSnackOk] = useState(false);
    const [openSnackError, setOpenSnackError] = useState(false);

    const [errorMessage, setErrorMessage] = useState("Hubo un error al guardar los datos.");
    const [successMessage, setSuccessMessage] = useState("Los datos se guardaron correctamente.");

    const handleCloseOk = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackOk(false);
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackError(false);
    }

    /* Snackbars */

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    }

    const handleActivate = async (mp_id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/bank-accounts?id=${mp_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();

        if (json.success === "1") {
            setOpenSnackOk(true);
            setIsLoaded(false);
        }
        if (json.success === "0") {
            setOpenSnackError(true);
        }
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (cuitProfile) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/producer-data`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                },
                body: JSON.stringify(values)
            });
            const json = await response.json();

            if (json.success === "1") {
                setSuccessMessage(json.message);
                setOpenSnackOk(true);
            }
            if (json.success === "0") {
                setOpenSnackError(true);
            }
        }
        else {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/producer-data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                },
                body: JSON.stringify(values)
            });
            const json = await response.json();

            //console.log(json);

            if (json.success === "1") {
                setSuccessMessage(json.message);
                setOpenSnackOk(true);
                setCuitProfile(true);
            }
            if (json.success === "0") {
                setErrorMessage(json.message);
                setOpenSnackError(true);
            }
        }
    }

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/producer-data`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();
        //console.log(json);
        setValues(json.producer);

        if (json.status === "success") {
            if (!json.producer.cuit) {
                setCuitProfile(false);
            }
        }
        setIsLoaded(true);
    }

    const bankLabel = (bank) => {
        if (bank === 1) {
            return "Cuenta Corriente"
        }
        else if (bank === 2) {
            return "Caja de Ahorro"
        }
        else if (bank === 4) {
            return "Cuenta Virtual"
        }
    }

    const [isLoaded, setIsLoaded] = useState(false);

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);;

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <>
            <AlertSnackbar open={openSnackOk} handleClose={handleCloseOk} severity="success" message={successMessage} />
            <AlertSnackbar open={openSnackError} handleClose={handleCloseError} severity="error" message={errorMessage} />
            <div className="row">
                <div className="col-12">
                    <h1>Configuración</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="mb-3">
                                <h5 className="card-title">Información de la cuenta</h5>
                                <small className="text-muted">Esta información es privada y no será mostrada a los visitantes.</small>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <form onSubmit={submitForm}>
                                        <div className="mb-3">
                                            <TextField label="Razón Social / Nombre Completo" type="text" variant="outlined" fullWidth value={values.razonSocial} onChange={handleChange('razonSocial')} />
                                        </div>
                                        <div className="mb-3">
                                            {cuitProfile ? (
                                            <TextField label="CUIT" type="text" variant="outlined" fullWidth value={values.cuit} onChange={handleChange('cuit')} disabled />
                                            ) : (
                                            <TextField label="CUIT" type="text" variant="outlined" fullWidth value={values.cuit} onChange={handleChange('cuit')} />
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <TextField label="Correo electrónico" type="email" variant="outlined" fullWidth value={values.email} onChange={handleChange('email')} />
                                        </div>
                                        <div className="mb-3">
                                            <TextField label="Teléfono" type="number" onWheel={(e) => e.preventDefault()}  variant="outlined" fullWidth value={values.phone} onChange={handleChange('phone')} />
                                        </div>
                                        {cuitProfile ? (
                                            <>
                                                <div className="mb-3">
                                                    <small className="text-muted">Si necesitas cambiar el CUIT contactate con nosotros a info@flashpass.com.ar para resolverlo.</small>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="mb-3">
                                                <small className="text-muted">Al registrar el productor estas aceptando nuestros <a href={"https://flashpass.com.ar/terminos-y-condiciones-productor"} target={"_blank"}
                                                                                                                                     className="text-decoration-underline text-muted">términos y condiciones para el productor.</a></small>
                                            </div>
                                        )}
                                        {cuitProfile ? (
                                            <button type="submit" className="btn btn-primary">Guardar</button>
                                        ) : (
                                            <button type="submit" className="btn btn-primary">Registrar Productor</button>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mt-5 mt-md-0 mb-4 mb-md-0">
                    <div className="card">
                        <div className="card-body">
                            <div className="mb-3">
                                <h5 className="card-title">Cuentas Bancarias</h5>
                                <small className="text-muted">Recibirás los fondos en la cuenta activa.</small>
                            </div>
                            {values.banks.length <= 0 &&
                            <div className="row text-center">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-center">
                                            <div className="p-3 rounded-circle d-flex align-items-center justify-content-center" style={{backgroundColor: "whitesmoke", height: "5rem", width: "5rem"}}>
                                                <BsBank size={40} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mt-3 d-flex align-items-center justify-content-center">
                                            <h6>Todavía no agregaste una cuenta bancaria</h6>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-center">
                                            <small className="text-muted">Para recibir fondos de tu evento, agregá una cuenta bancaria.</small>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 d-flex align-items-center justify-content-center">
                                            <button className="btn btn-primary" onClick={handleOpen}>Agregar cuenta bancaria</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {values.banks.length > 0 ? values.banks.map((bank, index) => (
                            <div key={bank.id} className="row mb-2" style={{backgroundColor: "whitesmoke", padding: ".5rem .25rem", borderRadius: "10px"}}>
                                <div className="col">
                                    <p className="m-0">{bank.bankName}</p>
                                    <small className="text-muted">{bankLabel(bank.bankType)}</small>
                                </div>
                                <div className="col-auto d-flex defaultChip align-items-start justify-content-end">
                                    {bank.active === 1 ? <Chip className="me-2" label="ACTIVA" variant={"outlined"} color="success" /> : (
                                        <Button onClick={() => handleActivate(bank.id)} className="me-2" variant="text" color="primary" style={{fontSize: "12px"}}>Activar</Button>
                                    )}
                                    <TooltipButton done={setIsLoaded} values={bank} title="Editar" icon={<AiOutlineEdit size={18} />} />
                                    <div className="ms-2 me-0 pe-0">
                                        <TooltipDelete done={setIsLoaded} id={bank.id} title="Eliminar" icon={<MdDeleteOutline size={18} />} />
                                    </div>
                                </div>
                            </div>
                            )) : null}
                            {values.banks.length > 0 &&
                            <div className="row mt-3">
                                <div className="col-12 d-flex align-items-center justify-content-center">
                                    <button className="btn btn-primary" onClick={handleOpen}>Agregar cuenta bancaria</button>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <DialogBankForm done={setIsLoaded} type="add" open={open} values={{razonSocial: values.razonSocial, cuit: values.cuit}} handleClose={handleClose} />
        </>
    );
    }
};

export default CreatorSettings;