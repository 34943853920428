import React, {useState, useEffect} from 'react';
import { BsPersonPlusFill, BsBarChartFill } from 'react-icons/bs';
import {TbReceipt} from "react-icons/tb";
import {AiFillCaretUp, AiFillCaretDown} from "react-icons/ai";
import {GiTicket} from "react-icons/gi";
import './KPI.css';
import Paper from "@mui/material/Paper";

const Kpi = (props) => {

    const KPIData = props.KPIData;

    const symbols = [
        { value: 1, symbol: '', digits: 0 },
        { value: 1e3, symbol: 'k', digits: 0 },
        { value: 1e6, symbol: 'M', digits: 2 },
        { value: 1e9, symbol: 'G', digits: 0 },
        { value: 1e12, symbol: 'T', digits: 0 },
        { value: 1e15, symbol: 'P', digits: 0 },
        { value: 1e18, symbol: 'E', digits: 0 }
    ];

    const variation = (percentage, direct) => {

        if (percentage >= 999) {
            percentage = 999;
        } else if (percentage <= -999) {
            percentage = -999;
        }

        if (direct === true) {
            if (percentage === "-") {
                return (
                    <>
                        <AiFillCaretUp size={20} style={{color: "green"}} />
                        {percentage}%
                    </>
                )
            }
            else {
                if (percentage > 0) {
                    return (
                        <>
                            <AiFillCaretUp size={20} style={{color: "green"}} />
                            {Math.abs(percentage)}%
                        </>
                    )
                }
                else {
                    return (
                        <>
                            <AiFillCaretDown size={20} style={{color: "red"}} />
                            {Math.abs(percentage)}%
                        </>
                    )
                }
        }
        }
        else {
            if (percentage === "-") {
                return (
                    <>
                        <AiFillCaretUp size={20} style={{color: "green"}} />
                        {percentage}%
                    </>
                )
            }
            else {
                if (percentage > 0) {
                    return (
                        <>
                            <AiFillCaretUp size={20} style={{color: "red"}} />
                            {Math.abs(percentage)}%
                        </>
                    )
                }
                else {
                    return (
                        <>
                            <AiFillCaretDown size={20} style={{color: "green"}} />
                            {Math.abs(percentage)}%
                        </>
                    )
                }
            }
        }
    }

    function numberFormatter(num) {
        const numToCheck = Math.abs(num);
        for (let i = symbols.length - 1; i >= 0; i--) {
            if (numToCheck >= symbols[i].value) {
                if (numToCheck < 1e5) {
                    const newNumber = (num / symbols[i].value).toFixed(1);
                    return `${newNumber}${symbols[i].symbol}`;
                }
                else {
                    const newNumber = (num / symbols[i].value).toFixed(symbols[i].digits);
                    return `${newNumber}${symbols[i].symbol}`;
                }
            }
        }
        return '0';
    }

    return (
        <>
            <Paper className="kpi-container" style={{borderRadius: "1rem", overflow: "hidden", boxShadow: "0 0 0.5rem #ccc"}}>
                <div className="row">
                    <div className="col">
                        <div className="row align-items-center p-2 mt-3 mb-3">
                            <div className="col-4 justify-content-center d-flex">
                                <GiTicket
                                    size={60}
                                    style={{transform: "rotate(45deg)"}}
                                />
                            </div>
                            <div className="col-8 p-0">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-6">
                                        <p className="kpi-value" style={{fontSize: 24}}>
                                            {KPIData?.ventaUni.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                        </p>
                                    </div>
                                    <div className="col-6 kpi-value">
                                        {variation(KPIData?.varUni, true)}
                                    </div>
                                </div>
                                <div className="row">
                                    <p className="kpi-label">ENTRADAS VENDIDAS</p>
                                </div>
                            </div>
                        </div>
                        <hr style={{margin: 0, padding: 0, width: '90%', alignContent: "center", marginLeft: "auto", marginRight: "auto"}} />
                        <div className="row align-items-center p-2 mt-3 mb-3">
                            <div className="col-4 justify-content-center d-flex">
                                <BsBarChartFill size={60} />
                            </div>
                            <div className="col-8 p-0">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-6">
                                        <p className="kpi-value" style={{fontSize: 24}}>
                                            $ {numberFormatter(KPIData?.ventaPes)}
                                        </p>
                                    </div>
                                    <div className="col-6 kpi-value">
                                        {variation(KPIData?.varPes, true)}
                                    </div>
                                </div>
                                <div className="row">
                                    <p className="kpi-label">VENTAS TOTALES</p>
                                </div>
                            </div>
                        </div>
                        <hr style={{margin: 0, padding: 0, width: '90%', alignContent: "center", marginLeft: "auto", marginRight: "auto"}} />
                        <div className="row align-items-center p-2 mt-3 mb-3">
                            <div className="col-4 justify-content-center d-flex">
                                <TbReceipt size={60} />
                            </div>
                            <div className="col-8 p-0">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-6">
                                        <p className="kpi-value" style={{fontSize: 24}}>
                                            $ {numberFormatter(KPIData?.ticketPromedio)}
                                        </p>
                                    </div>
                                    <div className="col-6 kpi-value">
                                        {variation(KPIData?.ticketPromedioVar, true)}
                                    </div>
                                </div>
                                <div className="row">
                                    <p className="kpi-label">TICKET PROMEDIO</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
    </>
    );
};

export default Kpi;
