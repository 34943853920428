import React, {useEffect} from 'react';
import {Col, Container, Nav, Row, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import './Sidebar.css';
import {IconContext} from "react-icons";
import {SidebarData} from "./SidebarData";
import {useDispatch, useSelector} from "react-redux";
import {selectUser, setType, setMktPermission, setTableAdminPermission} from "../redux/userSlice";
import {useNavigate} from "react-router";

const Sidebar = () => {

    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [sideData, setSideData] = React.useState([]);

    const fetchData = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/check-user-type`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
                'credentials': 'same-origin',
            }
        });

        const json = await request.json();

        dispatch(setType(json?.user_type));

        const request2 = await fetch(`${process.env.REACT_APP_API_URL}/producer_permissions`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
                'credentials': 'same-origin',
            }
        });

        const json2 = await request2.json();
        dispatch(setMktPermission(json2?.mkt_permission));
        dispatch(setTableAdminPermission(json2?.table_admin_permission));
    }

    useEffect(() => {
        if (user.token) {
            const requests = setTimeout(() => {
                fetchData();
            }, 300);
            return () => clearTimeout(requests);
        }
    }, [user.token]);

    const sideBar = () => {
        if (user.type === 1 && user.mktPermission) {
            setSideData(SidebarData);
        }
        else if (user.type === 1 && !user.mktPermission) {
            setSideData(SidebarData.filter(item => item.mktPermission === false));
        }
        else if (user.tableAdminPermission && (user.type === 2 || user.type === 3)) {
            // Filter sidebar data for items where the user has table admin permission and not a producer
            setSideData(SidebarData.filter(item => item.tableAdminPermission === true || item.producer === false));

            // Redirect to '/organizations/events' if the current URL ends with 'dashboard'
            if (window.location.href.endsWith('dashboard')) {
                navigate('/organizations/events');
            }
        }
        else if (user.type === 2 || user.type === 3) {
            setSideData(SidebarData.filter(item => item.producer === false));

            //if current url ends in dashboard then redirect to /organizations/events
            if (window.location.href.endsWith('dashboard')) {
                navigate('/organizations/events');
            }
        }
    }

    useEffect(() => {
        sideBar();
    }, [user.type, user.mktPermission, user.tableAdminPermission]);

    return (
        <div className="d-none d-lg-block navbar sidebar-main-border" style={{marginTop: "60px"}}>
            <IconContext.Provider value={{ color: '#fff' }}>
                <Nav className="nav-menu">
                    <ul className="nav-menu-items">
                        {sideData.map((item, index) => {
                            return (
                                <li key={index} className={item.cName}>
                                    <Link to={item.path}>
                                        {item.icon}
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </Nav>
            </IconContext.Provider>
        </div>
    );
};

export default Sidebar;
