import React from 'react'
import { Line } from 'react-chartjs-2'
import 'chartjs-adapter-date-fns';

const BarChartEventAnalytics = (props) => {

    function number_format (number, decimals, dec_point, thousands_sep) {
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    const salesData = props.salesData;
    const viewsData = props.viewsData;
    const quantityData = props.quantityData; // Assuming you have quantityData passed as a prop

    const mergeData = (salesData, viewsData, quantityData) => {
        const allDates = Array.from(new Set([...salesData, ...viewsData, ...quantityData].map(item => item.x))).sort();
        const salesDataMap = salesData.reduce((map, item) => ({ ...map, [item.x]: item.y }), {});
        const viewsDataMap = viewsData.reduce((map, item) => ({ ...map, [item.x]: item.y }), {});
        const quantityDataMap = quantityData.reduce((map, item) => ({ ...map, [item.x]: item.y }), {});

        return allDates.map(date => ({
            x: date,
            ySales: salesDataMap[date] || 0,
            yViews: viewsDataMap[date] || 0,
            yQuantity: quantityDataMap[date] || 0, // Add quantity data here
        }));
    }

    const mergedData = mergeData(salesData, viewsData, quantityData);

    return (
        <div>
            <Line
                data={{
                    datasets: [
                        {
                            label: 'Ventas',
                            borderColor: '#300166',
                            fill: true,
                            tension: 0.4,
                            data: mergedData.map(item => ({x: item.x, y: item.ySales})),
                            yAxisID: 'sales',
                            backgroundColor: (context) => {
                                const chart = context.chart;
                                if (!chart.chartArea || !chart.chartArea.top || !chart.chartArea.bottom) {
                                    return;
                                }
                                const {ctx, chartArea: {top, bottom}} = chart;
                                if (!chart.gradient) {
                                    chart.gradient = ctx.createLinearGradient(0, top, 0, bottom);
                                    chart.gradient.addColorStop(0, 'rgba(48, 1, 102, 0.3)');
                                    chart.gradient.addColorStop(1, 'rgba(48, 1, 102, 0)');
                                }
                                return chart.gradient;
                            },
                        },
                        {
                            label: 'Visitas',
                            borderColor: 'blue',
                            borderWidth: 2,
                            borderDash: [5, 5],
                            fill: false,
                            tension: 0.4,
                            data: mergedData.map(item => ({x: item.x, y: item.yViews})),
                            yAxisID: 'views',
                        }
                    ]
                }}
                options={{
                    plugins: {
                        title: {
                            display: true,
                            text: 'Ingresos por ventas',
                        },
                        legend: {
                            display: true,
                            position: 'top',
                            labels: {
                                boxWidth: 20,
                                padding: 20
                            }
                        },
                        tooltip: {
                            callbacks: {
                                title: context => {
                                    const d = new Date(context[0].raw.x);
                                    d.setHours(d.getHours() + 3);
                                    const formattedDate = d.toLocaleDateString([], {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    });
                                    return formattedDate;
                                },
                                label: context => {
                                    const label = context.dataset.label;
                                    const item = mergedData.find(item => item.x === context.raw.x);

                                    if (label === 'Visitas') {
                                        return `${label}: ${number_format(context.raw.y, 0, ',', '.')}`;
                                    } else {
                                        return `$${number_format(context.raw.y, 0, ',', '.')}`;
                                    }
                                },
                                afterLabel: context => {
                                    const label = context.dataset.label;
                                    const item = mergedData.find(item => item.x === context.raw.x);
                                    if (label === 'Ventas') {
                                        return `${number_format(item.yQuantity, 0, ',', '.')} tickets`;
                                    }
                                    return ''; // No additional line for "Visitas"
                                }
                            }
                        },
                    },
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'day',
                            },
                            grid: {
                                display: false,
                            }
                        },
                        sales: {
                            position: 'left',
                            title: {
                                display: true,
                                text: 'Ventas ($)'
                            },
                            ticks: {
                                callback: function(value, index, values) {
                                    return '$' + number_format(value, 0, ',', '.');
                                }
                            },
                            grid: {
                                display: false,
                            }
                        },
                        views: {
                            position: 'right',
                            title: {
                                display: true,
                                text: 'Visitas'
                            },
                            grid: {
                                drawOnChartArea: false,
                            }
                        },
                    }
                }}
            />
        </div>
    )
}

export default BarChartEventAnalytics;
