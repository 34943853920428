import React, {useState} from 'react';
import {
    Alert,
    Button, CircularProgress,
    Snackbar,
    TextField
} from "@mui/material";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {useNavigate} from "react-router";

const PromoterAdd = (props) => {

    const user = useSelector(selectUser)

    const [values, setValues] = useState({
        email: '',
        name: '',
        id: '',
        events: '',
        role: '',
        phone: '',
    });

    const navigate = useNavigate();

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const addPromoter = async (e) => {
        e.preventDefault();
        setIsLoaded(false);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/promoter-details`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify(values),
        });
        const json = await response.json();
        if (json.success == 1) {
            setOpenS(true);
            setSuccessMessage(json.message);
            setTimeout(() => {
                navigate('/organizations/team');
            }, 2000);
        }
        else {
            setOpenE(true);
            setErrorMessage(json.message);
        }
        setIsLoaded(true);

    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const [isLoaded, setIsLoaded] = useState(true);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            <div className="row">
                <div className="col">
                    <h1>Agregar Colaborador</h1>
                </div>
            </div>
            <form onSubmit={addPromoter}>
                <div className="row">
                    <div className="col-md-6">
                        <TextField label="Nombre" value={values.name} onChange={handleChange('name')} className="mb-3" fullWidth variant="outlined" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <TextField label="Email" type="email" value={values.email} onChange={handleChange('email')} className="mb-3" fullWidth variant="outlined" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <TextField label="Teléfono" value={values.phone} onChange={handleChange('phone')} className="mb-3" fullWidth variant="outlined" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Button variant="contained" type="submit" color="primary" className="me-2">
                            Agregar
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
    }
};

export default PromoterAdd;
