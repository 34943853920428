import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Menu, MenuItem } from '@material-ui/core';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useNavigate } from 'react-router';
import DropdownMenu from "./DropdownMenu";
import {money_format} from "./functions/numberFormat";

const columns = [
    { id: 'name', label: 'Nombre', minWidth: 0 },
    { id: 'tables', label: 'Mesas', minWidth: 0 },
    { id: 'initial_amount', label: 'Monto inicial', minWidth: 0 },
    { id: 'final_amount', label: 'Monto al cierre', minWidth: 0 },
    { id: 'balance', label: 'Saldo', minWidth: 100 },
    { id: 'status', label: 'Estado', minWidth: 0 },
    {
        id: 'actions',
        label: 'Acciones',
        minWidth: 300,
        align: 'center',
    },
];

export default function StickyHeadTable(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const navigate = useNavigate();

    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <TableContainer sx={{ maxHeight: "75vh" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.rows.map((row) => (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                            {column.id === 'actions' ? (
                                                <>
                                                    <div>
                                                        <Button className={"me-2"} variant="text" color="default" onClick={() => navigate(`./${row.id}/transactions`)}>Transacciones</Button>
                                                        <Button className={"me-2"} variant="text" color="default" onClick={() => {
                                                            window.open(row.downloadUrl, "_blank");
                                                        }}>Imprimir</Button>
                                                    </div>
                                                </>
                                            ) : ['balance', 'initial_amount', 'final_amount'].includes(column.id) ? (
                                                <>
                                                    {value ? money_format(value) : null}
                                                </>
                                            ) : column.id === 'status' ? (
                                                <span className="font-weight-bold" style={{ color: row.status === 'Abierta' ? 'blue' : row.status === 'Cerrada' ? 'black' : 'black' }}>
                                                    {value}
                                                </span>
                                            ) : column.id === 'amount_due' ? (
                                                <span style={{ color: row.amount_due <= 0 ? 'green' : 'red' }}>
                                                    {value > 0 ? `$ ${value}` : null}
                                                </span>
                                            ) : column.format && typeof value === 'number' ? (
                                                column.format(value)
                                            ) : (
                                                value
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}