import React, {useState} from 'react';
import {Button, Table} from "react-bootstrap";
import {TextField} from "@mui/material";
import {useNavigate} from "react-router";


const MenuSupervisorsOrders = () => {

    const token = localStorage.getItem('token');

    const [orders, setOrders] = useState([]);
    const navigate = useNavigate();

    const fetchData = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-orders-supervisor`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        const response = await request.json();

        if (response.status === "success") {
            setOrders(response.data);
        }
    }

    function getViewport () {
        // https://stackoverflow.com/a/8876069
        const width = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
        )

        if (width <= 992) return 'md'
        return 'xl'
    }

    function searchData() {
        // Declare variables
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();

        table = document.getElementsByTagName("table")[0];

        tr = table.getElementsByTagName("tr");

        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
            var td0 = tr[i].getElementsByTagName("td")[0];
            var td1 = tr[i].getElementsByTagName("td")[1];
            var td2 = tr[i].getElementsByTagName("td")[2];
            var td3 = tr[i].getElementsByTagName("td")[4];
            if (td0 || td1 || td2 || td3) {
                var txtValue0 = td0.textContent || td0.innerText;
                var txtValue1 = td1.textContent || td1.innerText;
                var txtValue2 = td2.textContent || td2.innerText;
                var txtValue3 = td3.textContent || td3.innerText;
                if (txtValue0.toUpperCase().indexOf(filter) > -1 || txtValue1.toUpperCase().indexOf(filter) > -1 || txtValue2.toUpperCase().indexOf(filter) > -1 || txtValue3.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }

    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [token]);

    return (
        <div className="container" style={{backgroundColor: "white", minHeight: "100vh"}}>
            <div className="row mt-3">
                <div className="col-12">
                    <h3>Ordenes</h3>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col">
                    <TextField id="myInput" fullWidth onKeyUp={searchData} label="Buscar por ID, email, monto" variant="outlined" />
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-12">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12" style={{maxHeight: "80vh", overflowY: "scroll"}}>
                            <Table striped bordered responsive id="myTable" size="sm">
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Cliente</th>
                                    <th>Monto</th>
                                    <th>Mesa</th>
                                    <th>Estado</th>
                                    <th>Acciones</th>
                                </tr>
                                </thead>
                                <tbody>
                                {orders.map((order, key) => (
                                    <tr key={key} style={{fontSize: "12px"}}>
                                        <td style={{wordWrap: "break-word", maxWidth: "150px"}}>{order.id}</td>
                                        <td>{order.client_email}</td>
                                        <td>{order.order_total}</td>
                                        <td>{order.table_number}</td>
                                        <td>{order.order_status}</td>
                                        <td>
                                            <div className="row">
                                                <div className="col">
                                                    <Button className="w-100 d-flex align-items-center" variant={"dark"} onClick={() => navigate(`./${order.id}`)}>
                                                        Ver
                                                    </Button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MenuSupervisorsOrders;