import React, {useEffect, useState} from 'react';
import {
    Button, CircularProgress,
    FormControl, FormControlLabel,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select, Switch,
    TextField
} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {useNavigate} from "react-router";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";


const EditTicketInfo = (props) => {

    const user = useSelector(selectUser)

    const navigate = useNavigate();

    const [event_start, setEventStart] = useState(new Date());
    const [event_end, setEventEnd] = useState(new Date());

    const [values, setValues] = useState({
        event_ticket_id: '',
        event_ticket_name: '',
        event_ticket_description: '',
        event_ticket_limit: '',
        limit_per_order: 5,
        event_ticket_value: '',
        event_ticket_date_init: new Date(),
        event_ticket_date_end: null,
        event_ticket_limit_validation: null,
        event_ticket_active: '',
        event_ticket_gender: 'T',
        visible: 1,
        event_id: props.event,
        event_ticket_limitage: '',
        combo: 1,
        show_coming_soon: true,
    });

    const [errors, setErrors] = useState({
        event_ticket_name: false,
        event_ticket_description: false,
        event_ticket_limit: false,
        limit_per_order: false,
        event_ticket_value: false,
        event_ticket_date_init: false,
        event_ticket_date_end: false,
        event_ticket_limit_validation: false,
        event_ticket_active: false,
        event_ticket_limitage: false,
        event_ticket_gender: false,
    });

    const [errorMessages, setErrorMessages] = useState({
        event_ticket_name: '',
        event_ticket_description: '',
        event_ticket_limit: '',
        limit_per_order: '',
        event_ticket_value: '',
        event_ticket_date_init: '',
        event_ticket_date_end: '',
        event_ticket_limit_validation: '',
        event_ticket_active: '',
        event_ticket_limitage: '',
        event_ticket_gender: '',
    });

    const inputValidation = (e, prop) => {
        let value = e.target.value;
        let error = false;
        let errorMessage = '';

        switch (prop) {
            case 'event_ticket_name':
                if (value.length < 3) {
                    error = true;
                    errorMessage = 'El nombre debe tener al menos 3 caracteres';
                }
                break;
            case 'event_ticket_limit':
                if (value < 1) {
                    error = true;
                    errorMessage = 'El límite debe ser mayor a 0';
                }
                break;
            case 'limit_per_order':
                if (value < 1 || value > 10) {
                    error = true;
                    errorMessage = 'El límite debe ser entre 1 y 10';
                }
                break;
            case 'event_ticket_value':
                if (value < 0) {
                    error = true;
                    errorMessage = 'El precio debe ser mayor o igual a 0';
                }
                break;
            case 'event_ticket_date_end':
                if (value < values.event_ticket_date_init) {
                    error = true;
                    errorMessage = 'La fecha de finalización debe ser mayor a la de inicio';
                }
                break;
        }
        if (!value) {
            error = false;
            errorMessage = '';
        }
        setErrors({...errors, [prop]: error});
        setErrorMessages({...errorMessages, [prop]: errorMessage});
    }




    const fetchData = async () => {
        // Retrieve client's timezone identifier
        const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Retrieve client's time difference in minutes and its direction
        const moment = require('moment-timezone');
        const gmtOffset = moment.tz(timeZone).utcOffset();

        if (props.ticket) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/ticket-details?event_id=${props.event}&event_ticket_id=${props.ticket}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                },
            });
            const json = await response.json();

            if (json.status === 'success') {
                // Adjust event_ticket_date_init and event_ticket_date_end dates according to client's timezone
                let eventTicketInit = new Date(json.ticket.event_ticket_date_init);
                eventTicketInit.setMinutes(eventTicketInit.getMinutes() + gmtOffset);
                eventTicketInit.setSeconds(0); // Set seconds to 0

                if (json.ticket.event_ticket_date_init === null) {
                    eventTicketInit = new Date();
                }

                let eventTicketEnd = new Date(json.ticket.event_ticket_date_end);
                eventTicketEnd.setMinutes(eventTicketEnd.getMinutes() + gmtOffset);
                eventTicketEnd.setSeconds(0); // Set seconds to 0

                if (json.ticket.event_ticket_date_end === null) {
                    eventTicketEnd = new Date();
                }

                const eventStart = new Date(json.event_date);
                eventStart.setMinutes(eventStart.getMinutes() + gmtOffset);
                eventStart.setSeconds(0); // Set seconds to 0

                const eventDateEnd = new Date(json.ticket.event_date_end);
                eventDateEnd.setMinutes(eventDateEnd.getMinutes() + gmtOffset);
                eventDateEnd.setSeconds(0); // Set seconds to 0

                setEventStart(eventStart);
                setEventEnd(eventDateEnd);

                let eventTicketValidationEnd

                if (json.ticket.event_ticket_limit_validation !== null) {
                    eventTicketValidationEnd = new Date(json.ticket.event_ticket_limit_validation);
                    eventTicketValidationEnd.setMinutes(eventTicketValidationEnd.getMinutes() + gmtOffset);
                    eventTicketValidationEnd.setSeconds(0); // Set seconds to 0
                } else {
                    eventTicketValidationEnd = eventDateEnd;
                }

                if (eventTicketEnd > eventDateEnd) {
                    eventTicketEnd = eventDateEnd;
                }

                setValues({
                    ...values,
                    ...json.ticket,
                    event_ticket_date_init: eventTicketInit,
                    event_ticket_date_end: eventTicketEnd,
                    event_ticket_limit_validation: eventTicketValidationEnd,
                });
                setIsLoaded(true);
            }
        } else {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/ticket-details?event_id=${props.event}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                },
            });
            const json = await response.json();
            if (json.status === 'success') {
                const eventTicketEnd = new Date(json.event_ticket_date_end);
                eventTicketEnd.setMinutes(eventTicketEnd.getMinutes() + gmtOffset);
                eventTicketEnd.setSeconds(0); // Set seconds to 0

                const eventStart = new Date(json.event_date);
                eventStart.setMinutes(eventStart.getMinutes() + gmtOffset);
                eventStart.setSeconds(0); // Set seconds to 0

                let eventTicketValidationEnd = new Date(json.event_ticket_date_end);
                eventTicketValidationEnd.setMinutes(eventTicketValidationEnd.getMinutes() + gmtOffset);
                eventTicketValidationEnd.setSeconds(0); // Set seconds to 0

                setEventStart(eventStart);
                setEventEnd(eventTicketEnd);

                setValues({ ...values, event_ticket_date_end: eventTicketEnd, event_ticket_limitage: json.event_ticket_limitage, event_ticket_limit_validation: eventTicketValidationEnd});
                setIsLoaded(true);
            }
        }
    };


    const [isLoaded, setIsLoaded] = useState(false);

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    React.useEffect(() => {
        //console.log(values);
    }, [values]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        inputValidation(event, prop);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let request;

        const errorCheck = Object.values(errors).some((error) => error === true);

        if (!errorCheck) {
            if (props.ticket) {
                request = await fetch(`${process.env.REACT_APP_API_URL}/ticket-details`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${user.token}`,
                        'credentials': 'same-origin',
                    },
                    body: JSON.stringify(values)
                });
            }
            else {
                request = await fetch(`${process.env.REACT_APP_API_URL}/ticket-details`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${user.token}`,
                        'credentials': 'same-origin',
                    },
                    body: JSON.stringify(values)
                });
            }
            const json = await request.json();
            //console.log(json);

            if (json.status === 'success') {
                setIsLoaded(false);
                navigate(`/organizations/events/${props.event}/tickets`);
            }
        }
    }

    const eliminarTicket = async (ticket_id) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/ticket-details`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({event_ticket_id: ticket_id, event_id: props.event})
            });
        const json = await request.json();
        if (json.status === 'success') {
            return json;
        }
    }

    const swalEliminarTicket = (ticket_id) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                eliminarTicket(ticket_id).then((json) => {
                    if (json.status === 'success') {
                        MySwal.fire(
                            'Eliminado',
                            'El ticket ha sido eliminado',
                            'success'
                        ).then(() => {
                            navigate(`/organizations/events/${props.event}/tickets`);
                        })
                    }
                    else {
                        MySwal.fire(
                            'Error',
                            'Ha ocurrido un error',
                            'error'
                        )
                    }
                })
            }
        })
    }

    useEffect(() => {
        if (values.event_ticket_date_init <= values.event_ticket_date_end) {
            setErrors({...errors, event_ticket_date_init: false, event_ticket_date_end: false});
        }
    }, [errors, values.event_ticket_date_init, values.event_ticket_date_end]);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <div className="row justify-content-center">
                <div className="col">
                    <h1>{props.ticket ? "Editar" : "Crear"} Ticket</h1>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row justify-content-center">
                    <div className="col">
                        <TextField label="Nombre del ticket" value={values.event_ticket_name}
                                   onChange={handleChange('event_ticket_name')} className="mb-3" fullWidth
                                   variant="outlined" required error={errors.event_ticket_name}
                                   helperText={errorMessages.event_ticket_name}/>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col">
                        <TextField label="Descripción del ticket" value={values.event_ticket_description} rows={4}
                                   multiline onChange={handleChange('event_ticket_description')} className="mb-3"
                                   fullWidth variant="outlined"/>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-6 col-md">
                        <TextField label="Cantidad disponible" type="number" onWheel={(e) => e.preventDefault()}
                                   value={values.event_ticket_limit} onChange={handleChange('event_ticket_limit')}
                                   className="mb-3" fullWidth variant="outlined" required
                                   error={errors.event_ticket_limit} helperText={errorMessages.event_ticket_limit}/>
                    </div>
                    <div className="col-6 col-md">
                        <FormControl error={errors.event_ticket_value} fullWidth>
                            <InputLabel htmlFor="outlined-adornment-amount">Precio Unitario</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                value={values.event_ticket_value}
                                onChange={handleChange('event_ticket_value')}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                label="Precio Unitario"
                                type="number" onWheel={(e) => e.preventDefault()}
                            />
                            <small className="text-danger"
                                   style={{fontSize: "12px"}}>{errorMessages.event_ticket_value}</small>
                        </FormControl>
                    </div>
                    <div className="col-6 col-md">
                        <FormControl fullWidth className="mb-3">
                            <InputLabel id="demo-simple-select-label">Género del Ticket</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.event_ticket_gender}
                                label="Género del Ticket"
                                onChange={handleChange('event_ticket_gender')}
                            >
                                <MenuItem value={'T'}>Todos</MenuItem>
                                <MenuItem value={'M'}>Hombre</MenuItem>
                                <MenuItem value={'F'}>Mujer</MenuItem>
                                <MenuItem value={'O'}>Otros</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-6 col-md">
                        <FormControl fullWidth className="mb-3">
                            <InputLabel id="demo-simple-select-label">Visibilidad</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.visible}
                                label="Visibilidad"
                                onChange={handleChange('visible')}
                            >
                                <MenuItem value={1}>Publico</MenuItem>
                                <MenuItem value={0}>Oculto</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-6 col-md">
                        <TextField label="Limite por compra" type="number" onWheel={(e) => e.preventDefault()}
                                   value={values.limit_per_order} onChange={handleChange('limit_per_order')}
                                   className="mb-3" fullWidth variant="outlined" required
                                   error={errors.limit_per_order} helperText={errorMessages.limit_per_order}/>
                    </div>
                    <div className="col-6 col-md">
                        <TextField label="Edad mínima" type="number" value={values.event_ticket_limitage}
                                   onChange={handleChange('event_ticket_limitage')} className="mb-3" fullWidth
                                   variant="outlined" required error={errors.event_ticket_limitage}
                                   helperText={errorMessages.event_ticket_limitage}/>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col">
                        <h3>Fecha y Hora</h3>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                renderInput={(props) => <TextField fullWidth {...props} />}
                                label="El ticket esta disponible desde"
                                value={values.event_ticket_date_init}
                                inputFormat={'dd/MM/yyyy HH:mm'}
                                ampm={false}
                                onChange={(newValue) => {
                                    // Set seconds to 0
                                    newValue.setSeconds(0);
                                    setValues({...values, event_ticket_date_init: newValue});
                                }}
                                maxDateTime={event_end}
                                onError={() => setErrors({...errors, event_ticket_date_init: true})}
                                onAccept={() => setErrors({...errors, event_ticket_date_init: false})}
                                desktopModeMediaQuery={'(max-width: 0px)'}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                renderInput={(props) => <TextField fullWidth {...props} />}
                                label="El ticket esta disponible hasta"
                                value={values.event_ticket_date_end}
                                maxDateTime={event_end}
                                inputFormat={'dd/MM/yyyy HH:mm'}
                                minDateTime={values.event_ticket_date_init}
                                defaultCalendarMonth={values.event_ticket_date_end}
                                ampm={false}
                                onChange={(newValue) => {
                                    // Set seconds to 0
                                    newValue.setSeconds(0);
                                    setValues({...values, event_ticket_date_end: newValue});
                                }}
                                onError={() => setErrors({...errors, event_ticket_date_end: true})}
                                onAccept={() => setErrors({...errors, event_ticket_date_end: false})}
                                desktopModeMediaQuery={'(max-width: 0px)'}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                renderInput={(props) => <TextField fullWidth {...props} />}
                                label="El ticket se puede validar hasta"
                                value={values.event_ticket_limit_validation}
                                maxDateTime={event_end}
                                inputFormat={'dd/MM/yyyy HH:mm'}
                                minDateTime={event_start}
                                defaultCalendarMonth={event_end}
                                ampm={false}
                                onChange={(newValue) => {
                                    // Set seconds to 0
                                    newValue.setSeconds(0);
                                    setValues({...values, event_ticket_limit_validation: newValue});
                                }}
                                onError={() => setErrors({...errors, event_ticket_limit_validation: true})}
                                onAccept={() => setErrors({...errors, event_ticket_limit_validation: false})}
                                desktopModeMediaQuery={'(max-width: 0px)'}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                {values.event_ticket_date_init > new Date() && (
                    <div className="row justify-content-center mt-3">
                        <div className="col">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={values.show_coming_soon}
                                        onChange={(event) => {
                                            setValues({...values, show_coming_soon: event.target.checked});
                                        }}
                                    />
                                }
                                label="Mostrar próximamente"
                            />
                        </div>
                    </div>
                )}
                <div className="row justify-content-center mt-4">
                    <div className="col">
                        <h3>Combos / Promociones</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <FormControl fullWidth className="mt-2">
                            <InputLabel id="demo-simple-select-label">Cantidad de tickets asociados</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.combo}
                                label="Cantidad de tickets asociados"
                                onChange={handleChange('combo')}
                                fullWidth
                            >
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                                        <MenuItem key={index} value={item}>{item}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <small className="mt-2">Tickets asociados es la cantidad de tickets que se enviarán al comprar
                            una unidad de este ticket. Se recomienda modificarlo solo para generar combos o
                            promociones.</small>
                    </div>
                </div>
                <div className="row justify-content-between mt-4">
                    <div className="col">
                        <Button type="submit" variant="contained" color="primary" className="me-2">
                            Guardar
                        </Button>
                    </div>
                    {props.ticket && (
                        <div className="col text-end">
                            <Button variant="contained" color="error" onClick={() => swalEliminarTicket(props.ticket)}>
                                Eliminar Ticket
                            </Button>
                        </div>
                    )}
                </div>
            </form>
        </div>
    );
    }
};

export default EditTicketInfo;
