import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { useEffect } from "react";
import { TextField, Chip } from "@mui/material";
import { Autocomplete } from "@mui/lab";

export default function MultipleAutocompleteColaborators(props) {
    const [personName, setPersonName] = React.useState([]);
    const [names, setNames] = React.useState([]);

    const handleChange = (event, value) => {
        setPersonName(value);
        props.onChange(value);
    };

    useEffect(() => {
        setNames(props.events)
        setPersonName(props.values)
    }, [props.events, props.values]);

    return (
        <FormControl fullWidth>
            <Autocomplete
                multiple
                options={names}
                getOptionLabel={(option) => option.name}
                value={personName}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={props.tag}
                        placeholder="Buscar"  // Changed "Search" to "Buscar"
                    />
                )}
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id || option.name}>
                        <Checkbox checked={selected} />
                        <ListItemText primary={option.name} />
                    </li>
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
                    ))
                }
                onChange={handleChange}
                disableCloseOnSelect={true} // prevent options from collapsing when an option is clicked
            />
        </FormControl>
    );
}
