import React, {useEffect, useState} from 'react';
import {BsInstagram, BsFacebook, BsWhatsapp} from "react-icons/bs";
import './ImageUpload.css';

const CampaignPreview = (props) => {

    const [preview, setPreview] = useState();

    useEffect(() => {
        if (!props.values.image) {
            setPreview(undefined)
            return
        }

        if (typeof props.values.image === 'string') {
            setPreview(props.values.image)
        }
        else {
        const objectUrl = URL.createObjectURL(props.values.image)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
        }
    }, [props.values.image])

    return (
        <>
            <div className="card scrollCampaign">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <span style={{opacity: "60%"}}>Asunto: </span>
                            <span>{props.values.subject}</span>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <span style={{opacity: "60%"}}>De: </span>
                            <span>{props.values.from_name}</span>
                        </div>
                    </div>
                    <hr />
                    {props.values.image &&
                        <div className="row">
                            <div className="col-12">
                                <img className="img-fluid w-100" src={preview} alt={"evento"} />
                            </div>
                        </div>
                    }
                    {props.values.title &&
                        <div className="row mt-3 mb-3 text-center" style={{whiteSpace: "pre-wrap"}}>
                            <div className="col-12 d-flex justify-content-center">
                                <h2>{props.values.title}</h2>
                            </div>
                        </div>
                    }
                    {props.values.body &&
                        <div className="row mt-3 text-center" style={{whiteSpace: "pre-wrap"}}>
                            <div className="col-12 d-flex justify-content-center">
                                <p>{props.values.body}</p>
                            </div>
                        </div>
                    }
                    <div className="footer p-1 pb-3" style={{backgroundColor: "whitesmoke"}}>
                        <div className="row mt-3">
                            <div className="col-12 d-flex justify-content-center">
                                <div className="row">
                                    {props.values.instagram &&
                                    <div className="col-auto p-1">
                                        <div className="bg-white d-flex align-items-center justify-content-center" style={{borderRadius: "50%", height: "40px", width: "40px"}}>
                                            <BsInstagram size={20} />
                                        </div>
                                    </div>
                                    }
                                    {props.values.whatsapp &&
                                    <div className="col-auto p-1">
                                        <div className="bg-white d-flex align-items-center justify-content-center" style={{borderRadius: "50%", height: "40px", width: "40px"}}>
                                            <BsWhatsapp size={20} />
                                        </div>
                                    </div>
                                    }
                                    {props.values.facebook &&
                                    <div className="col-auto p-1">
                                        <div className="bg-white d-flex align-items-center justify-content-center" style={{borderRadius: "50%", height: "40px", width: "40px"}}>
                                            <BsFacebook size={20} />
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {props.values.producerName &&
                        <div className="row mt-2">
                            <div className="col-12 d-flex justify-content-center">
                                <p style={{opacity: "60%"}}>{props.values.producerName}</p>
                            </div>
                        </div>
                        }
                        <div className="row mb-0">
                            <div className="col-12 d-flex justify-content-center">
                                <p style={{opacity: "60%", fontSize: "12px"}}>Copyright &#169; {new Date().getFullYear()}. Todos los derechos reservados.</p>
                            </div>
                        </div>
                        <div className="row mt-0">
                            <div className="col-12 d-flex justify-content-center">
                                <p style={{opacity: "60%", fontSize: "12px"}}>Cancelar suscripción | Política de privacidad</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CampaignPreview;