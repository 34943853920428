import React, { useEffect, useState } from 'react';
import { CircularProgress, TextField, InputAdornment } from "@mui/material";
import { Button } from "react-bootstrap";
import Swal from 'sweetalert2';

const DigitalMenuCurrencies = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [currencies, setCurrencies] = useState([]);
    const [originalCurrency, setOriginalCurrency] = useState('');
    const token = localStorage.getItem('token');

    const [values, setValues] = useState({
        name: '',
        symbol: '',
        exchangeRate: '',
    })

    const fetchData = async () => {
        setIsLoaded(false);
        try {
            const request = await fetch(`${process.env.REACT_APP_API_V2_URL}/menus/currencies/${props.menu}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            });

            const response = await request.json();
            if (response.status === "success") {
                setCurrencies(response.data);
                setOriginalCurrency(response.baseCurrency);
            }
        } catch (error) {
            console.error("Error fetching currencies:", error);
        } finally {
            setIsLoaded(true);
        }
    };

    const submitData = async () => {
        try {
            const request = await fetch(`${process.env.REACT_APP_API_V2_URL}/menus/currencies`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify({ currency: values, menuId: props.menu })
            });

            const response = await request.json();
            if (response.status === "success") {
                Swal.fire('Añadida', response.message, 'success');
            } else {
                Swal.fire('Error', response.message, 'error');
            }
        } catch (error) {
            console.error("Error submitting data:", error);
            Swal.fire('Error', 'Error al enviar los datos', 'error');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        Swal.fire({
            title: '¿Estás seguro?',
            text: "Se añadirá la moneda al menú",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, guardar',
            cancelButtonText: 'No, cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                submitData();
            }
        });
    };

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [token]);

    return (
        <>
            {!isLoaded ? (
                <div className="d-flex justify-content-center align-items-center" style={{ marginTop: "25vh" }}>
                    <CircularProgress color="secondary" size={100} />
                </div>
            ) : (
                <div>
                    <div className="row mt-2 justify-content-between align-items-center">
                        <div className="col">
                            <h3>Añadir moneda (Base: {originalCurrency})</h3>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="row mt-3">
                            <div className="col-lg-6">
                                <TextField
                                    fullWidth
                                    label={"Nombre"}
                                    type="text"
                                    variant="outlined"
                                    value={values.name}
                                    onChange={(e) => setValues({ ...values, name: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-6">
                                <TextField
                                    fullWidth
                                    label={"Símbolo"}
                                    type="text"
                                    variant="outlined"
                                    value={values.symbol}
                                    onChange={(e) => setValues({ ...values, symbol: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-6">
                                <TextField
                                    fullWidth
                                    label={"Tasa de cambio"}
                                    type="number"
                                    variant="outlined"
                                    value={values.exchangeRate}
                                    onChange={(e) => setValues({ ...values, exchangeRate: e.target.value })}
                                    InputProps={{
                                        endAdornment: <InputAdornment
                                            position="end">{originalCurrency}</InputAdornment>,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Button variant="dark" type="submit" className="mt-3">
                                    Añadir
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};

export default DigitalMenuCurrencies;
