import React, {useEffect, useState} from 'react';
import './EventPreview.css';
import {
    Button, CircularProgress,
    Dialog,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Step,
    StepLabel,
    Stepper,
    TextField, Typography
} from "@mui/material";
import {IoClose} from "react-icons/io5";
import {FaRegCalendarAlt} from "react-icons/fa";
import {MdLocationOn, MdPayment} from "react-icons/md";
import {GrUserAdd} from "react-icons/gr";
import {BsTrash} from "react-icons/bs";
import EventsSwiper from "./EventsSwiper";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";

const EventPreview = (props) => {

    const user = useSelector(selectUser)

    const [selectedTickets, setSelectedTickets] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [disabledButton, setDisabledButton] = useState(true);
    const [tickets, setTickets] = useState([]);
    const [event, setEvent] = useState({id: "", name: "", description: "", date_start: "", date_end: "", location: "", limitage: ""});

    const fp_fee = 0.15;

    const [values, setValues] = useState({
        buyerData: {
            name: "",
            surname: "",
            email: "",
            confirmEmail: "",
            phone: "",
            dni: "",
            province: "",
        },
        ticketsData: [],
    })

    const [errors, setErrors] = useState({
        buyerData: {
            name: false,
            surname: false,
            email: false,
            confirmEmail: false,
            phone: false,
            dni: false,
            province: false,
        },
        ticketsData: [{
            ticketId: false,
            ticketPrice: false,
            ticketName: false,
            ticketDNI: false,
        }],
    });

    const [errorMessages, setErrorMessages] = useState({
        buyerData: {
            name: "",
            surname: "",
            email: "",
            confirmEmail: "",
            phone: "",
            dni: "",
            province: "",
        },
        ticketsData: [],
    });

    const inputValidation = (e, prop, type, index) => {
        let value = e.target.value;
        let error = false;
        let errorMessage = '';

        switch (prop) {
            case 'name':
                if (value.length < 2) {
                    error = true;
                    errorMessage = 'El nombre debe tener al menos 2 caracteres';
                }
                break;
            case 'surname':
                if (value.length < 2) {
                    error = true;
                    errorMessage = 'El apellido debe tener al menos 2 caracteres';
                }
                break;
            case 'email':
                if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                    error = true;
                    errorMessage = 'El email no es válido';
                }
                if (values.buyerData.confirmEmail !== '' && value !== values.buyerData.confirmEmail) {
                    error = true;
                    errorMessage = 'Los emails no coinciden';
                }
                break;
            case 'confirmEmail':
                if (value !== values.buyerData.email) {
                    error = true;
                    errorMessage = 'Los emails no coinciden';
                }
                break;
            case 'dni':
                if (!value.match(/^[0-9]{7,8}$/)) {
                    error = true;
                    errorMessage = 'El DNI no es válido';
                }
                break;
            case 'province':
                if (value === '') {
                    error = true;
                    errorMessage = 'Debe seleccionar una provincia';
                }
                break;
            case 'ticketName':
                if (value.length < 2) {
                    error = true;
                    errorMessage = 'El nombre debe tener al menos 2 caracteres';
                }
                break;
            case 'ticketDNI':
                if (!value.match(/^[0-9]{7,8}$/)) {
                    error = true;
                    errorMessage = 'El DNI no es válido';
                }
        }
        if (!value) {
            error = false;
            errorMessage = '';
        }

        if (type === 'buyerData') {
            setErrors({...errors, buyerData: {...errors.buyerData, [prop]: error}});
            setErrorMessages({...errorMessages, buyerData: {...errorMessages.buyerData, [prop]: errorMessage}});
        } else {
            setErrors({...errors, ticketsData: {...errors.ticketsData, [index]: {...errors.ticketsData[index], [prop]: error}}});
            setErrorMessages({...errorMessages, ticketsData: {...errorMessages.ticketsData, [index]: {...errorMessages.ticketsData[index], [prop]: errorMessage}}});
        }
    }

    const steps = [
        'Revisá tu orden',
        'Datos del comprador',
        'Datos de los tickets',
        'Confirmación',
    ];

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = (e) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const submitForm = (e) => {
        e.preventDefault();
        const errorCheck = Object.values(errors).some((error) => error === true);
        if (!errorCheck) {
            handleNext();
            //console.log(values);
        }
    }

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false)
    };

    const handleBuyerChange = (prop) => (event) => {
        setValues({...values, buyerData: {...values.buyerData, [prop]:event.target.value}});
        inputValidation(event, prop, 'buyerData');
    }

    const handleTicketChange = (prop, index, ticketId, ticketPrice) => (event) => {
        setValues({...values, ticketsData: {...values.ticketsData, [index]: {...values?.ticketsData[index], [prop]:event.target.value, ticketId:ticketId, ticketPrice:ticketPrice}}});
        inputValidation(event, prop, 'ticketsData', index);
    }

    const handleSelectTickets = (ticket) => {
        //add to the array the ticket.id the times the ticket.target.value is
        let tickets = [];
        for (let i = 0; i < ticket.target.value; i++) {
            tickets.push(parseInt(ticket.target.id));
        }
        setSelectedTickets([...selectedTickets.filter(tick => tick !== parseInt(ticket.target.id)), ...tickets]);
        //console.log(selectedTickets);
    }

    const reduceTicket = (ticket) => {
        if (selectedTickets.filter(tick => tick === parseInt(ticket)).length > 1) {
            for (let i = 0; i < selectedTickets.length; i++) {
                if (selectedTickets[i] === parseInt(ticket)) {
                    selectedTickets.splice(i, 1);
                    setSelectedTickets([...selectedTickets]);
                    break;
                }
            }
        }
        else {
            setSelectedTickets(selectedTickets.filter(tick => tick !== parseInt(ticket)));
        }
        ////console.log(selectedTickets);
    }

    const addTicket = (ticket) => {
        setSelectedTickets([...selectedTickets, parseInt(ticket)]);
        //console.log(selectedTickets);
    }

    const fetchData = async () => {
        //Fetch province list
        const response = await fetch(`${process.env.REACT_APP_API_URL}/provinces?country=1`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();
        setProvinceList(json);

        //Fetch event data
        const response3 = await fetch(`${process.env.REACT_APP_API_URL}/event?event_id=${props.event}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
            },
        });
        const json3 = await response3.json();

        //console.log(json3);

        if (json3.success === "1") {
            setEvent(json3.event);
            setTickets(json3.tickets);
        }

        setIsLoaded(true);
    }

    const checkout = async () => {
        setOpen(false);
        setIsLoaded(false);
        const request = await fetch(`${process.env.REACT_APP_API_URL}/checkout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                "buyerData": values.buyerData,
                "tickets": values.ticketsData,
                "promoter": 0,
                "event": 90,
            })
        });

        const response = await request.text();
        //console.log(response);

        if (request.status === 200) {
            window.location.href = response;
        }
    }

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);

    useEffect(() => {
        setErrors({...errors, ticketsData: [...selectedTickets.map((ticket, index) => {
            return {
                ticketId: false,
                ticketPrice: false,
                ticketName: false,
                ticketDNI: false,
            }
        })]});
        setErrorMessages({...errorMessages, ticketsData: [...selectedTickets.map((ticket, index) => {
            return {
                ticketId: '',
                ticketPrice: '',
                ticketName: '',
                ticketDNI: '',
            }
        })]});
        setValues({...values, ticketsData: [...selectedTickets.map((ticket, index) => {
            if (index !== 0) {
                return {
                    ticketId: ticket,
                    ticketPrice: tickets.find(tick => tick.id === ticket).price,
                    ticketName: '',
                    ticketDNI: '',
                }
            } else {
                return {
                    ticketId: ticket,
                    ticketPrice: tickets.find(tick => tick.id === ticket).price,
                    ticketName: values.buyerData.name + ' ' + values.buyerData.surname,
                    ticketDNI: values.buyerData.dni,
                }
            }
            })]});
    }, [selectedTickets, activeStep]);

    useEffect(() => {
        const errorCheck = Object.values(errors.buyerData).some((error) => error === true);
        const requiredCheck = Object.values(values.buyerData).some((value) => value === '');

        if (activeStep === 1) {
            if (!errorCheck && !requiredCheck) {
                setDisabledButton(false);
            } else {
                setDisabledButton(true);
            }
        }
    }, [values.buyerData, activeStep]);


    useEffect(() => {
        let errorCheck = false;
        let requiredCheck = false;

        if (activeStep === 2) {

            for (let i = 0; i < selectedTickets.length; i++) {
                try {
                    errorCheck = Object.values(errors.ticketsData[i]).some((error) => error === true);
                    requiredCheck = Object.values(values.ticketsData[i]).some((value) => value === '');

                    if (errorCheck || requiredCheck) {
                        break;
                    }
                } catch (e) {
                }
            }

            if (!errorCheck && !requiredCheck) {
                setDisabledButton(false);
            } else {
                setDisabledButton(true);
            }
        }
    }, [values.ticketsData, activeStep]);

    return (
        <>
            <div className="p-md-5" style={{backgroundColor: "whitesmoke"}}>
                <div className="container">
                    <div className="eventCardPreview mt-5 p-3 p-md-4 p-lg-5" style={{minHeight: "100vh"}}>
                        {!isLoaded ? (
                            <div style={{position: "absolute", top: "50%", left: "50%", transform: `translate(-50%, -50%)`}}>
                                <CircularProgress color="secondary" size={100} />
                            </div>
                        ) : (
                        <>
                        <div className="row">
                            <div className="col-lg-5">
                                <div style={{overflow: "hidden", borderRadius: "1rem"}}>
                                    <img className="img-fluid" src={event?.portada}/>
                                </div>
                            </div>
                            <div className="col-lg-7 ps-lg-5">
                                <div className="row">
                                    <div className="col-12 mt-5 mt-lg-0">
                                        <h2>{event?.name}</h2>
                                        <h5 style={{opacity: "0.75"}}>{event?.producer}</h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mt-3">
                                        <FaRegCalendarAlt/><span className="ms-2">Fecha</span>
                                        <Typography variant="body2" color="text.secondary"
                                                    className="mt-2">{event?.date_start}</Typography>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <MdLocationOn/><span className="ms-2">Ubicación</span>
                                        <Typography variant="body2" color="text.secondary"
                                                    className="mt-2">{event?.location}</Typography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mt-3">
                                        <MdPayment/><span className="ms-2">Medios de pago</span>
                                        <Typography variant="body2" color="text.secondary" className="mt-2">
                                            <img className="img-fluid" width="30"
                                                 src={"../assets/images/visa.png"}/>
                                            <img className="img-fluid ms-3" width="30"
                                                 src={"../assets/images/mastercard.png"}/>
                                            <img className="img-fluid ms-3" width="30"
                                                 src={"../assets/images/american-express.png"}/>
                                        </Typography>
                                    </div>
                                    {event?.limitage && (
                                        <div className="col-md-6 mt-3">
                                            <GrUserAdd/><span className="ms-2">Edad mínima</span>
                                            <Typography variant="body2" color="text.secondary"
                                                        className="mt-2">{event?.limitage} años</Typography>
                                        </div>
                                    )}
                                </div>
                                {event.artists && (
                                    <>
                                <hr className="mb-3 mt-4" style={{marginTop: 0}}/>
                                <div className="row">
                                    <div className="col-12">
                                        <h5>Artistas</h5>
                                        <div className="row">
                                            {event?.artists.map((artist, index) => (
                                                <div className="col-6 col-md-4 col-lg-3 mt-3" key={index}>
                                                    <div className="card" style={{borderRadius: "1rem"}}>
                                                        <img className="card-img-top" src={artist?.image}
                                                                style={{borderRadius: "1rem 1rem 0 0"}}/>
                                                        <div className="card-body">
                                                            <h6 className="card-title">{artist?.name}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                    </>
                                )}
                                <hr className="mb-3 mt-4" style={{marginTop: 0}}/>
                                <div className="row">
                                    <div className="col-12">
                                        <b>Tickets</b>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <div className="eventTickets">
                                            <table className="table table-borderless table-sm">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Ticket</th>
                                                    <th scope="col">Precio</th>
                                                    <th scope="col">Cantidad</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {tickets.map((ticket, index) => (
                                                    <tr key={index}>
                                                        <td style={{verticalAlign: "middle"}}>
                                                            <div>
                                                                {ticket.name}
                                                                <small className="d-block" style={{opacity: "0.75", fontSize: "70%"}}>{ticket.description}</small>
                                                            </div>
                                                        </td>
                                                        <td style={{verticalAlign: "middle", minWidth: "100px"}}>$ {ticket.price.toLocaleString().replace(",", ".")}</td>
                                                        <td style={{verticalAlign: "middle"}}>
                                                            <select id={ticket.id}
                                                                    value={selectedTickets.filter(tickets => tickets === ticket.id).length}
                                                                    onChange={handleSelectTickets}
                                                                    className="form-select"
                                                                    style={{maxWidth: "8rem"}}>
                                                                <option key={0}>0</option>
                                                                {Array.from(Array(ticket.quantity).keys()) < 10 ? (Array.from(Array(ticket.quantity).keys()).map((i) => (
                                                                    <option key={i + 1}
                                                                            value={i + 1}>{i + 1}</option>
                                                                ))) : (
                                                                    Array.from(Array(10).keys()).map((i) => (
                                                                        <option key={i + 1}
                                                                                value={i + 1}>{i + 1}</option>
                                                                    ))
                                                                )}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mt-3">
                                        <b style={{fontSize: "1.5rem"}}>Subtotal</b>
                                        <p style={{fontSize: "2rem"}}><b>$ {
                                            //sum total of ticket prices and multiply by the amount of tickets
                                            tickets.map(ticket => ticket.price * selectedTickets.filter(tickets => tickets === ticket.id).length).reduce((a, b) => a + b, 0).toLocaleString().replace(",", ".")
                                        }</b></p>

                                    </div>
                                </div>
                                <div className="row mt-0">
                                    <div className="col-12">
                                        <button className="btn btn-primary" onClick={() => setOpen(true)}
                                                disabled={!selectedTickets.length > 0}>Comprar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <Typography variant="h6" gutterBottom>
                                    Descripción del evento
                                </Typography>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12" style={{whiteSpace: "pre-wrap"}}>
                                <p>{event?.description}</p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12" style={{whiteSpace: "pre-wrap",textAlign: "justify", textJustify: "inter-word"}}>
                                <small className="text-muted" style={{fontSize: ".8rem"}}>La carga de productos y textos en el sistema licenciado son de exclusiva responsabilidad del Productor/Organizador. FlashPass no se hace responsable civil o jurídicamente por las declaraciones emitidas por estos en lo relativo a los productos vendidos en su plataforma. El Productor/Organizador es el único y exclusivo responsable de la producción y organización del Evento, en forma oportuna y en conformidad a la ley. <a href={"https://flashpass.com.ar/terminos-y-condiciones"} className="text-muted text-decoration-underline">Ver términos y condiciones</a>.</small>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <EventsSwiper title={"Otros eventos que te podrían interesar"} />
                        </div>
                    </>)}
                    </div>
                </div>
            </div>


            <Dialog onClose={handleClose} open={open} fullWidth>
                <div className="container pt-3" style={{position: "relative"}}>
                    <div className="closeIcon p-1 align-items-center d-flex justify-content-center">
                        <IoClose size={25} color={"rgba(0, 0, 0, 0.38)"} onClick={handleClose}/>
                    </div>
                    <div className="p-2 p-md-3 p-lg-5">
                        <div className="row">
                            <div className="col-12">
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>
                                                <div className="d-none d-md-block">{label}</div>
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>
                        </div>
                        {activeStep === 0 && (
                            <>
                                <div className="row mt-3 text-center">
                                    <div className="col-12">
                                        <p>Tus entradas</p>
                                    </div>
                                </div>
                                {tickets.map((ticket, index) => selectedTickets.filter(tickets => tickets === ticket.id).length > 0 && (
                                    <div className="row mb-2" key={index}>
                                        <div className="col-12">
                                            <div className="p-2"
                                                 style={{borderRadius: "0.5rem", border: "solid 1px"}}>
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <span><b>{ticket.name}</b></span>
                                                        <p className="mt-0 mb-0">$ {(ticket.price * selectedTickets.filter(sel => sel === ticket.id).length).toLocaleString().replace(",", ".")}</p>
                                                    </div>
                                                    <div className="col text-end">
                                                        {selectedTickets.filter(tickets => tickets === ticket.id).length > 1 ? (
                                                            <button style={{
                                                                border: "none",
                                                                backgroundColor: "transparent"
                                                            }} onClick={() => reduceTicket(ticket.id)}>-</button>
                                                        ) : (
                                                            <button style={{
                                                                border: "none",
                                                                backgroundColor: "transparent"
                                                            }} onClick={() => reduceTicket(ticket.id)}><BsTrash
                                                                style={{marginBottom: "2.4px"}} size={14}/></button>
                                                        )}
                                                        <span>{selectedTickets.filter(tickets => tickets === ticket.id).length}</span>
                                                        {selectedTickets.filter(tickets => tickets === ticket.id).length < ticket.quantity && selectedTickets.filter(tickets => tickets === ticket.id).length < 10 ? (
                                                            <button style={{
                                                                border: "none",
                                                                backgroundColor: "transparent"
                                                            }} onClick={() => addTicket(ticket.id)}>+</button>
                                                        ) : (
                                                            <button style={{
                                                                border: "none",
                                                                backgroundColor: "transparent"
                                                            }} disabled>+</button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="row mt-3">
                                    <div className="col-8">
                                        <p>Costo de las entradas:</p>
                                        <p>Costo del servicio:</p>
                                    </div>
                                    <div className="col text-end">
                                        <p>$ {tickets.map(ticket => ticket.price * selectedTickets.filter(tickets => tickets === ticket.id).length).reduce((a, b) => a + b, 0).toLocaleString().replace(",", ".")}</p>
                                        <p>$ {tickets.map(ticket => fp_fee * ticket.price * selectedTickets.filter(tickets => tickets === ticket.id).length).reduce((a, b) => a + b, 0).toLocaleString().replace(",", ".")}</p>
                                    </div>
                                </div>
                                <hr className="mb-3" style={{marginTop: 0}}/>
                                <div className="row mt-3">
                                    <div className="col-8">
                                        <p><b>Total:</b></p>
                                    </div>
                                    <div className="col text-end">
                                        <p>
                                            <b>$ {tickets.map(ticket => (1 + fp_fee) * ticket.price * selectedTickets.filter(tickets => tickets === ticket.id).length).reduce((a, b) => a + b, 0).toLocaleString().replace(",", ".")}</b>
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-3 text-center">
                                    <div className="col-12">
                                        <Button onClick={handleNext} disabled={!selectedTickets.length > 0}>
                                            {activeStep === steps.length - 1 ? 'Ir al pago' : 'Siguiente'}
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                        {activeStep === 1 && (
                            <>
                                <form onSubmit={submitForm}>
                                    <div className="row text-center">
                                        <div className="col-lg-6 mt-3">
                                            <TextField label="Nombre" variant="outlined" fullWidth
                                                       value={values.buyerData.name}
                                                       onChange={handleBuyerChange('name')}
                                                       error={errors.buyerData.name}
                                                       helperText={errorMessages.buyerData.name} required/>
                                        </div>
                                        <div className="col-lg-6 mt-3">
                                            <TextField label="Apellido" variant="outlined" fullWidth
                                                       value={values.buyerData.surname}
                                                       onChange={handleBuyerChange('surname')}
                                                       error={errors.buyerData.surname}
                                                       helperText={errorMessages.buyerData.surname} required/>
                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        <div className="col-12 mt-3">
                                            <TextField label="DNI" variant="outlined" type="number" onWheel={(e) => e.preventDefault()}  fullWidth
                                                       value={values.buyerData.dni}
                                                       onChange={handleBuyerChange('dni')}
                                                       error={errors.buyerData.dni}
                                                       helperText={errorMessages.buyerData.dni} required/>
                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        <div className="col-lg-6 mt-3">
                                            <TextField label="Email" variant="outlined" type="email" fullWidth
                                                       value={values.buyerData.email}
                                                       onChange={handleBuyerChange('email')}
                                                       error={errors.buyerData.email}
                                                       helperText={errorMessages.buyerData.email} required/>
                                        </div>
                                        <div className="col-lg-6 mt-3">
                                            <TextField label="Confirmar Email" variant="outlined" type="email"
                                                       fullWidth value={values.buyerData.confirmEmail}
                                                       onChange={handleBuyerChange('confirmEmail')}
                                                       error={errors.buyerData.confirmEmail}
                                                       helperText={errorMessages.buyerData.confirmEmail} required/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 mt-3">
                                            <TextField label="Teléfono" variant="outlined" type="number" onWheel={(e) => e.preventDefault()}  fullWidth
                                                       value={values.buyerData.phone}
                                                       onChange={handleBuyerChange('phone')} required/>
                                        </div>
                                        <div className="col-lg-6 mt-3">
                                            <FormControl fullWidth className="mb-3">
                                                <InputLabel id="demo-simple-select-label">Provincia</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={values.buyerData.province}
                                                    label="Provincia"
                                                    onChange={(event) => {
                                                        setValues({
                                                            ...values,
                                                            buyerData: {
                                                                ...values.buyerData,
                                                                province: event.target.value
                                                            }
                                                        });
                                                    }}
                                                    required
                                                >
                                                    {provinceList.map((province, key) => (
                                                        <MenuItem key={key}
                                                                  value={province.province_id}>{province.province_name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        <div className="col-12 mt-3">
                                            <Button onClick={handleBack}>
                                                Atrás
                                            </Button>
                                            <Button type="submit" disabled={disabledButton}>
                                                Siguiente
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </>
                        )}
                        {activeStep === 2 && (
                            <>
                                {selectedTickets.sort().map((sel, index) => (
                                    <div key={index}>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <span>Entrada {index + 1} - {tickets?.filter(ticket => ticket?.id === sel)[0]?.name}</span>
                                            </div>
                                        </div>
                                        <div className="row text-center">
                                            <div className="col-lg-6 mt-2">
                                                <TextField label="Nombre Completo" variant="outlined" fullWidth
                                                           value={values?.ticketsData[index]?.ticketName}
                                                           onChange={handleTicketChange('ticketName', index, tickets?.filter(ticket => ticket?.id === sel)[0]?.id, tickets?.filter(ticket => ticket?.id === sel)[0]?.price)}
                                                           error={errors?.ticketsData[index]?.ticketName}
                                                           helperText={errorMessages?.ticketsData[index]?.ticketName}/>
                                            </div>
                                            <div className="col-lg-6 mt-2">
                                                <TextField label="DNI" variant="outlined" type="number" onWheel={(e) => e.preventDefault()}  fullWidth
                                                           value={values?.ticketsData[index]?.ticketDNI}
                                                           onChange={handleTicketChange('ticketDNI', index, tickets?.filter(ticket => ticket?.id === sel)[0]?.id, tickets?.filter(ticket => ticket?.id === sel)[0]?.price)}
                                                           error={errors?.ticketsData[index]?.ticketDNI}
                                                           helperText={errorMessages?.ticketsData[index]?.ticketDNI}/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="row text-center">
                                    <div className="col-12 mt-3">
                                        <Button onClick={handleBack}>
                                            Atrás
                                        </Button>
                                        <Button onClick={handleNext} disabled={disabledButton}>
                                            Siguiente
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                        {activeStep === 3 && (
                            <>
                                <div className="row mt-3 text-center">
                                    <div className="col-12">
                                        <p>Revisa tus datos</p>
                                    </div>
                                </div>
                                <div className="row mt-3 text-center">
                                    <div className="col-12">
                                        <p>Nombre: <b>{values.buyerData.name}</b></p>
                                        <p>Apellido: <b>{values.buyerData.surname}</b></p>
                                        <p>DNI: <b>{values.buyerData.dni}</b></p>
                                        <p>Email: <b>{values.buyerData.email}</b></p>
                                        <p>Teléfono: <b>{values.buyerData.phone}</b></p>
                                        <p>Tus entradas:</p>
                                        {tickets.map((ticket, index) => selectedTickets.filter(tickets => tickets === ticket.id).length > 0 && (
                                            <p key={index}>{ticket.name}: <b>{selectedTickets.filter(tickets => tickets === ticket.id).length}</b>
                                            </p>
                                        ))}
                                        <p>Al confirmar la orden estas aceptando nuestros <span
                                            className="text-decoration-underline">términos y condiciones</span></p>
                                    </div>
                                </div>
                                <div className="row mt-3 text-center">
                                    <div className="col-12">
                                        <Button onClick={handleBack}>
                                            Atras
                                        </Button>
                                        <Button onClick={checkout}>
                                            Confirmar
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default EventPreview;