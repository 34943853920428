import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button, CircularProgress, FormControl, InputLabel, MenuItem, Select,
    Snackbar,
    TextField
} from "@mui/material";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";

const MenuEventAssign = (props) => {

    const user = useSelector(selectUser)

    const [values, setValues] = useState({
        event_id: '',
        menu_id: props.menu,
    });

    const navigate = useNavigate();

    const [events, setEvents] = useState([]);
    const [roles, setRoles] = useState([]);
    const [availableRoles, setAvailableRoles] = useState([]);
    const [hideFreeTickets, setHideFreeTickets] = useState(false);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const assignEvent = async (e) => {
        e.preventDefault();
        setIsLoaded(false);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/menu-events`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify(values),
        });

        const json = await response.json();

        //console.log(json);
        if (json.status === "success") {
            setOpenS(true);
            setSuccessMessage(json.message);
            setTimeout(() => {
                navigate(-1);
            }, 2000);
        }
        else {
            setOpenE(true);
            setErrorMessage(json.message);
        }
        setIsLoaded(true);

    }

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/menu-events?menu_id=${props.menu}&type=assign-event`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const status = await response.status;

        const json = await response.json();

        if (json.status === "success") {
            setEvents(json.events);
        }
        setIsLoaded(true);
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    useEffect(() => {
        // Check if values.role is one of 6, 7, or 8
        if ([7, 8, 9].includes(values.role)) {
            setValues(prevValues => ({ ...prevValues, cash: 0, cortesias: 0 }));
            setHideFreeTickets(true);
        }

        if (values.role !== 9) {
            setValues(prevValues => ({ ...prevValues, table_id: '' }));
        }
    }, [values.role]);


    useEffect(() => {
        setValues({...values, role: ''})
        if (events.filter(event => event.event_id === values.events)[0]?.rrpp === 1) {
            setAvailableRoles(roles.filter(role => role.role_id == 4));
            setValues({...values, cash: 0, cortesias: 0});
            setHideFreeTickets(true);
        }
        else {
            setAvailableRoles(roles.filter(role => role.role_id != 4));
            setHideFreeTickets(false);
        }
    }, [values.events]);

    const [isLoaded, setIsLoaded] = useState(false);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
                <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                    <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                        No hay eventos disponibles para asignar!
                    </Alert>
                </Snackbar>
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            <div className="row">
                <div className="col">
                    <h1>Asignar Carta Digital a Evento</h1>
                </div>
            </div>
            <form onSubmit={assignEvent}>
                <div className="row mb-2 mt-2">
                    <div className="col-md-6">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Evento</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.event_id}
                                label="Evento"
                                onChange={handleChange('event_id')}
                                required
                            >
                                {events.map((event, index) => (
                                    <MenuItem key={index} value={event.event_id}>{event.event_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Button variant="contained" type="submit" color="primary" className="me-2">
                            Asignar
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
    }
};

export default MenuEventAssign;
