import React, {useState} from 'react';
import {Alert, CircularProgress, Snackbar, TextField} from "@mui/material";
import {setEventChange} from "../redux/eventsChangeSlice";
import {Button} from "react-bootstrap";
import {selectUser} from "../redux/userSlice";
import {useSelector} from "react-redux";

const DigitalMenuCategory = (props) => {

    const user = useSelector(selectUser);

    const [values, setValues] = useState({
        category_name: '',
        category_description: '',
    });

    const [isLoaded, setIsLoaded] = useState(false);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const fetchData = async () => {
        if (props.category) {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-categories?menu_id=${props.menu}&category_id=${props.category}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                    'Content-Type': 'application/json',
                }
            });

            const json = await request.json();

            if (json.status === "success") {
                setValues({
                    category_name: json.category.name,
                    category_description: json.category.description,
                });
                setIsLoaded(true);
            }
        } else {
            setIsLoaded(true);
        }
    }

    React.useEffect(() => {
        if (isLoaded) return;
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        let response;

        if (props.category) {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-categories`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'credentials': 'same-origin',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify({
                    ...values,
                    menu_id: props.menu,
                    category_id: props.category,
                }),
            });

            response = await request.json();
        } else {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-categories`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'credentials': 'same-origin',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify({
                    ...values,
                    menu_id: props.menu,
                }),
            });

            response = await request.json();
        }

        if (response.status === "success") {
            setSuccessMessage(response.message);
            setOpenS(true);
        } else {
            setErrorMessage(response.message);
            setOpenE(true);
        }
    }

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            <div className="row mt-2">
                <div className="col-12">
                    <h4>{
                        props.category ? "Editar categoría " : "Crear categoría"
                    }</h4>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-lg-6">
                    <form onSubmit={handleSubmit}>
                        <TextField label="Nombre de la categoría" value={values.category_name} onChange={handleChange('category_name')} className="mb-3" fullWidth variant="outlined" />
                        <TextField label="Descripción de la categoría" value={values.category_description} rows={4} multiline onChange={handleChange('category_description')} className="mb-3" fullWidth variant="outlined" />
                        <Button variant="dark" type="submit">
                            {
                                props.category ? "Editar categoría" : "Crear categoría"
                            }
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
    }
};

export default DigitalMenuCategory;