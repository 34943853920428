import React, {useState} from 'react';
import {useNavigate} from "react-router";
import {CircularProgress} from "@mui/material";
import {Button} from "react-bootstrap";
import MenuContainerAdmin from "./MenuContainerAdmin";

const ComponentsList = () => {
    const navigate = useNavigate();

    const [components, setComponents] = React.useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const token = localStorage.getItem('token');

    const fetchData = async () => {
        setIsLoaded(false);
        const request = await fetch(`${process.env.REACT_APP_API_URL}/component`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        const data = await request.json();

        if (data.status === "success") {
            setComponents(data.components);
            setIsLoaded(true);
        }

    }

    const deleteComponent = async (id) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-component`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                component_id: id
            }),
        });

        const response = await request.json();

        if (response.status === 'success') {
            fetchData();
        }
    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [token]);

    return (
        <>
            {!isLoaded ? (
                <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                    <CircularProgress color="secondary" size={100} />
                </div>
            ) : (
                <div>
                    <div className="row mt-2 justify-content-between align-items-center">
                        <div className="col">
                            <h3>Componentes</h3>
                        </div>
                        <div className="col-auto">
                            <div className="row justify-content-end">
                                <div className="col-12">
                                    <Button variant="dark" onClick={() => navigate("./add")} className="float-right">+ Crear</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            {components?.length > 0 ? (
                                <MenuContainerAdmin delete={deleteComponent} rows={components} />
                            ) : (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-info" role="alert">
                                            No hay componentes creados.
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ComponentsList;