// EmailsSentAndRevenueChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';

const EmailsSentAndRevenueChart = ({ data }) => {
    const chartData = {
        labels: data.map((d) => d.date),
        datasets: [
            {
                label: 'Emails Sent',
                data: data.map((d) => d.sent),
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
                fill: false,
            },
            {
                label: 'Opened',
                data: data.map((d) => d.opened),
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 2,
                fill: false,
            },
        ],
    };

    return <Line data={chartData} />;
};

export default EmailsSentAndRevenueChart;
