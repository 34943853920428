import React, {useState} from 'react';
import {Button, Table} from "react-bootstrap";
import {useNavigate} from "react-router";
import {Alert, AlertTitle, CircularProgress} from "@mui/material";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {number_format} from "./functions/numberFormat";

const EditTickets = (props) => {

    const user = useSelector(selectUser)

    let navigate = useNavigate();
    const [tickets, setTickets] = React.useState([]);

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/event-tickets-list?event_id=${props.event}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();
        //console.log(json.tickets);
        setTickets(json.tickets);
        setIsLoaded(true);
    }

    const agotarTicket = async (ticketId) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/event-ticket-finish?ticket_id=${ticketId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();

        if (json.status === "success") {
            //console.log(json);
        }
        return json;
    }

    const confirmarAgotarTicket = (ticketId) => {
        const mySwal = withReactContent(Swal);
        mySwal.fire({
            title: '¿Estás seguro?',
            text: "Podras activar el ticket nuevamente en cualquier momento",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Sí, agotar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                agotarTicket(ticketId).then((response) => {
                    if (response.status === "success") {
                        mySwal.fire(
                            '¡Agotado!',
                            'El ticket ha sido agotado.',
                            'success'
                        ).then(() => {
                            setIsLoaded(false);
                        })
                    }
                    else {
                        mySwal.fire(
                            '¡Error!',
                            'Ha ocurrido un error.',
                            'error'
                        )
                    }
                })
            }
        })
    }

    const activarTicket = async (ticketId) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/event-ticket-activate?ticket_id=${ticketId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();

        if (json.status === "success") {
            //console.log(json);
        }
        return json;
    }

    const confirmarActivarTicket = (ticketId) => {
        const mySwal = withReactContent(Swal);
        mySwal.fire({
            title: '¿Estás seguro?',
            text: "El ticket volverá a estar disponible para la venta",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Sí, activar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                activarTicket(ticketId).then((response) => {
                    if (response.status === "success") {
                        mySwal.fire(
                            '¡Activado!',
                            'El ticket ha sido activado.',
                            'success'
                        ).then(() => {
                            setIsLoaded(false);
                        })
                    }
                    else {
                        mySwal.fire(
                            '¡Error!',
                            'Ha ocurrido un error.',
                            'error'
                        )
                    }
                })
            }
        })
    }

    const [isLoaded, setIsLoaded] = useState(false);

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [isLoaded, user.token]);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <div className="row align-items-center mb-2">
                <div className="col-6">
                    <h1>Tickets</h1>
                </div>
                <div className="col text-end">
                    <Button variant="primary" onClick={() => navigate(`/organizations/events/${props.event}/tickets/new`)}>Agregar ticket</Button>
                </div>
            </div>
            {tickets.length > 0 ? (
            <div className="row">
                <div className="col-12 d-none d-md-block">
                    <Table striped bordered responsive="md">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Precio</th>
                                <th>Vendidas</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tickets.map((ticket, key) => (
                                <tr key={key}>
                                    <td>{ticket.event_ticket_name.toUpperCase()}</td>
                                    <td>$ {number_format(ticket.event_ticket_value, 0, ',', '.')}</td>
                                    <td>{`${ticket.sold} / ${ticket.event_ticket_limit}`}</td>
                                    <td>
                                        <div className="row mb-2">
                                            <div className="col">
                                                <Button className="w-100" variant="primary" onClick={() => navigate(`./${ticket.event_ticket_id}`)}>Editar</Button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                {ticket.event_ticket_active === 1 ? (
                                                <Button className="w-100" variant="danger" onClick={() => confirmarAgotarTicket(ticket.event_ticket_id)}>Agotar</Button>
                                                ) : (
                                                <Button className="w-100" variant="success" onClick={() => confirmarActivarTicket(ticket.event_ticket_id)}>Activar</Button>
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                <div className="col-12 d-block d-md-none">
                    <Table striped bordered responsive="md">
                        <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Vendidas</th>
                            <th>Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tickets.map((ticket, key) => (
                            <tr key={key}>
                                <td>{ticket.event_ticket_name.toUpperCase()}</td>
                                <td>{`${ticket.sold} / ${ticket.event_ticket_limit}`}</td>
                                <td>
                                    <div className="row mb-2">
                                        <div className="col">
                                            <Button className="w-100" variant="primary" onClick={() => navigate(`./${ticket.event_ticket_id}`)}>Editar</Button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            {ticket.event_ticket_active === 1 ? (
                                                <Button className="w-100" variant="danger" onClick={() => confirmarAgotarTicket(ticket.event_ticket_id)}>Agotar</Button>
                                            ) : (
                                                <Button className="w-100" variant="success" onClick={() => confirmarActivarTicket(ticket.event_ticket_id)}>Activar</Button>
                                            )}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            </div>
            ) : (
                <div className="d-flex justify-content-center align-items-start" style={{marginTop: "10vh"}}>
                    <Alert severity="warning">
                        <AlertTitle>No se encontraron tickets para este evento.</AlertTitle>
                        No tenes tickets creados — <strong><Link style={{textDecoration: "none", color: "indianred"}} to={"./new"}>Agregá el primer ticket</Link></strong>
                    </Alert>
                </div>
            )}
        </div>
    );
    }
};

export default EditTickets;