import React, { useEffect } from 'react';
import useDebounce from './hooks/useDebounce';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';

const DateFilter = ({ fromDate, toDate, onDateRangeChange, minDate, maxDate }) => {
    const debouncedFromDate = useDebounce(fromDate, 300);
    const debouncedToDate = useDebounce(toDate, 300);

    useEffect(() => {
        if (debouncedFromDate && debouncedToDate) {
            onDateRangeChange({ fromDate: debouncedFromDate, toDate: debouncedToDate });
        }
    }, [debouncedFromDate, debouncedToDate, onDateRangeChange]);

    const handleFromDateChange = (value) => {
        const formattedDate = dayjs(value).format('YYYY-MM-DD');
        if (toDate && dayjs(formattedDate).isAfter(dayjs(toDate))) {
            onDateRangeChange({ fromDate: toDate, toDate: formattedDate });
        } else {
            onDateRangeChange({ fromDate: formattedDate, toDate });
        }
    };

    const handleToDateChange = (value) => {
        const formattedDate = dayjs(value).format('YYYY-MM-DD');
        if (fromDate && dayjs(formattedDate).isBefore(dayjs(fromDate))) {
            onDateRangeChange({ fromDate: formattedDate, toDate: fromDate });
        } else {
            onDateRangeChange({ fromDate, toDate: formattedDate });
        }
    };


    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="From:"
                    inputFormat="DD/MM/YYYY"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    renderInput={(params) => <TextField fullWidth variant="filled" {...params} />}
                    minDate={minDate}
                    maxDate={maxDate}
                />
                <DatePicker
                    label="To:"
                    inputFormat="DD/MM/YYYY"
                    value={toDate}
                    onChange={handleToDateChange}
                    renderInput={(params) => <TextField fullWidth variant="filled" {...params} />}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            </LocalizationProvider>
        </div>
    );
};

export default DateFilter;