import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {useEffect} from "react";
import {CircularProgress} from "@mui/material";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function MultipleSelect(props) {

    const user = useSelector(selectUser);
    const [personName, setPersonName] = React.useState([]);


    const [names, setNames] = React.useState([]);
    const [total, setTotal] = React.useState(0);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        props.onChange(event);
        setIsLoaded(false);
    };

    const checkTotal = async (value) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/campaign-groups`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                "ids": personName
            })
            });
        const response = await request.json();
        //console.log(response);
        if (response.success === "1") {
            setTotal(response.total);
            setIsLoaded(true);
        }
    }

    const [isLoaded, setIsLoaded] = React.useState(false);

    useEffect(() => {
        checkTotal();
    }, [personName]);

    useEffect(() => {
        setNames(props.events)
        setPersonName(props.values)
    }, [props.events]);

    return (
        <>
            <FormControl fullWidth>
                <InputLabel id="demo-multiple-checkbox-label">{props.tag}</InputLabel>
                <Select
                    multiple
                    label={props.tag}
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput label={props.tag} />}
                    renderValue={(selected) => names.filter((name) => selected.includes(name.id)).map((name) => name.name).join(', ')}
                    MenuProps={MenuProps}
                    required
                >
                    {names.map((name) => (
                        <MenuItem key={name.id} value={name.id}>
                            <Checkbox checked={personName.indexOf(name.id) > -1} />
                            <ListItemText primary={name.name} />
                        </MenuItem>
                    ))}
                </Select>
                <small className="mt-1 text-muted">{isLoaded ? `${total} emails seleccionados.` : <CircularProgress className="mt-2" color="secondary" size={20} />}</small>
            </FormControl>
        </>
    );
}
