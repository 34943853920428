import React, {useState} from 'react';
import {Alert, CircularProgress, Snackbar, TextField} from "@mui/material";
import {setEventChange} from "../redux/eventsChangeSlice";
import {Button} from "react-bootstrap";
import {selectUser} from "../redux/userSlice";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";
import MenuContainerAdmin from "./MenuContainerAdmin";
import WaiterOrdersTable from "./WaiterOrdersTable";

const WaiterOrdersList = (props) => {
    const navigate = useNavigate();

    const [orders, setOrders] = React.useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const token = localStorage.getItem('token');

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/menu-orders`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2OTY3MTU4NzQsImlzcyI6ImZsYXNocGFzcy5jb20uYXIiLCJuYmYiOjE2OTY3MTU4NzQsImV4cCI6MTcxMjUyNzA3NCwidXNlcklkIjo1OTk2LCJwcm9kdWNlcklkIjpudWxsfQ.pRd5-GF4NFqi7dAFhjebylyD69V3dh03I3OgBk0C-Pg',
            },
        });

        const result = await response.json();

        if (result?.status === 'success') {
            setOrders(result.data.filter((order) => props.tables.includes(order?.table_number)));
            setIsLoaded(true);
        }

    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();

            const interval = setInterval(fetchData, 10000);

            // Clear the interval when the component is unmounted
            return () => clearInterval(interval);
        }, 300);
        return () => clearTimeout(requests);
    }, [token]);

    return (
        <>
            {!isLoaded ? (
                <div className="container" style={{minHeight: "100vh"}}>
                    <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                        <CircularProgress color="secondary" size={100} />
                    </div>
                </div>
            ) : (
                <div className="container" style={{minHeight: "100vh"}}>
                    <div className="row mt-2 justify-content-between align-items-center">
                        <div className="col">
                            <h3>Pedidos</h3>
                        </div>
                        <div className="col-auto">
                            <div className="row justify-content-end">
                                <div className="col-12">
                                    <Button variant="dark" onClick={() => {
                                        window.open("/menu/mata-club-7041/mata-club-vip-34-6286", "_blank");
                                    }} className="float-right">Hacer Pedido</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            {orders?.length > 0 ? (
                                <WaiterOrdersTable rows={orders} />
                            ) : (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-info" role="alert">
                                            No hay eventos asignados.
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default WaiterOrdersList;