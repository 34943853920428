import React, {useEffect, useRef, useState} from 'react';
import {Button, Table} from "react-bootstrap";
import {CircularProgress, InputAdornment, TextField} from "@mui/material";
import {useNavigate} from "react-router";
import {BsSearch} from "react-icons/bs";
import {money_format} from "./functions/numberFormat";


const MenuOrders = (props) => {

    const token = localStorage.getItem('token');

    const [isLoaded, setIsLoaded] = useState(false);
    const [orders, setOrders] = useState([]);
    const navigate = useNavigate();

    const [prevOrdersNumber, setprevOrdersNumber] = React.useState(0);
    const [totalOrders, setTotalOrders] = React.useState([]);
    const [search, setSearch] = React.useState("");

    const searchValueRef = useRef(search);
    const searchRequestRef = useRef(null);

    const [loadingTable, setLoadingTable] = React.useState(false);

    const [page, setPage] = React.useState(1);

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

        // Check if we're within 100 pixels of the bottom edge
        if (!loadingTable && scrollHeight - scrollTop <= clientHeight + 100 && orders.length > prevOrdersNumber) {
            setprevOrdersNumber(orders.length);
            loadMoreData();
        }
    };


    const loadMoreData = () => {
        setPage(prevPage => prevPage + 1);
    };

    const [loadingSearch, setLoadingSearch] = useState(false);

    useEffect(() => {
        if (token) {
            searchValueRef.current = search;
            if (searchRequestRef.current) {
                searchRequestRef.current.abort();
            }
            const controller = new AbortController();
            searchRequestRef.current = controller;

            setLoadingSearch(true);

            const requests = setTimeout(() => {
                fetchData(search, 1, controller.signal)
                    .catch((error) => {
                        if (error.name !== 'AbortError') {
                            console.error('Error fetching data:', error);
                        }
                    })
                    .finally(() => {
                        setLoadingSearch(false);
                    });
            }, 300);

            return () => {
                clearTimeout(requests);
                controller.abort();
            };
        }
    }, [search]);

    useEffect(() => {
        if (token && !loadingSearch) {
            const controller = new AbortController();
            if (page === 1) {
                setIsLoaded(false);
            } else {
                setLoadingTable(true);
            }
            fetchData(searchValueRef.current, page, controller.signal) // Fetch data with current search query when page changes
                .catch((error) => {
                    if (error.name !== 'AbortError') {
                        console.error('Error fetching data:', error);
                    }
                });
            return () => {
                controller.abort();
            };
        }
    }, [page, token]);

    const fetchData = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-orders-supervisor?menu_id=${props.menu}&search=${search}&page=${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        const response = await request.json();

        if (response.status === "success") {
            setOrders(response.data);
        }
        setIsLoaded(true);
        setLoadingTable(false);
    }

    function getViewport () {
        // https://stackoverflow.com/a/8876069
        const width = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
        )

        if (width <= 992) return 'md'
        return 'xl'
    }

    function searchData() {
        // Declare variables
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();

        table = document.getElementsByTagName("table")[0];

        tr = table.getElementsByTagName("tr");

        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
            var td0 = tr[i].getElementsByTagName("td")[0];
            var td1 = tr[i].getElementsByTagName("td")[1];
            var td2 = tr[i].getElementsByTagName("td")[2];
            var td3 = tr[i].getElementsByTagName("td")[4];
            if (td0 || td1 || td2 || td3) {
                var txtValue0 = td0.textContent || td0.innerText;
                var txtValue1 = td1.textContent || td1.innerText;
                var txtValue2 = td2.textContent || td2.innerText;
                var txtValue3 = td3.textContent || td3.innerText;
                if (txtValue0.toUpperCase().indexOf(filter) > -1 || txtValue1.toUpperCase().indexOf(filter) > -1 || txtValue2.toUpperCase().indexOf(filter) > -1 || txtValue3.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }

    }

    React.useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [token]);


    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
        return (
            <div className="container" style={{backgroundColor: "white", minHeight: "100vh"}}>
                <div className="row mt-3">
                    <div className="col-12">
                        <h3>Ordenes</h3>
                    </div>
                </div>

                <div className="row mb-3 mt-2">
                    <div className="col">
                        <TextField
                            label="Buscar por ID, email, monto"
                            id="outlined-start-adornment"
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><BsSearch /></InputAdornment>,
                            }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12">
                        {loadingSearch ? (
                            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                                <CircularProgress color="secondary" size={100} />
                            </div>
                        ) : (
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12" style={{maxHeight: "80vh", overflowY: "scroll"}}>
                                <Table striped bordered responsive id="myTable" size="sm">
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Evento</th>
                                        <th>Cliente</th>
                                        <th>Monto</th>
                                        <th>Mesa</th>
                                        <th>Estado</th>
                                        <th>Acciones</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {orders.map((order, key) => (
                                        <tr key={key} style={{fontSize: "12px"}}>
                                            <td style={{wordWrap: "break-word", maxWidth: "150px"}}>{order.id}</td>
                                            <td>{order.event_name}</td>
                                            <td>{order.client_email}</td>
                                            <td>{money_format(order.order_total)}</td>
                                            <td>{order.table_number}</td>
                                            <td>{order.order_status}</td>
                                            <td>
                                                <div className="row">
                                                    <div className="col">
                                                        <Button className="w-100 d-flex align-items-center"
                                                                variant={"dark"}
                                                                onClick={() => navigate(`./${order.id}`)}>
                                                            Ver
                                                        </Button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
};

export default MenuOrders;