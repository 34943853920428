import React, {useState} from 'react';
import CampaignPreview from "./CampaignPreview";
import {CircularProgress, FormControl, Input, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {ImageUpload} from "./ImageUpload";
import "./ImageUpload.css";
import AlertSnackbar from "./AlertSnackbar";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";

const CreateCampaign = (props) => {

    const user = useSelector(selectUser)

    const [groups, setGroups] = useState([]);
    const [availableEvents, setAvailableEvents] = useState([]);

    const [values, setValues] = useState({
        name: '',
        subject: '',
        from_name: '',
        reply_to: '',
        title: '',
        body: '',
        image: '',
        imageAlt: '',
        whatsapp: '',
        instagram: '',
        facebook: '',
        producerName: '',
        group: '',
        campaignLink: '',
        event_id: '',
    });

    const [errors, setErrors] = useState({
        name: false,
        subject: false,
        from_name: false,
        reply_to: false,
        title: false,
        body: false,
        image: false,
        imageAlt: false,
        whatsapp: false,
        instagram: false,
        facebook: false,
        producerName: false,
        campaignLink: false,
    });

    const [errorMessages, setErrorMessages] = useState({
        name: '',
        subject: '',
        from_name: '',
        reply_to: '',
        title: '',
        body: '',
        image: '',
        imageAlt: '',
        whatsapp: '',
        instagram: '',
        facebook: '',
        producerName: '',
        campaignLink: '',
    });

    const inputValidation = (e, prop) => {
        let value = e.target.value;
        let error = false;
        let errorMessage = '';

        switch (prop) {
            case 'from_name':
                if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                    error = true;
                    errorMessage = 'El email no es válido';
                }
                break;
            case 'reply_to':
                if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                    error = true;
                    errorMessage = 'El email no es válido';
                }
                break;
            case 'whatsapp':
                if (!value.startsWith('https://wa.me/message/') && !value.startsWith('https://api.whatsapp.com')) {
                    error = true;
                    errorMessage = 'La URL de WhatsApp debe ser una dirección válida: https://wa.me/message/ o https://api.whatsapp.com';
                }
                break;
            case 'instagram':
                if (!value.startsWith('https://instagram.com/')) {
                    error = true;
                    errorMessage = 'La URL de Instagram debe ser una dirección válida: https://www.instagram.com/usuario';
                }
                break;
            case 'facebook':
                if (!value.startsWith('https://facebook.com/')) {
                    error = true;
                    errorMessage = 'La URL de Facebook debe ser una dirección válida: https://www.facebook.com/usuario';
                }
                break;

        }
        if (!value) {
            error = false;
            errorMessage = '';
        }
        setErrors({...errors, [prop]: error});
        setErrorMessages({...errorMessages, [prop]: errorMessage});
    }

    /* Snackbars */

    const [openSnackOk, setOpenSnackOk] = useState(false);
    const [openSnackError, setOpenSnackError] = useState(false);

    const handleCloseOk = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackOk(false);
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackError(false);
    }

    /* Snackbars */

    const [isLoaded, setIsLoaded] = useState(false);

    const submitCampaign = async (e) => {
        e.preventDefault();
        let request;
        const errorCheck = Object.values(errors).some((error) => error === true);

        if (!errorCheck) {
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('subject', values.subject);
            formData.append('from_name', values.from_name);
            formData.append('reply_to', values.reply_to);
            formData.append('title', values.title);
            formData.append('body', values.body);
            formData.append('image', values.image);
            formData.append('imageAlt', values.imageAlt);
            formData.append('whatsapp', values.whatsapp);
            formData.append('instagram', values.instagram);
            formData.append('facebook', values.facebook);
            formData.append('producerName', values.producerName);
            formData.append('imageLink', values.image);
            formData.append('group', values.group);
            formData.append('campaignLink', values.campaignLink);
            formData.append('event_id', values.event_id);

            if (props.campaign) {
                formData.append('campaign_id', props.campaign);
            }

            request = await fetch(`${process.env.REACT_APP_API_URL}/campaign`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                },
                body: formData,
            });

            const response = await request.json();
            //console.log(response);

            if (response.status === 'success') {
                setOpenSnackOk(true);
                setIsLoaded(false);
            }
            if (response.status === 'error') {
                setOpenSnackError(true);
            }

        }
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        inputValidation(event, prop);
        //console.log(values);
    };

    const changeImage = (image) => {
        setValues({...values, image: image});
    }


    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/campaign?id=${props.campaign}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });
        const json = await response.json();
        //console.log(json);
        setValues(json.campaign);
        setIsLoaded(true);
    }

    const fetchGroups = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/campaign-groups`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });

        const json = await response.json();

        if (json.success === "1") {
            //console.log(json);
            setGroups(json.groups);
            setIsLoaded(true);
        }
        else {
            setIsLoaded(true);
        }
    }

    const fetchEvents = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/campaign-events`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': `Bearer ${user.token}`
            },
        });
        const response = await request.json();
        //console.log(response);
        if (response.status === 'success') {
            setAvailableEvents(response.events);
        }
    }


    React.useEffect(() => {
        setTimeout(() => {
            fetchGroups();
            fetchEvents();
            if (props.campaign) {
                fetchData();
            }
            else {
                setIsLoaded(true);
            }
        }, 200);
    }, [isLoaded, user.token]);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <>
            <AlertSnackbar open={openSnackOk} handleClose={handleCloseOk} severity="success" message="Los datos se guardaron correctamente." />
            <AlertSnackbar open={openSnackError} handleClose={handleCloseError} severity="error" message="Hubo un error al guardar los datos." />
            <div className="row">
                <div className="col-12">
                    <h1>{props.campaign ? "Editar campaña" : "Crear campaña"}</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8">
                    <div className="row">
                        <div className="col-12">
                            <CampaignPreview values={values} />
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mt-3 mt-md-0">
                    <div className="card scrollCampaign">
                        <div className="card-body">
                            <h5 className="card-title">Información de la campaña</h5>
                            <div className="row">
                                <div className="col-12">
                                    <form onSubmit={submitCampaign}>
                                        <TextField label="Nombre de la campaña" value={values.name} required onChange={handleChange('name')} className="mb-2" fullWidth variant="outlined" />
                                        <TextField label="Asunto" value={values.subject} required onChange={handleChange('subject')} className="mb-2" fullWidth variant="outlined" />
                                        <TextField label="De" type="email" value={values.from_name} required onChange={handleChange('from_name')} className="mb-2" fullWidth variant="outlined" error={errors.from_name} helperText={errorMessages.from_name} />
                                        <TextField label="Email de respuesta" type="email" value={values.reply_to} required onChange={handleChange('reply_to')} className="mb-2" fullWidth variant="outlined" />
                                        <ImageUpload title="Imágen" figure="square" onSelectImage={changeImage} image={values.image} />
                                        <TextField label="Texto alternativo Imágen" value={values.imageAlt} onChange={handleChange('imageAlt')} className="mb-2 mt-3" fullWidth variant="outlined" />
                                        <FormControl variant="outlined" className="mb-2 mt-2" fullWidth>
                                            <InputLabel id="demo-simple-select-outlined-label-2">Redirigir a:</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label-2"
                                                id="demo-simple-select-outlined-2"
                                                value={values.event_id}
                                                onChange={handleChange('event_id')}
                                                label="Redirigir a:"
                                            >
                                                {availableEvents.map((group) => (
                                                    <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                                                ))}

                                                {availableEvents?.length === 0 && (
                                                    <MenuItem disabled value="0">
                                                        <em>No hay eventos disponibles</em>
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        <h6 className="mt-3 mb-2">Texto</h6>
                                        <TextField label="Titulo"  value={values.title} onChange={handleChange('title')} fullWidth variant="outlined" />
                                        <TextField label="Cuerpo "  value={values.body} rows={4} multiline onChange={handleChange('body')} className="mt-2" fullWidth variant="outlined" />
                                        <h6 className="mt-3 mb-2">Footer</h6>
                                        <TextField label="Nombre de la Productora" value={values.producerName} onChange={handleChange('producerName')} className="mb-2" fullWidth variant="outlined" />
                                        <TextField label="Whatsapp" value={values.whatsapp} onChange={handleChange('whatsapp')} className="mb-2" fullWidth variant="outlined" error={errors.whatsapp} helperText={errorMessages.whatsapp} />
                                        <TextField label="Instagram" value={values.instagram} onChange={handleChange('instagram')} className="mb-2" fullWidth variant="outlined" error={errors.instagram} helperText={errorMessages.instagram} />
                                        <TextField label="Facebook" value={values.facebook} onChange={handleChange('facebook')} className="mb-2" fullWidth variant="outlined" error={errors.facebook} helperText={errorMessages.facebook} />
                                        <h6 className="mt-1 mb-2">Destinatarios</h6>
                                        <FormControl variant="outlined" className="mb-2 mt-2" fullWidth>
                                            <InputLabel id="demo-simple-select-outlined-label">Grupo</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={values.group}
                                                onChange={handleChange('group')}
                                                label="Grupo"
                                            >
                                                <MenuItem value="0">
                                                    <em>Ninguno</em>
                                                </MenuItem>
                                                {groups?.length > 0 && groups.map((group) => (
                                                    <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                                                ))}

                                                {groups?.length === 0 && (
                                                    <MenuItem disabled value="-1">
                                                        <em>No hay grupos</em>
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <button type="submit" className="btn btn-primary w-100">{props.campaign ? "Editar campaña" : "Crear campaña"}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    }
};

export default CreateCampaign;