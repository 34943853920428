import React, {useState} from 'react';
import {
    Alert,
    Button, CircularProgress,
    Snackbar,
    TextField
} from "@mui/material";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {useNavigate} from "react-router";
import {Table} from "react-bootstrap";
import ButtonContainer from "./ButtonContainer";
import {RiDeleteBin6Line, RiFileEditLine} from "react-icons/ri";
import {HiPlusCircle} from "react-icons/hi";
import {FiEdit} from "react-icons/fi";
import {FaUpload, FaUserEdit} from "react-icons/fa";
import {TiUserDelete} from "react-icons/ti";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import FileInput from "./FileInput";
import {IoClose} from "react-icons/io5";
import {Button as ButtonBT} from "react-bootstrap";



const WhitelistAdd = (props) => {

    const user = useSelector(selectUser);
    const navigate = useNavigate();

    const [values, setValues] = useState({
        name: '',
        email: '',
    });

    const [auxEmail, setAuxEmail] = useState('');
    const [edit, setEdit] = useState(false);
    const [manualInput, setManualInput] = useState(false);

    const [emails, setEmails] = useState([]);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const swalAlert = (attendee) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, borrarlo!'
        }).then((result) => {
            if (result.isConfirmed) {
                //console.log("Borrando");
                }
            })
    }

    function getViewport () {
        // https://stackoverflow.com/a/8876069
        const width = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
        )

        if (width <= 992) return 'md'
        return 'xl'
    }
    function searchData() {
        // Declare variables
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();

        if (getViewport() == 'xl') {
            table = document.getElementsByTagName("table")[0];

            tr = table.getElementsByTagName("tr");

            // Loop through all table rows, and hide those who don't match the search query
            for (i = 0; i < tr.length; i++) {
                var td0 = tr[i].getElementsByTagName("td")[0];

                if (td0) {
                    var txtValue0 = td0.textContent || td0.innerText;
                    if (txtValue0.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                    } else {
                        tr[i].style.display = "none";
                    }
                }
            }
        } else {
            table = document.getElementsByTagName("table")[1];

            tr = table.getElementsByTagName("tr");

            // Loop through all table rows, and hide those who don't match the search query
            for (i = 0; i < tr.length; i++) {
                var td0 = tr[i].getElementsByTagName("td")[0];
                if (td0) {
                    var txtValue0 = td0.textContent || td0.innerText;
                    if (txtValue0.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                    } else {
                        tr[i].style.display = "none";
                    }
                }
            }
        }

    }

    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const editEmail = (email) => {
        setAuxEmail(email);
        setValues({ ...values, email: email });
        setEdit(true);
    }

    const deleteEmail = (email) => {
        setEmails(emails.filter(item => item !== email));
    }

    const addWhitelist = async (e) => {
        e.preventDefault();

        const request = await fetch(`${process.env.REACT_APP_API_URL}/whitelist-group`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                name: values.name,
                emails: emails,
            })
        })

        const response = await request.json();

        if (response.status === 'success') {
            setOpenS(true);
            setTimeout(() => {
                navigate('/organizations/whitelist');
            }, 2000);
        }
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const handleUpload = (event) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: 'array' });
            let firstSheet = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[firstSheet];
            let newEmails = XLSX.utils.sheet_to_csv(worksheet).split('\n');
            newEmails = newEmails.filter((email) => email !== '');
            let validEmails = [];
            for (let email of newEmails) {
                let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (emailRegex.test(email)) {
                    validEmails.push(email);
                }
            }
            setEmails([...emails, ...validEmails]);
            alert(`Se agregaron ${validEmails.length} nuevos emails.`);
        };
        reader.readAsArrayBuffer(file);
    };



    const [isLoaded, setIsLoaded] = useState(true);

    if (!isLoaded) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                <CircularProgress color="secondary" size={100} />
            </div>
        )
    }
    else {
    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    Lista actualizada con éxito!
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    Hubo un error al actualizar la lista!
                </Alert>
            </Snackbar>

            <div className="row align-items-center">
                <div className="col-6">
                    <h1>Agregar Whitelist</h1>
                </div>
                <div className="col justify-content-end d-flex text-end ms-2">
                    <ButtonBT variant="outline-dark" onClick={() => {navigate(`./import`)}}>
                        <FaUpload />
                        &nbsp; Cargar desde Excel
                    </ButtonBT>
                    {/*<FileInput handleUpload={handleUpload} */}
                </div>
            </div>
            <form onSubmit={addWhitelist}>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <TextField label="Nombre de la lista" value={values.name} onChange={handleChange('name')} fullWidth variant="outlined" />
                    </div>
                </div>

                {!manualInput ? (
                    <div className="row">
                        <div className="col-12">
                            <Button
                                variant="text"
                                color="primary"
                                className="me-2"
                                onClick={() => setManualInput(true)}
                            >
                                + Agregar Email Manualmente
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-md-6">
                            <div className="whitelist-email-input-container">
                                <div className="closeIconDark p-1 align-items-center d-flex justify-content-center">
                                    <IoClose size={25} color={"rgba(0, 0, 0, 0.38)"} onClick={() => {
                                        setManualInput(false);
                                        setEdit(false);
                                        setValues({ ...values, email: "" });
                                    }}/>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h5>{!edit ? "Agregar a la lista" : "Editar email"}</h5>
                                        <TextField label="Email" value={values.email} onChange={handleChange('email')} className="mb-1" id={"whitelist-email-input-1"} fullWidth variant="outlined" />
                                        {edit === false && (
                                            <>
                                                {emails.includes(values.email.trim()) ? <p style={{color: "red", marginBottom: "0.1rem"}}>El email ingresado ya existe</p> : null}
                                                {validateEmail(values.email) === false && values.email !== "" ? <p style={{color: "red", marginBottom: "0.1rem"}}>El email ingresado no es válido</p> : null}
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    className="me-2"
                                                    onClick={() => {
                                                        if (validateEmail(values.email) === false) {
                                                            return;
                                                        } else if (emails.includes(values.email.trim())) {
                                                            return;
                                                        } else if (values.email === "") {
                                                            return;
                                                        } else {
                                                            setEmails([...emails, values.email.trim()]);
                                                            setValues({ ...values, email: '' });
                                                        }
                                                    }}
                                                >
                                                    + Agregar Email
                                                </Button>
                                            </>
                                        )}
                                        {edit === true && (
                                            <Button
                                                variant="text"
                                                color="primary"
                                                className="me-2"
                                                onClick={() => {
                                                    if (validateEmail(values.email) === false) {
                                                        return;
                                                    } else if (emails.includes(values.email.trim())) {
                                                        return;
                                                    } else if (values.email === "") {
                                                        return;
                                                    } else {

                                                        const NewEmails = emails.filter((email) => email !== auxEmail);
                                                        NewEmails.push(values.email.trim());
                                                        setEmails(NewEmails);
                                                        setValues({ ...values, email: '' });
                                                    }
                                                    setEdit(false);
                                                }}
                                            >
                                                Editar Email
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="row mt-3 mb-3">
                    <div className="col">
                        <TextField id="myInput" fullWidth onKeyUp={searchData} label="Buscar por nombre" variant="outlined" />
                    </div>
                </div>

                <div className="col-12 d-none d-md-block table-sm" style={{maxHeight: "80vh", overflowY: "scroll"}}>
                    <Table striped bordered responsive id="myTable">
                        <thead>
                        <tr>
                            <th>Emails</th>
                            <th style={{maxWidth: "80px", minWidth: "80px"}}>Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {emails.map((attendee, key) => (
                            <tr key={key} style={{fontSize: "12px"}}>
                                <td>{attendee.toLowerCase()}</td>
                                <td style={{maxWidth: "80px", minWidth: "80px"}}>
                                    <div className="row align-items-center justify-content-center">
                                        <ButtonContainer title="Editar" click={() => editEmail(attendee) } link={"#"} icon={<RiFileEditLine size={15} />} />
                                        <ButtonContainer click={() => deleteEmail(attendee) } title={"Eliminar"} link={`#`} icon={<RiDeleteBin6Line size={20} />} />
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>

                <div className="col-12 d-block d-md-none" style={{maxHeight: "80vh", overflowY: "scroll"}}>
                    <Table striped responsive bordered size="sm" id="myTable">
                        <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {emails.map((attendee, key) => (
                            <tr key={key} style={{fontSize: "10px"}}>
                                <td style={{wordWrap: "break-word", maxWidth: "150px"}}>{attendee.toLowerCase()}</td>
                                <td style={{wordWrap: "break-word", minWidth: "180px"}}>
                                    <div className="row align-items-center justify-content-center">
                                        <ButtonContainer title="Editar" click={() => editEmail(attendee) } link={"#"} icon={<RiFileEditLine size={15} />} />
                                        <ButtonContainer click={() => deleteEmail(attendee) } title={"Eliminar"} link={`#`} icon={<RiDeleteBin6Line size={20} />} />
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
                <div className="row mt-5 mb-5">
                    <div className="col-md-6">
                        <Button variant="contained" type="submit" color="primary" className="me-2">
                            Agregar Lista
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
    }
};

export default WhitelistAdd;
